import React from 'react';
import moment from 'moment';
import * as Common from '../../common/Common';
import { useNavigate } from 'react-router-dom';
import Status from '../../../../common/Status';
import * as Actions from "../../../../../state/Actions";
import CustomTitle from '../../../../common/CustomTitle';
import CommonSearch from '../../../../common/CommonSearch';
import { routesName } from '../../../../../config/routesName';
import ListItemActions from '../../../../common/ListItemActions';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { NoDataComponent } from '../../../../common/NoDataComponent';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import CommonPreviewShare from '../../../../common/CommonPreviewShare';
import { HeadingWithSortable } from '../../../../common/SortableHeading';
import CommonAsyncDropdown from '../../../../common/CommonAsyncDropdown';
import CommonDownloadLayout from '../../../../common/CommonDownloadLayout';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import { eventsNames, AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';
import CustomDateRangePicker from '../../../../custom/Datepicker/CustomDateRangePicker';

import {
    ActionTextLinkBtn,
    SalesOrderViewLink,
} from '../../common/CommonLinks';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
    apiAction,
    apiBlobResponse,
    apiHandleDownload,
} from '../../../../../api/api';

import {
    setLoader,
    getDateFormat,
    setFilterMessage,
    isFiltersApplied,
    stateChangeManager,
} from '../../../../../utils/Utils';

import {
    list_party,
    delete_sales_order,
    preview_sales_order,
    retrieve_sales_order,
    mark_sales_order_sent,
    get_list_of_sales_order,
} from '../../../../../api/urls';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';

import {
    Box,
    Grid,
} from '@mui/material';
import { CommonWebSocket } from '../../../../../api/webSocket';
import CurrencyFormatterWithExchangeRate from '../../common/CurrencyFormatterWithExchangeRate';

const ListSalesOrder = () => {
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [page, setPage] = React.useState(1);
    const [results, setResults] = React.useState([])
    const [reLoad, setReLoad] = React.useState(false)
    const [disabledDataExport, setDisabledDataExport] = React.useState(false);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    })


    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })

    const webSocketResponse = CommonWebSocket();
    React.useEffect(() => {
        if (webSocketResponse) {
            let webSocketData = JSON.parse(webSocketResponse.data)
            if (webSocketData.document_type === "sales_order") {
                let index = results.findIndex((item) => item.id === webSocketData.object_id);

                if (webSocketData.event === "send_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        results[index]['status'] = webSocketData.object_status
                        setResults([...results])
                        stateChangeManager(dispatch, Actions, true, "success", `Email delivered successfully for ${webSocketData.object_number}`);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Email delivery failed for ${webSocketData.object_number}`);
                    }
                }

                if (webSocketData.event === "delete_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Sales Order ${webSocketData.object_number} deleted successfully`);
                        getApiResults(body, page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to delete Sales Order ${webSocketData.object_number}`);
                    }
                }

                if (webSocketData.event === "update_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Sales Order ${webSocketData.object_number} updated successfully`);
                        getApiResults(body, page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to update Sales Order ${webSocketData.object_number}`);
                    }
                }

            }
        }
    }, [webSocketResponse])

    const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage) => {
        setState({
            url: url,
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            deleteMessage: deleteMessage,
            onDeleteAction: () => { getApiResults(body, page) }

        })
    }

    const [filters, setFilters] = React.useState({
        sort_by: 'sales_order_date',
        sort_order: 'D'
    })

    let body = { role: "customer", business_id: getBusinessInfo().id, ...filters }
    React.useEffect(() => {
        getApiResults(body, page)
        // eslint-disable-next-line
    }, [filters, page, reLoad])

    const getApiResults = async (body, page) => {
        setLoader(dispatch, Actions, true);
        setFilterMessage(dispatch, Actions, null);
        setDisabledDataExport(false);
        let res = await apiAction({
            data: body,
            method: 'post',
            dispatch: dispatch,
            navigate: navigate,
            url: get_list_of_sales_order(page),
        })

        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.next,
                count: res?.result.count,
                previous: res?.result.previous,
                number_of_pages: res?.result.number_of_pages,
            });
            setResults(res?.result.result);
            setLoader(dispatch, Actions, false);
            if (res?.result.count === 0) { setDisabledDataExport(true) }
            if (isFiltersApplied(filters)) { setFilterMessage(dispatch, Actions, `No sales orders found for your current filters. Verify your filters and try again.`) };
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    React.useEffect(() => {
        const statusMapping = {
            sent: eventsNames.actions.salesOrder.filter_type.SENT,
            unsent: eventsNames.actions.salesOrder.filter_type.UNSENT,
            closed: eventsNames.actions.salesOrder.filter_type.CLOSED,
        };

        const filter_type = filters.status
            ? statusMapping[filters.status]
            : eventsNames.actions.salesOrder.filter_type.ALL;

        AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.CLICK, filter_type: filter_type });
    }, [filters.status]);

    const [sendMail, setSendMail] = React.useState(false);
    const [sendMailId, setSendMailId] = React.useState(false);
    const [isDownload, setIsDownload] = React.useState(false);

    return (
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <CommonPreviewShare
                open={sendMail}
                id={sendMailId}
                type={'sales_order'}
                file_key={'sales_order_number'}
                export_Url={preview_sales_order}
                retrieve_Url={retrieve_sales_order}
                setOpen={(value) => { setSendMail(value); setReLoad(!reLoad); setSendMailId(null) }}
            />
            <CommonDownloadLayout
                open={isDownload}
                setOpen={setIsDownload}
                filters={filters}
                componentType={"sales_order"}
            />
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                        <CustomTitle title={'Sales Orders'} />
                    </Grid>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', gap: 1 }}>
                        <CustomButton
                            variant="contained"
                            btnLabel='Create Sales Order'
                            id={'create_sales_order_btn'}
                            sx={{ textTransform: 'none', }}
                            dataTestId={'create_sales_order_btn'}
                            onClick={() => {
                                navigate(routesName.invoicingSalesOrderAdd.path)
                                AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.NEW })
                            }}
                        />
                        <CustomButton
                            disabled={disabledDataExport}
                            variant="contained"
                            btnLabel='Download CSV'
                            id={'download_sales_order_btn'}
                            sx={{ textTransform: 'none', }}
                            dataTestId={'download_sales_order_btn'}
                            onClick={() => {
                                setIsDownload(true);
                                AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.DOWNLOAD })
                            }}
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{ maxHeight: { xs: 'calc(100vh - 240px)', sm: '100%' } }}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '24px', display: '-webkit-flex', }}>
                    <Filters stateChangeHandler={stateChangeHandler} filters={filters} setFilters={setFilters} setPage={setPage} />
                </div>

                <CustomTableContainer>
                    <CustomTable sx={{ mt: 2, }}>
                        <ListSalesOrderHeaders filters={filters} setFilters={setFilters} />
                        <ListSalesOrderBody setSendMail={setSendMail} setSendMailId={setSendMailId} data={results} stateChangeHandler={stateChangeHandler} reload={() => setReLoad(!reLoad)} />
                    </CustomTable>
                    <Box sx={{ pt: !results.length ? 10 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>

            <CustomPagination
                page={page}
                count={pagination.number_of_pages}
                onChange={(event, newValue) => setPage(newValue)}
            />
        </div>
    )
}

export default ListSalesOrder;

const ListSalesOrderHeaders = (props) => {
    const { filters, setFilters } = props

    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell style={{ width: 100 }} align='center'><span style={{ cursor: '' }}>Status </span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 100 }}><HeadingWithSortable heading={'Date'} sortableKey={'sales_order_date'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 120 }}><HeadingWithSortable heading={'Order Number'} sortableKey={'sales_order_number'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 210 }}><HeadingWithSortable heading={'Customer'} sortableKey={'customer_display_name'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 150 }} align='right' ><HeadingWithSortable heading={'Total'} sortableKey={'total_amount'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} align='right' ><span>Actions</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const ListSalesOrderBody = (props) => {
    const { data, stateChangeHandler, reload, setSendMailId, setSendMail } = props

    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const onSelectAction = (action, item) => {
        setLoader(dispatch, Actions, true)
        if (action === "edit") {
            navigate(routesName.invoicingSalesOrderAdd.path + "?id=" + item.id)
        }
        if (action === "view") {
            navigate(routesName.invoicingSalesOrderView.path + "?id=" + item.id)
            AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.VIEW })
        }
        if (action === "send") {
            sendSalesOrder(item.id)
            AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.SEND })
        }
        if (action === "mark_sent") {
            markSent(item.id)
            AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.MARK_SENT })
        }
        if (action === "print") {
            printSalesOrder(item)
            AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.PRINT })
        }
        if (action === "download") {
            downloadSalesOrder(item)
            AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.DOWNLOAD })
        }
        if (action === "convert") {
            setLoader(dispatch, Actions, false)
            navigate(routesName.invoicingSalesInvoiceAdd.path + "?sales_id=" + item.id)
            AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.salesOrder.action.CONVERT_INVOICE })
        }
    }


    const sendSalesOrder = async (id) => {
        setSendMail(true);
        setSendMailId(id);
    }

    const markSent = async (id) => {
        const sentRes = await apiAction({ url: mark_sales_order_sent(id), method: 'POST', data: { business_id: getBusinessInfo().id } })
        if (sentRes.success) {
            stateChangeManager(dispatch, Actions, true, 'success', sentRes.status);
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', sentRes.status);
        }
    }
    const printSalesOrder = async (salesOrder) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_sales_order(salesOrder.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            apiBlobResponse({
                url: preview_sales_order(), data: { ...data.result, terms_and_conditions_id: data.result.terms_and_conditions }, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }

    const downloadSalesOrder = async (salesOrder) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_sales_order(salesOrder.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            apiHandleDownload({
                url: preview_sales_order(), data: { ...data.result, terms_and_conditions_id: data.result.terms_and_conditions }, filename: data.result.sales_order_number, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }


    const toActionText = (status) => {
        let text = ''
        if (status === 'unsent') {
            text = 'Send'
        } else if (status === 'sent') {
            text = 'Convert to invoice'
        } else if (status === 'closed') {
            text = 'View'
        }
        return text
    }

    const onClickActionText = (status, item) => {
        if (status === 'unsent') {
            onSelectAction("send", item);
        } else if (status === 'sent') {
            onSelectAction("convert", item);
        } else if (status === 'closed') {
            onSelectAction("view", item);
        }
    }


    return (
        <CustomTableBody>
            {
                data.map((item, index) => {
                    // console.log('SalesOrderListBody', item,)
                    return (
                        <CustomTableRow key={index}>
                            <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}}  ><span>{moment(item.sales_order_date).format(getDateFormat())}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}}  ><SalesOrderViewLink id={item.id} title={item.sales_order_number} /></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}}  ><span>{item.customer_display_name}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>
                                <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                            </CustomTableBodyCell>

                            <CustomTableBodyCell sx={{}} align='right'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <ActionTextLinkBtn
                                        index={index}
                                        toActionText={toActionText(item.status)}
                                        onClickActionText={() => onClickActionText(item.status, item)} />
                                    <ListItemActions
                                        index={index}
                                        actions={
                                            [
                                                item.status !== 'closed' && { name: 'View', onClick: () => { onSelectAction("view", item); } },
                                                item.status !== 'closed' && { name: 'Edit', onClick: () => { onSelectAction("edit", item); } },
                                                item.status !== 'unsent' && { name: 'Resend', onClick: () => { onSelectAction("send", item); } },
                                                { name: 'Print', onClick: () => { onSelectAction("print", item); } },
                                                item.status === 'unsent' && { name: 'Mark as sent', onClick: () => { onSelectAction("mark_sent", item); } },
                                                { name: 'Export as PDF', onClick: () => { onSelectAction("download", item); } },
                                                { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Sales Order', 'delete', 'sm', delete_sales_order(item.id), `The sales order will be deleted and can not be retrieved later.\n Are you sure about deleting it?`); AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.DELETE }) } }
                                            ]
                                        }
                                    />
                                </Box>
                            </CustomTableBodyCell>
                        </CustomTableRow>
                    )
                })
            }
        </CustomTableBody>
    )
}

const Filters = (props) => {
    const { setPage, stateChangeHandler, setFilters, filters } = props

    const [customer, setCustomer] = React.useState(null)

    const onDateRangeChange = (dates) => {
        setPage(1);
        if (dates) {
            setFilters({ ...filters, sales_order_start_date: dates[0].format("YYYY-MM-DD"), sales_order_end_date: dates[1].format("YYYY-MM-DD"), dates: dates })
        } else {
            delete filters.sales_order_start_date
            delete filters.sales_order_end_date
            delete filters.dates
            setFilters({ ...filters })
        }

    }

    return (
        <Grid item xs={12} container spacing={1}>
            <Grid item xs={6} sm={2.5} >
                <Common.FilterStatus
                    filters={filters}
                    setPage={setPage}
                    setFilters={setFilters}
                    dataKey={'salesOrderStatus'}
                />
            </Grid>

            <Grid item xs={6} sm={2.5}>
                <CommonAsyncDropdown
                    id={'customer_dropdown'}
                    dataTestId={'customer_dropdown'}
                    autoSelect={false}
                    disableClearable={false}
                    optionLabel="display_name"
                    placeholder='Select Customer'
                    noOptionsText={"No result found"}

                    item={{
                        method: 'post',
                        value: customer,
                        label: 'Customers',
                        url: list_party(1),
                        body: { is_inactive: false, business_id: getBusinessInfo().id, role: 'customer' },
                        onChange: (event, value) => {
                            setPage(1);
                            if (value) {
                                setFilters({ ...filters, customer_id: value.id, selectedCustomer: value })
                            } else {
                                delete filters.customer_id
                                delete filters.selectedCustomer
                                setFilters({ ...filters })
                            }

                            setCustomer(value)
                        },
                    }}
                    addButton={{
                        title: '+ Add new customer',
                        onClick: () => stateChangeHandler('New Customer', 'new_customer', 'lg')
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4.5} container>
                <CustomDateRangePicker onDateRangeChange={onDateRangeChange} />
            </Grid>

            <Grid item xs={12} sm={2.5}>
                <CommonSearch
                    id={'input_search_number'}
                    dataTestId={'input_search_number'}
                    title={'Search'}
                    fullWidth={true}
                    titleVisibility={'hidden'}
                    placeholder={'Enter Order Number #'}
                    onSearchValue={filters.sales_order_number ? filters.sales_order_number : ''}
                    onSearch={(search_text) => {
                        setPage(1);
                        setFilters({ ...filters, sales_order_number: search_text })
                    }} />
            </Grid>
        </Grid>
    )
}
