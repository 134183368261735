import React from 'react';
import { ws_document_status } from './socketUrl';
import { getBusinessInfo } from '../config/cookiesInfo';

export const CommonWebSocket = (socketUrl = ws_document_status()) => {
  const [lastMessage, setLastMessage] = React.useState(null);
  const [connectionStatus, setConnectionStatus] = React.useState('connecting'); // Initialize with 'connecting'

  React.useEffect(() => {
    const socket = new WebSocket(socketUrl);

    socket.onopen = () => {
      setConnectionStatus('open'); // Update connection status on open
    };

    socket.onmessage = (event) => {
      setLastMessage(event);
    };

    socket.onclose = () => {
      setConnectionStatus('closed'); // Update connection status on close
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      setConnectionStatus('error'); // Update connection status on error
    };

    return () => {
      socket.close();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBusinessInfo()?.id]);
  // console.log('connectionStatus', connectionStatus);
  return lastMessage;
};







