import React from 'react';
import moment from 'moment';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import { useNavigate, useLocation } from 'react-router-dom';
import { routesName } from '../../../../config/routesName';
import { CommonWebSocket } from '../../../../api/webSocket';
import { CustomLink } from '../../../custom/link/CustomLink';
import ListItemActions from '../../../common/ListItemActions';
import CustomButton from '../../../custom/button/CustomButton';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { NoDataComponent } from '../../../common/NoDataComponent';
import CommonPreviewShare from '../../../common/CommonPreviewShare';
import { eventsNames } from '../../../../firebase/firebaseAnalytics';
import CommonAsyncDropdown from '../../../common/CommonAsyncDropdown';
import { HeadingWithSortable } from '../../../common/SortableHeading';
import CommonDownloadLayout from '../../../common/CommonDownloadLayout';
import CustomPagination from '../../../custom/pagination/CustomPagination';
import CustomDateRangePicker from '../../../custom/Datepicker/CustomDateRangePicker';

import {
    payment_made_number,
    payment_received_number,
} from '../../../../utils/Constant';

import {
    PaymentMadeViewLink,
    PaymentReceivedViewLink,
} from '../common/CommonLinks';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../custom/container/CustomContainer';

import {
    apiAction,
    apiBlobResponse,
    apiHandleDownload
} from '../../../../api/api';

import {
    setLoader,
    getDateFormat,
    getPaymentType,
    setFilterMessage,
    isFiltersApplied,
    stateChangeManager,
    get_last_12_month_date,
} from '../../../../utils/Utils';

import {
    list_party,
    delete_payment,
    retrieve_payment,
    get_list_of_payment,
    export_payment_receipt,
    send_payment_receipt_mail
} from '../../../../api/urls';

import {
    Box,
    Grid,
} from '@mui/material';



import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';
import CurrencyFormatterWithExchangeRate from '../common/CurrencyFormatterWithExchangeRate';


const PaymentList = (props) => {
    const { title, btnLabel, btnClick, viewURL, columns, body, AnalyticsEvent } = props

    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext)

    const [page, setPage] = React.useState(1);
    const [results, setResults] = React.useState([]);
    const [disabledDataExport, setDisabledDataExport] = React.useState(false);

    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    });

    const [filters, setFilters] = React.useState({
        sort_order: 'D',
        sort_by: 'payment_date',
    });

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    });

    let payment_number = body.payment_type === 'pay' ? payment_made_number : payment_received_number;

    const webSocketResponse = CommonWebSocket();
    React.useEffect(() => {
        if (webSocketResponse) {
            let webSocketData = JSON.parse(webSocketResponse.data)
            if (webSocketData.document_type === "payment") {
                let index = results.findIndex((item) => item.id === webSocketData.object_id);

                if (webSocketData.event === "delete_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Payment ${payment_number}-${webSocketData.object_id} deleted successfully`);
                        getApiResults(page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to delete Payment ${payment_number}-${webSocketData.object_id}`);
                    }
                }

                if (webSocketData.event === "update_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Payment ${payment_number}-${webSocketData.object_id} updated successfully`);
                        getApiResults(page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to update Payment ${payment_number}-${webSocketData.object_id}`);
                    }
                }
            }
        }
    }, [webSocketResponse])

    const stateChangeHandler = (title, condition, maxWidth, id, item, url, deleteMessage) => {
        setState({
            id: id,
            url: url,
            open: true,
            item: item,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            deleteMessage: deleteMessage,
            onDeleteAction: () => { getApiResults(page) }
        })
    };

    React.useEffect(() => {
        getApiResults(page);
        // eslint-disable-next-line
    }, [page, filters]);

    const getApiResults = async (page) => {
        setLoader(dispatch, Actions, true);
        setFilterMessage(dispatch, Actions, null);
        setDisabledDataExport(false);
        let res = await apiAction({
            method: 'post',
            dispatch: dispatch,
            navigate: navigate,
            url: get_list_of_payment(page),
            data: { ...body, business_id: getBusinessInfo().id, ...filters },
        })
        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.next,
                count: res?.result.count,
                previous: res?.result.previous,
                number_of_pages: res?.result.number_of_pages,
            })
            setResults(res?.result.result)
            setLoader(dispatch, Actions, false);
            if (res?.result.count === 0) { setDisabledDataExport(true) }
            if (isFiltersApplied(filters)) { setFilterMessage(dispatch, Actions, `No payments found for your current filters. Verify your filters and try again.`) };
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    const sendPaymentReceiptMail = async (payment_id) => {
        setSendMail(true);
        setSendMailId(payment_id);
        // const res = await apiAction({ url: send_payment_receipt_mail(), method: 'POST', data: { business_id: getBusinessInfo().id, payment_id: payment_id } })
        // if (res?.success) {
        //     stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        // }
    }

    const printPaymentReceipt = async (payment_id) => {
        apiBlobResponse({
            url: export_payment_receipt(), data: { business_id: getBusinessInfo().id, payment_id: payment_id }, onSuccess: () => {
                setLoader(dispatch, Actions, false)
            }
        })
    }
    const downloadPaymentReceipt = async (payment_id) => {
        apiHandleDownload({
            url: export_payment_receipt(), data: { business_id: getBusinessInfo().id, payment_id: payment_id }, filename: `${payment_number}-${payment_id}`, onSuccess: () => {
                setLoader(dispatch, Actions, false)
            }
        })
    }

    const [sendMail, setSendMail] = React.useState(false);
    const [sendMailId, setSendMailId] = React.useState(false);
    const [isDownload, setIsDownload] = React.useState(false);

    return (
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <CommonPreviewShare
                open={sendMail}
                id={sendMailId}
                type={'payment'}
                retrieve_Url={retrieve_payment}
                export_Url={export_payment_receipt}
                setOpen={(value) => { setSendMail(value); getApiResults(page); setSendMailId(null) }}
                file_key={body.payment_type === 'pay' ? `${payment_made_number}-${sendMailId}` : `${payment_received_number}-${sendMailId}`}
            />
            <CommonDownloadLayout
                open={isDownload}
                setOpen={setIsDownload}
                filters={filters}
                componentType={'payment'}
                payment_type={body.payment_type}
            />
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={7} sm={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                        <CustomTitle title={title} />
                    </Grid>
                    <Grid item xs={5} sm={6} sx={{ display: '-webkit-flex', justifyContent: 'end', gap: 1 }}>
                        <CustomButton
                            variant="contained"
                            btnLabel={btnLabel}
                            id={'add_payments_btn'}
                            dataTestId={'add_payments_btn'}
                            sx={{ textTransform: 'none', }}
                            onClick={() => {
                                navigate(btnClick)
                                AnalyticsEvent({ action: eventsNames.actions.NEW })
                            }}
                        />
                        <CustomButton
                            disabled={disabledDataExport}
                            variant="contained"
                            btnLabel={"Download CSV"}
                            id={'download_payments_btn'}
                            dataTestId={'download_payments_btn'}
                            sx={{ textTransform: 'none', }}
                            onClick={() => {
                                setIsDownload(true);
                                AnalyticsEvent({ action: eventsNames.actions.DOWNLOAD })
                            }}
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{maxHeight:{xs:'calc(100vh - 240px)',sm:'100%'}}}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '24px', display: '-webkit-flex', }}>
                    <Filters filters={filters} setFilters={setFilters} stateChangeHandler={stateChangeHandler} type={body.payment_type === 'pay'} setPage={setPage} />
                </div>

                <CustomTableContainer>
                    <CustomTable sx={{}}>
                        <PaymentHeader columns={columns} filters={filters} setFilters={setFilters} />
                        <PaymentBody
                            rows={results}
                            viewURL={viewURL}
                            editURL={btnClick}
                            requestFrom={body.payment_type}
                            AnalyticsEvent={AnalyticsEvent}
                            stateChangeHandler={stateChangeHandler}
                            printPaymentReceipt={printPaymentReceipt}
                            sendPaymentReceiptMail={sendPaymentReceiptMail}
                            downloadPaymentReceipt={downloadPaymentReceipt}
                        />
                    </CustomTable>
                    <Box sx={{ pt: !results.length ? 10 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>

            <CustomPagination
                page={page}
                count={pagination.number_of_pages}
                onChange={(event, newValue) => setPage(newValue)}
            />
        </div>
    )
}

const PaymentHeader = (props) => {
    const { columns, filters, setFilters } = props;

    return (
        <CustomTableHead>
            <CustomTableRow >
                {columns.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            {
                                item.sortable ?
                                    <CustomTableHeadCell style={item.width} align={item.align}><HeadingWithSortable heading={item.label} sortableKey={item.field} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                                    :
                                    <CustomTableHeadCell style={item.width} align={item.align}><span style={{ display: 'inline' }} >{item.label}</span></CustomTableHeadCell>
                            }
                        </React.Fragment>
                    )
                })}
            </CustomTableRow>
        </CustomTableHead>
    )
}

const PaymentBody = (props) => {
    let navigate = useNavigate();
    const { editURL, viewURL, rows, stateChangeHandler, sendPaymentReceiptMail, printPaymentReceipt, downloadPaymentReceipt, requestFrom,AnalyticsEvent } = props

    return (
        <CustomTableBody>
            {
                rows.map((item, index) => {
                    return (
                        <CustomTableRow key={index}>
                            <CustomTableBodyCell sx={{}} ><span>{moment(item.payment_date).format(getDateFormat())}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} >
                                {requestFrom === "pay" ?
                                    <PaymentMadeViewLink id={item.id} title={item.id} />
                                    :
                                    <PaymentReceivedViewLink id={item.id} title={item.id} />
                                }
                            </CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><span>{item.party_display_name}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} >{item.payment_for_reference_number.map((value, index) => { return (<span key={index}>{index ? ', ' : ''}{value}</span>) })}</CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} >
                                <p style={{ margin: '0px', marginBottom: '5px' }}>{item.payment_method ? getPaymentType(item.payment_method)?.name : '-'}</p>
                                {
                                    item.is_advance &&
                                    <span style={{ fontSize: '10px', opacity: 0.6, }}>Advance Payment</span>
                                }

                            </CustomTableBodyCell>
                            {/* <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter currency={item.currency_code} amount={item.total_amount} />}</span></CustomTableBodyCell> */}
                            <CustomTableBodyCell sx={{}} align='right'>
                                <span>{<CurrencyFormatter currency={item.currency_code} amount={item.total_amount} />}</span><br />
                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                            </CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>
                                <span>{<CurrencyFormatter currency={item.currency_code} amount={item.balance_amount} />}</span><br />
                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.balance_amount} exchange_rate={item.exchange_rate} />
                            </CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>


                                <ListItemActions
                                    index={index}
                                    actions={
                                        [
                                            {
                                                name: 'View', onClick: () => {
                                                    navigate(viewURL + "?payment_id=" + item.id, { replace: false });
                                                    AnalyticsEvent({ action: eventsNames.actions.VIEW })
                                                }
                                            },
                                            item.is_advance ?
                                                {
                                                    name: 'Edit', onClick: () => {
                                                        navigate(editURL + "?advance_payment_id=" + item.id, { replace: false });
                                                    }
                                                }
                                                : {
                                                    name: 'Edit', onClick: () => {
                                                        navigate(editURL + "?payment_id=" + item.id, { replace: false });
                                                    }
                                                },
                                            item.balance_amount > 0 && {
                                                name: 'Refund', onClick: () => {
                                                    AnalyticsEvent({ action: eventsNames.actions.REFUND })

                                                    item.payment_type === "pay" ?
                                                        navigate(routesName.invoicingPaymentsMadeRefund.path + "?payment_id=" + item.id, { state: routesName.invoicingPaymentsMadeList.path, replace: false })
                                                        :
                                                        navigate(routesName.invoicingPaymentsReceivedRefund.path + "?payment_id=" + item.id, { state: routesName.invoicingPaymentsReceivedList.path, replace: false })
                                                }
                                            },
                                            {
                                                name: 'Send', onClick: () => {
                                                    sendPaymentReceiptMail(item.id);
                                                    AnalyticsEvent({ action: eventsNames.actions.SEND })

                                                }
                                            },
                                            {
                                                name: 'Print', onClick: () => {
                                                    printPaymentReceipt(item.id);
                                                    AnalyticsEvent({ action: eventsNames.actions.PRINT })

                                                }
                                            },
                                            {
                                                name: 'Export as PDF', onClick: () => {
                                                    downloadPaymentReceipt(item.id);
                                                    AnalyticsEvent({ action: eventsNames.actions.DOWNLOAD })
                                                }
                                            },
                                            {
                                                name: 'Delete', showDivider: true, onClick: () => {
                                                    stateChangeHandler('Delete Payment', 'delete', 'sm', item.id, item, delete_payment(item.id), `Are you sure about deleting the selected payment?`);
                                                    AnalyticsEvent({ action: eventsNames.actions.DELETE })

                                                }
                                            }
                                        ]
                                    }
                                />
                            </CustomTableBodyCell>
                        </CustomTableRow>
                    )
                })
            }
        </CustomTableBody>
    )
}

const Filters = (props) => {
    let location = useLocation();
    const { setPage, type, setFilters, filters, stateChangeHandler } = props

    let [dates, setDate] = React.useState();
    const [partyData, setPartyData] = React.useState(null)


    const onDateRangeChange = (dates) => {
        setPage(1);
        if (dates) {
            filters['payment_date_end_date'] = dates[1].format("YYYY-MM-DD");
            filters['payment_date_start_date'] = dates[0].format("YYYY-MM-DD");
            filters.dates = dates;
        } else {
            delete filters.payment_date_end_date;
            delete filters.payment_date_start_date;
            delete filters.dates;
        }
        setFilters({ ...filters })

    }


    //This code block is use when we navigate customer page to view the Total Payments Received
    React.useEffect(() => {
        if (location.state) {
            if (location.state.action === 'total_received') {
                setPartyData(location.state.party)
                filters.party_id = location.state.party_id;
            }
            if (location.state.action === 'total_paid') {
                setPartyData(location.state.party)
                filters.party_id = location.state.party_id;
            }
            filters.payment_date_end_date = get_last_12_month_date().to_date
            filters.payment_date_start_date = get_last_12_month_date().from_date
            setDate([moment(get_last_12_month_date().from_date), moment(get_last_12_month_date().to_date)])
            setFilters({ ...filters })
        }
    }, [location.state]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={3} sx={{pb:{xs:2,sm:0}}}>
                <CommonAsyncDropdown
                    id={'party_dropdown'}
                    dataTestId={'party_dropdown'}
                    autoSelect={false}
                    disableClearable={false}
                    optionLabel="display_name"
                    noOptionsText={"No result found"}
                    placeholder={type ? 'Select Vendor' : 'Select Customer'}

                    item={{
                        method: 'post',
                        label: type ? 'Vendor' : 'Customers',
                        value: partyData,
                        url: list_party(1),
                        body: { is_inactive: false, business_id: getBusinessInfo().id, role: type ? 'vendor' : 'customer' },
                        onChange: (event, value) => {
                            setPage(1);
                            if (value) {
                                setFilters({ ...filters, party_id: value.id, selectedCustOrVendor: value })
                            } else {
                                delete filters.party_id
                                delete filters.selectedCustOrVendor
                                setFilters({ ...filters })
                            }
                            setPartyData(value)
                        },
                    }}
                // addButton={type ? {
                //     title: '+ Add new Vendor',
                //     onClick: () => stateChangeHandler('New Vendor', 'new_vendor', 'lg')
                // } : {
                //     title: '+ Add new customer',
                //     onClick: () => stateChangeHandler('New Customer', 'new_customer', 'lg')
                // }}
                />
            </Grid>
            <Grid item xs={12} sm={5} sx={{}}>
                <CustomDateRangePicker dates={dates} onDateRangeChange={onDateRangeChange} />
            </Grid>
        </Grid>
    )
}

export default PaymentList;