import React from 'react';
import moment from 'moment';
import Input from '../../../../common/Input';
import { useNavigate } from 'react-router-dom';
import DatePicker from '../../../../common/DatePicker';
import * as Actions from '../../../../../state/Actions';
import { sumOfArray } from '../../../../../utils/Utils';
import { InputFile } from '../../../../common/InputFile';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import AsyncDropdown from '../../../../common/AsyncDropdown';
import { routesName } from '../../../../../config/routesName';
import { apiAction, apiFormData } from '../../../../../api/api';
import { journalEntryKeys } from '../../../../../utils/Constant';
import CustomButton from '../../../../custom/button/CustomButton';
import CurrencyDropdown from '../../../../common/CurrencyDropdown';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import { eventsNames } from '../../../../../firebase/firebaseAnalytics';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import CustomDefaultDropdown from '../../../../custom/dropdown/CustomDefaultDropdown';
import { CustomCheckbox } from '../../../setting/settings/Preferences/common/CustomElement';

import {
    getDateFormat,
    stateChangeManager,
} from '../../../../../utils/Utils';

import {
    assign_journalentry,
    create_journal_entry,
    retrieve_journal_entry,
    get_chart_of_account_nested_nodes,
    list_journal_entry_for_contra_entry_reference,
} from '../../../../../api/urls';

import {
    CustomContainer,
    CustomButtonContainer,
} from '../../../../custom/container/CustomContainer';

import {
    tallyTransaction,
    addTransactionManager,
    createTransactionBody,
    upDateTransactionBody,
    removeTransactionManager,
    createJournalEntryValidation,
    onChangeInputHandlerManager,
} from './helper'

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';

import {
    Box,
    Grid,
    styled,
    DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import { LabelWithAsteriskMark } from '../../../../common/CommonLabel';
import CommaSeparatedInputField from '../../../../custom/input/CommaSeparatedInputField';


const BootstrapDialogActions = styled(DialogActions)(({ theme }) => ({
    marginTop: '-3px',
    marginBottom: '-40px',
    borderTop: '2px solid #F2F2F2',
}));

const Create = (props) => {

    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);
    const { onClose, updateData, transactions, url, assignJournalEntry, transaction_id, currencyDisabled, current_journal_entry_id = null, contra_entry_for_id = null, onAddTransactionAction, AnalyticsEvent = () => { } } = props

    const ButtonWrapper = updateData ? BootstrapDialogActions : CustomButtonContainer;

    const [note, setNote] = React.useState('');
    const [amount, setAmount] = React.useState('');
    const [date, setDate] = React.useState(moment());
    const [attachment, setAttachment] = React.useState();
    const [source, setSource] = React.useState('manual');
    const [itemIndex, setItemIndex] = React.useState(null);
    const [description, setDescription] = React.useState('');
    const [isUpdateData, setIsUpdateData] = React.useState(false);
    const [isFormSubmitted, setFormSubmitted] = React.useState(false);

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        compulsory: '',
        fullWidth: true,
    })

    //////////////////////////////////////////////map the default currency and default symbol////////////////////////////////////////////////////////////
    const [currency, setCurrency] = React.useState(getBusinessInfo().currency);
    const [symbol, setSymbol] = React.useState(getBusinessInfo().currency.symbol);

    const initial_data = [
        { debit: '', credit: '', validation: false, debitDisable: false, creditDisable: false, account: null, currency: getBusinessInfo().currency },
        { debit: '', credit: '', validation: false, debitDisable: false, creditDisable: false, account: null, currency: getBusinessInfo().currency }
    ]
    //////////////////////////////////////////////Default the transactions/////////////////////////////////////////////////////////////////////////////////
    const [data, setData] = React.useState([...initial_data]);

    React.useEffect(() => {
        // eslint-disable-next-line
        data.map((item, index) => {
            data[index]['currency'] = currency
        })
        setSymbol(currency.symbol)
        // eslint-disable-next-line
    }, [currency])

    const resetData = () => {
        setNote('')
        setAmount('')
        setDate(moment())
        setDescription('')
        setData([...initial_data])
        setCurrency(getBusinessInfo().currency)
        setSymbol(getBusinessInfo().currency.symbol)
    }

    const [filter, setFilter] = React.useState({
        mark: false,
        debit_account_data: null,
        credit_account_data: null,
        transaction_amount: null,
        business_account_id: null,
    })
    const [nextUrl, setNextUrl] = React.useState();
    const [hasMore, setHasMore] = React.useState(false);
    const [contraEntryId, setContraEntryId] = React.useState();
    const [contraEntryData, setContraEntryData] = React.useState([]);
    const getContraEntryData = async (url = list_journal_entry_for_contra_entry_reference(1), apiBody, previousResult = contraEntryData) => {
        let res = await apiAction({
            url: url,
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            data: { ...apiBody, current_journal_entry_id: current_journal_entry_id, business_id: getBusinessInfo().id, ...filter, },
        })
        if (res?.success) {
            let contra_entry_res = [...res?.result.result];
            if (contra_entry_for_id) {
                let contra_entry = await apiAction({
                    method: 'post',
                    navigate: navigate,
                    dispatch: dispatch,
                    data: { business_id: getBusinessInfo().id },
                    url: retrieve_journal_entry(contra_entry_for_id),
                })
                contra_entry_res = [contra_entry.result, ...contra_entry_res,]
            }
            const renderData = contra_entry_res.map((value) => {
                let transactions = value.transactions.map((item, index) => {
                    return (
                        <Box key={index} sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <CustomTypography
                                text={item.business_account}
                                sx={{
                                    color: '#7A7A7A',
                                    fontSize: '12px',
                                    fontWeight: '700',
                                    lineHeight: '27px',
                                    overflow: 'hidden',
                                    fontStyle: 'normal',
                                    marginRight: '10px',
                                    whiteSpace: 'nowrap',
                                    textTransform: 'none',
                                    fontFamily: "Noto Sans",
                                    textOverflow: 'ellipsis',
                                }} />

                            <CustomTypography
                                text={<span style={{ whiteSpace: 'nowrap', }}>
                                    <CurrencyFormatter amount={item.amount} currency={item.currency} />
                                    <span> {item.transaction_type === 'DEBIT' ? '(Dr.)' : '(Cr)'}</span>
                                </span>}
                                sx={{
                                    color: '#7A7A7A',
                                    fontSize: '12px',
                                    fontWeight: '700',
                                    lineHeight: '27px',
                                    fontStyle: 'normal',
                                    whiteSpace: 'nowrap',
                                    textTransform: 'none',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Box>
                    )
                })


                return ({
                    ...value,
                    name: <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <CustomTypography
                            text={value.description}
                            sx={{
                                color: '#141414',
                                fontSize: '14px',
                                fontWeight: '700',
                                lineHeight: '20px',
                                marginRight: '25px',
                                fontStyle: 'normal',
                                textTransform: 'none',
                                fontFamily: "Noto Sans",

                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        />
                        <CustomTypography
                            text={moment(value.transaction_date).utc().format(getDateFormat() + ' hh:mm a')}
                            sx={{
                                color: '#141414',
                                fontSize: '12px',
                                fontWeight: '700',
                                lineHeight: '20px',
                                fontStyle: 'normal',
                                whiteSpace: 'nowrap',
                                textTransform: 'none',
                                fontFamily: "Noto Sans",
                            }}
                        />
                    </Box>,
                    helperText: <Box>
                        {transactions.map((item) => item)}
                    </Box>

                })
            })
            const uniqueOptions = [
                ...previousResult,
                ...renderData.filter((item) =>
                    !previousResult?.some((option) => option['id'] === item['id'])
                )
            ];
            setNextUrl(res?.result.next);
            setHasMore(!!res?.result.next);
            setContraEntryData(uniqueOptions);
        } else {
        }
    }


    React.useEffect(() => {

        if (filter.mark && filter.business_account_id && filter.transaction_amount && filter.transaction_amount !== '' &&
            filter.credit_account_data && filter.credit_account_data && (filter.credit_account_data.length + filter.debit_account_data.length) === data.length
        ) {
            setContraEntryId(null);
            setContraEntryData([]);
            getContraEntryData(list_journal_entry_for_contra_entry_reference(1), {}, []);
        }
        if (filter.transaction_amount === null) {
            setContraEntryId(null);
            setContraEntryData([]);
            setFilter((previous) => ({ ...previous, mark: false }));
        }
    }, [filter.mark, filter.transaction_amount, filter.credit_account_data, filter.debit_account_data])


    ///////////////////////////////////////////////Id's for the contra entry////////////////////////////////////////////////////////////////
    const onChangeData = (data = []) => {
        let id = []
        let debit_account_data = []
        let credit_account_data = []
        data.map((item) => {
            console.log('===>item', item)
            if (item.account) {
                let account = item.account
                let parent_account = account && account?.parent_account_name;
                if (parent_account === "Cash" || parent_account === "Bank") {
                    id.push(account?.id)
                    if (item.debitDisable) {
                        credit_account_data.push({ [account.id]: Number(item.credit) })
                    }
                    if (item.creditDisable) {
                        debit_account_data.push({ [account.id]: Number(item.debit) })
                    }
                }
            }
        })
        if (id.length >= data.length) {
            setFilter((previous) => ({ ...previous, ...filter, transaction_amount: amount, business_account_id: id, debit_account_data: debit_account_data, credit_account_data: credit_account_data }))
        } else {
            setContraEntryId(null);
            setContraEntryData([]);
            setFilter((previous) => ({ ...previous, ...filter, mark: false, business_account_id: null, debit_account_data: null, credit_account_data: null }));
        }
    }

    React.useEffect(() => {
        onChangeData(data)
    }, [data])

    //////////////////////////////////////////////use for the onChange  inputHandler//////////////////////////////////////////////////////////////////////
    const onChangeInputHandler = (index, key, value, disableKey) => {
        setData([...onChangeInputHandlerManager(index, key, value, disableKey, data)])
    }

    //////////////////////////////////////////////use for the onChange inputAmountHandler/////////////////////////////////////////////////////////////////
    const onAmountHandler = (value) => {

        let data_amount = data[0].creditDisable ? data[0].debit : data[0].credit
        let key = data[0].debitDisable ? journalEntryKeys.credit : journalEntryKeys.debit
        let disabled = data[0].debitDisable ? journalEntryKeys.debitDisable : journalEntryKeys.creditDisable;

        if (data_amount !== value) {
            setContraEntryId(null);
            if (data_amount === '') {
                onChangeInputHandler(0, key, value, disabled);
            } else {
                onChangeInputHandler(0, key, data_amount, disabled);
            }
        } else if (value !== filter.transaction_amount) {
            setFilter((previous) => ({ ...previous, transaction_amount: value }));
        }
    }

    //////////////////////////////////////////////Add the new line for transactions/////////////////////////////////////////////////////////////////////////
    const addNewTransactionField = () => {
        setData([
            ...addTransactionManager(
                data,
                { debit: '', credit: '', validation: false, debitDisable: false, creditDisable: false, account: null, currency: currency }
            )
        ])
    }

    //////////////////////////////////////////////remove the transactions//////////////////////////////////////////////////////////////////////////////////
    const removeTransactionField = (index) => {
        if (data.length > 2) {
            setData([...removeTransactionManager(index, data)])
        }
    }

    //////////////////////////////////////////////use to post the data on server/////////////////////////////////////////////////////////////////////////

    const OnClickHandler = async (multiple, is_posted) => {
        setFormSubmitted(is_posted || filter.mark || description === '');
        const formData = {
            note: note,
            source: source,
            is_posted: is_posted,
            is_contra: filter.mark,
            attachment: attachment,
            description: description,
            transaction_amount: amount,
            business_id: getBusinessInfo().id,
            contra_entry_for_id: contraEntryId,
            transactions: createTransactionBody(data),
            transaction_date: date.format('YYYY-MM-DD HH:mm:ss'),
        }

        if ((is_posted || filter.mark) ? createJournalEntryValidation({ description, amount, data }, dispatch, Actions) : createJournalEntryValidation({ description, amount }, dispatch, Actions)) {
            if (url) {
                if (AnalyticsEvent) {
                    AnalyticsEvent({ action: eventsNames.actions.EDIT })
                }

            } else {
                if (AnalyticsEvent) {
                    if (is_posted) {
                        AnalyticsEvent({ action: eventsNames.actions.CREATE, state: eventsNames.actions.journalEntry.state.SAVE })
                    } else {
                        AnalyticsEvent({ action: eventsNames.actions.CREATE, state: eventsNames.actions.journalEntry.state.DRAFT })
                    }
                }

            }
            let res = await apiFormData({
                method: 'post',
                navigate: navigate,
                dispatch: dispatch,
                data: { ...formData },
                url: url ? url : create_journal_entry(),
            })
            if (res?.success) {
                stateChangeManager(dispatch, Actions, true, 'success', res?.status)
                if (assignJournalEntry) {
                    let journalEntry = await apiAction({
                        method: 'PATCH',
                        navigate: navigate,
                        dispatch: dispatch,
                        url: assign_journalentry(),
                        data: {
                            transaction_id: transaction_id,
                            journal_entry_id: res?.result.id,
                            business_id: getBusinessInfo().id,
                        },
                    })
                    if (journalEntry.success) {
                        if (onAddTransactionAction) { onAddTransactionAction() }
                    }
                }
                if (onClose) {
                    onClose()
                }
                if (multiple) {
                    resetData()
                    navigate(routesName.journalEntryCreate.path)
                }
                else {
                    if (onClose) {
                        onClose()
                    } else {
                        navigate(assignJournalEntry ? routesName.sourceTransactions.path : routesName.journalEntryList.path)
                    }
                }
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', res?.status)
            }
        } else {
            // setValidation(true)
        }
    }

    //////////////////////////////////////////////use for the update the journal entry//////////////////////////////////////////////////////////////////

    React.useEffect(() => {
        if (transactions && updateData.date && !isUpdateData) {

            setIsUpdateData(true);
            setNote(updateData.note);
            setSource(updateData.source);
            setAmount(updateData.amount);
            setCurrency(updateData.currency);
            setAttachment(updateData.attachment);
            setDate(moment(updateData.date).utc());
            setDescription(updateData.description || '');

            setContraEntryId(updateData.contra_entry_for_id);
            setFilter((previous) => ({ ...previous, mark: updateData.is_contra, transaction_amount: updateData.amount, business_account_id: null, credit_account_data: null, debit_account_data: null }));

            setData(
                upDateTransactionBody(transactions).length === 1 ?
                    addTransactionManager(upDateTransactionBody(transactions), { debit: '', credit: '', validation: false, debitDisable: false, creditDisable: false, account: null, currency: currency })
                    : upDateTransactionBody(transactions).length > 1 ?
                        upDateTransactionBody(transactions)
                        : data
            );
        }
        // eslint-disable-next-line
    }, [updateData])


    ///////////////////////////////////////////////calculate the total credit & debit amount////////////////////////////////////////////////////////////
    const { debit, credit } = tallyTransaction(data)
    const totalDebit = sumOfArray(debit)
    const totalCredit = sumOfArray(credit)

    ///////////////////////////////////////////////////////////////Adding the New chart of account/////////////////////////////////////////////////////////////////////////////
    const addButtonHandler = (title, condition, maxWidth, index) => {
        setState({
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
        })
        setItemIndex(index)
    }

    const [newAccountResult, setNewAccountResult] = React.useState()
    const onAddAccount = (value) => {
        setNewAccountResult(value);
        onChangeInputHandler(itemIndex, journalEntryKeys.account, value);
        // apiResults();
    }

    const amount_validation = (Number(amount) === Number(totalDebit)) & (Number(amount) === Number(totalCredit))

    return (
        <React.Fragment>
            <CustomDialog
                state={state}
                setState={setState}
                onAddAccount={(account) => onAddAccount(account)}
            />
            <CustomContainer maxWidth={"400"} sx={{}}>
                <Grid container spacing={3} style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '32px', display: 'flex' }}>
                    <Grid item xs={12} sm={4.5}>
                        <Input
                            autoFocus={true}
                            id={'input_description'}
                            dataTestId={'input_description'}
                            item={{
                                type: 'text',
                                required: true,
                                fullWidth: true,
                                value: description,
                                title: 'Description',
                                placeholder: 'Enter Description',
                                onChange: (e) => setDescription(e.target.value),
                                validation: isFormSubmitted && description === '' ? true : false,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2.5} sx={{ m: 0 }}>
                        <CommaSeparatedInputField
                            id={'input_amount'}
                            dataTestId={'input_amount'}
                            title={<LabelWithAsteriskMark label={'Amount'} />}

                            value={amount}
                            placeholder='Enter Amount'
                            onChange={(value) => { setAmount(value) }}
                            onBlur={(value) => { onAmountHandler(value) }}
                            validation={isFormSubmitted && amount ? amount_validation ? false : true : false}
                        />
                    </Grid>

                    <Grid item xs={12} sm={2.5}>
                        <DatePicker
                            date={date}
                            isTime={false}
                            setDate={setDate}
                            id={'date_select'}
                            disabledCloseIcon={true}
                            dataTestId={'date_select'}
                            no_minDate={false}
                            dateTimeFormat={'DD-MM-YYYY HH:mm:ss'}
                            title={<LabelWithAsteriskMark label={'Date'} />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                        <CurrencyDropdown
                            id={'currency_dropdown'}
                            dataTestId={'currency_dropdown'}
                            disableClearable={true}
                            item={{
                                value: currency,
                                disabled: currencyDisabled,
                                label: <LabelWithAsteriskMark label={'Currency'} />,
                                onChange: (event, newValue) => setCurrency(newValue ? newValue : null),
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4.5}>
                        <Input
                            id={'input_note'}
                            dataTestId={'input_note'}
                            item={{
                                rows: 4,
                                type: 'text',
                                label: 'Note',
                                multiline: true,
                                fullWidth: true,
                                value: note ? note : '',
                                variant: "standard",
                                placeholder: 'Write your note...',
                                onChange: (e) => setNote(e.target.value)
                            }}
                        />
                    </Grid>

                    {/* /////////////////////////////////////////// Attachment /////////////////////////////////////////////////////// */}
                    <Grid item xs={12} sm={5}>
                        <InputFile
                            onFileUpload={(file) => {
                                setAttachment(file)
                            }}
                            attachment={attachment}
                            onFileRemove={() => {
                                setAttachment(null);
                            }}
                        />
                    </Grid>
                </Grid>
                <CustomTabs
                    id={'tabs_'}
                    dataTestId={'tabs_'}
                    tabs={[{ name: 'Transactions' }]}
                    value={0}
                />
                <CustomTableContainer sx={{ height: 'auto' }}>
                    <Box sx={{ display: { sm: 'flex', xs: 'block' } }} style={{ paddingLeft: '24px', paddingRight: '24px', paddingBottom: filter.business_account_id && filter.transaction_amount !== null ? '34px' : '40vh', }}>
                        <CustomTable sx={{ flexGrow: 1, }}>
                            <TransactionHeaders />
                            <CustomTableBody>
                                {
                                    data.map((item, index) => {
                                        return (
                                            <CustomTableRow key={index}>
                                                <Transaction
                                                    data={data}
                                                    item={item}
                                                    index={index}
                                                    symbol={symbol}
                                                    newAccountResult={newAccountResult}
                                                    addButtonHandler={addButtonHandler}
                                                    onChangeInputHandler={onChangeInputHandler}
                                                    onDeleteHandler={() => removeTransactionField(index, data)}

                                                />
                                            </CustomTableRow>
                                        )
                                    })
                                }
                            </CustomTableBody>
                            <CustomTypography
                                id={'add_new_line_action_btn'}
                                dataTestId={'add_new_line_action_btn'}
                                text={'+Add Line'}
                                sx={{
                                    color: '#2464EB',
                                    fontSize: '12px',
                                    display: 'inline',
                                    cursor: 'pointer',
                                    fontWeight: '700',
                                    lineHeight: '16px',
                                    fontStyle: 'normal',
                                    paddingLeft: '20px',
                                    fontFamily: 'Noto Sans',
                                }}
                                onClick={() => addNewTransactionField()}
                            />
                        </CustomTable>

                        <CustomTable sx={{ pl: { sm: 4, xs: 0 }, width: { sm: '50%', xs: '100%' } }}>
                            <CustomTableHead>
                                <CustomTableRow>
                                    <CustomTableHeadCell sx={{ whiteSpace: 'nowrap', width: '240px' }}>Transaction Difference</CustomTableHeadCell>
                                </CustomTableRow>
                            </CustomTableHead>
                            <CustomTableBody>
                                <CustomTableRow>
                                    <TalleyTransaction
                                        totalDebit={totalDebit}
                                        totalCredit={totalCredit}
                                        symbol={currency.currency_code}
                                    />
                                </CustomTableRow>
                            </CustomTableBody>
                        </CustomTable>

                    </Box>

                    <Box sx={{ borderTop: '2px solid #e8e8e8', }}>
                        {filter.business_account_id && filter.transaction_amount && filter.transaction_amount !== '' &&
                            filter.credit_account_data && filter.credit_account_data && (filter.credit_account_data.length + filter.debit_account_data.length) === data.length &&
                            <Grid container spacing={3} style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '40vh', }}>
                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <CustomCheckbox id={'mark_as_contra_entry_check_box'} dataTestId={'mark_as_contra_entry_check_box'} checked={filter.mark} onClickedHandler={(value) => { setFilter((previous) => ({ ...previous, mark: !value })); setContraEntryId(null); }} label={<span style={{ fontWeight: 700 }}>Mark this transaction as contra entry</span>} />
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ mt: -2 }}>
                                    {filter.mark &&
                                        <CustomDefaultDropdown
                                            fullWidth={true}
                                            validation={false}
                                            hasMore={hasMore}
                                            nextUrl={nextUrl}
                                            labelKey='name'
                                            isTooltip={true}
                                            tooltipLabelKey={'description'}
                                            disabledCloseIcon={false}
                                            results={contraEntryData}
                                            valueLabelKey={'description'}
                                            url={list_journal_entry_for_contra_entry_reference(1)}
                                            fetchOptions={getContraEntryData}
                                            placeholder='Select the transaction'
                                            id={'mark_as_contra_entry_dropdown'}
                                            dataTestId={'mark_as_contra_entry_dropdown'}
                                            noResult={`No journal entry found for these transaction.`}
                                            value={contraEntryId ? contraEntryData.find((item) => item.id === contraEntryId) : null}
                                            setValue={(selectedData) => {
                                                if (selectedData) {
                                                    setContraEntryId(selectedData.id);
                                                } else {
                                                    setContraEntryId(null);
                                                }
                                            }}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        }
                    </Box>
                </CustomTableContainer>

            </CustomContainer>

            <ButtonWrapper>
                <Grid container>
                    {filter.mark ?
                        <Grid item xs={12} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                            {/* <CustomButton
                                sx={{ mr: 2, }}
                                variant='outlined'
                                btnLabel='Contra Entry as  Draft'
                                id={'mark_as_contra_entry_draft_btn'}
                                dataTestId={'mark_as_contra_entry_draft_btn'}
                                onClick={() => OnClickHandler(false, false)}
                            /> */}

                            <CustomButton
                                variant='contained'
                                id={'mark_as_contra_entry_btn'}
                                disabled={contraEntryId === null}
                                dataTestId={'mark_as_contra_entry_btn'}
                                onClick={() => OnClickHandler(false, false)}
                                btnLabel={url ? 'Update as Contra Entry' : 'Save as Contra Entry'}
                            />
                        </Grid>
                        :
                        <Grid item xs={12} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                            <CustomButton
                                id={'save_as_draft_btn'}
                                dataTestId={'save_as_draft_btn'}
                                disabled={(description || amount) === '' ? true : false}
                                color='primary'
                                variant='outlined'
                                btnLabel='Save as Draft'
                                onClick={() => OnClickHandler(false, false)}
                                sx={{ mr: 1, textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }}
                            />
                            {
                                !url & !assignJournalEntry ?
                                    <CustomButton
                                        id={'save_and_add_btn'}
                                        dataTestId={'save_and_add_btn'}
                                        disabled={(totalCredit === totalDebit)
                                            &
                                            ((totalCredit || totalDebit) === Number(amount))
                                            &
                                            ((totalCredit || totalDebit) !== 0) ? false : true}
                                        variant='contained'
                                        btnLabel={'Save and Add another'}
                                        onClick={() => OnClickHandler(true, true)}
                                        sx={{ mr: 1, textTransform: 'none', }}
                                    />
                                    : null
                            }
                            <CustomButton
                                id={'save_btn'}
                                dataTestId={'save_btn'}
                                disabled={(totalCredit === totalDebit)
                                    &
                                    ((totalCredit || totalDebit) === Number(amount))
                                    &
                                    ((totalCredit || totalDebit) !== 0) ? false : true}
                                variant='contained'
                                btnLabel={url ? 'Update Journal Entry' : 'Save Journal Entry'}
                                onClick={() => OnClickHandler(false, true)}
                                sx={{ textTransform: 'none', }}
                            />
                        </Grid>
                    }
                </Grid>
            </ButtonWrapper>
        </React.Fragment>
    )
}

const TransactionHeaders = () => {
    return (
        <CustomTableHead>
            <CustomTableRow>
                <CustomTableHeadCell style={{ width: '240px' }}><span>Account</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: '160px' }}><span>Debit</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: '160px' }}><span>Credit</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: '20px' }} align='right' />
            </CustomTableRow>
        </CustomTableHead>
    )
}

const Transaction = (props) => {
    const {
        data,
        item,
        index,
        symbol,
        onDeleteHandler,
        newAccountResult,
        addButtonHandler,
        onChangeInputHandler,
    } = props

    return (
        <React.Fragment>
            <CustomTableBodyCell sx={{ border: 'none' }} >
                <AsyncDropdown
                    sx={{}}
                    fullWidth={true}
                    autoFocus={false}
                    validation={false}
                    disabledCloseIcon={false}
                    isGroupHeaderSticky={false}
                    newResults={newAccountResult}
                    id={'account_dropdown_' + index}
                    dataTestId={'account_dropdown_' + index}

                    selectedValue={item.account}
                    setSelectedValue={(selectedData) => { onChangeInputHandler(index, journalEntryKeys.account, selectedData) }}

                    valueLabelKey='id'
                    uniqueOptionKey='id'
                    searchKey='account_name'
                    optionLabelKey='account_name'
                    placeholder='Select the Account'
                    optionGroupLabelKey='parent_account_name'
                    URL={get_chart_of_account_nested_nodes(1)}

                    addButton={{
                        title: '+ Add accounts',
                        onClick: () => addButtonHandler('Add accounts', 'create_sub_account', 'sm', index),
                    }}
                />

            </CustomTableBodyCell>
            <CustomTableBodyCell sx={{ border: 'none' }} >
                {/* <Input
                    id={'input_debit_' + index}
                    dataTestId={'input_debit_' + index}
                    item={{
                        type: 'number',
                        required: true,
                        fullWidth: true,
                        value: item.debit,
                        variant: "standard",
                        disabled: item.debitDisable,
                        // label: !index ? "Debit" : "",
                        placeholder: symbol ? symbol + ' 0.00' : '0.00',
                        onChange: (e) => onChangeInputHandler(index, journalEntryKeys.debit, e.target.value, journalEntryKeys.creditDisable, data)
                    }}
                    validation={item.validation}
                    InputProps={{
                        inputProps: { min: 0 },
                        // disableUnderline: true,

                    }}
                    onKeyPress={(event) => {
                        if (event?.key === '-' || event?.key === '+') {
                            event.preventDefault();
                        }
                    }}
                /> */}
                <CommaSeparatedInputField
                    id={'input_debit_' + index}
                    dataTestId={'input_debit_' + index}

                    value={item.debit}
                    placeholder={symbol ? symbol + ' 0.00' : '0.00'}
                    onChange={(value, e) => onChangeInputHandler(index, journalEntryKeys.debit, value, journalEntryKeys.creditDisable, data)}
                    validation={item.validation}
                />
            </CustomTableBodyCell>
            <CustomTableBodyCell sx={{ border: 'none' }} >
                {/* <Input
                    id={'input_credit_' + index}
                    dataTestId={'input_credit_' + index}
                    item={{
                        type: 'number',
                        required: true,
                        fullWidth: true,
                        value: item.credit,
                        variant: "standard",
                        disabled: item.creditDisable,
                        // label: !index ? "Credit" : "",
                        placeholder: symbol ? symbol + ' 0.00' : '0.00',
                        onChange: (e) => onChangeInputHandler(index, journalEntryKeys.credit, e.target.value, journalEntryKeys.debitDisable, data)
                    }}
                    validation={item.validation}
                    InputProps={{
                        inputProps: { min: 0 },
                    }}
                    onKeyPress={(event) => {
                        if (event?.key === '-' || event?.key === '+') {
                            event.preventDefault();
                        }
                    }}
                /> */}
                <CommaSeparatedInputField
                    id={'input_credit_' + index}
                    dataTestId={'input_credit_' + index}

                    value={item.credit}
                    placeholder={symbol ? symbol + ' 0.00' : '0.00'}
                    onChange={(value, e) => onChangeInputHandler(index, journalEntryKeys.credit, e.target.value, journalEntryKeys.debitDisable, data)}
                    validation={item.validation}
                />
            </CustomTableBodyCell>
            <CustomTableBodyCell sx={{ border: 'none' }} align='right'>
                <DeleteIcon sx={{ color: '#7A7A7A', cursor: 'pointer' }} onClick={() => onDeleteHandler()}
                    id={'delete_icon_' + index}
                    dataTestId={'delete_icon_' + index}
                />
            </CustomTableBodyCell>

        </React.Fragment>
    )
}

const TalleyTransaction = (props) => {
    const { symbol, totalDebit, totalCredit } = props

    let condition = totalCredit === totalDebit
    return (

        <Grid container sx={{ mt: 2, background: '#FFF7DC', border: '1px solid #FFF0C0' }}>
            <Grid container item xs={12} sx={{ p: 2, borderBottom: '1px solid #FFF0C0' }}>
                <Grid item xs={6} sx={{ textAlign: 'left' }}>
                    <CustomTypography
                        text={'Total Debit'}
                        sx={{
                            color: '#000000',
                            fontSize: '12px',
                            fontWeight: '400',
                            lineHeight: '16px',
                            fontStyle: 'normal',
                            fontFamily: 'Noto Sans',
                        }}
                    />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <CustomTypography
                        id={'total_debit'}
                        dataTestId={'total_debit'}
                        text={<CurrencyFormatter currency={symbol} amount={totalDebit} />}
                        sx={{
                            color: '#000000',
                            fontSize: '12px',
                            fontWeight: '700',
                            lineHeight: '14px',
                            fontStyle: 'normal',
                            fontFamily: 'Noto Sans',
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container item xs={12} sx={{ p: 2, borderTop: '1px solid #FFF0C0', borderBottom: '1px solid #FFF0C0' }}>
                <Grid item xs={6} sx={{ textAlign: 'left' }}>
                    <CustomTypography
                        text={'Total Credit'}
                        sx={{
                            color: '#000000',
                            fontSize: '12px',
                            fontWeight: '400',
                            lineHeight: '16px',
                            fontStyle: 'normal',
                            fontFamily: 'Noto Sans',
                        }}
                    />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <CustomTypography
                        id={'total_credit'}
                        dataTestId={'total_credit'}
                        text={<CurrencyFormatter currency={symbol} amount={totalCredit} />}
                        sx={{
                            color: '#000000',
                            fontSize: '12px',
                            fontWeight: '700',
                            lineHeight: '14px',
                            fontStyle: 'normal',
                            fontFamily: 'Noto Sans',
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container item xs={12} sx={{ p: 2, borderTop: '1px solid #FFF0C0' }}>
                <Grid item xs={6} sx={{ textAlign: 'left' }}>
                    <CustomTypography
                        text={'Difference'}
                        sx={{
                            color: '#000000',
                            fontSize: '12px',
                            fontWeight: '400',
                            lineHeight: '16px',
                            fontStyle: 'normal',
                            fontFamily: 'Noto Sans',
                        }}
                    />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <CustomTypography
                        id={'total_difference'}
                        dataTestId={'total_difference'}
                        text={<CurrencyFormatter currency={symbol} amount={Math.abs(totalCredit - totalDebit)} />}
                        sx={{
                            color: '#000000',
                            fontSize: '12px',
                            fontWeight: '700',
                            lineHeight: '14px',
                            fontStyle: 'normal',
                            fontFamily: 'Noto Sans',
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>

    )
}
export default Create;