import React from 'react';
import Input from '../../common/Input';
import { isNumeric, limitDecimalPlaces } from '../../../utils/Utils';

const CommaSeparatedInputField = ({ value = '', disabled = false, onFocus, onChange, onBlur, validation, title = '', placeholder = '', currency_code, id, dataTestId }) => {
    const [editable, setEditable] = React.useState(false);

    const handleFocus = (event) => {
        const inputValue = limitDecimalPlaces(event.target.value);
        setEditable(true);
        if (onFocus) {
            if (isNumeric(inputValue)) {
                onFocus(inputValue, event);
            } else {
                onFocus('', event);
            }
        }
    };

    const handleBlur = (event) => {
        const inputValue = limitDecimalPlaces(event.target.value);
        if (onBlur) {
            if (isNumeric(inputValue)) {
                onBlur(inputValue, event);
            } else {
                onBlur('', event);
            }
        }
        setEditable(false);
    };

    const handleChange = (event) => {
        const inputValue = limitDecimalPlaces(event.target.value);
        if (onChange) {
            if (isNumeric(inputValue)) {
                onChange(inputValue, event);
            } else {
                onChange('', event);
            }
        }
    };

    const handleKeyPress = (event) => {
        const restrictedKeys = ["-", "+", "e", "E"];
        if (restrictedKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    return (
        <Input
            id={id}
            editable={editable}
            onFocus={handleFocus}
            dataTestId={dataTestId}
            isSearchableComponent={true}
            currency_code={currency_code}
            isShowCommaSeparatedValue={true}
            item={{
                title: title,
                value: value,
                type: 'number',
                required: true,
                fullWidth: true,
                disabled: disabled,
                onBlur: handleBlur,
                validation: validation,
                onChange: handleChange,
                placeholder: placeholder,
            }}
            InputProps={{
                inputProps: { min: 0 },
            }}
            onKeyPress={handleKeyPress}
        />
    );
};

export default CommaSeparatedInputField;
