import React from 'react';
import { Grid, Box, Divider } from '@mui/material';
import DateRange from '../../../../common/DateRange';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CustomDateRangePicker from '../../../../custom/Datepicker/CustomDateRangePicker';
import { ApplyDefaultFilterCheckBox, DownloadButtons, DownloadRadioButtons } from '../../../../common/CommonDownloadLayout';

const Dropdown = DateRange;
const DownloadVendorsData = (props) => {
    const { setOpen, url, defaultFilters } = props;

    const [filters, setFilters] = React.useState({
        "export_to": "csv",
        "business_id": getBusinessInfo().id,
        "party_role": "vendor",
        "is_inactive": null,
        "party_type": null,
    });
    const [timePeriod, setTimePeriod] = React.useState("all");
    const [isDefaultFiltersApplied, setIsDefaultFiltersApplied] = React.useState(false);

    const statusList = [
        { name: 'Active Vendors', value: false },
        { name: 'Inactive Vendors', value: true },
        { name: 'All Vendors', value: null },
    ]
    const vendorTypeList = [
        { name: 'Business', value: "business" },
        { name: 'Individual', value: "individual" },
        { name: 'All Vendors', value: null },
    ]

    React.useEffect(() => {
        if (timePeriod === "all") {
            setFilters({ ...filters, from_datetime: null, to_datetime: null })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timePeriod])

    React.useEffect(() => {
        if (isDefaultFiltersApplied) {
            setFilters({ ...filters, is_inactive: defaultFilters.is_inactive })
        } else {
            setFilters({ ...filters })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDefaultFiltersApplied])

    const onDateRangeChange = (dates) => {
        if (dates) {
            setFilters({ ...filters, from_datetime: dates[0].format("YYYY/MM/DD HH:mm:ss"), to_datetime: dates[1].format("YYYY/MM/DD HH:mm:ss") })
        } else {
            delete filters.to_datetime
            delete filters.from_datetime
            setFilters({ ...filters })
        }
    }

    return (
        <Box>
            <Grid container spacing={1}>
                <ApplyDefaultFilterCheckBox
                    isDefaultFiltersApplied={isDefaultFiltersApplied}
                    setIsDefaultFiltersApplied={setIsDefaultFiltersApplied}
                />
                <Grid item container xs={11} spacing={1} sx={{ mb: 2 }}>
                    <Grid item xs={10} sm={6}>
                        <Dropdown
                            id={'vendorStatus_dropdown'}
                            dataTestId={'status_dropdown'}
                            label="Vendor Status"
                            placeholder='Select Status'
                            results={statusList}
                            value={statusList.find((item) => item?.value === filters?.is_inactive)}
                            setValue={(event, value) => {
                                if (value) {
                                    if (isDefaultFiltersApplied) {
                                        filters['is_inactive'] = value.value;
                                        setIsDefaultFiltersApplied(false);
                                    } else {
                                        filters['is_inactive'] = value.value;
                                    }
                                } else {
                                    delete filters?.is_inactive;
                                    setIsDefaultFiltersApplied(false);
                                }
                                setFilters({ ...filters })
                            }}
                        />
                    </Grid>
                    <Grid item xs={10} sm={6}>
                        <Dropdown
                            id={'vendorType'}
                            dataTestId={'vendorType'}
                            label="Type"
                            placeholder='Select Type'
                            results={vendorTypeList}
                            value={vendorTypeList.find((item) => item?.value === filters?.party_type)}
                            setValue={(event, value) => {
                                if (value) {
                                    filters["party_type"] = value.value;
                                } else {
                                    delete filters?.party_type
                                }
                                setFilters({ ...filters })
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={15} sm={12} sx={{ mb: 2 }}>
                    <DownloadRadioButtons
                        timePeriod={timePeriod}
                        setTimePeriod={setTimePeriod}
                        allButtonLabel={"All Vendors"}
                    />
                    {timePeriod === "specificTimePeriod" &&
                        <CustomDateRangePicker onDateRangeChange={onDateRangeChange} />
                    }
                </Grid>
            </Grid>

            <Divider />
            <DownloadButtons
                onClose={setOpen}
                url={url}
                filters={filters}
                setOpen={setOpen}
                timePeriod={timePeriod}
            />
        </Box>
    )
}

export default DownloadVendorsData;
