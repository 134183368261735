import React from 'react';
import TopSales from './TopSales';
import TopCustomers from './TopCutomers';
import TodayStatestics from './TodayStatestics';
import YourTopExpenses from './YourTopExpenses';
import { date_range } from '../../../../utils/Utils';
import CashflowComponents from './CashflowComponents';
import { useNavigate, useLocation } from 'react-router-dom';
import { routesName } from '../../../../config/routesName';
import IncomeExpenseComponents from './IncomeExpenseComponents';
import PayableReceivableComponent from './PayableReceivableComponent';
import { CustomContainer } from '../../../custom/container/CustomContainer';

import {
  dashboard_total_payable,
  dashboard_top_sales_data,
  dashboard_total_receivable,
  dashboard_top_expense_data,
  dashboard_top_customer_data,
  dashboard_todays_statestics,
  dashboard_cash_flow_graph_data,
  dashboard_income_expense_graph_data,
} from '../../../../api/urls';

import { Box, Grid } from '@mui/material';

const Dashboard = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [isVisible, setIsVisible] = React.useState(false);

  const date_range_default = [
    {
      name: 'This Fiscal Year',
      value: 'this_fiscal_year'
    },
    {
      name: 'Previous Fiscal Year',
      value: 'previous_fiscal_year'
    }, {
      name: 'Last 12 Months',
      value: 'last_12_month'
    },
  ]

  React.useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <CustomContainer maxWidth={"400"} className="custom-container" sx={{maxHeight:{xs:'calc(100vh - 120px)',sm:'100%'}}}>
      <div className="box">
        <TodayStatestics url={dashboard_todays_statestics()} />
      </div>
      <div className="box-mt-4">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <div className="box">
              <PayableReceivableComponent
                type='invoice'
                title={'Total Receivables '}
                url={dashboard_total_receivable()}
                navigate={() => navigate(routesName.arDetailedReports.path)}
                id={'dashboard_total_receivable'}
                dataTestId={'dashboard_total_receivable'}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="box">
              <PayableReceivableComponent
                type='bill'
                title={'Total Payables '}
                url={dashboard_total_payable()}
                navigate={() => navigate(routesName.apDetailedReports.path)}
                id={'dashboard_total_payable'}
                dataTestId={'dashboard_total_payable'}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="box-mt-4">
        <div className="box">
          <CashflowComponents
            date_range={date_range_default}
            custom_date_range={date_range()}
            url={dashboard_cash_flow_graph_data()}
            id={'dashboard_cash_flow_graph_data'}
            dataTestId={'dashboard_cash_flow_graph_data'}
          />
        </div>
      </div>
      {isVisible && (
        <React.Fragment>
          <div className="box-mt-4">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div className="box">
                  <IncomeExpenseComponents
                    date_range={date_range_default}
                    custom_date_range={date_range()}
                    url={dashboard_income_expense_graph_data()}
                    id={'dashboard_income_expense_graph_data'}
                    dataTestId={'dashboard_income_expense_graph_data'}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div className="box">
                  <YourTopExpenses
                    date_range={date_range_default}
                    url={dashboard_top_expense_data()}
                    custom_date_range={date_range()}
                    routeName={routesName.profitLoss.path}
                    id={'dashboard_top_expense_data'}
                    dataTestId={'dashboard_top_expense_data'}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="box-mt-4">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div className="box">
                  <TopCustomers
                    url={dashboard_top_customer_data()}
                    custom_date_range={date_range()}
                    id={'dashboard_top_customer_data'}
                    dataTestId={'dashboard_top_customer_data'}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div className="box">
                  <TopSales
                    url={dashboard_top_sales_data()}
                    custom_date_range={date_range()}
                    id={'dashboard_top_sales_data'}
                    dataTestId={'dashboard_top_sales_data'}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      )}
    </CustomContainer>
  )
}

export default Dashboard;
