import moment from "moment/moment";
import Emails from "../../common/Emails";
import React, { useContext } from "react";
import "react-multi-email/dist/style.css";
import { TaxList } from "../../common/GST";
import Input from "../../../../common/Input";
import * as Common from "../../common/Common";
import { ListTerms } from "../../common/Terms";
import DateRange from "../../../../common/DateRange";
import DatePicker from "../../../../common/DatePicker";
import * as Actions from "../../../../../state/Actions";
import CustomTitle from "../../../../common/CustomTitle";
import { InputFile } from "../../../../common/InputFile";
import CommonAlertBox from "../../common/CommonAlertBox";
import { SalesPersonList } from "../../common/salesPerson";
import PrintAndPreview from "../../common/PrintAndPreview";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { routesName } from "../../../../../config/routesName";
import CommonCalculation from "../../common/CommonCalculation";
import CommonDropdown from "../../../../common/CommonDropdown";
import { input_css_style } from "../../../../../utils/Constant";
import CustomDialog from "../../../../custom/dialog/CustomDialog";
import CustomButton from "../../../../custom/button/CustomButton";
import { getBusinessInfo } from "../../../../../config/cookiesInfo";
import CurrencyFormatter from "../../../../common/CurrencyFormatter";
import { TermsAndConditions } from "../../common/TermsAndConditions";
import CommonPreviewShare from "../../../../common/CommonPreviewShare";
import { LabelWithAsteriskMark } from "../../../../common/CommonLabel";
import CommonAsyncDropdown from "../../../../common/CommonAsyncDropdown";
import CommonCurrencySelection from "../../common/CommonCurrencySelection";
import CustomGroupButton from "../../../../custom/button/CustomGroupButton";
import CustomTypography from "../../../../custom/typography/CustomTypography";
import { eventsNames, AnalyticsEvent } from "../../../../../firebase/firebaseAnalytics";

import {
  getNotesPreferences,
  getDiscountPreferences,
  getAmountArePreferences,
  getSalesPersonPreferences,
  getTermsAndConditionPreferences,
} from "../../../setting/settings/Preferences/common/getPreferences";

import {
  CustomContainer,
  CustomTitleContainer,
  CustomButtonContainer,
} from "../../../../custom/container/CustomContainer";

import {
  checkItemDiscount,
  useFinalCalculations,
  isFinalAmountRangeCrossed,
  convertExclusiveToInclusive,
  convertInclusiveToExclusive,
} from "./InvoiceManager";

import {
  setLoader,
  getAddress,
  filterTax,
  isFormValid,
  filterArray,
  isAddressSame,
  findDefaultTax,
  getQueryParams,
  stateChangeManager,
  isApplicableForTax,
  isBusinessRegistered,
  breadCrumbsStateChangeManager,
} from "../../../../../utils/Utils";
import { customerMappings } from "./Helper";

import { apiAction, apiFormData } from "../../../../../api/api";

import {
  get_item,
  get_party,
  list_party,
  list_items,
  create_invoice,
  update_invoice,
  export_invoice,
  get_list_of_tax,
  retrieve_invoice,
  get_list_of_state,
  retrieve_estimate,
  retrieve_expense,
  list_payment_term,
  retrieve_sales_order,
  retrieve_delivery_challan,
  get_create_invoice_meta_data,
} from "../../../../../api/urls";

import {
  Box,
  Grid,
  Checkbox,
  IconButton,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableBodyCell,
  CustomTableHeadCell,
  CustomTableContainer,
} from "../../../../custom/table/CustomTable";

const Dropdown = DateRange;

const CreateNewInvoice = (props) => {
  const {
    onClose,
    customer,
    isRecurringInvoice,
    customerEditable,
    invoice_data,
    title,
    recurringInvoiceData,
    repeat_every_list,
    repeat_list,
    preference,
    setRecurringInvoiceData,
    onClickSave,
  } = props;

  let location = useLocation();
  const navigate = useNavigate();
  const params = getQueryParams(location.search);
  const [isClear, setClear] = React.useState(false);
  const dispatch = Actions.getDispatch(React.useContext);
  const [isFormSubmitted, setFormSubmitted] = React.useState(false);
  const isProFormaInvoice = Boolean(location.state?.isProFormaInvoice);
  const [state, setState] = React.useState({
    id: "",
    title: "",
    open: false,
    condition: "",
    maxWidth: "lg",
    compulsory: "",
    fullWidth: true,
  });
  const [zero, setZero] = React.useState(false);
  const [saveAndSend, setSaveAndSend] = React.useState(false);

  const [newItem, setNewItem] = React.useState(null);
  const [itemIndex, setItemIndex] = React.useState(null);

  const addButtonHandler = (title, condition, maxWidth, compulsory, index) => {
    setState({
      open: true,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      compulsory: compulsory,
    });
    setItemIndex(index);
    if (index !== null || index !== undefined) {
      setInvoice({ ...invoice });
    }
  };

  const invoice_initial_data = {
    customer_emails: {
      cc: [],
      to: [],
      bcc: [],
      toEmails: "",
      ccEmails: "",
      bccEmails: "",
    },
    tax: null,
    total_amount: 0,
    discount: 0,
    customer: null,
    customer_id: null,
    order_number: null,
    payment_term: null,
    place_of_supply: null,
    payment_method: "cash",
    sales_person_id: null,
    payment_status: null,
    payment_term_id: null,
    place_of_supply_id: null,
    invoice_number: null,
    entity_level_discount: null,
    terms_and_conditions_id: getTermsAndConditionPreferences(isRecurringInvoice ? 'recurring_invoice' : 'invoice'),

    business_id: getBusinessInfo().id,
    due_date: moment().format("YYYY-MM-DD"),
    invoice_date: moment().format("YYYY-MM-DD"),
    terms_and_condition: null,
    sales_items: [
      {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        description: null,
        hsn_sac_code: null,
        item_level_discount: "",
        is_item_discount_in_percentage: true,
      },
    ],

    tds_tcs_id: null,
    tds_tcs_amount: 0,
    selected_tds_tcs: null,
    tds_tcs_choice: "no_tax",

    is_draft: true,
    invoice_title: "Invoice Test",
    is_pro_forma: isProFormaInvoice,
    invoice_sub_heading: "Invoice Test",

    // Address
    billing_address: null,
    shipping_address: null,
    same_as_billing_address: false,
    note: getNotesPreferences(isRecurringInvoice ? 'recurring_invoice' : 'invoice'),

    is_entity_discount: true,
    tax_applied: getAmountArePreferences(),
    is_amount_tax_inclusive: getAmountArePreferences() === 'inclusive',
    currency_symbol: null,
    currency_code: null,
    exchange_rate: 1,
    attachment: null,

    newCustomer: null,
    itemIndex: null,
    newItem: null,
  };

  const [invoice, setInvoice] = React.useState(invoice_initial_data);

  React.useEffect(() => {
    if (params.id) {
      breadCrumbsStateChangeManager(dispatch, 'Edit');
      const getInvoice = async () => {
        setLoader(dispatch, Actions, true);

        let res = await apiAction({
          method: "post",
          url: retrieve_invoice(params.id),
          data: { business_id: getBusinessInfo().id },
        });
        const paymentRes = await apiAction({
          method: "post",
          url: list_payment_term(),
          data: {
            business_id: getBusinessInfo().id,
            sort_by: "name",
            sort_order: "A",
          },
        });
        if (res?.success && paymentRes.success) {
          const partyRes = await apiAction({
            method: "post",
            url: get_party(res?.result.customer_id),
            data: { business_id: getBusinessInfo().id },
          });

          // eslint-disable-next-line
          res?.result.sales_items.map((item) => {
            if (item?.tax) {
              item["tax_id"] = item?.tax.id;
              item["item"] = {
                name: item?.item ? item?.item : item?.expense_category_name,
                id: item?.item_id,
                gst_rate: item?.tax.rate ? item?.tax.rate : null,
              };
            } else {
              item["tax_id"] = null;
              item["item"] = {
                name: item?.item ? item?.item : item?.expense_category_name,
                id: item?.item_id,
                gst_rate: null,
              };
            }
            item["expense_category_id"] = item?.expense_category;
            item["expense_category_name"] = item?.expense_category_name;
            item["disabled"] = item?.item_id ? false : true;
          });

          let payment_item = paymentRes.result.find(
            (data) => data.id === res?.result.payment_term
          );
          setLoader(dispatch, Actions, false);
          setInvoice({
            ...invoice,
            ...res?.result,
            customer: partyRes?.result,
            invoice_no: res?.result.invoice_number,
            tds_tcs_amount: res?.result.tds_tcs_amount,
            sales_person_id: res?.result.sales_person,
            same_as_billing_address: isAddressSame(res?.result.billing_address, res?.result.shipping_address),

            place_of_supply_id: res?.result.place_of_supply.id,
            place_of_supply: res?.result.place_of_supply.id ? { ...res?.result.place_of_supply, name: res?.result.place_of_supply.state, } : null,

            payment_term: payment_item,
            payment_term_id: payment_item?.id,

            due_date: res?.result.due_date,

            terms_and_condition: res?.result.terms_and_conditions
              ? {
                id: res?.result.terms_and_conditions,
                name: res?.result.terms_and_conditions_name,
              }
              : null,
            terms_and_conditions_id: res?.result.terms_and_conditions,
            discount: res?.result.discount,
            tax_applied: res?.result.tax_applied,
            is_amount_tax_inclusive: res?.result.tax_applied === "inclusive" ? true : false,
            currency_symbol: res?.result.currency_symbol,
            currency_code: res?.result.currency_code,
          });
        }
      };

      getInvoice();
    }
    // eslint-disable-next-line
  }, []);

  // Clone
  React.useEffect(() => {

    if (params.clone_id) {
      breadCrumbsStateChangeManager(dispatch, 'Add')
      const getInvoice = async () => {
        setLoader(dispatch, Actions, true);

        let res = await apiAction({
          method: "post",
          url: retrieve_invoice(params.clone_id),
          data: { business_id: getBusinessInfo().id },
        });
        const paymentRes = await apiAction({
          method: "post",
          url: list_payment_term(),
          data: {
            business_id: getBusinessInfo().id,
            sort_by: "name",
            sort_order: "A",
          },
        });
        if (res?.success && paymentRes.success) {
          const partyRes = await apiAction({
            method: "post",
            url: get_party(res?.result.customer_id),
            data: { business_id: getBusinessInfo().id },
          });
          let payment_item = paymentRes.result.find((data) => data.id === res?.result.payment_term);

          setLoader(dispatch, Actions, false);

          // eslint-disable-next-line
          res?.result.sales_items.map((item) => {
            if (item?.tax) {
              item["tax_id"] = item?.tax.id;
              item["item"] = {
                name: item?.item ? item?.item : item?.expense_category_name,
                id: item?.item_id,
                gst_rate: item?.tax.rate ? item?.tax.rate : null,
              };
            } else {
              item["tax_id"] = null;
              item["item"] = {
                name: item?.item ? item?.item : item?.expense_category_name,
                id: item?.item_id,
                gst_rate: null,
              };
            }
            item["expense_category_id"] = item?.expense_category;
            item["expense_category_name"] = item?.expense_category_name;
            item["disabled"] = item?.item_id ? false : true;
          });

          setInvoice({
            ...invoice,
            ...res?.result,
            customer: partyRes?.result,

            tds_tcs_amount: res?.result.tds_tcs_amount,
            sales_person_id: res?.result.sales_person,
            same_as_billing_address: isAddressSame(
              res?.result.billing_address,
              res?.result.shipping_address
            ),

            place_of_supply_id: res?.result.place_of_supply.id,
            place_of_supply: res?.result.place_of_supply.id
              ? {
                ...res?.result.place_of_supply,
                name: res?.result.place_of_supply.state,
              }
              : null,

            payment_term: payment_item,
            payment_term_id: payment_item?.id,

            order_number: null,
            payment_status: null,
            invoice_number: invoice.invoice_number,
            invoice_date: moment().format("YYYY-MM-DD"),
            due_date: res?.result.payment_term ? moment().add(payment_item?.number_of_days, "days").format("YYYY-MM-DD") : invoice.due_date,
            terms_and_condition: res?.result.terms_and_conditions ? { id: res?.result.terms_and_conditions, name: res?.result.terms_and_conditions_name, } : null,
            terms_and_conditions_id: res?.result.terms_and_conditions,
            discount: res?.result.discount,
            tax_applied: res?.result.tax_applied,
            is_amount_tax_inclusive:
              res?.result.tax_applied === "inclusive" ? true : false,
            currency_symbol: res?.result.currency_symbol,
            currency_code: res?.result.currency_code,
          });
        }
      };

      getInvoice();
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {

    if (params.sales_id) {
      const getSalesOrder = async () => {
        setLoader(dispatch, Actions, true);
        let res = await apiAction({
          method: "post",
          url: retrieve_sales_order(params.sales_id),
          data: { business_id: getBusinessInfo().id },
        });
        if (res?.success) {
          setLoader(dispatch, Actions, false);
          let item = res?.result;

          item?.sales_order_items.map((item) => {
            if (item?.tax) {
              item["tax_id"] = item?.tax.id;
              item["item"] = {
                name: item?.item,
                id: item?.item_id,
                gst_rate: item?.tax.rate ? item?.tax.rate : null,
              };
            } else {
              item["tax_id"] = null;
              item["item"] = {
                name: item?.item,
                id: item?.item_id,
                gst_rate: null,
              };
            }
            item["disabled"] = true;
            item["expense_category_id"] = null;
            item["expense_category_name"] = null;
          });

          const partyRes = await apiAction({
            method: "post",
            url: get_party(res?.result.customer_id),
            data: { business_id: getBusinessInfo().id },
          });
          setLoader(dispatch, Actions, false);
          setInvoice({
            ...invoice,
            ...item,
            sales_order_id: item?.id,
            customer: partyRes?.result,

            place_of_supply_id: item?.place_of_supply.id,
            place_of_supply: item?.place_of_supply.id
              ? { ...item?.place_of_supply, name: item?.place_of_supply.state }
              : null,

            sales_person_id: item?.sales_person,
            same_as_billing_address: isAddressSame(
              item?.billing_address,
              item?.shipping_address
            ),

            payment_term: item?.payment_term
              ? {
                name: item?.payment_term_name,
                id: item?.payment_term,
                number_of_days: item?.payment_term_number_of_days,
              }
              : invoice.payment_term,
            payment_term_id: item?.payment_term
              ? item?.payment_term
              : invoice.payment_term_id,
            due_date: item?.payment_term
              ? moment()
                .add(item?.payment_term_number_of_days, "days")
                .format("YYYY-MM-DD")
              : invoice.due_date,

            note: null,
            attachment: null,
            terms_and_condition: null,
            terms_and_conditions_id: null,

            is_amount_tax_inclusive:
              item?.tax_applied === "inclusive" ? true : false,

            order_number: item?.sales_order_number,

            sales_items: item?.sales_order_items,

            payment_method: item?.payment_method ? item?.payment_method : "cash",

            options: [...item?.sales_order_items],
          });
        }
      };
      setTimeout(() => {
        getSalesOrder();
      }, 1500);
      // eslint-disable-next-line
    }
  }, []);

  React.useEffect(() => {

    if (params.challan_id) {
      const getDeliveryChallan = async () => {
        setLoader(dispatch, Actions, true);
        let res = await apiAction({
          method: "post",
          url: retrieve_delivery_challan(params.challan_id),
          data: { business_id: getBusinessInfo().id },
        });
        if (res?.success) {
          const partyRes = await apiAction({
            method: "post",
            url: get_party(res?.result.customer_id),
            data: { business_id: getBusinessInfo().id },
          });
          if (partyRes?.success) {
            setLoader(dispatch, Actions, false);
            let item = res?.result;
            let Part_item = partyRes?.result;
            let party_contact_data = Part_item?.party_contact_data.find(
              (data) => data.contact_type === "primary"
            );

            item?.delivery_challan_items.map((item) => {
              if (item?.tax) {
                item["tax_id"] = item?.tax.id;
                item["item"] = {
                  name: item?.item,
                  id: item?.item_id,
                  gst_rate: item?.tax.rate ? item?.tax.rate : null,
                };
              } else {
                item["tax_id"] = null;
                item["item"] = {
                  name: item?.item,
                  id: item?.item_id,
                  gst_rate: null,
                };
              }
              item["disabled"] = true;
              item["expense_category_id"] = null;
              item["expense_category_name"] = null;
              item["challan_quantity"] = item?.quantity;
            });
            setInvoice({
              ...invoice,
              ...item,
              delivery_challan_id: item?.id,

              customer: Part_item,
              customer_emails: {
                ...invoice_initial_data.customer_emails,
                to: party_contact_data.email ? [party_contact_data.email] : [],
              },

              payment_term: Part_item?.payment_term
                ? Part_item?.payment_term
                : invoice.payment_term,
              payment_term_id: Part_item?.payment_term
                ? Part_item?.payment_term.id
                : invoice.payment_term_id,
              due_date: Part_item?.payment_term
                ? moment()
                  .add(Part_item?.payment_term.number_of_days, "days")
                  .format("YYYY-MM-DD")
                : invoice.due_date,

              place_of_supply_id: item?.place_of_supply.id,
              place_of_supply: item?.place_of_supply.id
                ? { ...item?.place_of_supply, name: item?.place_of_supply.state }
                : null,

              same_as_billing_address: isAddressSame(
                item?.billing_address,
                item?.shipping_address
              ),

              note: null,
              attachment: null,
              terms_and_condition: null,
              terms_and_conditions_id: null,

              is_amount_tax_inclusive:
                item?.tax_applied === "inclusive" ? true : false,

              order_number: item?.delivery_challan_number,

              sales_items: item?.delivery_challan_items,

              payment_method: item?.payment_method
                ? item?.payment_method
                : "cash",

              options: [...item?.delivery_challan_items],
            });
          }
        }
      };
      setTimeout(() => {
        getDeliveryChallan();
      }, 1500);
      // eslint-disable-next-line
    }
  }, []);

  React.useEffect(() => {

    if (params.estimate_id) {
      const getSalesOrder = async () => {
        setLoader(dispatch, Actions, true);

        // console.log(partyRes)
        let res = await apiAction({
          method: "post",
          url: retrieve_estimate(params.estimate_id),
          data: { business_id: getBusinessInfo().id },
        });
        if (res?.success) {
          setLoader(dispatch, Actions, false);
          let item = res?.result;
          item?.estimate_items.map((item) => {
            if (item?.tax) {
              item["tax_id"] = item?.tax.id;
              item["item"] = {
                name: item?.item,
                id: item?.item_id,
                gst_rate: item?.tax.rate ? item?.tax.rate : null,
              };
            } else {
              item["tax_id"] = null;
              item["item"] = {
                name: item?.item,
                id: item?.item_id,
                gst_rate: null,
              };
            }
            item["disabled"] = true;
            item["expense_category_id"] = null;
            item["expense_category_name"] = null;
          });

          const partyRes = await apiAction({
            method: "post",
            url: get_party(res?.result.customer_id),
            data: { business_id: getBusinessInfo().id },
          });
          if (partyRes?.success) {
            setInvoice({
              ...invoice,
              ...item,
              estimate_id: item?.id,
              customer: partyRes?.result,

              place_of_supply_id: item?.place_of_supply.id,
              place_of_supply: item?.place_of_supply.id ? { ...item?.place_of_supply, name: item?.place_of_supply.state } : null,

              sales_person_id: item?.sales_person,
              same_as_billing_address: isAddressSame(
                item?.billing_address,
                item?.shipping_address
              ),

              payment_term: partyRes?.result.payment_term ? partyRes?.result.payment_term : invoice.payment_term,
              payment_term_id: partyRes?.result.payment_term ? partyRes?.result.payment_term.id : invoice.payment_term_id,
              due_date: partyRes?.result.payment_term ? moment().add(partyRes?.result.payment_term.number_of_days, "days").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),

              note: null,
              attachment: null,
              terms_and_condition: null,
              terms_and_conditions_id: null,

              is_amount_tax_inclusive:
                item?.tax_applied === "inclusive" ? true : false,


              order_number: item?.estimate_number,

              sales_items: item?.estimate_items,

              payment_method: item?.payment_method
                ? item?.payment_method
                : "cash",

              options: [...item?.estimate_items],
            });
          }
        }
      };
      setTimeout(() => {
        getSalesOrder();
      }, 1000);
      // eslint-disable-next-line
    }
  }, []);

  const fetchExpense = async (taxRes) => {
    try {
      const expenseResponse = await fetchExpenseData(params.expense_id);
      if (expenseResponse?.success) {
        const partyResponse = await fetchPartyData(expenseResponse.result.customer_id);
        if (partyResponse?.success) {
          const updatedExpenseItems = processExpenseItems(
            expenseResponse.result,
            taxRes
          );
          updateInvoice(expenseResponse.result, partyResponse.result, updatedExpenseItems);
        }
      }
    } catch (error) {
      console.error("Error fetching expense data:", error);
    } finally {
      setLoader(dispatch, Actions, false);
    }
  };

  const fetchExpenseData = async (expenseId) => {
    return await apiAction({
      method: "post",
      url: retrieve_expense(expenseId),
      data: { business_id: getBusinessInfo().id },
    });
  };

  const fetchPartyData = async (customerId) => {
    return await apiAction({
      method: "post",
      url: get_party(customerId),
      data: { business_id: getBusinessInfo().id },
    });
  };

  const processExpenseItems = (item, taxRes) => {
    const markUpPercent = item?.mark_up_percent;

    return item?.expense_accounts_list.map((expense) => {
      const defaultTax = taxRes.find((tax) => tax.id === expense?.tax_id);
      const markUpAmount = (expense?.amount * markUpPercent) / 100;
      const rateWithMarkUp = Number((expense?.amount + markUpAmount).toFixed(2));

      return {
        ...expense,
        disabled: true,
        quantity: 1,
        rate_with_mark_up_amount: rateWithMarkUp,
        mark_up_amount: Number(markUpAmount.toFixed(2)),
        rate: rateWithMarkUp,
        amount: rateWithMarkUp,
        item_level_discount: 0,
        expense_category_id: expense?.account_id,
        expense_category_name: expense?.account_name,
        is_item_discount_in_percentage: checkItemDiscount(invoice?.sales_items),
        tax: isBusinessRegistered() ? defaultTax : null,
        tax_id: isBusinessRegistered() ? defaultTax?.id : null,
        item: {
          id: null,
          name: expense?.account_name,
          gst_rate: isBusinessRegistered() ? defaultTax?.rate || null : null,
        },
      };
    });
  };

  const updateInvoice = (item, party, updatedExpenseItems) => {
    const partyContactData = party?.party_contact_data.find((data) => data.contact_type === "primary");

    const isGstWithZero = ["overseas", "sez"].includes(party?.gst_registration_type);
    const isTaxApplied = updatedExpenseItems?.some((expense) => isBusinessRegistered() && expense.tax_id);

    const taxApplied = isTaxApplied || isGstWithZero ? 'exclusive' : 'no_tax';
    const isAmountTaxInclusive = taxApplied === 'inclusive';

    setInvoice({
      ...invoice,
      ...item,
      expense_id: item?.id,
      customer: party,
      customer_emails: {
        ...invoice_initial_data.customer_emails,
        to: partyContactData?.email ? [partyContactData.email] : [],
      },
      payment_term: party?.payment_term || invoice.payment_term,
      payment_term_id: party?.payment_term?.id || invoice.payment_term_id,
      billing_address: party?.billing_address ? getAddress(party.billing_address) : "",
      shipping_address: party?.shipping_address ? getAddress(party.shipping_address) : "",
      due_date: party?.payment_term ? moment().add(party.payment_term.number_of_days, "days").format("YYYY-MM-DD") : invoice.due_date,
      same_as_billing_address: isAddressSame(party?.billing_address ? getAddress(party.billing_address) : null, party?.shipping_address ? getAddress(party.shipping_address) : null),

      note: null,
      attachment: null,
      terms_and_condition: null,
      terms_and_conditions_id: null,
      order_number: item?.delivery_challan_number,
      payment_method: item?.payment_method || "cash",

      tax_applied: taxApplied,
      is_amount_tax_inclusive: isAmountTaxInclusive,
      sales_items: updatedExpenseItems,
    });
  };


  React.useEffect(() => {

    if (params.expense_id) {
      const fetchTax = async () => {
        let res = await apiAction({
          method: "post",
          url: get_list_of_tax(),
          data: { business_id: getBusinessInfo().id },
        });
        if (res?.success) {
          fetchExpense(res?.result);
        }
      };

      setTimeout(() => {
        fetchTax();
      }, 1500);
    }
  }, []);

  // api call to set the default invoice_number
  React.useEffect(() => {

    const apiResults = async () => {
      let res = await apiAction({
        method: "post",
        url: get_create_invoice_meta_data(),
        data: { business_id: getBusinessInfo().id },
      });
      if (res?.success) {
        invoice.invoice_number = res?.result.invoice_number;
        setInvoice({ ...invoice, invoice_number: res?.result.invoice_number });
      } else {
      }
    };
    if (!params.id) {
      apiResults();
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {

    const fetchCustomerData = async (id) => {
      setLoader(dispatch, Actions, true);

      let res = await apiAction({
        method: "post",
        url: get_party(id),
        data: { business_id: getBusinessInfo().id },
      });
      if (res?.success) {
        let customer = res?.result;
        let party_contact_data = res?.result.party_contact_data.find(
          (data) => data.contact_type === "primary"
        );

        setInvoice({
          ...invoice,
          customer: customer, customer_emails: { ...invoice_initial_data.customer_emails, to: party_contact_data.email ? [party_contact_data.email] : [], },
          customer_id: customer.id,
          billing_address: customer.billing_address ? getAddress(customer.billing_address) : "",
          shipping_address: customer.shipping_address ? getAddress(customer.shipping_address) : "",
          payment_term: customer.payment_term ? customer.payment_term : null,
          payment_term_id: customer.payment_term ? customer.payment_term.id : null,
          due_date: customer.payment_term ? moment().add(customer.payment_term.number_of_days, "days").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
          payment_method: customer.preferred_payment_method ? customer.preferred_payment_method : "cash",
          same_as_billing_address: isAddressSame(customer.billing_address ? getAddress(customer.billing_address) : null, customer.shipping_address ? getAddress(customer.shipping_address) : null),

          currency_id: customer.currency ? customer.currency.id : null,
          currency_symbol: customer.currency ? customer.currency.symbol : null,
          currency_code: customer.currency ? customer.currency.currency_code : null,
        });

        setLoader(dispatch, Actions, false);
      } else {
        setLoader(dispatch, Actions, false);
      }
    };

    if (params.customer_id) {
      fetchCustomerData(params.customer_id);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {

    if (isRecurringInvoice) {
      setRecurringInvoiceData({
        ...recurringInvoiceData,
        invoice_data: invoice,
      });
    }
  }, [invoice]);

  React.useEffect(() => {
    if (invoice_data) {
      setTimeout(() => {
        setLoader(dispatch, Actions, false);
        setInvoice({
          ...invoice_initial_data,
          ...invoice_data,
        });
      }, 1000);
    }
  }, [invoice_data]);

  React.useEffect(() => {
    if (onClickSave) {
      setFormSubmitted(onClickSave);
    }
  }, [onClickSave]);

  const onClear = () => {
    setClear(true);
    setFormSubmitted(false);
    setInvoice({
      ...invoice_initial_data,
      invoice_number: invoice.invoice_number,
    });
  };
  const saveEvents = (is_draft) => {
    if (params.id) {
      AnalyticsEvent(eventsNames.categories.INVOICES, { action: eventsNames.actions.EDIT })
    } else if (is_draft) {
      AnalyticsEvent(eventsNames.categories.INVOICES, { action: eventsNames.actions.CREATE, state: eventsNames.actions.invoice.state.DRAFT })
    } else {
      AnalyticsEvent(eventsNames.categories.INVOICES, { action: eventsNames.actions.CREATE, state: eventsNames.actions.invoice.state.SAVE })
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  const gst_registration_type =
    invoice.customer && invoice.customer.gst_registration_type;

  let is_Location = gst_registration_type === "overseas";
  let is_gst_with_zero =
    gst_registration_type === "overseas" || gst_registration_type === "sez";
  //////////////////////////////////////////////////////////////////////////////////////////
  const onSave = async ({ is_draft = false, is_save_send = false }) => {
    console.log("invoice On save====>", invoice);

    setFormSubmitted(true);
    const {
      due_date,
      sales_items,
      tax_applied,
      invoice_date,
      tds_tcs_choice,
      exchange_rate,
      is_pro_forma,
      customer_emails,
    } = invoice;
    const { to } = customer_emails;

    const is_rate = sales_items.find((item) => item?.rate === null);
    const is_quantity = sales_items.find((item) => !item?.quantity);
    const is_sales_item_not_selected = sales_items.find((item) => !item?.item_id && !item?.expense_category_id);
    const is_tax = sales_items.find((item) => tax_applied !== "no_tax" && !item?.tax_id);

    let validation_data = [
      { key: "customer_id", message: "Please Select Customer" },
      { key: "invoice_number", message: "Please Enter Invoice Number" },
      isBusinessRegistered() && !is_Location && { key: "place_of_supply_id", message: "Please Select Place of Supply", },

      { key: "payment_term_id", message: "Please Select Terms" },
      { key: "invoice_date", message: "Please Enter Valid Invoice Date" },
      { key: "due_date", message: "Please Enter Valid Due Date" },
      { key: "", validation: invoice_date && due_date && moment(invoice_date).format("YYYY-MM-DD") > moment(due_date).format("YYYY-MM-DD"), due_date, message: "The Payment Due Date can not be Before the Invoice Date.", },
      { key: "", validation: is_sales_item_not_selected, message: "Please Select Item", },
      { key: "", validation: is_quantity, message: "Please Enter the Quantity", },
      { key: "", validation: is_rate, message: "Please Enter the Rate" },
      { key: "", validation: is_tax, message: "Please Select the Tax" },
      tds_tcs_choice !== "no_tax" && { key: "tds_tcs_id", message: `Please Select the ${tds_tcs_choice.toLocaleUpperCase()} Tax`, },
      { key: "", validation: !exchange_rate, message: 'Exchange rate can not be empty or zero' },
    ];

    const { isValid, message } = isFormValid(invoice, validation_data);
    if (isValid) {
      invoice.customer_emails.to = filterArray(invoice.customer_emails.to)
      if (is_draft || is_pro_forma) {
        invoice.is_draft = true;
      } else {
        invoice.is_draft = false;
      }
      if (isApplicableForTax(invoice.tax_applied, isBusinessRegistered())) {
        setZero('isApplicableForTax');
        setSaveAndSend(is_save_send);
      } else if (invoice.total_amount === 0) {
        setZero('withZero');
        setSaveAndSend(is_save_send);
      } else {
        onSaveAction(is_save_send);
      }
    } else {
      stateChangeManager(dispatch, Actions, true, "error", message);
    }
  };

  const onSaveAction = async (is_save_send) => {
    saveEvents(invoice.is_draft)
    setLoader(dispatch, Actions, true);
    let res = await apiFormData({
      method: "post",
      data: invoice,
      url: params.id ? update_invoice(params.id) : create_invoice(),
    });

    if (res?.success) {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, "success", res?.status);
      if (is_save_send) {
        sendInvoice(res?.result.id);
      } else {
        redirect(res?.result.id);
        setLoader(dispatch, Actions, false);
      }
    } else {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, "error", res?.status);
    }
  };
  const redirect = (id) => {
    if (onClose) {
      onClose(id);
    } else {
      navigate(routesName.invoicingSalesInvoiceView.path + "?id=" + id);
    }
  };

  const sendInvoice = async (invoice_id) => {
    setSendMail(true);
    setSendMailId(invoice_id);
  };

  const onAddCustomer = (customer) => {
    setInvoice({ ...invoice, newCustomer: customer });
  };


  const onAddItem = (value) => {
    // Construct the new item data
    const newItemData = {
      item: value,
      quantity: 1,
      item_order: null,
      item_id: value.id,
      unit_id: value?.unit?.id,
      amount: value.selling_price,
      description: value.description,
      hsn_sac_code: value.hsn_sac_code,
      rate: parseFloat(value.selling_price),
      item_level_discount: value.item_level_discount || 0,
      is_item_discount_in_percentage: checkItemDiscount(invoice?.sales_items),

      gst_rate: value?.tax?.rate,
      tax: invoice.tax_applied === "no_tax" ? null : value?.tax,
      tax_id: invoice.tax_applied === "no_tax" ? null : value?.tax?.id,
    };

    // Update the sales order items
    invoice.sales_items[itemIndex] = newItemData;

    // Update the sales order state
    setInvoice(prevOrder => ({
      ...prevOrder,
      newItem: value,
      sales_items: [...prevOrder.sales_items],
    }));

    // Update the new item state
    setNewItem(value);
  };


  const {
    accounts,
    totalAmount,
    totalTax,
    totalDiscount,
    subTotal,
    tds_tcs_amount,
    finalPayment,
  } = useFinalCalculations(invoice,'sales_items');



  const [sendMail, setSendMail] = React.useState(false);
  const [sendMailId, setSendMailId] = React.useState(false);

  return (
    <div>
      <CustomDialog
        state={state}
        setState={setState}
        itemIndex={invoice.itemIndex}
        onAddItem={(item) => onAddItem(item)}
        onAddCustomer={(customer) => onAddCustomer(customer)}
      />
      <CommonPreviewShare
        open={sendMail}
        id={sendMailId}
        type={"invoice"}
        file_key={"invoice_number"}
        export_Url={export_invoice}
        retrieve_Url={retrieve_invoice}
        setOpen={(value) => {
          setSendMail(value);
          redirect(sendMailId);
        }}
      />
      <CommonAlertBox
        open={zero}
        setOpen={setZero}
        onSaveAction={() => {
          if (zero==='withZero') {
            onSaveAction(saveAndSend);
          }else if (invoice.total_amount === 0) {
            setTimeout(()=>{
              setZero('withZero');
            },100)
          } else{
            onSaveAction(saveAndSend);
          }
        }}
        title={`${invoice.is_pro_forma ? 'Pro Forma ' : ''}Invoice No. ${invoice.invoice_number ? invoice.invoice_number : ""}`}
        message={
          zero==='withZero'?
          `You are about to create the invoice with zero amount. Are you sure you want to proceed?`
          :
          `Please consult the relevant provisions of the laws to determine the application of taxes for GST unregistered entities.`
        }
      />
      <CustomTitleContainer>
        <Grid
          container
          spacing={0}
          style={{
            alignItems: "center",
            justifyItems: "center",
            alignContent: "space-evenly",
            justifyContent: "space-evenly",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "-webkit-flex", justifyContent: "start" }}
          >
            {isRecurringInvoice ? (
              <Box sx={{ display: "-webkit-flex" }}>
                <CustomTitle
                  title={
                    <span>
                      {title}
                      <FormControlLabel
                        id={"is_active_checkbox"}
                        dataTestId={"is_active_checkbox"}
                        sx={{ paddingLeft: "20px", margin: '0px' }}
                        control={
                          <Checkbox
                            size="small"
                            checked={recurringInvoiceData.is_active}
                            onClick={() => {
                              setRecurringInvoiceData({
                                ...recurringInvoiceData,
                                is_active: !recurringInvoiceData.is_active,
                              });
                            }}
                            sx={{ color: "#9b9898" }}
                          />
                        }
                        label={
                          <span
                            style={{
                              color: "#000000",
                              fontFamily: "Noto Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "16px",
                              fontSize: "14px",
                            }}
                          >
                            Is active
                          </span>
                        }
                      />
                    </span>
                  }
                />
              </Box>
            ) : (
              <CustomTitle
                title={`${invoice.is_pro_forma ? 'Pro Forma ' : ''}Invoice No. ${invoice.invoice_number ? invoice.invoice_number : ""
                  }`}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "-webkit-flex", justifyContent: "end" }}
          >
            <CustomTypography
              text={
                <span>
                  Balance Due:{" "}
                  <span style={{ fontWeight: "700", color: "#000000" }}>
                    <CurrencyFormatter
                      amount={invoice.total_amount}
                      currency={invoice.currency_code}
                    />
                  </span>
                </span>
              }
              sx={{
                fontSize: "16px",
                color: "#7A7A7A",
                fontWeight: "600",
                textAlign: "right",
                lineHeight: "22px",
                fontStyle: "normal",
                fontFamily: "Noto Sans",
              }}
            />
          </Grid>
        </Grid>
      </CustomTitleContainer>

      <CustomContainer maxWidth={"400"} sx={{ height: isRecurringInvoice ? 'calc(100vh - 202px)' : 'calc(100vh - 191px)', }}>
        <form noValidate>
          <div style={{ borderBottom: "4px solid #F5F5F5" }}>
            <CustomDetails
              invoice={invoice}
              isClear={isClear}
              open={state.open}
              setInvoice={setInvoice}
              preference={preference}
              repeat_list={repeat_list}
              is_Location={is_Location}
              isFormSubmitted={isFormSubmitted}
              customerEditable={customerEditable}
              is_gst_with_zero={is_gst_with_zero}
              addButtonHandler={addButtonHandler}
              repeat_every_list={repeat_every_list}
              isRecurringInvoice={isRecurringInvoice}
              recurringInvoiceData={recurringInvoiceData}
              isFromCustomer={props.customer ? true : false}
              setRecurringInvoiceData={setRecurringInvoiceData}
              customer={props.customer ? props.customer : null}
            />
          </div>
          <ProductAndServicesDetails
            open={state.open}
            isClear={isClear}
            newItem={newItem}
            invoice={invoice}
            totalTax={totalTax}
            accounts={accounts}
            subTotal={subTotal}
            itemIndex={itemIndex}
            setInvoice={setInvoice}
            totalAmount={totalAmount}
            is_Location={is_Location}
            finalPayment={finalPayment}
            totalDiscount={totalDiscount}
            tds_tcs_amount={tds_tcs_amount}
            isFormSubmitted={isFormSubmitted}
            addButtonHandler={addButtonHandler}
            is_gst_with_zero={is_gst_with_zero}
            isRecurringInvoice={isRecurringInvoice}
          />
        </form>
      </CustomContainer>
      {isRecurringInvoice ? null : (
        <CustomButtonContainer>
          <Buttons
            onSave={onSave}
            onClear={onClear}
            isClear={isClear}
            invoice={invoice}
            customer={customer}
            is_Location={is_Location}
            onCancel={() => navigate(-1)}
            is_gst_with_zero={is_gst_with_zero}
            setFormSubmitted={setFormSubmitted}
          />
        </CustomButtonContainer>
      )}
    </div>
  );
};

const CustomDetails = (props) => {
  const {
    is_Location,
    open,
    invoice,
    setInvoice,
    addButtonHandler,
    isFormSubmitted,
    isClear,
    isFromCustomer,
    customer,
    isRecurringInvoice,
    repeat_every_list,
    repeat_list,
    preference,
    recurringInvoiceData,
    setRecurringInvoiceData,
    customerEditable,
  } = props;
  const { customer_emails } = invoice;

  const location = useLocation();
  const params = React.useMemo(() => getQueryParams(location.search), [location.search]);

  const onChange = React.useCallback((key_name, value) => {
    setInvoice((prevInvoice) => ({ ...prevInvoice, [key_name]: value }));
  }, [setInvoice]);

  React.useEffect(() => {
    if (isClear && Object.keys(params).length === 0) {
      setInvoice((prevInvoice) => ({ ...prevInvoice, customer: null }));
    }
  }, [isClear, params, setInvoice]);

  React.useEffect(() => {
    if (invoice.same_as_billing_address) {
      setInvoice((prevInvoice) => ({ ...prevInvoice, shipping_address: invoice.billing_address }));
    } else {
      setInvoice((prevInvoice) => ({ ...prevInvoice, shipping_address: invoice.shipping_address }));
    }
  }, [invoice.same_as_billing_address, invoice.billing_address, setInvoice]);

  const [defaultTerm, setDefaultTerm] = React.useState();
  const onFetchTerms = React.useCallback((term) => {
    if (term && Object.keys(params).length === 0 && (!isFromCustomer || !customer.payment_term)) {
      setTimeout(() => {
        setInvoice((prev) => ({
          ...prev,
          payment_term: term,
          payment_term_id: term.id,
          due_date: moment(prev.invoice_date).add(term.number_of_days, "days").format("YYYY-MM-DD"),
        }));
      }, 2000);
    }
  }, [params, isFromCustomer, customer, setInvoice]);

  React.useEffect(() => {
    if (invoice.newCustomer) {
      customerMappings(invoice.newCustomer, invoice, setInvoice);
    }
  }, [invoice.newCustomer, setInvoice]);

  const setEmails = React.useCallback((emails) => {
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      customer_emails: { ...customer_emails, cc: emails.cc, bcc: emails.bcc },
    }));
  }, [customer_emails, setInvoice]);

  const [clickedOutside, setClickedOutside] = React.useState(true);
  const myRef = React.useRef();

  const handleClickOutside = React.useCallback((e) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      setClickedOutside(true);
    }
  }, []);

  const handleClickInside = React.useCallback(() => setClickedOutside(false), []);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  const [isSalesPersonField, setIsSalesPersonField] = React.useState(getSalesPersonPreferences());
  React.useEffect(() => {
    if (invoice.sales_person) {
      setIsSalesPersonField(getSalesPersonPreferences() || !!invoice.sales_person);
    }
  }, [invoice.sales_person]);

  return (
    <Box
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "32px",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4.5}>
          <CommonAsyncDropdown
            id={"customer_dropdown"}
            dataTestId={"customer_dropdown"}
            autoFocus={true}
            autoSelect={false}
            disableClearable={false}
            optionLabel="display_name"
            placeholder="Search Customer"
            noOptionsText={"No result found"}
            validation={isFormSubmitted && !invoice.customer_id}
            item={{
              open: open,
              method: "post",
              url: list_party(1),
              value: invoice.customer,
              label: <LabelWithAsteriskMark label={"Customer"} />,
              body: {
                is_inactive: false,
                business_id: getBusinessInfo().id,
                role: "customer",
              },
              disabled: customerEditable !== undefined ? customerEditable : params.expense_id ? true : (!params.clone_id && !invoice.is_draft && Object.keys(params).length !== 0),
              onChange: async (event, newValue) => {
                if (newValue) {
                  if (newValue.id !== invoice.customer_id) {
                    const partyRes = await apiAction({
                      method: "post",
                      url: get_party(newValue.id),
                      data: { business_id: getBusinessInfo().id },
                    });
                    if (partyRes?.success) {
                      customerMappings(partyRes?.result, invoice, setInvoice);
                    }
                  }
                } else {
                  customerMappings(newValue, invoice, setInvoice);
                }
              },
            }}
            addButton={{
              title: "+ Add new customer",
              onClick: () =>
                addButtonHandler("New Customer", "new_customer", "lg"),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4.5}>
          <Grid item xs={12} container>
            <Grid item xs={6}>
              <CustomTypography
                text={'Customer Email'}
                // text={<LabelWithAsteriskMark label={"Customer Email"} />}
                sx={{
                  mb: 1,
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: "700",
                  lineHeight: "18px",
                  fontStyle: "normal",
                  textTransform: "none",
                  fontFamily: "Noto Sans",
                  textTransform: "capitalize",
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Emails
                value={invoice}
                setValue={setInvoice}
                customer_emails={customer_emails}
                setEmail={setEmails}
              />
            </Grid>
          </Grid>

          <div onClick={handleClickInside} ref={myRef}>
            <ReactMultiEmail
              id={"input_to_email"}
              dataTestId={"input_to_email"}
              emails={filterArray(invoice.customer_emails.to)}
              onChange={(emails) => {
                setInvoice({
                  ...invoice,
                  customer_emails: { ...customer_emails, to: emails },
                });
              }}
              // placeholder={<span style={{}}><span style={{ margin: 0, height: "100%", overflow: 'hidden', color: '#2F2F2E', fontWeight: '400', lineHeight: '0px', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Noto Sans', opacity: 0.50, }}>Enter Email Address</span></span>}
              placeholder={
                <p
                  style={{
                    margin: "0px",
                    marginTop: "-8px",
                    position: "sticky",
                  }}
                >
                  <span
                    style={{
                      margin: 0,
                      height: "100%",
                      overflow: "hidden",
                      color: "#2F2F2E",
                      fontWeight: "400",
                      lineHeight: "0px",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontFamily: "Noto Sans",
                      opacity: 0.5,
                    }}
                  >
                    Enter Email Address
                  </span>
                </p>
              }
              style={{
                marginTop: "0px",
                textAlign: "center",
                borderRadius: "4px",
                overflowY: "scroll",
                maxHeight: 20,
                borderColor: clickedOutside
                  ?
                  // isFormSubmitted &&
                  //  invoice.customer_emails.to.length === 0
                  //   ? "#d32f2f":
                  '#c7c7c7'
                  : "#2464EB",
                borderWidth: clickedOutside ? 1.5 : 2,
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index} style={{ ...input_css_style }}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
              validateEmail={(email) => {
                if (email) {
                  return isEmail(email);
                } else {
                  return false;
                }
                // return email?isEmail(email):false; // return boolean
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          {isRecurringInvoice ? (
            <Input
              id={"input_profile_name"}
              dataTestId={"input_profile_name"}
              item={{
                type: "text",
                required: true,
                fullWidth: true,
                texttransform: "none",
                placeholder: "Enter Profile Name",
                title: <LabelWithAsteriskMark label={"Profile Name"} />,
                validation:
                  isFormSubmitted && !recurringInvoiceData.profile_name,
                value: recurringInvoiceData.profile_name
                  ? recurringInvoiceData.profile_name
                  : "",
                onChange: (e) =>
                  setRecurringInvoiceData({
                    ...recurringInvoiceData,
                    profile_name: e.target.value,
                  }),
              }}
            />
          ) : (
            <Input
              id={"input_invoice_number"}
              dataTestId={"input_invoice_number"}
              item={{
                type: "text",
                required: true,
                fullWidth: true,
                texttransform: "none",
                inputProps: { maxLength: 16 },
                placeholder: "Enter Invoice Number",
                validation: isFormSubmitted && !invoice.invoice_number,
                title: <LabelWithAsteriskMark label={"Invoice Number"} />,
                onChange: (e) => onChange("invoice_number", e.target.value),
                value: invoice.invoice_number ? invoice.invoice_number : "",
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ pt: 4 }}>
        <Grid container item xs={12} sm={9} spacing={3}>
          {/*//////////////////////////////// Place of supply ////////////////////////////////*/}
          <Grid item xs={12} sm={3}>
            <CommonDropdown
              stateComponent={true}
              id={"place_of_supply_dropdown"}
              dataTestId={"place_of_supply_dropdown"}
              disableClearable={false}
              placeholder="Select a Location"
              validation={
                isBusinessRegistered() &&
                !is_Location &&
                isFormSubmitted &&
                !invoice.place_of_supply_id
              }
              item={{
                method: "post",
                textTransform: "none",
                disabled: is_Location,
                url: get_list_of_state(),
                value: invoice.place_of_supply,
                body: { business_id: getBusinessInfo().id },
                label: <LabelWithAsteriskMark label={"Place of Supply"} isAsteriskMark={isBusinessRegistered()} />,
                onChange: (event, value) => {
                  if (value) {
                    setInvoice({
                      ...invoice,
                      place_of_supply: value,
                      place_of_supply_id: value.id,
                    });
                  } else {
                    setInvoice({
                      ...invoice,
                      place_of_supply: null,
                      place_of_supply_id: null,
                    });
                  }
                },
              }}
            />
          </Grid>

          {/*//////////////////////////////// Terms ////////////////////////////////*/}
          <Grid item xs={12} sm={3}>
            {isRecurringInvoice ? (
              <ListTerms
                id={"payment_term_dropdown"}
                dataTestId={"payment_term_dropdown"}
                isClear={isClear}
                remove_value_key={"Custom"}
                setTerms={(key_name, value, item) => {
                  if (value) {
                    setInvoice({
                      ...invoice,
                      [key_name]: value,
                      payment_term: item,
                      due_date:
                        item?.number_of_days !== null
                          ? moment(invoice.invoice_date)
                            .add(item?.number_of_days, "days")
                            .format("YYYY-MM-DD")
                          : null,
                    });
                  } else {
                    setInvoice({
                      ...invoice,
                      [key_name]: value,
                      payment_term: item,
                      due_date: invoice.invoice_date,
                    });
                  }
                }}
                onFetchTerms={onFetchTerms}
                value={invoice.payment_term}
                validation={isFormSubmitted}
                onDueDateChange={setDefaultTerm}
                defaultTerm={invoice.payment_term}
              />
            ) : (
              <ListTerms
                id={"payment_term_dropdown"}
                dataTestId={"payment_term_dropdown"}
                isClear={isClear}
                setTerms={(key_name, value, item) => {
                  if (value) {
                    setInvoice({
                      ...invoice,
                      [key_name]: value,
                      payment_term: item,
                      due_date:
                        item?.number_of_days !== null
                          ? moment(invoice.invoice_date)
                            .add(item?.number_of_days, "days")
                            .format("YYYY-MM-DD")
                          : null,
                    });
                  } else {
                    setInvoice({
                      ...invoice,
                      [key_name]: value,
                      payment_term: item,
                      due_date: invoice.invoice_date,
                    });
                  }
                }}
                onFetchTerms={onFetchTerms}
                value={invoice.payment_term}
                validation={isFormSubmitted}
                onDueDateChange={setDefaultTerm}
                defaultTerm={invoice.payment_term}
              />
            )}
          </Grid>

          {/*//////////////////////////////// Invoice date ////////////////////////////////*/}
          <Grid item xs={12} sm={3}>
            {isRecurringInvoice ? (
              <DatePicker
                id={"date_select_1"}
                dataTestId={"date_select_1"}
                isTime={false}
                placeholder=""
                title={<LabelWithAsteriskMark label={"Start On"} />}
                validation={
                  isFormSubmitted && !recurringInvoiceData.selected_starts_on
                }
                date={
                  recurringInvoiceData.selected_starts_on
                    ? moment(recurringInvoiceData.selected_starts_on)
                    : null
                }
                setDate={(date) => {
                  if (date) {
                    setRecurringInvoiceData({
                      ...recurringInvoiceData,
                      selected_starts_on: date.format("YYYY-MM-DD HH:mm:ssZ"),
                      starts_on: moment(date).utc().format("YYYY-MM-DD HH:mm:ssZ"),
                    });
                  } else {
                    setRecurringInvoiceData({
                      ...recurringInvoiceData,
                      starts_on: null,
                      selected_starts_on: null,
                    });
                  }
                }}
              />
            ) : (
              <DatePicker
                id={"date_select_1"}
                dataTestId={"date_select_1"}
                placeholder=""
                validation={isFormSubmitted && !invoice.invoice_date}
                title={<LabelWithAsteriskMark label={"Invoice Date"} />}
                date={invoice.invoice_date ? moment(invoice.invoice_date) : null}
                setDate={(date) => {
                  if (date) {
                    setInvoice({
                      ...invoice,
                      invoice_date: date.format("YYYY-MM-DD"),
                      due_date: invoice.payment_term && invoice.payment_term.number_of_days ?
                        moment(date.format("YYYY-MM-DD")).add(invoice.payment_term.number_of_days, "days").format("YYYY-MM-DD")
                        : date.format("YYYY-MM-DD") > moment(invoice.due_date).format("YYYY-MM-DD") ? date.format("YYYY-MM-DD") : invoice.due_date,
                    });
                  } else {
                    setInvoice({ ...invoice, invoice_date: null });
                  }
                }}
              />
            )}
          </Grid>

          {/*//////////////////////////////// Due date ////////////////////////////////*/}
          {isRecurringInvoice ? (
            <Grid item xs={12} sm={3}>
              <Box sx={{ display: 'flex', alignItem: 'center', justifyContent: 'space-between', mb: '-12px' }}>
                <CustomTypography
                  text={<LabelWithAsteriskMark label={"End On"} />}
                  sx={{
                    fontSize: "14px",
                    color: "#000000",
                    fontWeight: "700",
                    lineHeight: "18px",
                    fontStyle: "normal",
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                    textTransform: "capitalize",
                  }}
                />
                <FormControlLabel
                  id={"never_expires_checkbox"}
                  dataTestId={"never_expires_checkbox"}
                  sx={{ mt: -1, textAlign: "right" }}
                  label={
                    <span
                      style={{
                        color: "#000000",
                        fontSize: "12px",
                        fontWeight: "400",
                        overflow: "hidden",
                        lineHeight: "16px",
                        whiteSpace: 'nowrap',
                        fontStyle: "normal",
                        fontFamily: "Noto Sans",
                        textOverflow: 'ellipsis',
                      }}
                    >
                      Never Expires
                    </span>
                  }
                  control={
                    <Checkbox
                      size="small"
                      checked={recurringInvoiceData.never_expires}
                      onClick={() =>
                        setRecurringInvoiceData({
                          ...recurringInvoiceData,
                          never_expires: !recurringInvoiceData.never_expires,
                          ends_on: null,
                          selected_ends_on: null,
                        })
                      }
                      sx={{ color: "#9b9898" }}
                    />
                  }
                />
              </Box>
              <DatePicker
                id={"date_select_2"}
                dataTestId={"date_select_2"}
                title=""
                isTime={false}
                placeholder=""
                validation={
                  isFormSubmitted &&
                  !recurringInvoiceData.never_expires &&
                  !recurringInvoiceData.selected_ends_on
                }
                date={
                  recurringInvoiceData.selected_ends_on
                    ? moment(recurringInvoiceData.selected_ends_on)
                    : null
                }
                minDate={
                  recurringInvoiceData.selected_starts_on
                    ? moment(recurringInvoiceData.selected_starts_on).add(
                      1,
                      "d"
                    )
                    : null
                }
                setDate={(date) => {
                  if (date) {
                    setRecurringInvoiceData({
                      ...recurringInvoiceData,
                      never_expires: false,
                      selected_ends_on: date.format("YYYY-MM-DD HH:mm:ssZ"),
                      ends_on: moment(date).utc().format("YYYY-MM-DD HH:mm:ssZ"),
                    });
                  } else {
                    setRecurringInvoiceData({
                      ...recurringInvoiceData,
                      ends_on: null,
                      never_expires: true,
                      selected_ends_on: null,
                    });
                  }
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={3}>
              {invoice.invoice_date && Object.keys(params).length === 0 ? (
                <DatePicker
                  id={"date_select_2"}
                  dataTestId={"date_select_2"}
                  placeholder=""
                  minDate={moment(invoice.invoice_date)}
                  validation={isFormSubmitted && !invoice.due_date}
                  title={<LabelWithAsteriskMark label={"Due Date"} />}
                  date={invoice.due_date ? moment(invoice.due_date) : null}
                  setDate={(date) => {
                    setInvoice({
                      ...invoice,
                      due_date: date ? date.format("YYYY-MM-DD") : null,
                      payment_term_id: defaultTerm ? defaultTerm.id : null,
                      payment_term: defaultTerm,
                    });
                  }}
                />
              ) : (
                <DatePicker
                  id={"date_select_2"}
                  dataTestId={"date_select_2"}
                  placeholder=""
                  validation={isFormSubmitted && !invoice.due_date}
                  title={<LabelWithAsteriskMark label={"Due Date"} />}
                  date={invoice.due_date ? moment(invoice.due_date) : null}
                  // setDate={(date) => onChange('due_date', date ? date.format('YYYY-MM-DD') : null)}
                  setDate={(date) => {
                    setInvoice({
                      ...invoice,
                      due_date: date ? date.format("YYYY-MM-DD") : null,
                      payment_term_id: defaultTerm ? defaultTerm.id : null,
                      payment_term: defaultTerm,
                    });
                  }}
                />
              )}
            </Grid>
          )}
        </Grid>
        {/*//////////////////////////////// order Number ///////////////////////////////////////*/}
        <Grid item xs={12} sm={3}>
          <Input
            id={"input_order_number"}
            dataTestId={"input_order_number"}
            item={{
              type: "text",
              required: true,
              fullWidth: true,
              texttransform: "none",
              title: "Order Number",
              placeholder: "Enter Order Number",
              value: invoice.order_number ? invoice.order_number : "",
              onChange: (e) => onChange("order_number", e.target.value),
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ pt: 4 }}>
        <Grid container item xs={12} sm={9} spacing={3}>
          {/*//////////////////////////////// Billing address ////////////////////////////////*/}
          <Grid item xs={12} sm={6}>
            <Input
              id={"input_billing_address"}
              dataTestId={"input_billing_address"}
              item={{
                rows: 4,
                type: "text",
                required: true,
                multiline: true,
                fullWidth: true,
                title: "Billing Address",
                placeholder: "Enter Billing Address",
                // validation: isFormSubmitted && !invoice.billing_address,
                onChange: (e) => onChange("billing_address", e.target.value),
                value: invoice.billing_address ? invoice.billing_address : "",
              }}
            />
          </Grid>

          {/*//////////////////////////////// Shipping address ////////////////////////////////*/}
          <Grid item xs={12} sm={6}>
            <Grid container item xs={12}>
              <Grid item xs={5} sm={6}>
                <CustomTypography
                  text={"Shipping Address"}
                  sx={{
                    fontSize: "14px",
                    color: "#000000",
                    fontWeight: "700",
                    lineHeight: "18px",
                    fontStyle: "normal",
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                    textTransform: "capitalize",
                  }}
                />
              </Grid>
              <Grid item xs={7} sm={6} sx={{ mt: -3, textAlign: "right" }}>
                <FormControlLabel
                  id={"same_as_billing_address_checkbox"}
                  dataTestId={"same_as_billing_address_checkbox"}
                  sx={{ mb: -5, textAlign: "right" }}
                  control={
                    <Checkbox
                      size="small"
                      checked={invoice.same_as_billing_address}
                      onClick={() =>
                        onChange(
                          "same_as_billing_address",
                          !invoice.same_as_billing_address
                        )
                      }
                      sx={{ color: "#9b9898" }}
                    />
                  }
                  label={
                    <span
                      style={{
                        color: "#000000",
                        fontFamily: "Noto Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "16px",
                        fontSize: "12px",
                      }}
                    >
                      Same as Billing Address
                    </span>
                  }
                />
              </Grid>
            </Grid>
            <Input
              id={"input_shipping_address"}
              dataTestId={"input_shipping_address"}
              item={{
                rows: 4,
                title: "",
                type: "text",
                required: true,
                multiline: true,
                fullWidth: true,
                texttransform: "none",
                placeholder: "Enter Shipping Address",
                disabled: invoice.same_as_billing_address,
                // validation: isFormSubmitted && !invoice.shipping_address,
                onChange: (e) => onChange("shipping_address", e.target.value),
                value: invoice.shipping_address ? invoice.shipping_address : "",
              }}
            />
          </Grid>
        </Grid>
        {/*//////////////////////////////// Sales person ///////////////////////////////////////*/}
        {isSalesPersonField &&
          <Grid item xs={12} sm={3}>
            <SalesPersonList
              isClear={isClear}
              title="Sales Person"
              onAddItem={(item) => {
                onChange("sales_person_id", item ? item?.id : null);
              }}
              onChange={(value) => {
                setInvoice({
                  ...invoice,
                  sales_person_id: value ? value.id : null,
                  sales_person_name: value ? value.name : null,
                });
              }}
              defaultValue={
                invoice.sales_person_name
                  ? {
                    name: invoice.sales_person_name,
                    sales_person_id: invoice.sales_person,
                    id: invoice.sales_person,
                  }
                  : null
              }
            />
          </Grid>
        }
      </Grid>

      {isRecurringInvoice ? (
        <Grid container spacing={3} sx={{ pt: 4 }}>
          <Grid container item xs={12} sm={9} spacing={3}>
            <Grid item xs={12} sm={6}>
              <Dropdown
                id={"repeat_every_dropdown"}
                dataTestId={"repeat_every_dropdown"}
                disableClearable={true}
                placeholder="All statuses"
                results={repeat_every_list}
                label={<LabelWithAsteriskMark label={"Repeat Every"} />}
                setValue={(event, value) => {
                  setRecurringInvoiceData({
                    ...recurringInvoiceData,
                    repeat_every: value.repeat_every,
                    selected_repeat_every_list: value,
                    repeat_frequency: value.repeat_frequency,
                  });
                }}
                value={
                  recurringInvoiceData.selected_repeat_every_list
                    ? recurringInvoiceData.selected_repeat_every_list
                    : null
                }
              />
            </Grid>
            {recurringInvoiceData.selected_repeat_every_list.name ===
              "Custom" ? (
              <Grid item xs={6} container spacing={3}>
                <Grid item xs={5}>
                  <Input
                    id={"input_repeat_every"}
                    dataTestId={"input_repeat_every"}
                    item={{
                      type: "number",
                      required: true,
                      fullWidth: true,
                      placeholder: "",
                      title: "Order Number",
                      titleVisibility: "hidden",
                      value: recurringInvoiceData.repeat_every
                        ? recurringInvoiceData.repeat_every
                        : "",
                      onChange: (e) =>
                        setRecurringInvoiceData({
                          ...recurringInvoiceData,
                          repeat_every: Number(e.target.value),
                        }),
                    }}
                    onKeyPress={(event) => {
                      if (
                        event?.key === "-" ||
                        event?.key === "+" ||
                        event?.key === "." ||
                        event?.key === "e" ||
                        event?.key === "E"
                      ) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Dropdown
                    id={"repeat_frequency_dropdown"}
                    dataTestId={"repeat_frequency_dropdown"}
                    label={"Order Number"}
                    disableClearable={true}
                    titleVisibility="hidden"
                    placeholder=""
                    results={repeat_list}
                    setValue={(event, value) => {
                      setRecurringInvoiceData({
                        ...recurringInvoiceData,
                        selected_custom_every_list: value,
                        repeat_frequency: value.repeat_frequency,
                      });
                    }}
                    value={
                      recurringInvoiceData.selected_custom_every_list
                        ? recurringInvoiceData.selected_custom_every_list
                        : null
                    }
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6}>
                <Dropdown
                  id={"preference_dropdown"}
                  dataTestId={"preference_dropdown"}
                  results={preference}
                  disableClearable={true}
                  placeholder="All Preferences"
                  label={<LabelWithAsteriskMark label={"Preferences"} />}
                  value={
                    recurringInvoiceData.selected_preference
                      ? recurringInvoiceData.selected_preference
                      : null
                  }
                  setValue={(event, value) => {
                    setRecurringInvoiceData({
                      ...recurringInvoiceData,
                      selected_preference: value,
                      preference: value.preference,
                    });
                  }}
                />
              </Grid>
            )}
          </Grid>
          {recurringInvoiceData.selected_repeat_every_list.name === "Custom" ? (
            <Grid item xs={3}>
              <Dropdown
                id={"preference_dropdown"}
                dataTestId={"preference_dropdown"}
                results={preference}
                disableClearable={true}
                placeholder="All Preferences"
                label={<LabelWithAsteriskMark label={"Preferences"} />}
                value={
                  recurringInvoiceData.selected_preference
                    ? recurringInvoiceData.selected_preference
                    : null
                }
                setValue={(event, value) => {
                  setRecurringInvoiceData({
                    ...recurringInvoiceData,
                    selected_preference: value,
                    preference: value.preference,
                  });
                }}
              />
            </Grid>
          ) : null}
        </Grid>
      ) : null}
    </Box>
  );
};

const ProductAndServicesDetails = (props) => {
  const {
    is_gst_with_zero,
    open,
    isRecurringInvoice,
    invoice,
    setInvoice,
    addButtonHandler,
    isClear,
    newItem,
    itemIndex,
    isFormSubmitted,
    accounts,
    totalAmount,
    totalTax,
    totalDiscount,
    subTotal,
    finalPayment,
  } = props;
  const [data, setData] = React.useState(invoice.sales_items);

  const onAddLine = () => {
    const newItem = {
      item: null,
      tax: null,
      rate: null,
      quantity: 1,
      tax_id: null,
      amount: null,
      item_id: null,
      unit_id: null,
      editable: false,
      item_order: null,
      hsn_sac_code: null,
      item_level_discount: 0,
      is_item_discount_in_percentage: checkItemDiscount(invoice?.sales_items),
    };

    const updatedSalesItems = [...invoice.sales_items, newItem];
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      sales_items: updatedSalesItems
    }));
    setData(updatedSalesItems);
  };


  const onDeleteLine = (index) => {
    const updatedSalesItems = [...invoice.sales_items];
    if (updatedSalesItems.length > 1) {
      updatedSalesItems.splice(index, 1);
    } else {
      updatedSalesItems[0] = {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        editable: false,
        item_order: null,
        hsn_sac_code: null,
        item_level_discount: 0,
        is_item_discount_in_percentage: checkItemDiscount(invoice?.sales_items),
      };
    }
    setInvoice({ ...invoice, sales_items: updatedSalesItems });
    setData(updatedSalesItems);
  };

  const onClearLines = () => {
    const hasDisabledItems = invoice.sales_items?.some(item => item?.disabled);

    const clearedItems = hasDisabledItems
      ? invoice.sales_items.filter(item => item?.disabled)
      : [
        {
          item: null,
          tax: null,
          rate: null,
          quantity: 1,
          tax_id: null,
          amount: null,
          item_id: null,
          unit_id: null,
          editable: false,
          item_order: null,
          hsn_sac_code: null,
          item_level_discount: 0,
          is_item_discount_in_percentage: checkItemDiscount(invoice?.sales_items),
        },
      ];

    setInvoice({ ...invoice, sales_items: clearedItems });
    setData(clearedItems);
  };


  React.useEffect(() => {
    invoice.sales_items = data;
    invoice.subtotal = totalAmount.toFloat();
    invoice.discount = totalDiscount.toFloat();
    invoice.total_amount = finalPayment;
    setInvoice({ ...invoice });
    // eslint-disable-next-line
  }, [data]);

  if (!("IGST" in invoice.tax)) {
    invoice.tax["IGST"] = 0;
  }
  if (!("SGST" in invoice.tax)) {
    invoice.tax["SGST"] = 0;
  }
  if (!("CGST" in invoice.tax)) {
    invoice.tax["CGST"] = 0;
  }

  const filterList = [
    { name: "Exclusive", value: "exclusive" },
    { name: "Inclusive", value: "inclusive" },
    { name: "No Tax", value: "no_tax" },
  ];

  const onAmountsAreInChange = (selected_type) => {
    let previous = invoice.tax_applied;
    if (previous !== selected_type.value) {
      if (selected_type.value === "inclusive") {
        invoice.is_amount_tax_inclusive = true;
        convertExclusiveToInclusive(invoice.sales_items);
        invoice.total_amount = totalAmount - totalDiscount + totalTax;
      } else if (selected_type.value === "no_tax") {
        if (previous === filterList[1].value) {
          convertInclusiveToExclusive(invoice.sales_items);
        }
        // eslint-disable-next-line
        invoice.sales_items.map((sales_item) => {
          sales_item.tax = null;
          sales_item.tax_id = null;
          sales_item.tax_amount = "";
        });
        invoice.is_amount_tax_inclusive = false;
        invoice.total_amount = totalAmount - totalDiscount;
      } else {
        invoice.is_amount_tax_inclusive = false;
        convertInclusiveToExclusive(invoice.sales_items);
      }

      setInvoice({ ...invoice, tax_applied: selected_type.value });
      setData([...invoice.sales_items]);
    }
  };

  const [isDiscountFiled, setIsDiscountFiled] = React.useState(getDiscountPreferences());
  React.useEffect(() => {
    if (invoice.discount) {
      setIsDiscountFiled(getDiscountPreferences() || (invoice.discount ? true : false))
    }
  }, [invoice.discount]);

  return (
    <div>
      <Box
        style={{ paddingRight: "20px", }}
        sx={{ pb: 2, pt: 2, display: { sx: 'block', sm: 'flex' }, justifyContent: "flex-end" }}
      >
        <CommonCurrencySelection
          data={invoice}
          isCurrency={true}
          isAmountAre={true}
          setData={setInvoice}
          dropdown_key='customer_id'
          isFormSubmitted={isFormSubmitted}
          onAmountsAreInChange={onAmountsAreInChange}
        />
      </Box>

      <Box sx={{ mb: 2, }} >
        {/* <CustomTableContainer sx={{ overflowX: 'hidden',maxHeight:'400px',overflow:'auto' }} className="custom_table_css"> */}
        <CustomTableContainer sx={{ overflowX: 'hidden', }} className="custom_table_css">
          <CustomTable stickyHeader>
            <ProductAndServicesDetailsHeaders isDiscountFiled={isDiscountFiled} data={invoice} setData={setInvoice} />
            <ProductAndServicesDetailsBody
              isDiscountFiled={isDiscountFiled}
              open={open}
              invoice={invoice}
              setData={setData}
              newItem={newItem}
              itemIndex={itemIndex}
              setInvoice={setInvoice}
              data={invoice.sales_items}
              onDeleteLine={onDeleteLine}
              finalPayment={finalPayment}
              onClearLines={onClearLines}
              isFormSubmitted={isFormSubmitted}
              addButtonHandler={addButtonHandler}
              is_gst_with_zero={is_gst_with_zero}
              isRecurringInvoice={isRecurringInvoice}
            />
          </CustomTable>
        </CustomTableContainer>
      </Box>

      <Grid
        container
        style={{
          paddingBottom: "24px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Grid item xs={12} sm={12}>
            <CustomTypography
              id={"add_action"}
              dataTestId={"add_action"}
              text={"+ Add Line"}
              onClick={onAddLine}
              sx={{
                fontSize: "12px",
                color: "#2464EB",
                cursor: "pointer",
                display: "inline",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                textTransform: "none",
                fontFamily: "Noto Sans",
                ":hover": { textDecoration: "underline" },
              }}
            />
            <CustomTypography
              text={"|"}
              sx={{
                mr: 1,
                ml: 1,
                fontSize: "12px",
                color: "#B8B8B8",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                display: "inline",
                textTransform: "none",
                fontFamily: "Noto Sans",
              }}
            />
            <CustomTypography
              id={"clear_action"}
              dataTestId={"clear_action"}
              text={"Clear All Lines"}
              onClick={onClearLines}
              sx={{
                fontSize: "12px",
                color: "#2464EB",
                cursor: "pointer",
                display: "inline",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                textTransform: "none",
                fontFamily: "Noto Sans",
                ":hover": { textDecoration: "underline" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={9} sx={{ mt: 3 }}>
            <Input
              id={"input_note"}
              dataTestId={"input_note"}
              item={{
                rows: 4,
                type: "text",
                fullWidth: true,
                required: true,
                multiline: true,
                title: "Message on Invoice",
                value: invoice.note ? invoice.note : "",
                placeholder: "This will Show up on the Invoice",
                onChange: (event) => {
                  setInvoice({ ...invoice, note: event.target.value });
                  // invoice.note = event.target.value
                },
              }}
            />
          </Grid>

          {/*//////////////////////////////// Attachments ////////////////////////////////*/}
          {isRecurringInvoice ? null : (
            <Grid item xs={12} sm={9} sx={{ mt: 3 }}>
              <InputFile
                id={"attachment_action"}
                dataTestId={"attachment_action"}
                onFileUpload={(file) => {
                  setInvoice({ ...invoice, attachment: file });
                }}
                attachment={invoice.attachment}
                onFileRemove={() => {
                  setInvoice({ ...invoice, attachment: null });
                }}
              />
            </Grid>
          )}

          {/*//////////////////////////////// Terms and Conditions ////////////////////////////////*/}
          <Grid item xs={12} sm={9} sx={{ mt: 4 }}>
            <TermsAndConditions
              title="Terms and Conditions"
              value={invoice.terms_and_conditions_id}
              setValue={(value) =>
                setInvoice({
                  ...invoice,
                  terms_and_conditions_id: value ? value.id : null,
                })
              }
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <CommonCalculation
            data={invoice}
            isCurrency={true}
            setData={setInvoice}
            dropdown_key='customer_id'
            isTcsTds={!isRecurringInvoice}
            isFormSubmitted={isFormSubmitted}

            accounts={accounts}
            subTotal={subTotal}
            totalTax={totalTax}
            totalAmount={totalAmount}
            finalPayment={finalPayment}
            totalDiscount={totalDiscount}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const ProductAndServicesDetailsHeaders = (props) => {
  const { isDiscountFiled, data, setData } = props;
  return (
    <CustomTableHead>
      <CustomTableRow>
        <CustomTableHeadCell style={{ width: 5 }} align="left">  <span>#</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 310 }} align="left">  <span>Goods/Service</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 125 }} align="left">  <span>HSN/SAC</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 200 }} align="left">  <span>Description</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 185 }} align="left"><Common.QuantityLabelComponent data={data} setData={setData} items='sales_items' /></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 175 }} align="left"><Common.RateLabelComponent data={data} setData={setData} items='sales_items' /></CustomTableHeadCell>
        {isDiscountFiled &&
          <CustomTableHeadCell style={{ width: 100 }} align="left">  <Common.DiscountLabelComponent data={data} setData={setData} items='sales_items' /></CustomTableHeadCell>
        }
        <CustomTableHeadCell style={{ width: 200 }} align="left">  <span>Tax</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 145 }} align="left">  <span>Tax Amount</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 145 }} align="left">  <span>Amount</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 5 }} align="right" />
      </CustomTableRow>
    </CustomTableHead>
  );
};

const ProductAndServicesDetailsBody = (props) => {
  const {
    isDiscountFiled,
    is_gst_with_zero,
    open,
    data,
    onDeleteLine,
    setData,
    addButtonHandler,
    invoice,
    newItem,
    itemIndex,
    setInvoice,
    isFormSubmitted,
    finalPayment,
    isRecurringInvoice,
  } = props;
  React.useEffect(() => {
    setData(data);
  }, []);

  let location = useLocation();
  const params = getQueryParams(location.search);
  const dispatch = Actions.getDispatch(useContext);
  const [taxResult, setTaxResult] = React.useState([]);

  let taxApplied = invoice.tax_applied;
  const place_of_supply_id = invoice.place_of_supply_id;
  const taxList = filterTax(taxResult, place_of_supply_id, is_gst_with_zero);

  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: "post",
        url: get_list_of_tax(),
        data: {
          business_id: getBusinessInfo().id,
        },
      });
      if (res?.success) {
        setTaxResult(res?.result);
        // let findGst= (type)=>res?.result?.find((item)=>item.name === type)
        // dispatch(Actions.stateChange("gst_zero", findGst("GST-0")));
        // dispatch(Actions.stateChange("igst_zero", findGst("IGST-0")));
      } else {
      }
    };
    apiResults();
    // eslint-disable-next-line
  }, []);

  const lowInventoryAlert = (item, quantity = 1) => {
    if (!isRecurringInvoice) {
      let negativeStockAlert = item && item?.current_stock - quantity < 0;
      let lowStockAlert = item && item?.current_stock - quantity < item?.low_stock_value;

      if (item && item?.current_stock !== null) {
        if (lowStockAlert) {
          stateChangeManager(
            dispatch,
            Actions,
            true,
            "warning",
            `Item stock is below the set low stock reminder value ${item?.low_stock_value}. Please review inventory.`
          );
        }
        if (negativeStockAlert) {
          stateChangeManager(dispatch, Actions, true, "warning", "Item stock has gone negative. Immediate restock required."
          );
        }
      }
    }
  };

  const apiItemResults = async (id, index) => {
    let res = await apiAction({
      method: "post",
      url: get_item(id),
      data: { business_id: getBusinessInfo().id },
    });

    if (res?.success) {
      lowInventoryAlert(res?.result);
      onItemSelect(res?.result, index);
    } else {
    }
  };


  const onItemSelect = (value, index) => {

    const getDefaultTax = () => {
      return findDefaultTax(taxList, is_gst_with_zero, value)
    };

    const getItemData = () => ({
      item: value,
      item_order: null,
      unit_id: value.unit?.id,
      quantity: value.quantity || 1,
      description: value.description,
      hsn_sac_code: value.hsn_sac_code,
      item_id: value.item_id || value.id,
      amount: value.amount || value.selling_price,
      rate: parseFloat(value.rate || value.selling_price),
      item_level_discount: value.item_level_discount || 0,
      is_item_discount_in_percentage: checkItemDiscount(invoice?.sales_items),

      gst_rate: getDefaultTax()?.rate,
      tax: taxApplied === "no_tax" ? null : getDefaultTax(),
      tax_id: taxApplied === "no_tax" ? null : getDefaultTax()?.id,

      expense_category_id: value.sales_account?.id || value.expense_category_id,
      expense_category_name: value.sales_account?.account_name || value.expense_category_name,
    });

    const updateData = () => {
      data[index] = value ? getItemData() : {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        description: null,
        hsn_sac_code: null,
        item_level_discount: 0,
        is_item_discount_in_percentage: checkItemDiscount(invoice?.sales_items),
      };
    };

    const isAmountRangeExceeded = () => isFinalAmountRangeCrossed({
      quantity: data[index]?.quantity,
      rate: data[index]?.rate,
      item_level_discount: data[index].item_level_discount,
      item: data[index],
      invoice: invoice,
      finalPayment: data.length <= 1
        ? 0
        : finalPayment - (data[index]?.amount || 0) - (data[index]?.tax_amount || 0),
    });

    if (isAmountRangeExceeded()) {
      stateChangeManager(
        dispatch,
        Actions,
        true,
        "error",
        "Amount range max limit reached"
      );
    } else {
      updateData();
      setData([...data]);
      setInvoice({ ...invoice, sales_items: [...data] });
    }

  };

  React.useEffect(() => {
    if (newItem) {
      setTimeout(() => {
        onItemSelect(newItem, itemIndex);
      }, 500);
    }
    // eslint-disable-next-line
  }, [newItem]);

  React.useEffect(() => {

    const getDefaultTax = (value) => {
      return findDefaultTax(taxList, is_gst_with_zero, value);
    };

    const updatedData = data.map((item) => {
      const defaultTax = getDefaultTax(item);
      const isDefaultSelectTax = item.tax_id || is_gst_with_zero;
      // Calculate values based on conditions
      const gstRate = isDefaultSelectTax ? defaultTax?.rate ?? null : null;
      const tax = isDefaultSelectTax ? (taxApplied === "no_tax" ? null : defaultTax) : null;
      const taxId = isDefaultSelectTax ? (taxApplied === "no_tax" ? null : defaultTax?.id ?? null) : null;

      return {
        ...item,
        tax: tax,
        tax_id: taxId,
        gst_rate: gstRate,
      }
    });

    setData(updatedData);
    setInvoice((prev) => ({
      ...prev,
      sales_items: updatedData
    }));

    // eslint-disable-next-line
  }, [is_gst_with_zero, place_of_supply_id, taxApplied]);



  return (
    <CustomTableBody >
      {invoice.sales_items.map((item, index) => {
        item['item_order'] = index + 1;
        return (
          <CustomTableRow
            key={index}
          // sx={{ borderBottom: "1px solid #F5F5F5" }}
          >
            <CustomTableBodyCell sx={{}} align="left">
              <span>{index + 1}</span>
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <CommonAsyncDropdown
                className='item_dropdown'
                id={"item_dropdown_" + index}
                dataTestId={"item_dropdown_" + index}
                invoice={invoice}
                newItem={newItem}
                optionLabel="name"
                autoSelect={false}
                itemIndex={itemIndex}
                disableClearable={false}
                noOptionsText={"No result found"}
                placeholder="Select Goods/Service"
                isFectchOptions={!("sales_id" in params)}
                // options={invoice.options ? invoice.options : null}
                validation={item?.disabled ? false : isFormSubmitted && !data[index].item_id}
                item={{
                  open: open,
                  method: "post",
                  url: list_items(1),
                  disabled: item?.disabled,
                  value: data[index].item,
                  sx: item?.disabled ? { background: "#F1F3F4" } : {},
                  onChange: (event, value) => {
                    if (value) {
                      data[index].item = value.name;
                      setData([...data]);
                      apiItemResults(value.id, index);
                    } else {
                      onItemSelect(value, index);
                    }
                  },
                  body: {
                    role: "customer",
                    business_id: getBusinessInfo().id,
                    for_sales: true,
                  },
                }}
                addButton={{
                  title: "+ Add an item",
                  onClick: () =>
                    addButtonHandler("New Item", "new_pas", "lg", "sales", index
                    ),
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_hsn_sac_code_" + index}
                dataTestId={"input_hsn_sac_code_" + index}
                item={{
                  type: "text",
                  disabled: true,
                  required: true,
                  fullWidth: true,
                  placeholder: "",
                  value: data[index]?.hsn_sac_code
                    ? data[index]?.hsn_sac_code
                    : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                onFocus={() => {
                  data[index].focus = true;
                  setData([...data]);
                }}
                onBlur={() => {
                  data[index].focus = false;
                  setData([...data]);
                }}

                id={"input_description_" + index}
                dataTestId={"input_description_" + index}
                item={{
                  type: "text",
                  required: true,
                  multiline: true,
                  fullWidth: true,
                  placeholder: "",
                  rows: data[index]?.focus ? 4 : 1,
                  value: data[index]?.description ? data[index]?.description : "",
                  onChange: (event) => {
                    data[index].description = event.target.value;
                    setData([...data]);
                  },
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Common.QuantityInputComponent
                index={index}
                data={data}
                setData={setData}
                quantityData={invoice}
                finalPayment={finalPayment}
                isFormSubmitted={isFormSubmitted}
                lowInventoryAlert={lowInventoryAlert}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">

              <Common.RateInputComponent
                index={index}
                data={data}
                setData={setData}
                rateData={invoice}
                finalPayment={finalPayment}
                isFormSubmitted={isFormSubmitted}
              />
            </CustomTableBodyCell>

            {isDiscountFiled &&
              <CustomTableBodyCell sx={{}} align="left">
                <Common.DiscountInputComponent
                  index={index}
                  data={data}
                  setData={setData}
                  discountData={invoice}
                  finalPayment={finalPayment}
                />
              </CustomTableBodyCell>
            }

            <CustomTableBodyCell sx={{}} align="left">
              <TaxList
                result={taxList}
                disableClearable={false}
                value={data[index]?.tax}
                id={"tax_dropdown_" + index}
                dataTestId={"tax_dropdown_" + index}
                inputDisabled={
                  is_gst_with_zero
                    ? is_gst_with_zero
                    : invoice.tax_applied === "no_tax"
                }
                validation={
                  !is_gst_with_zero &&
                  isFormSubmitted &&
                  invoice.tax_applied !== "no_tax" &&
                  !data[index]?.tax_id
                }
                setValue={(tax) => {
                  if (
                    isFinalAmountRangeCrossed({
                      quantity: data[index]?.quantity,
                      rate: data[index]?.rate,
                      item_level_discount: data[index].item_level_discount,
                      item: { ...data[index], tax: tax },
                      invoice: invoice,
                      finalPayment: data.length <= 1 ? 0 : finalPayment - (data[index]?.amount ? data[index]?.amount : 0) - (data[index]?.tax_amount ? data[index]?.tax_amount : 0),
                    })
                  ) {
                    stateChangeManager(dispatch, Actions, true, "error", "Amount range max limit reached");
                  } else {
                    if (tax) {
                      data[index].tax = tax;
                      data[index].tax_id = tax.id;
                      data[index].gst_rate = tax.rate;
                    } else {
                      data[index].tax = null;
                      data[index].tax_id = null;
                      data[index].tax_amount = null;
                    }
                    setData([...data]);
                  }
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                editable={false}
                is_currency_symbol={true}
                isShowCommaSeparatedValue={true}
                currency_code={invoice.currency_code}

                id={"input_tax_amount_" + index}
                dataTestId={"input_tax_amount_" + index}
                item={{
                  type: "text",
                  disabled: true,
                  fullWidth: true,
                  required: true,
                  placeholder: "",
                  value: data[index]?.tax_amount && invoice.tax_applied !== "no_tax" ? data[index]?.tax_amount : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                editable={false}
                is_currency_symbol={true}
                isShowCommaSeparatedValue={true}
                currency_code={invoice.currency_code}

                id={"input_amount_" + index}
                dataTestId={"input_amount_" + index}
                item={{
                  type: "text",
                  disabled: true,
                  fullWidth: true,
                  required: true,
                  placeholder: "",
                  value: data[index]?.amount ? data[index]?.amount : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="right">
              <IconButton
                disabled={item?.disabled}
                id={"delete_icon_" + index}
                dataTestId={"delete_icon_" + index}
                onClick={() => onDeleteLine(index)}
              >
                <DeleteIcon />
              </IconButton>
            </CustomTableBodyCell>
          </CustomTableRow>
        );
      })}
    </CustomTableBody>

    // <ImprovedComponent
    //   data={data}
    //   open={open}
    //   params={params}
    //   invoice={invoice}
    //   newItem={newItem}
    //   setData={setData}
    //   Actions={Actions}
    //   dispatch={dispatch}
    //   taxResult={taxResult}
    //   itemIndex={itemIndex}
    //   onDeleteLine={onDeleteLine}
    //   onItemSelect={onItemSelect}
    //   finalPayment={finalPayment}
    //   apiItemResults={apiItemResults}
    //   isDiscountFiled={isDiscountFiled}
    //   isFormSubmitted={isFormSubmitted}
    //   addButtonHandler={addButtonHandler}
    //   is_gst_with_zero={is_gst_with_zero}
    //   lowInventoryAlert={lowInventoryAlert}
    //   stateChangeManager={stateChangeManager}
    // />
  );
};

const Buttons = (props) => {
  const {
    onSave,
    invoice,
    onClear,
    customer,
    onCancel,
    is_Location,
    setFormSubmitted,
  } = props;
  let location = useLocation();
  const params = getQueryParams(location.search);

  const { sales_items, tax_applied, customer_emails, invoice_date, due_date, exchange_rate, is_pro_forma } = invoice;
  const { to } = customer_emails;

  const is_rate = sales_items.find((item) => item?.rate === null);
  const is_quantity = sales_items.find((item) => !item?.quantity);
  const is_sales_item_not_selected = sales_items.find((item) => !item?.item_id && !item?.expense_category_id);
  const is_tax = sales_items.find((item) => tax_applied !== "no_tax" && !item?.tax_id);

  let validation_data = [
    { key: "customer_id", message: "Please Select Customer" },
    // { key: "", validation: to.length === 0, message: "Please enter valid email.", },
    { key: "invoice_number", message: "Please Enter Invoice Number" },
    isBusinessRegistered() && !is_Location && { key: "place_of_supply_id", message: "Please Select Place of Supply", },
    { key: "payment_term_id", message: "Please Select Terms" },
    { key: "invoice_date", message: "Please Enter Valid Invoice Date" },
    { key: "due_date", message: "Please Enter Valid Due Date" },
    { key: "", validation: invoice_date && due_date && moment(invoice_date).format("YYYY-MM-DD") > moment(due_date).format("YYYY-MM-DD"), message: "The Payment Due Date can not be Before the Invoice Date.", },
    { key: "", validation: !exchange_rate, message: 'Exchange rate can not be empty or zero' },

    // { key: "billing_address", message: 'Please Enter Billing Address' },
    // { key: "shipping_address", message: 'Please Enter Shipping Address' },
    { key: "", validation: is_sales_item_not_selected, message: "Please Select Item", },
    { key: "", validation: is_quantity, message: "Please Enter the Quantity" },
    { key: "", validation: is_rate, message: "Please Enter the Rate" },
    { key: "", validation: is_tax, message: "Please Select the Tax" },
  ];

  const printEvents = () => {
    AnalyticsEvent(eventsNames.categories.INVOICES, { action: eventsNames.actions.PREVIEW })
  };

  const clearActionDisabled = Object.keys(params).length === 0 || params.clone_id || params.customer_id;
  return (
    <Box component="form" noValidate>
      <Grid container>
        <Grid item xs={12} sm={4} sx={{ display: "-webkit-flex", justifyContent: "start" }}>
          {clearActionDisabled && (
            <CustomButton
              id={"clear_btn"}
              dataTestId={"clear_btn"}
              variant="outlined"
              sx={{
                textTransform: "none",
                "&:hover": { backgroundColor: "#e8f3ff" },
              }}
              btnLabel="Clear"
              onClick={() => {
                onClear();
              }}
            />
          )}
          <CustomButton
            id={"cancel_btn"}
            dataTestId={"cancel_btn"}
            variant="contained"
            sx={{ ml: 1, textTransform: "none" }}
            btnLabel="Cancel"
            onClick={() => {
              onCancel();
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} sx={{ display: "-webkit-flex", justifyContent: "end" }}>
          <PrintAndPreview
            apiUrl={export_invoice()}
            data={invoice}
            validation_data={validation_data}
            setFormSubmitted={() => {
              setFormSubmitted(true);
              printEvents();
            }}
          />
          {!is_pro_forma &&
            <CustomButton
              id={"save_as_draft_btn"}
              dataTestId={"save_as_draft_btn"}
              variant="outlined"
              sx={{
                mr: 1,
                textTransform: "none",
                "&:hover": { backgroundColor: "#e8f3ff" },
              }}
              btnLabel={params.id ? "Update as Draft" : "Save as Draft"}
              onClick={() => {
                onSave({ is_draft: true, is_save_send: false });
              }}
            />
          }
          <CustomGroupButton
            options={[
              {
                id: "save_send_btn",
                dataTestId: "save_send_btn",
                label: params.id ? "Update and Send" : "Save and Send",
                condition: () => {
                  onSave({ is_draft: false, is_save_send: true });
                },
              },
              {
                id: "save_send_later_btn",
                dataTestId: "save_send_later_btn",
                label: params.id
                  ? "Update and Send Later"
                  : "Save and Send Later",
                condition: () => {
                  onSave({ is_draft: false, is_save_send: false });
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateNewInvoice;

// Utility function to chunk the array
function chunkArray(array, chunkSize) {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

const ImprovedComponent = ({ invoice, data, setData, newItem, itemIndex, params, open, addButtonHandler, apiItemResults, onItemSelect, finalPayment, isFormSubmitted, lowInventoryAlert, isDiscountFiled, taxResult, is_gst_with_zero, stateChangeManager, dispatch, Actions, onDeleteLine }) => {
  const chunkSize = 50;
  const lastElementRef = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [chunksToShow, setChunksToShow] = React.useState(1);
  const chunks = React.useMemo(() => chunkArray(invoice.sales_items, chunkSize), [invoice.sales_items, chunkSize]);

  const loadMore = React.useCallback(() => {
    setIsLoading(true);
    setTimeout(() => {
      // setChunksToShow(chunks.length);
      setChunksToShow((prev) => Math.min(prev + 1, chunks.length));
      setIsLoading(false);
    }, 100);
  }, [chunks.length]);

  React.useEffect(() => {
    if (chunksToShow > 1 && chunks.length === Math.min(chunksToShow + 1, chunks.length)) {
      loadMore()
    }
  }, [chunks.length])



  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMore();
      }
    });

    if (lastElementRef.current) {
      observer.observe(lastElementRef.current);
    }

    return () => {
      if (lastElementRef.current) {
        observer.unobserve(lastElementRef.current);
      }
    };
  }, [loadMore]);

  console.log('====>ImprovedComponent', invoice.sales_items)
  return (
    <>
      <CustomTableBody>
        {/* {invoice?.sales_items?.map((item, index) => ( */}
        {chunks.slice(0, chunksToShow).flat().map((item, index) => (
          <MemoizedRow
            key={item?.id || `memoized_row_${index}`}
            open={open}
            item={item}
            data={data}
            index={index}
            params={params}
            setData={setData}
            invoice={invoice}
            Actions={Actions}
            newItem={newItem}
            dispatch={dispatch}
            taxResult={taxResult}
            itemIndex={itemIndex}
            onItemSelect={onItemSelect}
            finalPayment={finalPayment}
            onDeleteLine={onDeleteLine}
            apiItemResults={apiItemResults}
            isFormSubmitted={isFormSubmitted}
            isDiscountFiled={isDiscountFiled}
            is_gst_with_zero={is_gst_with_zero}
            addButtonHandler={addButtonHandler}
            lowInventoryAlert={lowInventoryAlert}
            stateChangeManager={stateChangeManager}
          />
        ))}

        {chunksToShow < chunks.length && (
          <tr ref={lastElementRef} style={{ minHeight: '40px' }}>
            <td colSpan={11} align="center" style={{ minHeight: '40px' }}>
              {
                isLoading &&
                <CircularProgress />
              }
              {/* {isLoading ? (
                <CircularProgress />
              ) : (
                <button variant="contained" color="primary" onClick={loadMore}>
                  Load More
                </button>
              )} */}
            </td>
          </tr>
        )}
      </CustomTableBody>
    </>
  );
};

const MemoizedRow = React.memo(({ item, index, data, setData, invoice, newItem, itemIndex, params, open, addButtonHandler, apiItemResults, onItemSelect, finalPayment, isFormSubmitted, lowInventoryAlert, isDiscountFiled, taxResult, is_gst_with_zero, stateChangeManager, dispatch, Actions, onDeleteLine }) => {
  const handleItemChange = React.useCallback((event, value) => {
    if (value) {
      data[index].item = value.name;
      setData([...data]);
      apiItemResults(value.id, index);
    } else {
      onItemSelect(value, index);
    }
  }, [data, setData, apiItemResults, onItemSelect, index]);

  const handleDescriptionChange = React.useCallback((event) => {
    data[index].description = event.target.value;
    setData([...data]);
  }, [data, setData, index]);

  const handleTaxChange = React.useCallback((tax) => {
    if (isFinalAmountRangeCrossed({
      quantity: data[index]?.quantity,
      rate: data[index]?.rate,
      item_level_discount: data[index].item_level_discount,
      item: { ...data[index], tax: tax },
      invoice: invoice,
      finalPayment: data.length <= 1 ? 0 : finalPayment - (data[index]?.amount ? data[index]?.amount : 0) - (data[index]?.tax_amount ? data[index]?.tax_amount : 0),
    })) {
      stateChangeManager(dispatch, Actions, true, "error", "Amount range max limit reached");
    } else {
      if (tax) {
        data[index].tax = tax;
        data[index].tax_id = tax.id;
        data[index].gst_rate = tax.rate;
      } else {
        data[index].tax = null;
        data[index].tax_id = null;
        data[index].tax_amount = null;
      }
      setData([...data]);
    }
  }, [data, setData, isFinalAmountRangeCrossed, invoice, finalPayment, dispatch, Actions, stateChangeManager, index]);

  const handleFocus = React.useCallback(() => {
    data[index].focus = true;
    setData([...data]);
  }, [data, setData, index]);

  const handleBlur = React.useCallback(() => {
    data[index].focus = false;
    setData([...data]);
  }, [data, setData, index]);

  item['item_order'] = index + 1;
  return (
    <CustomTableRow key={index}>
      <CustomTableBodyCell sx={{}} align="left">
        <span>{index + 1}</span>
      </CustomTableBodyCell>

      <CustomTableBodyCell sx={{}} align="left">
        <CommonAsyncDropdown
          className='item_dropdown'
          id={"item_dropdown_" + index}
          dataTestId={"item_dropdown_" + index}
          invoice={invoice}
          newItem={newItem}
          optionLabel="name"
          autoSelect={false}
          itemIndex={itemIndex}
          disableClearable={false}
          noOptionsText={"No result found"}
          placeholder="Select Goods/Service"
          isFectchOptions={!("sales_id" in params)}
          validation={item?.disabled ? false : isFormSubmitted && !data[index].item_id}
          item={{
            open: open,
            method: "post",
            url: list_items(1),
            disabled: item?.disabled,
            value: data[index]?.item,
            sx: item?.disabled ? { background: "#F1F3F4" } : {},
            onChange: handleItemChange,
            body: {
              role: "customer",
              business_id: getBusinessInfo().id,
              for_sales: true,
            },
          }}
          addButton={{
            title: "+ Add an item",
            onClick: () =>
              addButtonHandler("New Item", "new_pas", "lg", "sales", index),
          }}
        />
      </CustomTableBodyCell>

      <CustomTableBodyCell sx={{}} align="left">
        <Input
          id={"input_hsn_sac_code_" + index}
          dataTestId={"input_hsn_sac_code_" + index}
          item={{
            type: "text",
            disabled: true,
            required: true,
            fullWidth: true,
            placeholder: "",
            value: data[index]?.hsn_sac_code ? data[index]?.hsn_sac_code : "",
          }}
        />
      </CustomTableBodyCell>

      <CustomTableBodyCell sx={{}} align="left">
        <Input
          onFocus={handleFocus}
          onBlur={handleBlur}
          id={"input_description_" + index}
          dataTestId={"input_description_" + index}
          item={{
            type: "text",
            required: true,
            multiline: true,
            fullWidth: true,
            placeholder: "",
            rows: data[index]?.focus ? 4 : 1,
            value: data[index]?.description ? data[index]?.description : "",
            onChange: handleDescriptionChange,
          }}
        />
      </CustomTableBodyCell>

      <CustomTableBodyCell sx={{}} align="left">
        <Common.QuantityInputComponent
          index={index}
          data={data}
          setData={setData}
          quantityData={invoice}
          finalPayment={finalPayment}
          isFormSubmitted={isFormSubmitted}
          lowInventoryAlert={lowInventoryAlert}
        />
      </CustomTableBodyCell>

      <CustomTableBodyCell sx={{}} align="left">
        <Common.RateInputComponent
          index={index}
          data={data}
          setData={setData}
          rateData={invoice}
          finalPayment={finalPayment}
          isFormSubmitted={isFormSubmitted}
        />
      </CustomTableBodyCell>

      {isDiscountFiled &&
        <CustomTableBodyCell sx={{}} align="left">
          <Common.DiscountInputComponent
            index={index}
            data={data}
            setData={setData}
            discountData={invoice}
            finalPayment={finalPayment}
          />
        </CustomTableBodyCell>
      }

      <CustomTableBodyCell sx={{}} align="left">
        <TaxList
          id={"tax_dropdown_" + index}
          dataTestId={"tax_dropdown_" + index}
          result={taxResult}
          value={data[index]?.tax}
          disableClearable={false}
          inputDisabled={
            is_gst_with_zero
              ? is_gst_with_zero
              : invoice.tax_applied === "no_tax"
          }
          validation={
            !is_gst_with_zero &&
            isFormSubmitted &&
            invoice.tax_applied !== "no_tax" &&
            !data[index]?.tax_id
          }
          setValue={handleTaxChange}
        />
      </CustomTableBodyCell>

      <CustomTableBodyCell sx={{}} align="left">
        <Input
          editable={false}
          is_currency_symbol={true}
          isShowCommaSeparatedValue={true}
          currency_code={invoice.currency_code}
          id={"input_tax_amount_" + index}
          dataTestId={"input_tax_amount_" + index}
          item={{
            type: "text",
            disabled: true,
            fullWidth: true,
            required: true,
            placeholder: "",
            value: data[index]?.tax_amount && invoice.tax_applied !== "no_tax" ? data[index]?.tax_amount : "",
          }}
        />
      </CustomTableBodyCell>

      <CustomTableBodyCell sx={{}} align="left">
        <Input
          editable={false}
          is_currency_symbol={true}
          isShowCommaSeparatedValue={true}
          currency_code={invoice.currency_code}
          id={"input_amount_" + index}
          dataTestId={"input_amount_" + index}
          item={{
            type: "text",
            disabled: true,
            fullWidth: true,
            required: true,
            placeholder: "",
            value: data[index]?.amount ? data[index]?.amount : "",
          }}
        />
      </CustomTableBodyCell>

      <CustomTableBodyCell sx={{}} align="right">
        <IconButton
          disabled={item?.disabled}
          id={"delete_icon_" + index}
          dataTestId={"delete_icon_" + index}
          onClick={() => onDeleteLine(index)}
        >
          <DeleteIcon />
        </IconButton>
      </CustomTableBodyCell>
    </CustomTableRow>
  );
});

