import React from 'react';
import validator from 'validator';
import fileDownload from 'js-file-download';
import { useLocation } from 'react-router-dom';
import * as Actions from '../../state/Actions';
import { apiAction } from '../../api/api';
import Input from '../../components/common/Input';
import DownloadIcon from '@mui/icons-material/Download';
import CustomButton from '../../components/custom/button/CustomButton';
import CustomTypography from '../../components/custom/typography/CustomTypography';
import { Resend } from '../../components/pages/userManagement/onBoarding/VerifyOtp';
import { PasswordInput } from '../../components/pages/userManagement/onBoarding/SigninForm';
import { BootstrapDialog } from '../../components/pages/bulkStatementUpload/mappings/Transactions';

import {
    send_otp,
    verify_otp,
    view_sms_document
} from '../../api/urls';

import {
    getQueryParams,
    stateChangeManager,
    isValidPhoneNumber,
    getFilenameFromContentDisposition
} from '../../utils/Utils';

import {
    Fab,
    Box,
    Divider,
    IconButton,
    DialogContent,
    InputAdornment
} from '@mui/material';

import { Edit } from '@mui/icons-material';

const fetchDocument = async (link_uid, exportType = 'html', data = {}) => {
    const response = await fetch(view_sms_document(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ link_uid, export_type: exportType, ...data }),
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    if (exportType === 'html') {
        const result = await response.json();
        return { ...result };
    }

    if (exportType === 'pdf') {
        const contentDisposition = response.headers.get('content-disposition');
        const filename = getFilenameFromContentDisposition(contentDisposition) || 'sampleFile.pdf';
        const blob = await response.blob();
        return { blob, filename, success: true };
    }
};

const PreviewSMS = () => {
    const location = useLocation();
    const params = getQueryParams(location.search);
    const dispatch = Actions.getDispatch(React.useContext);

    const [data, setData] = React.useState({});
    const [modalOpen, setModalOpen] = React.useState(false);
    const [htmlContent, setHtmlContent] = React.useState(null);

    React.useEffect(() => {
        if (params.t) {
            fetchDocumentData();
        }
    }, [params.t]);

    const fetchDocumentData = (data = {}) => {
        setData(data);
        fetchDocument(params.t, 'html', data)
            .then((response) => {
                if (response?.success) {
                    setModalOpen(false);
                    setHtmlContent(response.result.html_string);
                } else {
                    if (Object.keys(data)?.length) {
                        stateChangeManager(dispatch, Actions, true, 'error', response.status);
                    }
                    setModalOpen(response?.secure_by);
                }
            })
            .catch((error) => {
                console.error('Error fetching HTML content:', error);
            });
    };

    const handleDownload = async () => {
        if (params.t) {
            try {
                const pdfDocument = await fetchDocument(params.t, 'pdf', data);
                if (pdfDocument.success) {
                    fileDownload(pdfDocument.blob, pdfDocument.filename);
                }
            } catch (error) {
                console.error('Error downloading PDF:', error);
            }
        }
    };

    return (
        <Box sx={styles.fullHeightBackground}>
            <ModalToViewDocument
                open={modalOpen}
                onSave={(data) => {
                    fetchDocumentData(data);
                }}
            />
            {htmlContent && (
                <>
                    <iframe frameBorder="0" title="sms-document" srcDoc={htmlContent} style={styles.iframe} />
                    <Box sx={styles.downloadButton}>
                        <Fab size="small" aria-label="download" onClick={handleDownload}>
                            <DownloadIcon fontSize="small" />
                        </Fab>
                    </Box>
                </>
            )}
        </Box>
    );
};

const styles = {
    fullHeightBackground: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        padding: '10px 0',
        background: '#FFFFFF',
        position: 'relative',
    },
    iframe: {
        width: '100%',
        background: '#FFFFFF',
        height: 'calc(100vh - 30px)',
    },
    downloadButton: {
        right: 20,
        bottom: 16,
        position: 'fixed',
    },
};

export default PreviewSMS;

const ModalToViewDocument = ({ open, onSave }) => {
    return (
        <BootstrapDialog fullWidth open={open} maxWidth="sm">
            <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box component="form" sx={{ pt: 3, pb: 4, flexGrow: 1, maxWidth: { xs: '100%', sm: 400 } }}>
                    <CustomTypography
                        text="View your digital document"
                        sx={{ mb: 4, fontSize: '30px', fontWeight: 700, color: '#000', textAlign: 'center' }}
                    />
                    {open === 'otp' && <OtpValidation onSave={onSave} />}
                    {open === 'password' && <PasswordValidation onSave={onSave} />}
                    {open === 'input_validation' && <MobileNumberValidation onSave={onSave} />}
                </Box>
            </DialogContent>
        </BootstrapDialog>
    );
};

const MobileNumberValidation = ({ onSave }) => {
    const [disabled, setDisabled] = React.useState(true);
    const [inputValue, setInputValue] = React.useState('');

    const validateData = (input) => {
        setDisabled(true);
        let validationResult;
        if (validator.isEmail(input)) {
            validationResult = { email: input };
        } else if (isValidPhoneNumber(input)) {
            validationResult = { mobile_number: input };
        }
        if (validationResult) setDisabled(false);
        return validationResult;
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Input
                autoFocus
                id="input_mobile_number_or_email"
                dataTestId="input_mobile_number_or_email"
                item={{
                    type: 'text',
                    required: true,
                    fullWidth: true,
                    value: inputValue,
                    title: 'Email/Mobile Number',
                    placeholder: 'Enter Your Email Address or Mobile number',
                    onChange: (e) => {
                        validateData(e.target.value);
                        setInputValue(e.target.value);
                    },
                }}
            />
            <CustomButton
                fullWidth
                type="submit"
                btnLabel="Next"
                variant="contained"
                disabled={disabled}
                sx={{ mt: 4, mb: 3 }}
                onClick={() => onSave(validateData(inputValue))}
            />
        </Box>
    );
};


const PasswordValidation = ({ onSave }) => {
    const [password, setPassword] = React.useState('');
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

    return (
        <Box sx={{ mt: 2 }}>
            <PasswordInput
                label="Password"
                password={password}
                isPasswordVisible={isPasswordVisible}
                handleInputChange={(e) => setPassword(e.target.value)}
                togglePasswordVisibility={() => setIsPasswordVisible(!isPasswordVisible)}
            />
            <CustomButton
                fullWidth
                type="submit"
                btnLabel="Next"
                variant="contained"
                disabled={!password}
                sx={{ mt: 4, mb: 3 }}
                onClick={() => onSave({ password })}
            />
        </Box>
    );
};


const OtpValidation = ({ onSave }) => {
    const location = useLocation();
    const params = getQueryParams(location.search);
    const dispatch = Actions.getDispatch(React.useContext);

    const [type, setType] = React.useState(true);
    const [disabled, setDisabled] = React.useState(true);
    const [isResend, setIsResend] = React.useState(false);
    const [nextStep, setNextStep] = React.useState(false);
    const [sessionID, setSessionID] = React.useState(null);
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

    const [otp, setOtp] = React.useState('');
    const [inputValue, setInputValue] = React.useState('');

    const validateData = (input) => {
        setType(null);
        setDisabled(true);
        let validationResult;
        if (validator.isEmail(input)) {
            setType('email');
            validationResult = { email: input };
        } else if (isValidPhoneNumber(input)) {
            setType('mobile_number');
            validationResult = { mobile_number: input };
        }
        if (validationResult) setDisabled(false);
        return validationResult;
    };

    const handleResendOtp = async () => {
        const validationResult = validateData(inputValue);
        if (validationResult) {
            const response = await apiAction({
                method: 'post',
                url: send_otp(),
                data: { link_uid: params.t, otp_for: 'view_template', ...validationResult },
            });
            if (response.success) {
                setNextStep(true);
                setIsResend(!isResend);
                setSessionID(response.session_id);
                stateChangeManager(dispatch, Actions, true, 'success', response.status);
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', response.status);
            }
        }
    };

    const verifyOTP = async ({ otp, type }) => {
        const validationResult = validateData(inputValue);

        if (otp) {
            const res = await apiAction({
                method: "POST",
                url: verify_otp(),
                data: {
                    otp: otp,
                    otp_through: type,
                    session_id: sessionID,


                },
            });
            if (res?.success) {
                onSave({ session_id: sessionID, ...validationResult, })
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', `${res?.status} for ${type === 'email' ? 'Email' : 'Mobile Number '}`);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', `Please enter the otp`);
        }
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Input
                autoFocus
                id="input_mobile_number_or_email"
                dataTestId="input_mobile_number_or_email"
                item={{
                    type: 'text',
                    required: true,
                    fullWidth: true,
                    disabled: nextStep,
                    value: inputValue,
                    title: 'Email/Mobile Number',
                    placeholder: 'Enter Your Email Address or Mobile number',
                    onChange: (e) => { setInputValue(e.target.value); validateData(e.target.value) },
                }}
                InputProps={{
                    endAdornment: nextStep && (
                        <InputAdornment position="end">
                            <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                            <IconButton
                                edge="end"
                                aria-label="Edit mode"
                                onClick={() => { setNextStep(false); setOtp('') }}
                            >
                                {<Edit fontSize='small' sx={{ color: '#2464EB' }} />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            {nextStep && (
                <>
                    <Divider sx={{ mb: 3 }} />
                    <PasswordInput
                        label="OTP"
                        password={otp}
                        isPasswordVisible={isPasswordVisible}
                        handleInputChange={(e) => setOtp(e.target.value)}
                        togglePasswordVisibility={() => setIsPasswordVisible(!isPasswordVisible)}
                    />

                    <Resend
                        resendLabel="Resend"
                        sx={{ mt: 4, mb: 3 }}
                        onClick={handleResendOtp}
                    />
                </>
            )}
            <CustomButton
                fullWidth
                type="submit"
                id="sign_in_btn"
                variant="contained"
                disabled={disabled}
                sx={{ mt: 4, mb: 3 }}
                btnLabel={nextStep ? 'Next' : 'Get OTP'}
                onClick={() => { nextStep ? verifyOTP({ otp, type }) : handleResendOtp() }}
            />
        </Box>
    );
};
