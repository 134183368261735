import React from 'react';
import * as Pages from './pages/Pages';
import { apiAction_social } from '../../../../api/api';
import { routesName } from '../../../../config/routesName';
import { useLocation,useNavigate } from 'react-router-dom';
import { isEmptyDict, getQueryParams } from '../../../../utils/Utils';
import { capture_marketing_page_metadata } from '../../../../api/urls';



const MarketingLandingPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = getQueryParams(location.search);

    const captureParamsInformation = async () => {
        let res = await apiAction_social({
            method: 'post',
            url: capture_marketing_page_metadata(),
            data: { ...params },
        })
    }

    React.useEffect(() => {
        if (!isEmptyDict(params)) {
            captureParamsInformation();
        }
    }, []);

    const navigateToLogin=()=>{navigate("/auth" + routesName.signIn.path)}
    const navigateToSignup=()=>{navigate("/auth" + routesName.signUp.path)}
    return (
        <div style={{ height: '100%', background: '#FFFFFF' }}>
            <Pages.Header navigateToSignup={()=>{navigateToSignup()}} navigateToLogin={()=>{navigateToLogin()}}/>
            <Pages.Banner navigateToSignup={()=>{navigateToSignup()}} navigateToLogin={()=>{navigateToLogin()}}/>
            <Pages.KeyFeatures navigateToSignup={()=>{navigateToSignup()}} navigateToLogin={()=>{navigateToLogin()}}/>
            <Pages.AuditCompliant navigateToSignup={()=>{navigateToSignup()}} navigateToLogin={()=>{navigateToLogin()}}/>
            <Pages.OverduePayment navigateToSignup={()=>{navigateToSignup()}} navigateToLogin={()=>{navigateToLogin()}}/>
            <Pages.Benefits navigateToSignup={()=>{navigateToSignup()}} navigateToLogin={()=>{navigateToLogin()}}/>
            <Pages.Pricing navigateToSignup={()=>{navigateToSignup()}} navigateToLogin={()=>{navigateToLogin()}}/>
            <Pages.Reviews navigateToSignup={()=>{navigateToSignup()}} navigateToLogin={()=>{navigateToLogin()}}/>
            <Pages.Footer navigateToSignup={()=>{navigateToSignup()}} navigateToLogin={()=>{navigateToLogin()}}/>
        </div>
    )
}

export default MarketingLandingPage;
