import React from 'react';
import { CommonNotes } from './common/CustomElement';
import { CustomHeading } from './common/CustomElement';
import CustomTitle from '../../../../common/CustomTitle';
import CustomButton from '../../../../custom/button/CustomButton';
import CustomTypography from '../../../../custom/typography/CustomTypography';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
    Box,
    Grid,
} from '@mui/material';

const NotesPreferences = (props) => {
    const { initial_data, onSaveHandler, type } = props;
    const [data, setData] = React.useState({ ...initial_data });


    return (
        data &&
        <div>
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: "center",
                    justifyItems: "center",
                    alignContent: "space-evenly",
                    justifyContent: "space-evenly",
                }}>
                    <Grid item xs={6}>
                        <CustomTitle title={type === 'sales' ? 'Customers Notes' : 'Vendors Notes'} />
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "end", alignSelf: "center" }}>
                        <CustomButton
                            sx={{}}
                            btnLabel="Save"
                            variant="contained"
                            id={"save_customer_preferences"}
                            dataTestId={"save_customer_preferences"}
                            onClick={() => { onSaveHandler(data) }}
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>
            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)',maxHeight:{xs:'calc(100vh - 180px)',sm:'100%'} }}>
                <Box sx={{ p: 2, pt: 0 }}>
                    <Box sx={{ pb: 2, mb: 2, borderBottom: '1px solid #e0e0e0' }}>
                        <CustomTypography
                            text={`Set the default Notes. This will be pre‑selected in the creation form.`}
                            sx={{
                                color: "#7A7A7A",
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "18px",
                                fontStyle: "normal",
                                fontFamily: "Noto Sans",
                            }}
                        />
                    </Box>
                    {type === 'sales' &&
                        <React.Fragment>
                            <EstimatePreference data={data} setData={setData} />
                            <SalesOrderPreference data={data} setData={setData} />
                            <DeliveryChallanPreference data={data} setData={setData} />
                            <InvoicePreference data={data} setData={setData} />
                            <RecurringInvoicePreference data={data} setData={setData} />
                            <PaymentReceivedPreference data={data} setData={setData} />
                            <CreditNotePreference data={data} setData={setData} />
                        </React.Fragment>
                    }

                    {type === 'purchase' &&
                        <React.Fragment>
                            <PurchaseOrderPreference data={data} setData={setData} />
                            <BillPreference data={data} setData={setData} />
                            <PaymentMadePreference data={data} setData={setData} />
                            <DebitNotePreference data={data} setData={setData} />
                        </React.Fragment>
                    }
                </Box>
            </CustomContainer>

        </div>
    )
}

export default NotesPreferences;

const EstimatePreference = (props) => {
    const { data, setData } = props;
    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, estimate: { ...data.estimate, [key_name]: value } }))
    }
    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Estimate</span>} />
                </Grid>
                <Grid item xs={10} sx={{}}>
                    <CommonNotes
                        componentRef={`estimate`}
                        value={data && data.estimate && data.estimate.notes}
                        setValue={(value) => { onHandleChange('notes', value); }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

const SalesOrderPreference = (props) => {
    const { data, setData } = props;
    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, sales_order: { ...data.sales_order, [key_name]: value } }))
    }
    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Sales Order</span>} />
                </Grid>
                <Grid item xs={10} sx={{}}>
                    <CommonNotes
                        componentRef={`sales_order`}
                        value={data && data.sales_order && data.sales_order.notes}
                        setValue={(value) => { onHandleChange('notes', value); }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

const DeliveryChallanPreference = (props) => {
    const { data, setData } = props;
    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, delivery_challan: { ...data.delivery_challan, [key_name]: value } }))
    }
    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Delivery Challan</span>} />
                </Grid>
                <Grid item xs={10} sx={{}}>
                    <CommonNotes
                        componentRef={`delivery_challan`}
                        setValue={(value) => { onHandleChange('notes', value); }}
                        value={data && data.delivery_challan && data.delivery_challan.notes}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

const InvoicePreference = (props) => {
    const { data, setData } = props;
    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, invoice: { ...data.invoice, [key_name]: value } }))
    }

    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Invoice</span>} />
                </Grid>
                <Grid item xs={10} sx={{}}>
                    <CommonNotes
                        componentRef={`invoice`}
                        value={data && data.invoice && data.invoice.notes}
                        setValue={(value) => { onHandleChange('notes', value); }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}


const RecurringInvoicePreference = (props) => {
    const { data, setData } = props;
    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, recurring_invoice: { ...data.recurring_invoice, [key_name]: value } }))
    }

    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Recurring Invoice</span>} />
                </Grid>
                <Grid item xs={10} sx={{}}>
                    <CommonNotes
                        componentRef={`recurring_invoice`}
                        setValue={(value) => { onHandleChange('notes', value); }}
                        value={data && data.recurring_invoice && data.recurring_invoice.notes}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}


const PaymentReceivedPreference = (props) => {
    const { data, setData } = props;
    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, payment_received: { ...data.payment_received, [key_name]: value } }))
    }

    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Payment Received</span>} />
                </Grid>
                <Grid item xs={10} sx={{}}>
                    <CommonNotes
                        componentRef={`payment_received`}
                        setValue={(value) => { onHandleChange('notes', value); }}
                        value={data && data.payment_received && data.payment_received.notes}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}


const CreditNotePreference = (props) => {
    const { data, setData } = props;
    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, credit_note: { ...data.credit_note, [key_name]: value } }))
    }

    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Credit Note</span>} />
                </Grid>
                <Grid item xs={10} sx={{}}>
                    <CommonNotes
                        componentRef={`credit_note`}
                        setValue={(value) => { onHandleChange('notes', value); }}
                        value={data && data.credit_note && data.credit_note.notes}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}


const PurchaseOrderPreference = (props) => {
    const { data, setData } = props;
    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, purchase_order: { ...data.purchase_order, [key_name]: value } }))
    }

    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Purchase Order</span>} />
                </Grid>
                <Grid item xs={10} sx={{}}>
                    <CommonNotes
                        componentRef={`purchase_order`}
                        setValue={(value) => { onHandleChange('notes', value); }}
                        value={data && data.purchase_order && data.purchase_order.notes}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}


const BillPreference = (props) => {
    const { data, setData } = props;
    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, bill: { ...data.bill, [key_name]: value } }))
    }

    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Bill</span>} />
                </Grid>
                <Grid item xs={10} sx={{}}>
                    <CommonNotes
                        componentRef={`bill`}
                        value={data && data.bill && data.bill.notes}
                        setValue={(value) => { onHandleChange('notes', value); }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}


const PaymentMadePreference = (props) => {
    const { data, setData } = props;
    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, payment_made: { ...data.payment_made, [key_name]: value } }))
    }

    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Payment Made</span>} />
                </Grid>
                <Grid item xs={10} sx={{}}>
                    <CommonNotes
                        componentRef={`payment_made`}
                        setValue={(value) => { onHandleChange('notes', value); }}
                        value={data && data.payment_made && data.payment_made.notes}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}


const DebitNotePreference = (props) => {
    const { data, setData } = props;
    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, debit_note: { ...data.debit_note, [key_name]: value } }))
    }

    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Debit Note</span>} />
                </Grid>
                <Grid item xs={10} sx={{}}>
                    <CommonNotes
                        componentRef={`debit_note`}
                        setValue={(value) => { onHandleChange('notes', value); }}
                        value={data && data.debit_note && data.debit_note.notes}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
