import * as React from 'react';
import DateRange from '../../common/DateRange';
import * as Actions from '../../../state/Actions';
import CustomTypography from '../typography/CustomTypography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import {
    Box,
    styled,
    Pagination,
    PaginationItem,
} from '@mui/material';

const Dropdown = DateRange;
const CustomPagination = (props) => {
    const { page, count, onChange, isRowCount = false} = props

    const state = Actions.getState(React.useContext);
    const dispatch = Actions.getDispatch(React.useContext);

    React.useEffect(() => {
        if (count < page) {
            onChange('', 1)
        }
    }, [count]);

    const {rows_per_page}=state;
    const setRowCount=(rows)=>{
        dispatch(Actions.stateChange("rows_per_page",rows));
    }
    return (
        <PaginationContainer sx={{}}>
            <Box sx={{ display: 'flex', alignItems: 'center',justifyContent:'center' }}>
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Pagination
                        sx={{}}
                        page={page}
                        size='small'
                        count={count}
                        color="primary"
                        shape="rounded"
                        onChange={onChange}
                        renderItem={(item) => (
                            <PaginationItem
                                components={{ previous: ArrowBackIosIcon, next: ArrowForwardIosIcon }}
                                {...item}
                            />
                        )}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end',textAlign:'end' }}>
                    {isRowCount ?
                        <RowsPerPageComponent
                            page={page}
                            count={count}
                            color="primary"
                            shape="rounded"
                            onChange={onChange}
                            sx={{
                                ul: {
                                  flexWrap: 'nowrap',
                                },
                              }}
                            renderItem={(item) => (
                                <PaginationItem
                                    components={{ previous: ArrowBackIosIcon, next: ArrowForwardIosIcon }}
                                    {...item}
                                />
                            )}
                        />
                        :
                        <CustomTypography
                            text={<span>Page : {page} of {count}</span>}
                            sx={{
                                color: '#8F8F8F',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '19px',
                                fontStyle: 'normal',
                                textTransform: 'none',
                                fontFamily: "Noto Sans",
                            }}
                        />
                    }

                </Box>
            </Box>
        </PaginationContainer>
    );
}

export default CustomPagination;

export const PaginationContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
    padding: '16px',
    marginTop: '8px',
    paddingTop: '10px',
    paddingBottom: '0px',
    maxHeight: '50px !important',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const RowsPerPageComponent = (props) => {
    const { page, count,rowCount=10, setRowCount} = props;

    const rowsCountList = [
        { name: '10', vale: 10 },
        { name: '25', vale: 25 },
        { name: '50', vale: 50 },
        { name: '100', vale: 100 },
        { name: '500', vale: 500 },
    ]


    return (
        <CustomTypography
            text={
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end !important' }}>
                    <span style={{ marginRight: 5 }}>Rows per page :</span>
                    <Dropdown
                        label=""
                        placeholder=''
                        results={rowsCountList}
                        disableClearable={true}
                        id={'rows_page_count_dropdown'}
                        dataTestId={'rows_page_count_dropdown'}

                        setValue={(event, value) => { setRowCount(value.vale) }}
                        value={rowCount ? rowsCountList.find((item) => item.vale === rowCount) : null}

                        item={{
                            sx: { minWidth: 80, border: 'none' }
                        }}
                    />
                    {/* –{rowCount} */}
                    <span style={{ marginLeft: 5 }}>{page} of {count}</span>
                </Box>
            }
            sx={{
                color: '#8F8F8F',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '19px',
                fontStyle: 'normal',
                textTransform: 'none',
                fontFamily: "Noto Sans",
            }}
        />
    )
}
