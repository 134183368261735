import React from 'react';
import moment from 'moment';
import * as Common from '../../common/Common';
import Status from '../../../../common/Status';
import DateRange from '../../../../common/DateRange';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import { useNavigate, useLocation } from 'react-router-dom';
import AsyncDropdown from '../../../../common/AsyncDropdown';
import { routesName } from '../../../../../config/routesName';
import { CommonWebSocket } from '../../../../../api/webSocket';
import { expense_number } from '../../../../../utils/Constant';
import ListItemActions from '../../../../common/ListItemActions';
import CustomButton from '../../../../custom/button/CustomButton';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { NoDataComponent } from '../../../../common/NoDataComponent';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import { HeadingWithSortable } from '../../../../common/SortableHeading';
import CommonAsyncDropdown from '../../../../common/CommonAsyncDropdown';
import CommonDownloadLayout from '../../../../common/CommonDownloadLayout';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import { eventsNames, AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';
import CustomDateRangePicker from '../../../../custom/Datepicker/CustomDateRangePicker';

import {
    ExpenseViewLink,
    ActionTextLinkBtn
} from '../../common/CommonLinks';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
    apiAction,
    apiBlobResponse,
    apiHandleDownload
} from '../../../../../api/api';

import {
    setLoader,
    getDateFormat,
    setFilterMessage,
    isFiltersApplied,
    stateChangeManager,
} from '../../../../../utils/Utils';

import {
    list_vendor,
    list_expense,
    export_expense,
    delete_expense,
    retrieve_expense,
    get_chart_of_account_nested_nodes,
} from '../../../../../api/urls';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';

import {
    Box,
    Grid,
} from '@mui/material';
import CurrencyFormatterWithExchangeRate from '../../common/CurrencyFormatterWithExchangeRate';

const Dropdown = DateRange;
const ExpenseList = () => {
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [page, setPage] = React.useState(1);
    const [results, setResults] = React.useState([])
    const [reLoad, setReLoad] = React.useState(false)
    const [isDownload, setIsDownload] = React.useState(false);
    const [disabledDataExport, setDisabledDataExport] = React.useState(false);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    })


    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })

    const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage) => {
        setState({
            url: url,
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            deleteMessage: deleteMessage,
            onDeleteAction: () => { getApiResults(body, page); }
        })
    }

    const [filters, setFilters] = React.useState({
        sort_by: 'expense_date',
        sort_order: 'D'
    })

    const webSocketResponse = CommonWebSocket();
    React.useEffect(() => {
        if (webSocketResponse) {
            let webSocketData = JSON.parse(webSocketResponse.data)
            if (webSocketData.document_type === "expense") {
                let index = results.findIndex((item) => item.id === webSocketData.object_id);

                if (webSocketData.event === "delete_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Expense ${expense_number}-${webSocketData.object_id} deleted successfully`);
                        getApiResults(body, page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to delete Expense ${expense_number}-${webSocketData.object_id}`);
                    }
                }

                if (webSocketData.event === "update_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Expense ${expense_number}-${webSocketData.object_id} updated successfully`);
                        getApiResults(body, page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to update Expense ${expense_number}-${webSocketData.object_id}`);
                    }
                }
            }
        }
    }, [webSocketResponse])


    let body = { business_id: getBusinessInfo().id, ...filters }
    React.useEffect(() => {
        getApiResults(body, page);
        // eslint-disable-next-line
    }, [filters, page, reLoad])

    const getApiResults = async (body, page) => {
        setLoader(dispatch, Actions, true);
        setFilterMessage(dispatch, Actions, null);
        setDisabledDataExport(false);
        let res = await apiAction({
            data: body,
            method: 'post',
            dispatch: dispatch,
            navigate: navigate,
            url: list_expense(page),
        })
        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.next,
                count: res?.result.count,
                previous: res?.result.previous,
                number_of_pages: res?.result.number_of_pages,
            });

            setResults(res?.result.result);
            setLoader(dispatch, Actions, false);
            if (res?.result?.count === 0) { setDisabledDataExport(true) }
            if (isFiltersApplied(filters)) { setFilterMessage(dispatch, Actions, `No expenses found for your current filters. Verify your filters and try again.`) };
        } else {
            setLoader(dispatch, Actions, false);
        }
    }


    React.useEffect(() => {
        const statusMapping = {
            invoiced: eventsNames.actions.expense.filter_type.INVOICED,
            unbilled: eventsNames.actions.expense.filter_type.UN_BILLED,
            reimbursed: eventsNames.actions.expense.filter_type.REIMBURSED,
            non_billable: eventsNames.actions.expense.filter_type.NON_BILLABLE,
        };

        const filter_type = filters.status
            ? statusMapping[filters.status]
            : eventsNames.actions.expense.filter_type.ALL;

        AnalyticsEvent(eventsNames.categories.EXPENSES, { action: eventsNames.actions.CLICK, filter_type: filter_type });
    }, [filters.status]);


    return (
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <CommonDownloadLayout
                open={isDownload}
                setOpen={setIsDownload}
                filters={filters}
                componentType={"expense"}
            />
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                        <CustomTitle title={'Expenses'} />
                    </Grid>
                    <Grid item xs={6} sm={6} sx={{ display: '-webkit-flex', justifyContent: 'end', gap : 1}}>
                        <CustomButton
                            variant="contained"
                            id={'create_expense_btn'}
                            btnLabel='Record Expense'
                            dataTestId={'create_expense_btn'}
                            sx={{ textTransform: 'none', }}
                            onClick={() => {
                                navigate(routesName.invoicingExpenseAdd.path)
                                AnalyticsEvent(eventsNames.categories.EXPENSES, { action: eventsNames.actions.NEW })
                            }}
                        />
                        <CustomButton
                            disabled={disabledDataExport}
                            variant="contained"
                            id={'download_expense_btn'}
                            btnLabel='Download CSV'
                            sx={{ textTransform: 'none', }}
                            dataTestId={'download_expense_btn'}
                            onClick={() => {
                                setIsDownload(true);
                                AnalyticsEvent(eventsNames.categories.EXPENSES,{action:eventsNames.actions.DOWNLOAD});
                            }}
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>
            <CustomContainer maxWidth={"400"} sx={{ maxHeight: { xs: 'calc(100vh - 240px)', sm: '100%' } }}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '24px', display: '-webkit-flex', }}>
                    <Filters stateChangeHandler={stateChangeHandler} filters={filters} setFilters={setFilters} setPage={setPage} />
                </div>

                <CustomTableContainer>
                    <CustomTable sx={{}}>
                        <ListHeaders filters={filters} setFilters={setFilters} />
                        <ListBody data={results} stateChangeHandler={stateChangeHandler} reload={() => setReLoad(!reLoad)} />
                    </CustomTable>
                    <Box sx={{ pt: !results.length ? 10 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>

            <CustomPagination
                page={page}
                count={pagination.number_of_pages}
                onChange={(event, newValue) => setPage(newValue)}
            />
        </div>
    )
}

export default ExpenseList;

const ListHeaders = (props) => {
    const { filters, setFilters } = props

    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell style={{ width: 100 }} align='center'><span style={{ cursor: '' }}>Status </span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 100 }}><HeadingWithSortable heading={'Date'} sortableKey={'expense_date'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 100 }}><HeadingWithSortable heading={'Number'} sortableKey={'id'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }}><HeadingWithSortable heading={'Expense Account'} sortableKey={'expense_account_name'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 180 }}><HeadingWithSortable heading={'Vendor Name'} sortableKey={'vendor_name'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }}><HeadingWithSortable heading={'Paid Through'} sortableKey={'paid_through_account'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 180 }}><HeadingWithSortable heading={'Customer Name'} sortableKey={'customer_name'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} align='right' ><HeadingWithSortable heading={'Amount'} sortableKey={'total_amount'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} align='right' ><span>Actions</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const ListBody = (props) => {
    const { data, stateChangeHandler, reload } = props
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const onSelectAction = (action, item) => {
        setLoader(dispatch, Actions, true)
        if (action === "edit") {
            setLoader(dispatch, Actions, true)
            navigate(routesName.invoicingExpenseAdd.path + "?id=" + item.id)
        }
        if (action === "view") {
            setLoader(dispatch, Actions, true)
            navigate(routesName.invoicingExpenseView.path + "?id=" + item.id)
            AnalyticsEvent(eventsNames.categories.EXPENSES, { action: eventsNames.actions.VIEW })
        }
        if (action === "print") {
            print(item)
            AnalyticsEvent(eventsNames.categories.EXPENSES, { action: eventsNames.actions.PRINT })

        }
        if (action === "download") {
            download(item)
            AnalyticsEvent(eventsNames.categories.EXPENSES, { action: eventsNames.actions.DOWNLOAD })
        }
        if (action === "convert") {
            setLoader(dispatch, Actions, true)
            navigate(routesName.invoicingSalesInvoiceAdd.path + "?expense_id=" + item.id)
            AnalyticsEvent(eventsNames.categories.EXPENSES, { action: eventsNames.actions.expense.action.CONVERT_INVOICE })
        }

    }

    const print = async (expense) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_expense(expense.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            apiBlobResponse({
                url: export_expense(), data: { ...data.result }, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }

    const download = async (expense) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_expense(expense.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            apiHandleDownload({
                url: export_expense(), data: { ...data.result }, filename: `${expense_number}-${data.result.id}`, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }

    const toActionText = (status) => {
        let text = ''
        if (status === 'unbilled') {
            text = 'Convert to Invoice'
        } else if (status !== 'unbilled') {
            text = 'View'
        }
        return text
    }

    const onClickActionText = (status, item) => {
        if (status === 'unbilled') {
            onSelectAction("convert", item);
        } else if (status !== 'unbilled') {
            onSelectAction("view", item);
        }
    }

    return (
        <CustomTableBody>
            {
                data.map((item, index) => {
                    return (
                        <CustomTableRow key={index}>
                            <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><span>{moment(item.expense_date).format(getDateFormat())}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><span><ExpenseViewLink id={item.id} title={item.id} /></span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><span>{item.expense_account_name}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><span>{item.vendor_name ? item.vendor_name : "-"}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><span>{item.paid_through_account_name}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><span>{item.customer_name ? item.customer_name : "-"}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>
                                <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                            </CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <ActionTextLinkBtn
                                        index={index}
                                        toActionText={toActionText(item.status)}
                                        onClickActionText={() => onClickActionText(item.status, item)} />
                                    <ListItemActions
                                        index={index}
                                        actions={
                                            [
                                                item.status === 'unbilled' && { name: 'View', onClick: () => { onSelectAction("view", item) } },
                                                item.status !== 'invoiced' && { name: 'Edit', onClick: () => { onSelectAction("edit", item) } },
                                                { name: 'Print', onClick: () => { onSelectAction("print", item) } },
                                                { name: 'Export as PDF', onClick: () => { onSelectAction("download", item) } },
                                                { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Expense', 'delete', 'sm', delete_expense(item.id), `The expense record will be deleted and can not be retrieved later.\n Are you sure about deleting it?`); AnalyticsEvent(eventsNames.categories.EXPENSES, { action: eventsNames.actions.DELETE }) } }
                                            ]
                                        }
                                    />
                                </Box>
                            </CustomTableBodyCell>
                        </CustomTableRow>
                    )
                })
            }
        </CustomTableBody>
    )
}

const Filters = (props) => {
    const { setPage, setFilters, filters, stateChangeHandler } = props
    let location = useLocation();

    let [dates, setDate] = React.useState();
    const [vendor, setVendor] = React.useState(null);
    const [customer, setCustomer] = React.useState(null);
    const [selectedAccount, setSelectedAccount] = React.useState();


    const onDateRangeChange = (dates) => {
        setPage(1);
        if (dates) {
            setFilters({ ...filters, expense_start_date: dates[0].format("YYYY-MM-DD"), expense_end_date: dates[1].format("YYYY-MM-DD"), dates : dates })
        } else {
            delete filters.expense_start_date
            delete filters.expense_end_date
            delete filters.dates
            setFilters({ ...filters })
        }

    }


    //This code block is use when we pass the state through navigate
    const setLocationData = (vendor, vendor_id, from_date, to_date) => {

        let end_date = to_date ? to_date : null;
        let start_date = from_date ? from_date : null;

        setVendor(vendor);
        filters.vendor_id = vendor_id;
        filters.expense_end_date = end_date;
        filters.expense_start_date = start_date;
        setDate([moment(start_date), moment(end_date)]);
    }

    React.useEffect(() => {
        if (location.state) {
            if (location.state.action === 'purchase_by_vendor') {
                setLocationData(location.state.vendor, location.state.vendor_id, location.state.from_date, location.state.to_date);
            }
        }
        setTimeout(() => setFilters({ ...filters }), 100);
    }, [location.state]);

    return (
        <Grid container>
            <Grid item xs={12} container sx={{ mb: 2 }} spacing={1}>
                <Grid item xs={6} sm={2.5} sx={{pb:{xs:2,sm:0}}} >
                    <Common.FilterStatus
                        filters={filters}
                        setPage={setPage}
                        setFilters={setFilters}
                        dataKey={'expenseStatus'}
                    />
                </Grid>
                <Grid item xs={6} sm={2.5} sx={{pb:{xs:2,sm:0}}}>
                    <CommonAsyncDropdown
                        id={'vendor_dropdown'}
                        dataTestId={'vendor_dropdown'}
                        autoSelect={false}
                        disableClearable={false}
                        optionLabel="display_name"
                        placeholder='Select Vendor'
                        noOptionsText={"No result found"}
                        item={{
                            method: 'post',
                            label: 'Vendors',
                            value: vendor,
                            url: list_vendor(1),
                            body: { is_inactive: false, business_id: getBusinessInfo().id, role: 'vendor' },
                            onChange: (event, value) => {
                                setPage(1);
                                if (value) {
                                    setFilters({ ...filters, vendor_id: value.id, selectedVendor : value  })
                                } else {
                                    delete filters.vendor_id
                                    delete filters.selectedVendor
                                    setFilters({ ...filters })
                                }
                                setVendor(value)
                            },
                        }}
                        addButton={{
                            title: '+ Add new vendor',
                            onClick: () => stateChangeHandler('New Vendor', 'new_vendor', 'lg')
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={2.5} sx={{pb:{xs:2,sm:0}}}>
                    <CommonAsyncDropdown
                        id={'customer_dropdown'}
                        dataTestId={'customer_dropdown'}
                        autoSelect={false}
                        disableClearable={false}
                        optionLabel="display_name"
                        placeholder='Select Customer'
                        noOptionsText={"No result found"}
                        item={{
                            method: 'post',
                            label: 'Customer',
                            value: customer,
                            url: list_vendor(1),
                            body: { business_id: getBusinessInfo().id, role: 'customer' },
                            onChange: (event, value) => {
                                setPage(1);
                                if (value) {
                                    setFilters({ ...filters, customer_id: value.id, selectedCustomer : value })
                                } else {
                                    delete filters.customer_id
                                    delete filters.selectedCustomer
                                    setFilters({ ...filters })
                                }
                                setCustomer(value)
                            },
                        }}

                    />
                </Grid>

                <Grid item xs={6} sm={2.5} sx={{pb:{xs:2,sm:0}, display: { xs: 'block', sm: 'none' } }}>
                    <AsyncDropdown
                        sx={{}}
                        fullWidth={true}
                        autoFocus={false}
                        newResults={null}
                        validation={false}
                        disabledCloseIcon={false}
                        isGroupHeaderSticky={true}
                        title={'Expense Account'}
                        id={"expense_account_dropdown"}
                        dataTestId={"expense_account_dropdown"}

                        selectedValue={selectedAccount}
                        setSelectedValue={(value) => {
                            setPage(1);
                            setSelectedAccount(value);
                            if (value) {
                                setFilters({ ...filters, expense_account_id: value.id, selectedExpenseAcc : value })
                            } else {
                                delete filters.expense_account_id
                                delete filters.selectedExpenseAcc
                                setFilters({ ...filters })
                            }
                        }}

                        valueLabelKey='id'
                        uniqueOptionKey='id'
                        searchKey='account_name'
                        optionLabelKey='account_name'
                        placeholder='Select the Account'
                        optionGroupLabelKey='account_type'
                        playLoad={{ account_type: ["EXPENSE"] }}
                        URL={get_chart_of_account_nested_nodes(1)}

                    />
                </Grid>

                <Grid item xs={12} sm={4.5} container>
                    <CustomDateRangePicker dates={dates} onDateRangeChange={onDateRangeChange} />
                </Grid>
            </Grid>
            <Grid item xs={6} sm={12} container spacing={1} sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Grid item xs={12} sm={7.5} />
                <Grid item xs={12} sm={4.5} container>
                    <Grid container item xs={12} sx={{}} spacing={1}>
                        <Grid item xs={12} sm={11}>
                            <AsyncDropdown
                                sx={{}}
                                fullWidth={true}
                                autoFocus={false}
                                newResults={null}
                                validation={false}
                                disabledCloseIcon={false}
                                isGroupHeaderSticky={true}
                                title={'Expense Account'}
                                id={"expense_account_dropdown"}
                                dataTestId={"expense_account_dropdown"}

                                selectedValue={selectedAccount}
                                setSelectedValue={(value) => {
                                    setPage(1);
                                    setSelectedAccount(value);
                                    if (value) {
                                        setFilters({ ...filters, expense_account_id: value.id, selectedExpenseAcc : value })
                                    } else {
                                        delete filters.expense_account_id
                                        delete filters.selectedExpenseAcc
                                        setFilters({ ...filters })
                                    }
                                }}

                                valueLabelKey='id'
                                uniqueOptionKey='id'
                                searchKey='account_name'
                                optionLabelKey='account_name'
                                placeholder='Select the Account'
                                optionGroupLabelKey='account_type'
                                playLoad={{ account_type: ["EXPENSE"] }}
                                URL={get_chart_of_account_nested_nodes(1)}

                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}