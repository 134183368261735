import React from 'react';
import moment from 'moment';
import { apiAction } from '../../../../api/api';
import UpdateUserProfile from './UpdateUserProfile';
import { retrieve_user } from '../../../../api/urls';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import CustomButton from '../../../custom/button/CustomButton';
import { setUserDetails } from '../../../../config/cookiesInfo';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
  setLoader,
  getDateFormat,
  get_party_contact_name,
} from '../../../../utils/Utils';

import {
  CustomContainer,
  CustomTitleContainer,
} from '../../../custom/container/CustomContainer';

import { Box, Grid, Avatar, IconButton, } from '@mui/material';
import { Edit } from '@mui/icons-material';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import ModalUpdateUserDetails from '../../userManagement/onBoarding/ModalUpdateUserDetails';

const ViewProfile = ({ initial_state }) => {
  const dispatch = Actions.getDispatch(React.useContext);
  const [edit, setEdit] = React.useState(false);
  const [result, setResult] = React.useState();

  React.useEffect(() => { setEdit(false) }, [initial_state]);
  const fetchUserProfile = async () => {
    setLoader(dispatch, Actions, true);
    const res = await apiAction({ method: 'get', url: retrieve_user() });
    if (res?.success) {
      setResult(res?.result);
      setUserDetails(res?.result);
    }
    setLoader(dispatch, Actions, false);
  };
  React.useEffect(() => {
    fetchUserProfile();
  }, [edit, dispatch]);

  if (edit) {
    return <UpdateUserProfile result={result} onCancel={() => setEdit(false)} />;
  }

  return (
    <div>
      <ProfileHeader onEdit={() => setEdit(true)} />
      <CustomContainer maxWidth="400" sx={{ height: 'calc(100vh - 134px)',maxHeight:{xs:'calc(100vh - 180px)',sm:'100%'} }}>
        {result && (
          <>
            <BasicInfo result={result} onEdit={() => setEdit(true)} />
            <ProfileDetails result={result} fetchUserProfile={fetchUserProfile}/>
          </>
        )}
      </CustomContainer>
    </div>
  );
};

const ProfileHeader = ({ onEdit }) => (
  <CustomTitleContainer>
    <Grid container spacing={0} alignItems="center" justifyContent="space-between">
      <Grid item xs={6}>
        <CustomTitle title="My Profile" />
      </Grid>
      <Grid item xs={6} textAlign="end" sx={{visibility:'hidden'}}>
        <CustomButton
          id="edit_btn"
          btnLabel="Edit"
          onClick={onEdit}
          variant="contained"
        />
      </Grid>
    </Grid>
  </CustomTitleContainer>
);

const BasicInfo = ({ result,onEdit }) => (
  <Box sx={{ borderBottom: '2px solid #F5F5F5', p: 2, display: 'flex', flexDirection: 'row' }}>
    <Box sx={{display: 'flex', alignItems: 'center', flexGrow: 1 }}>
    <Avatar />
    <Box sx={{ alignSelf: 'center', ml: 2.5 }}>
      <CustomTypography
        text={get_party_contact_name(result) || result.name}
        sx={profileTypographyStyle('14px', '700')}
      />
      <CustomTypography
        text={result.email}
        sx={profileTypographyStyle('12px', '500', '#7A7A7A')}
      />
    </Box>
    </Box>
    <IconButton onClick={() => {onEdit() }}>
          <Edit fontSize="small" sx={{ color: '#2464EB' }} />
        </IconButton>
  </Box>
);

const ProfileDetails = ({ result ,fetchUserProfile}) => (
  <Box sx={{ overflow: 'auto', height: 'calc(100vh - 222px)', }}>
    <DetailsInfo result={result} />
    <ContactInfo
      type='email'
      value={result.email}
      title="My Email Address"
      fetchUserProfile={fetchUserProfile}
      icon={<MailIcon sx={{ color: '#2464EB' }} />}
      description="You can use the following email address to sign in to your account and reset your password."
    />
    <ContactInfo
      type='Mobile_number'
      title="My Mobile Number"
      value={result.mobile_number}
      fetchUserProfile={fetchUserProfile}
      description="Your mobile number is used for OTP-based login."
      icon={<PhoneIcon sx={{ color: '#2464EB' }} />}
    />
  </Box>
);

const DetailsInfo = ({ result }) => {
  const listDetails = [
    { title: 'First Name', value: result.first_name },
    { title: 'Last Name', value: result.last_name },
    { title: 'Gender', value: result.gender?.capitalize() },
    { title: 'Date of Birth', value: result.date_of_birth && moment(result.date_of_birth).format(getDateFormat()) },
    { title: 'Country', value: result.country?.name },
    { title: 'State', value: result.state?.name },
    { title: 'City/Town', value: result.city },
    { title: 'Pin Code', value: result.zip_code },
  ];

  return (
    <Box sx={{ p: 2, }}>
      <Grid container spacing={3}>
        {listDetails.map((item, index) => (
          <DetailItem key={index} item={item} />
        ))}
      </Grid>
    </Box>
  );
};

const DetailItem = ({ item }) => (
  <Grid item xs={6} sm={4}>
    <CustomTypography text={item.title || '-'} sx={{...profileTypographyStyle('14px', '700')}} />
    <CustomTypography text={item.value || '-'} sx={{...profileTypographyStyle('12px', '400', '#7A7A7A'),mt:'10px'}} />
  </Grid>
);

const ContactInfo = ({ type, title, description, icon, value ,fetchUserProfile}) => {
const [open,setOpen]=React.useState(false);

if (open) {
  return <ModalUpdateUserDetails defaultValue={value} open={open} onClose={() => { setOpen(false)}} setOpen={() => { setOpen(false);fetchUserProfile()}} />
}
  return(
  <Box className={`contactInfo_${type}`} sx={{ borderTop: '20px solid #fafafa', p: 2.5, }}>
    <CustomTypography text={title} sx={profileTypographyStyle('14px', '700')} />
    <CustomTypography text={description} sx={profileTypographyStyle('12px', '500', '#7A7A7A')} />
    <Box sx={{ mt: 3, display: 'flex', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        {value && (
          <Box sx={iconWrapperStyle}>{icon}</Box>
        )}
        <Box sx={{ pl: 2 }}>
          <CustomTypography text={value} sx={profileTypographyStyle('18px', '700', '#141414')} />
        </Box>
      </Box>
      {/* Need to uncomment when email/mobile number change function required */}
      {/* {value && (
      <Box className='edit_icon'>
        <IconButton onClick={() => {setOpen(type === 'email' ?'is_email_empty':'is_mobile_number_empty')}}>
          <Edit fontSize="small" sx={{ color: '#2464EB' }} />
        </IconButton>
      </Box>
      )} */}
    </Box>
    {!value && (
      <CustomTypography text={type === 'email' ? "+ Add Email Address" : "+ Add Mobile Number"} sx={{ ...profileTypographyStyle('14px', '700', '#2464EB'), mt: 3, textAlign: 'center', cursor: 'pointer' }} />
    )}
  </Box>
)};

const profileTypographyStyle = (fontSize, fontWeight, color = '#000000') => ({
  mb: 1,
  fontSize,
  fontWeight,
  color,
  fontFamily: 'Noto Sans',
  lineHeight: '18px',
  marginBottom: '0px',
});

const iconWrapperStyle = {
  width: '48px',
  height: '48px',
  display: 'flex',
  borderRadius: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#EBF1FF',
};

export default ViewProfile;
