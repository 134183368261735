import React from 'react'
import moment from 'moment';
import { list_party } from '../../../../api/urls';
import { Grid, Box, Divider } from '@mui/material';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CommonAsyncDropdown from '../../../common/CommonAsyncDropdown';
import CustomDateRangePicker from '../../../custom/Datepicker/CustomDateRangePicker';
import { ApplyDefaultFilterCheckBox, DownloadButtons, DownloadRadioButtons } from '../../../common/CommonDownloadLayout';

const DownloadPaymentData = (props) => {
    const { setOpen, url, defaultFilters, payment_type } = props;

    const [customer, setCustomer] = React.useState(null)
    const [isDefaultFiltersApplied, setIsDefaultFiltersApplied] = React.useState(false);
    const [filters, setFilters] = React.useState({
        "export_to": "csv",
        "business_id": getBusinessInfo().id,
        "payment_type": payment_type,
    });
    const [timePeriod, setTimePeriod] = React.useState("all");

    React.useEffect(() => {
        if (isDefaultFiltersApplied) {
            const from_datetime = defaultFilters?.payment_date_start_date ? moment(defaultFilters?.payment_date_start_date)?.format("YYYY/MM/DD HH:mm:ss") : null;
            const to_datetime = defaultFilters?.payment_date_end_date ? moment(defaultFilters?.payment_date_end_date)?.format("YYYY/MM/DD HH:mm:ss") : null;
            setFilters({
                ...filters,
                status: defaultFilters?.status ? defaultFilters?.status : null,
                party_id: defaultFilters?.party_id ? defaultFilters?.party_id : null,
                from_datetime: from_datetime,
                to_datetime: to_datetime,
                dates: defaultFilters?.dates,
                selectedCustOrVendor: defaultFilters?.selectedCustOrVendor ? defaultFilters?.selectedCustOrVendor : null,
            })
            setCustomer(defaultFilters?.selectedCustOrVendor);
            if (defaultFilters?.payment_date_start_date) {
                setTimePeriod("specificTimePeriod");
            } else if (!defaultFilters?.payment_date_start_date) {
                setTimePeriod("all");
            }
        } else {
            setFilters({ ...filters })
        }
    }, [isDefaultFiltersApplied])

    React.useEffect(() => {
        if (timePeriod === "all") {
            setFilters({ ...filters, from_datetime: null, to_datetime: null, dates: [] })
        }
        if (defaultFilters?.payment_date_start_date && timePeriod === "all") {
            setIsDefaultFiltersApplied(false);
        }
    }, [timePeriod])

    const onDateRangeChange = (dates) => {
        if (dates) {
            setFilters({
                ...filters,
                from_datetime: dates[0].format("YYYY/MM/DD HH:mm:ss"),
                to_datetime: dates[1].format("YYYY/MM/DD HH:mm:ss"),
                dates: dates
            })
            if (isDefaultFiltersApplied) {
                setIsDefaultFiltersApplied(false);
            }
        } else {
            delete filters.to_datetime
            delete filters.from_datetime
            filters.dates = null
            setFilters({ ...filters })
            setIsDefaultFiltersApplied(false);
        }
    }

    // console.log("defaultFilters in payment", defaultFilters);

    return (
        <Box>
            <Grid container spacing={1}>
                <ApplyDefaultFilterCheckBox
                    isDefaultFiltersApplied={isDefaultFiltersApplied}
                    setIsDefaultFiltersApplied={setIsDefaultFiltersApplied}
                />
                <Grid item container xs={11} spacing={1} sx={{ mb: 2 }}>
                    <Grid item xs={10} sm={6}>
                        <CommonAsyncDropdown
                            id={'PaymentDownload_dropdown'}
                            dataTestId={'PaymentDownload_dropdown'}
                            autoSelect={false}
                            disableClearable={false}
                            optionLabel="display_name"
                            placeholder={payment_type === "pay" ? 'Select Vendor' : 'Select Customer'}
                            noOptionsText={"No result found"}
                            // options={[{ name: "All Customers", id: null }]}
                            item={{
                                method: 'post',
                                value: customer,
                                label: payment_type === 'pay' ? 'Vendor' : 'Customers',
                                url: list_party(1),
                                body: { is_inactive: false, business_id: getBusinessInfo().id, role: payment_type === "pay" ? 'vendor' : 'customer' },
                                onChange: (event, value) => {
                                    if (value) {
                                        setFilters({ ...filters, party_id: value.id, selectedCustOrVendor: value })
                                        if (isDefaultFiltersApplied) {
                                            setIsDefaultFiltersApplied(false);
                                        }
                                    } else {
                                        delete filters.party_id
                                        delete filters.selectedCustOrVendor
                                        setFilters({ ...filters })
                                        setIsDefaultFiltersApplied(false);
                                    }

                                    setCustomer(value)
                                },
                            }}
                            addButton={false}
                        />
                    </Grid>
                    <Grid item xs={10} sm={6}>
                    </Grid>
                </Grid>

                <Grid item xs={15} sm={12} sx={{ mb: 2 }}>
                    <DownloadRadioButtons
                        timePeriod={timePeriod}
                        setTimePeriod={setTimePeriod}
                        allButtonLabel={`All Payments ${payment_type === "receive" ? "Recieved" : "Made"}`}
                    />
                    {timePeriod === "specificTimePeriod" &&
                        <CustomDateRangePicker dates={filters?.dates} onDateRangeChange={onDateRangeChange} />
                    }
                </Grid>
            </Grid>

            <Divider />
            <DownloadButtons
                onClose={setOpen}
                url={url}
                filters={filters}
                setOpen={setOpen}
                timePeriod={timePeriod}
            />
        </Box>
    )
}

export default DownloadPaymentData
