import React from 'react';
import validator from 'validator';
import { Resend } from './VerifyOtp';
import Input from '../../../common/Input';
import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import CustomButton from '../../../custom/button/CustomButton';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { BootstrapDialog, BootstrapDialogTitle } from '../../bulkStatementUpload/mappings/Transactions';

import {
    send_otp,
    verify_otp,
    update_user_details,
} from '../../../../api/urls';

import {
    isFormValid,
    stateChangeManager,
    isValidPhoneNumber,
} from '../../../../utils/Utils';

import {
    Box,
    Divider,
    IconButton,
    DialogContent,
    InputAdornment,
} from '@mui/material';

import { Edit } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const styles = {
    title: {
        color: '#000000',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '18px',
        fontFamily: "Noto Sans",
    },
};

const ModalUpdateUserDetails = ({ open, setOpen, defaultValue,onClose }) => {
    const dispatch = Actions.getDispatch(React.useContext);

    const [nextStep, setNextStep] = React.useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
    const [data, setData] = React.useState({ loginInputValue: '', otp: '' });

    React.useEffect(() => {
        if (defaultValue) {
            setData((prev) => ({ ...prev, loginInputValue: defaultValue }));
        }
    }, [defaultValue])
    const isEmail = open === 'is_email_empty';

    const handleInputChange = (field) => (event) => {
        setData((prev) => ({ ...prev, [field]: event.target.value }));
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prev) => !prev);
    };

    const getOTP = async () => {
        const additionalData = isEmail
            ? { email: data.loginInputValue }
            : { mobile_number: data.loginInputValue };

        const res = await apiAction({
            method: 'post',
            url: send_otp(),
            data: { otp_for: 'verification', ...additionalData },
        });

        if (res?.success) {
            setNextStep(true);
            setIsFormSubmitted(false);
            setData((prev) => ({
                ...prev,
                ...additionalData,
                otp: '',
                session_id: res?.session_id,
            }));
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    };

    const handleValidation = () => {
        const validationData = [
            { key: 'loginInputValue', message: isEmail ? 'Email address field cannot be empty' : 'Mobile number field cannot be empty' },
            {
                key: 'loginInputValue',
                message: isEmail ? 'Please enter a valid Email Address' : 'Please enter a valid Mobile Number',
                validation: isEmail
                    ? data.loginInputValue && !validator.isEmail(data.loginInputValue)
                    : data.loginInputValue && !isValidPhoneNumber(data.loginInputValue),
            },
        ];

        return isFormValid(data, validationData);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsFormSubmitted(true);

        const { isValid, message } = handleValidation();
        if (isValid) {
            getOTP();
            setIsFormSubmitted(false);
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    };

    const verifyOTP = async (event) => {
        event.preventDefault();
        const validationData = [{ key: 'otp', message: 'OTP field cannot be empty' }];
        const { isValid, message } = isFormValid(data, validationData);

        if (isValid) {
            const res = await apiAction({
                method: 'post',
                url: verify_otp(),
                data: { ...data, otp_through: isEmail ? 'email' : 'mobile_number' },
            });
            if (res?.success) {
                await updateUserDetails();
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    };

    const updateUserDetails = async () => {
        const res = await apiAction({
            method: 'post',
            url: update_user_details(),
            data: { ...data },
        });
        if (res?.success) {
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            setOpen();
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    };

    return (
        <BootstrapDialog fullWidth open={open} maxWidth="sm">
            <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                <CustomTypography text="Verify Details" sx={styles.title} />
            </BootstrapDialogTitle>
            <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                    component="form"
                    onSubmit={nextStep ? verifyOTP : handleSubmit}
                    sx={{ flexGrow: 1, maxWidth: { xs: '100%', sm: 400 } }}
                >
                    <Box sx={{ mt: 2 }}>
                        <Input
                            autoFocus
                            id="input_missing_field"
                            dataTestId="input_missing_field"
                            item={{
                                type: 'text',
                                required: true,
                                fullWidth: true,
                                disabled: nextStep,
                                value: data.loginInputValue,
                                onChange: handleInputChange('loginInputValue'),
                                validation: isFormSubmitted && !data.loginInputValue,
                                placeholder: isEmail ? 'Enter Your Email Address' : 'Enter Your Mobile Number',
                                title: <LabelWithAsteriskMark label={isEmail ? 'Email Address' : 'Mobile Number'} />,
                            }}
                            InputProps={{
                                endAdornment: nextStep && (
                                    <InputAdornment position="end">
                                        <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                                        <IconButton onClick={() => { setNextStep(false); setData({ loginInputValue: '', otp: '' }); }}>
                                            <Edit fontSize="small" sx={{ color: '#2464EB' }} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    {nextStep && (
                        <Box sx={{ mt: 3 }}>
                            <Input
                                autoFocus
                                id="otp_input"
                                dataTestId="otp_input"
                                item={{
                                    title: 'OTP',
                                    required: true,
                                    fullWidth: true,
                                    value: data.otp,
                                    placeholder: 'Enter Your OTP',
                                    onChange: handleInputChange('otp'),
                                    validation: isFormSubmitted && !data.otp,
                                    type: isPasswordVisible ? 'text' : 'password',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                                            <IconButton onClick={togglePasswordVisibility}>
                                                {isPasswordVisible ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Resend handleResend={getOTP} />
                        </Box>
                    )}

                    <CustomButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        sx={{ mt: 4, mb: 3 }}
                        id={nextStep ? 'verify_otp' : 'send_otp'}
                        onClick={nextStep ? verifyOTP : handleSubmit}
                        dataTestId={nextStep ? 'verify_otp' : 'send_otp'}
                        btnLabel={nextStep ? 'Verify your OTP' : 'Send OTP'}
                    />
                </Box>
            </DialogContent>
        </BootstrapDialog>
    );
};

export default ModalUpdateUserDetails;
