import React from 'react'
import moment from 'moment';
import { Grid, Box, Divider } from '@mui/material';
import DateRange from '../../../../common/DateRange';
import { list_party } from '../../../../../api/urls';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CommonAsyncDropdown from '../../../../common/CommonAsyncDropdown';
import CustomDateRangePicker from '../../../../custom/Datepicker/CustomDateRangePicker';
import { ApplyDefaultFilterCheckBox, DownloadButtons, DownloadRadioButtons } from '../../../../common/CommonDownloadLayout';

const Dropdown = DateRange;
const DownloadInvoiceData = (props) => {
    const { setOpen, url, defaultFilters } = props;

    const [isDefaultFiltersApplied, setIsDefaultFiltersApplied] = React.useState(false);
    const [customer, setCustomer] = React.useState(null)
    const [filters, setFilters] = React.useState({
        "export_to": "csv",
        "business_id": getBusinessInfo().id,
    });
    const [timePeriod, setTimePeriod] = React.useState("all");

    const statusList = [
        { name: 'Sent', value: "sent" },
        { name: 'Paid', value: 'paid' },
        { name: 'Unsent', value: 'unsent' },
        { name: 'Overdue ', value: 'overdue' },
        { name: 'Partially Paid', value: 'partially_paid' },
    ]

    React.useEffect(() => {
        if (isDefaultFiltersApplied) {
            const from_datetime = defaultFilters?.invoice_start_date ? moment(defaultFilters?.invoice_start_date)?.format("YYYY/MM/DD HH:mm:ss") : null;
            const to_datetime = defaultFilters?.invoice_end_date ? moment(defaultFilters?.invoice_end_date)?.format("YYYY/MM/DD HH:mm:ss") : null;
            setFilters({
                ...filters,
                status: defaultFilters?.status ? defaultFilters?.status : null,
                customer_id: defaultFilters?.customer_id ? defaultFilters?.customer_id : null,
                from_datetime: from_datetime,
                to_datetime: to_datetime,
                dates: defaultFilters?.dates,
                selectedCustomer: defaultFilters?.selectedCustomer ? defaultFilters?.selectedCustomer : null,
            })
            setCustomer(defaultFilters?.selectedCustomer);
            if (defaultFilters?.invoice_start_date) {
                setTimePeriod("specificTimePeriod");
            } else if (!defaultFilters?.invoice_start_date) {
                setTimePeriod("all");
            }
        } else {
            setFilters({ ...filters })
        }
    }, [isDefaultFiltersApplied])

    React.useEffect(() => {
        if (timePeriod === "all") {
            setFilters({ ...filters, from_datetime: null, to_datetime: null, dates: [] })
        }
        if (defaultFilters?.invoice_start_date && timePeriod === "all") {
            setIsDefaultFiltersApplied(false);
        }
    }, [timePeriod])

    const onDateRangeChange = (dates) => {
        if (dates) {
            setFilters({
                ...filters,
                from_datetime: dates[0].format("YYYY/MM/DD HH:mm:ss"),
                to_datetime: dates[1].format("YYYY/MM/DD HH:mm:ss"),
                dates: dates
            })
            if (isDefaultFiltersApplied) {
                setIsDefaultFiltersApplied(false);
            }
        } else {
            delete filters.to_datetime
            delete filters.from_datetime
            filters.dates = null
            setFilters({ ...filters })
            setIsDefaultFiltersApplied(false);
        }
    }

    return (
        <Box>
            <Grid container spacing={1}>
                <ApplyDefaultFilterCheckBox
                    isDefaultFiltersApplied={isDefaultFiltersApplied}
                    setIsDefaultFiltersApplied={setIsDefaultFiltersApplied}
                />
                <Grid item container xs={11} spacing={1} sx={{ mb: 2 }}>
                    <Grid item xs={10} sm={6}>
                        <Dropdown
                            id={'InvoiceDownload_dropdown'}
                            dataTestId={'InvoiceDownload_dropdown'}
                            label="Status"
                            placeholder='Select Status'
                            results={statusList}
                            value={statusList.find((item) => item?.value === filters?.status)}
                            setValue={(event, value) => {
                                if (value) {
                                    if (isDefaultFiltersApplied) {
                                        filters['status'] = value.value;
                                        setIsDefaultFiltersApplied(false);
                                    } else {
                                        filters['status'] = value.value;
                                    }
                                } else {
                                    delete filters?.status;
                                    setIsDefaultFiltersApplied(false);
                                }
                                setFilters({ ...filters })
                            }}
                        />
                    </Grid>
                    <Grid item xs={10} sm={6}>
                        <CommonAsyncDropdown
                            id={'InvoiceDownload_dropdown'}
                            dataTestId={'InvoiceDownload_dropdown'}
                            autoSelect={false}
                            disableClearable={false}
                            optionLabel="display_name"
                            placeholder='Select Customer'
                            noOptionsText={"No result found"}
                            // options={[{ name: "All Customers", id: null }]}
                            item={{
                                method: 'post',
                                value: customer,
                                label: 'Customers',
                                url: list_party(1),
                                body: { is_inactive: false, business_id: getBusinessInfo().id, role: 'customer' },
                                onChange: (event, value) => {
                                    if (value) {
                                        setFilters({ ...filters, customer_id: value.id })
                                        if (isDefaultFiltersApplied) {
                                            setIsDefaultFiltersApplied(false);
                                        }
                                    } else {
                                        delete filters.customer_id
                                        delete filters.selectedCustomer
                                        setFilters({ ...filters })
                                        setIsDefaultFiltersApplied(false);
                                    }

                                    setCustomer(value)
                                },
                            }}
                            addButton={false}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={15} sm={12} sx={{ mb: 2 }}>
                    <DownloadRadioButtons
                        timePeriod={timePeriod}
                        setTimePeriod={setTimePeriod}
                        allButtonLabel={"All Invoices"}
                    />
                    {timePeriod === "specificTimePeriod" &&
                        <CustomDateRangePicker dates={filters?.dates} onDateRangeChange={onDateRangeChange} />
                    }
                </Grid>
            </Grid>

            <Divider />
            <DownloadButtons
                onClose={setOpen}
                url={url}
                filters={filters}
                setOpen={setOpen}
                timePeriod={timePeriod}
            />
        </Box>
    )
}

export default DownloadInvoiceData;
