import React from 'react';
import * as Custom from '../Custom/Custom';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { ReactComponent as ExpenseIcon } from '../assets/svg/chat-33.svg';
import { ReactComponent as InvoicingIcon } from '../assets/svg/ic_layers_48px.svg';
import { ReactComponent as AccountingIcon } from '../assets/svg/ic_timelapse_48px.svg';
import { ReactComponent as DashboardIcon } from '../assets/svg/business-contact-86.svg';

import {
    Box,
    Grid,
} from '@mui/material';

const Benefits = () => {
    // Data for feature cards
    const featureCards = [
        {
            icon: DashboardIcon,
            backgroundColor: '#e8e7f9',
            title: 'Dashboard & Simple UI',
            message: <span>A powerful tool that gives you real-time<br /> data for a clear view of your business’s<br /> performance.</span>,
        },
        {
            title: 'Invoicing',
            icon: InvoicingIcon,
            backgroundColor: '#fae9e9',
            message: <span>Simplifies and streamlines your invoicing<br /> process for efficient and effective<br /> management.</span>,
        },
        {
            title: 'Expenses',
            icon: ExpenseIcon,
            backgroundColor: '#ebf6ee',
            message: <span>Create and track expenses, categorize<br /> them efficiently, and bill them to your <br />customers when necessary.</span>,
        },
        {
            title: 'Accounting',
            icon: AccountingIcon,
            backgroundColor: '#e3e4e6',
            message: <span>Streamlines accounting processes,<br /> simplifying income and expense tracking<br /> while ensuring compliance.</span>,
        },
        {
            title: 'Inventory',
            icon: DashboardIcon,
            backgroundColor: '#e8e7f9',
            message: <span>Finycs offers a comprehensive set of<br /> features to effortlessly manage inventory,<br /> providing real-time visibility, valuable<br /> insights, and streamlined processes.</span>,
        },
        {
            title: 'Reports',
            icon: InvoicingIcon,
            backgroundColor: '#fae9e9',
            message: <span>Make smart business decisions by offering<br /> a diverse range of reports to keep you<br /> informed about crucial aspects of your<br /> business.</span>,
        },
        {
            title: 'GST',
            icon: ExpenseIcon,
            backgroundColor: '#ebf6ee',
            message: <span>Easily manage GST with Finycs and simplify<br /> your business’s financial obligations.</span>,
        },
        {
            icon: AccountingIcon,
            title: 'Tally Integration',
            backgroundColor: '#e3e4e6',
            message: <span>Seamless Tally Integration with Finycs for<br /> Real-Time Tally Data Accessibility.</span>,
        },
    ];

    return (
        <Custom.Container>
            <Box sx={styles.container}>
                {/* Section title */}
                <SectionTitle text="Benefits of Finycs" sx={{pb:{xs:4,sm:0}, textAlign: 'center' }} />

                <Box sx={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                }}>
                {/* Features grid */}
                <Grid container
                    sx={{maxWidth: '1200px', p: { xs: 0, lg: 8 }, pt: 6, }}
                >
                    {featureCards.map((card, index) => (
                        <Grid item xs={12} sm={6} key={index} sx={{ p: 2 }}>
                            <FeatureCard
                                icon={card.icon}
                                title={card.title}
                                message={card.message}
                                backgroundColor={card.backgroundColor}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
            </Box>
        </Custom.Container>
    );
};

export default Benefits;

/**
 * FeatureCard: Component for individual feature cards
 */
const FeatureCard = ({ icon: Icon, title, message, backgroundColor }) => (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Box sx={{ ...styles.iconWrapper, backgroundColor }}>
            <Icon />
        </Box>
        <Box sx={{ pl: 2 }}>
            <CustomTypography
                text={title}
                sx={styles.cardTitle}
            />
            <CustomTypography
                text={message}
                sx={styles.cardMessage}
            />
            <CustomTypography
                text={'READ MORE'}
                sx={styles.readMore}
            />
        </Box>
    </Box>
);

/**
 * SectionTitle: Reusable component for section titles
 */
const SectionTitle = ({ text, sx }) => (
    <CustomTypography text={text} sx={{ ...styles.sectionTitle, ...sx }} />
);

// Reusable styles
const styles = {
    container: {
        p: 2,
        pt: 4,
        mt: 4,
        height: '100%',
        pl:{xs:2,lg:'160px'},
        pr:{xs:2,lg:'160px'},
        // background: '#FAFAFA',
    },
    sectionTitle: {
        fontWeight: 700,
        color: '#141414',
        fontSize: '40px',
        lineHeight: '66px',
        letterSpacing: '0px',
    },
    iconWrapper: {
        width: '64px',
        height: '64px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '12.5px',
        justifyContent: 'center',
    },
    cardTitle: {
        fontSize: '18px',
        fontWeight: 700,
        color: '#000000',
        lineHeight: '32px',
    },
    cardMessage: {
        fontSize: '16px',
        fontWeight: 400,
        color: '#141414',
        lineHeight: '24px',
    },
    readMore: {
        mt: 1,
        fontSize: '11px',
        fontWeight: 400,
        color: '#2464EB',
        lineHeight: '24px',
    },
};
