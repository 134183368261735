import _ from 'lodash';
import React from "react";
import { styled } from '@mui/material';
import { apiAction } from '../../../../../api/api';
import { isNumeric } from '../../../../../utils/Utils';
import { MentionsInput, Mention } from "react-mentions";
import { list_of_data_point } from '../../../../../api/urls';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { LabelWithAsteriskMark } from '../../../../common/CommonLabel';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { mentionsInputWrapperStyle, mentionStyle } from "./QueryWrapperStyle";

import {
  cleanString,
  splitExpression,
  getUniqueObjects,
  replaceStringWithSlug,
  replaceSlugWithString,
} from '../common/Helper';

const Query = ({
  data,
  getData,
  isFormulaValid,
}) => {
  const [nextUrl, setNextUrl] = React.useState();
  const [results, setResults] = React.useState([]);
  const [searchText, setSearchText] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [accountSelected, setAccountSelected] = React.useState([]);

  const business_id = getBusinessInfo().id;
  const API_ENDPOINT = list_of_data_point(business_id) + '&sort_by=data_point_type&sort_order=A';

  const fetchData = async (url = API_ENDPOINT, previousResults = []) => {
    setIsLoading(true);
    if (!url) return;

    const res = await apiAction({ url });
    if (!res?.success) return;
    setIsLoading(false);
    const newResults = getUniqueObjects([...previousResults, ...res?.result?.result]).map((obj) => ({
      ...obj,
      display: obj.name,
    }));


    const nextUrlParams = searchText && searchText !== '' ? `&business_id=${business_id}&name=${searchText}` : `&business_id=${business_id}`;
    setNextUrl(res?.result?.next ? `${res?.result?.next}${nextUrlParams}` : null);
    setResults([...newResults]);
    setIsLoading(false);
    return newResults;
  };

  const onChangeHandler = (event, newValue, newPlainTextValue, mentions) => {
    setTextAreaValue(event.target.value);

  };

  const onItemSelected = (id) => {
    const newAccount = results.find((obj) => obj.id === id);
    const equation = replaceStringWithSlug(textAreaValue, accountSelected);
    const splitExpressionArray = typeof equation === 'string' ? splitExpression(equation) : [];
    const selectedAccount = splitExpressionArray.length ? accountSelected.filter((item) => item ? splitExpressionArray.includes(item.slug_name) : false) : [];
    setAccountSelected(getUniqueObjects([...selectedAccount, newAccount]));
    setSearchText('');
  };


  const getFinalData = (equation = '', accounts = []) => {
    const splitExpressionArray = typeof equation === 'string' ? splitExpression(equation) : [];
    if (splitExpressionArray.length) {
      const selectedAccount = accounts.filter((item) => item ? splitExpressionArray.includes(item.slug_name) : false);
      const unknownAccount = splitExpressionArray.filter(element => isNumeric(element) ? false : !accounts?.some(item => item && item.slug_name === element));

      getData({
        getAccounts: getUniqueObjects([...accounts]),
        unknownAccount: getUniqueObjects([...unknownAccount]),
        equation: replaceSlugWithString(equation, selectedAccount),
        getCalculationFormula: replaceStringWithSlug(equation, selectedAccount),
        getDataPointDependenciesId: getUniqueObjects([...selectedAccount]).map(item => item.id),
      });
    } else {
      getData({
        equation: null,
        getAccounts: [],
        unknownAccount: [],
        getCalculationFormula: null,
        getDataPointDependenciesId: [],
      });
    }
  };

  React.useEffect(() => {
    getFinalData(replaceStringWithSlug(textAreaValue, accountSelected), accountSelected);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAreaValue, accountSelected]);

  React.useEffect(() => {
    if (data) {
      const equationText = replaceSlugWithString(data.calculation_formula, data.data_point_dependencies);
      const arrangedAccounts = data.calculation_formula && splitExpression(data.calculation_formula).map(slug_name => isNumeric(slug_name) ? false : data.data_point_dependencies.find(item => item.slug_name === slug_name));

      if (data.calculation_type === 'equation') {
        setTextAreaValue(equationText || '');
      }
      setAccountSelected(arrangedAccounts || []);
    }
  }, [data]);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchUsernames = React.useCallback(
    _.debounce((query, callback) => {
      setSearchText(cleanString(query));
      fetchData(list_of_data_point(business_id, cleanString(query), []) + `&sort_by=data_point_type&sort_order=A`)
        .then(callback)
    }, 300),
    []
  );

  return (
    <div>
      <CustomTypography
        text={<span><LabelWithAsteriskMark label={'Calculation Formula'} /> <span style={{ marginLeft: '5px', opacity: .5 }}>Mention accounts using '@' + account name</span></span>}
        sx={{
          mb: 1,
          color: '#000000',
          fontSize: '14px',
          fontWeight: '700',
          lineHeight: '18px',
          fontStyle: 'normal',
          textTransform: 'none',
          fontFamily: "Noto Sans",
        }}
      />
      <MentionsInput
        value={textAreaValue}
        onChange={onChangeHandler}
        id="mentions_input_text_area"
        allowSuggestionsAboveCursor={true}
        forceSuggestionsAboveCursor={true}
        dataTestId="mentions_input_text_area"
        placeholder="To mention the accounts use '@'"
        style={{ ...mentionsInputWrapperStyle(isFormulaValid) }}
        className={isFormulaValid ? 'mentions_input_error' : 'mentions_input'}
        allowSpaceInQuery={true}
        customSuggestionsContainer={(item) => <CustomSuggestionsContainer children={item} />}
      >
        <Mention
          trigger={'@'}
          isLoading={isLoading}
          data={fetchUsernames}
          appendSpaceOnAdd={true}
          style={{ ...mentionStyle(isFormulaValid) }}
          onAdd={(id) => { onItemSelected(id) }}

          renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => {
            return <CustomRenderSuggestion children={highlightedDisplay} />;
          }}

        />
      </MentionsInput>
    </div>
  );
};


export default Query;

const CustomRenderSuggestion = ({ children }) => {

  return (
    <div style={{ fontWeight: 400 }}>{children}</div>
  );
};


const CustomSuggestionsContainer = ({ children }) => {
  if (!children || !children.props || !children.props.children) {
    return null; // Return null or handle the case where children are not provided
  }

  const { id, role, style, className } = children.props;

  const GroupHeader = styled('div')(({ theme }) => ({
    fontWeight: 700,
    color: '#141414',
    fontSize: '12px',
    lineHeight: '16px',
    fontStyle: 'normal',
    padding: '8px 10px',
    background: '#FFFFFF',
    fontFamily: 'Noto Sans',
  }));

  const GroupItems = styled('ul')({
    padding: 0,
    paddingTop: 1,
    fontWeight: 400,
    fontSize: '12px',
    color: '#141414',
    paddingBottom: 1,
    lineHeight: '16px',
    fontStyle: 'normal',
    background: '#FFFFFF',
    fontFamily: 'Noto Sans',
  });

  // Group children based on data_point_type
  const groupedChildren = {};
  React.Children.forEach(children.props.children, (child) => {
    const suggestion = child.props && child.props.suggestion;
    if (suggestion && suggestion.data_point_type) {
      const dataPointType = suggestion.data_point_type;
      if (!groupedChildren[dataPointType]) {
        groupedChildren[dataPointType] = [];
      }
      groupedChildren[dataPointType].push(child);
    }
  });

  return (
    <div className="custom-suggestions-container">
      <ul id={id} role={role} style={style} className={className}>
        {Object.entries(groupedChildren).map(([group, items], index) => (
          <li key={index}>
            <GroupHeader
              sx={{
                top: '0px',
                position: 'sticky',
                borderBottom: '1px solid #e8e8e8'
              }}
            >{group}</GroupHeader>
            <GroupItems>
              {items.map((item, idx) => (
                <React.Fragment key={idx}>{item}</React.Fragment>
              ))}
            </GroupItems>
          </li>
        ))}
      </ul>
    </div>
  );
};


// const RenderSuggestions = ({ children }) => {
//   const ul = React.Children.only(children);
//   const suggestions = React.Children.toArray(ul.props.children);
//   const groupedSuggestions = _.groupBy(suggestions, suggestion => suggestion.props.suggestion.group);

//   return (
//     <div className="suggestionList">
//       {Object.entries(groupedSuggestions).map(([group, groupedSuggestions]) => (
//         <ul {...ul.props} key={group}>
//           <li key={group} className="suggestionGroup">
//             {group}
//           </li>
//           {groupedSuggestions}
//         </ul>
//       ))}
//     </div>
//   );
// };
