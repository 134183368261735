import React from 'react';
import SocialLogin from './SocialLogin';
import { apiAction } from '../../../api/api';
import * as Actions from '../../../state/Actions';
import { images, Img } from '../../../utils/Constant';
import { routesName } from '../../../config/routesName';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTypography from '../../custom/typography/CustomTypography';
import { eventsNames,AnalyticsEvent } from '../../../firebase/firebaseAnalytics';

import {
    send_forgot_password_mail_view,
    send_verify_registration_mail_view,
} from '../../../api/urls';
import {
    setLoader,
    stateChangeManager,
} from '../../../utils/Utils';

import {
    Box,
    Button,
    Divider
} from '@mui/material';

const OpenEmailScreen = () => {

    let location = useLocation();
    let navigate = useNavigate();

    const [counter, setCounter] = React.useState(60);
    const dispatch = Actions.getDispatch(React.useContext);

    let data = location.state ? location.state.data : '';
    let path = location.state ? location.state.path : '';


    const handleResend = async () => {

        let res = await apiAction({
            data: { ...data, send_type: 'resend',is_privacy_policy_and_terms_accepted: true },
            method: 'post',
            url: path ? send_forgot_password_mail_view() : send_verify_registration_mail_view(),
        })
        if (res?.success) {
            setCounter(60);
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            navigate(-1);
            setCounter(0);
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    };

    let domain = data.email && data.email.substring(data.email.lastIndexOf("@"));

    React.useEffect(() => {
        if (counter > 0) {
            setTimeout(() => setCounter(counter - 1), 1000);
        }
    }, [counter]);

    const openEmailEventLog=()=>{
        AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION,{action:eventsNames.actions.userAuthentication.action.OPEN_EMAIL})
    }
    return (
        location.state &&
        <Box sx={{ display: "flex" }}>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Box
                    className='m_login_container'
                    sx={{
                        pt: 8,
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {
                        path &&
                        <Box
                            sx={{
                                p: 2,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: "center",
                                flexDirection: 'column',
                            }}>
                            <Img sx={{
                                maxWidth: '18%',
                            }} alt={images.openEmail.alt} src={images.openEmail.src} />
                            <Box>
                                <CustomTypography
                                    text={<span>Check your email for a link to reset your password.<br />If it doesn't appear within a few minutes, check your spam folder.</span>}
                                    sx={{
                                        mb: 4,
                                        mt: 4,
                                        opacity: 0.8,
                                        color: '#000000',
                                        fontSize: '20px',
                                        fontWeight: '300',
                                        textAlign: 'center',
                                        fontStyle: 'normal',
                                        lineHeight: '40.86px',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                                <Box sx={{ textAlign: 'center' }}>
                                    <Button
                                     id='sign_in_btn'
                                     dataTestId='sign_in_btn'
                                        size='medium'
                                        variant="contained"
                                        href={"/auth" + routesName.signIn.path}
                                        sx={{
                                            ml: 2,
                                            color: 'white',
                                            textTransform: 'none',
                                        }}>
                                        Return to sign in
                                    </Button>
                                </Box>

                                <Box sx={{ mt: 3, }}>

                                    <Divider >
                                        <CustomTypography
                                            text={'OR'}
                                            sx={{
                                                color: '#000000',
                                                fontSize: '14px',
                                                fontWeight: '700',
                                                lineHeight: '18px',
                                                fontStyle: 'normal',
                                                fontFamily: "Noto Sans",
                                                textTransform: 'capitalize',
                                            }}
                                        />
                                    </Divider>
                                </Box>
                            </Box>


                            <Box sx={{ mt: 2, display: 'flex' }}>
                                <CustomTypography
                                    text={<span>Didn't receive an email?</span>}
                                    sx={{
                                        opacity: 0.8,
                                        color: '#000000',
                                        fontSize: '18px',
                                        textAlign: 'center',
                                        fontWeight: '500',
                                        fontStyle: 'normal',
                                        lineHeight: '40.86px',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                                <CustomTypography
                                id='resend_email_btn'
                                dataTestId='resend_email_btn'
                                    text={<span>Resend email.</span>}
                                    onClick={() => counter ? {} : handleResend()}
                                    sx={{
                                        ml: .5,
                                        fontSize: '16px',
                                        cursor: 'pointer',
                                        fontWeight: '500',
                                        lineHeight: '16px',
                                        fontStyle: 'normal',
                                        lineHeight: '40.86px',
                                        fontFamily: 'Noto Sans',
                                        color: counter ? '#646f79' : '#2464EB',
                                        ":hover": { textDecoration: counter ? 'none' : 'underline' }
                                    }}
                                />
                                {
                                    counter ?
                                        <CustomTypography
                                            text={<span className="badge">{counter}</span>}
                                            sx={{
                                                ml: 2,
                                                fontSize: '16px',
                                                cursor: 'pointer',
                                                fontWeight: '500',
                                                lineHeight: '16px',
                                                fontStyle: 'normal',
                                                lineHeight: '40.86px',
                                                fontFamily: 'Noto Sans',
                                            }}
                                        />
                                        : ''
                                }
                            </Box>
                        </Box>
                    }
                    {
                        !path &&
                        <Box
                            sx={{
                                p: 2,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: "center",
                                flexDirection: 'column',
                            }}>
                            <Img sx={{
                                maxWidth: '18%',
                            }} alt={images.openEmail.alt} src={images.openEmail.src} />
                            <CustomTypography
                                text={<span>Please verify your email address</span>}
                                sx={{
                                    mb: 4,
                                    mt: 4,
                                    opacity: 0.8,
                                    color: '#000000',
                                    fontSize: '40px',
                                    textAlign: 'center',
                                    fontWeight: '300',
                                    fontStyle: 'normal',
                                    lineHeight: '40.86px',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            {
                                domain === "@gmail.com" ?
                                    <React.Fragment>
                                        <Box>
                                            <CustomTypography
                                                text={<span>Get started with finycs! continue with google to verify</span>}
                                                sx={{
                                                    opacity: 0.8,
                                                    color: '#000000',
                                                    fontSize: '18px',
                                                    textAlign: 'center',
                                                    fontWeight: '500',
                                                    fontStyle: 'normal',
                                                    lineHeight: '40.86px',
                                                    fontFamily: "Noto Sans",
                                                }}
                                            />
                                            <CustomTypography
                                                text={data.email}
                                                sx={{
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    fontWeight: '700',
                                                    textAlign: 'center',
                                                    fontStyle: 'normal',
                                                    fontFamily: "Noto Sans",
                                                }}
                                            />
                                            <Box sx={{ textAlign: 'center' }}>
                                                <SocialLogin btnText={'Continue with Google'} fullWidth={false} />
                                            </Box>

                                            <Box sx={{ mt: 3, }}>

                                                <Divider >
                                                    <CustomTypography
                                                        text={'OR'}
                                                        sx={{
                                                            color: '#000000',
                                                            fontSize: '14px',
                                                            fontWeight: '700',
                                                            lineHeight: '18px',
                                                            fontStyle: 'normal',
                                                            fontFamily: "Noto Sans",
                                                            textTransform: 'capitalize',
                                                        }}
                                                    />
                                                </Divider>
                                            </Box>
                                        </Box>

                                        <Box sx={{ display: 'flex' }}>
                                            <CustomTypography
                                                text={<span>If you don't want to continue with Google, complete your signup through the email we sent to your email address.Didn't receive an email?</span>}
                                                sx={{
                                                    opacity: 0.8,
                                                    color: '#000000',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                    fontWeight: '500',
                                                    fontStyle: 'normal',
                                                    lineHeight: '40.86px',
                                                    fontFamily: "Noto Sans",
                                                }}
                                            />
                                            <CustomTypography
                                            id='resend_email_btn'
                                            dataTestId='resend_email_btn'
                                                text={<span>Resend email.</span>}
                                                onClick={() => counter ? {} : handleResend()}
                                                sx={{
                                                    ml: .5,
                                                    fontSize: '16px',
                                                    cursor: 'pointer',
                                                    fontWeight: '500',
                                                    lineHeight: '16px',
                                                    fontStyle: 'normal',
                                                    lineHeight: '40.86px',
                                                    fontFamily: 'Noto Sans',
                                                    color: counter ? '#646f79' : '#2464EB',
                                                    ":hover": { textDecoration: counter ? 'none' : 'underline' }
                                                }}
                                            />
                                            {
                                                counter ?
                                                    <CustomTypography
                                                        text={<span className="badge">{counter}</span>}
                                                        sx={{
                                                            ml: 2,
                                                            fontSize: '12px',
                                                            cursor: 'pointer',
                                                            fontWeight: '500',
                                                            lineHeight: '16px',
                                                            fontStyle: 'normal',
                                                            lineHeight: '40.86px',
                                                            fontFamily: 'Noto Sans',
                                                        }}
                                                    />
                                                    : ''
                                            }
                                        </Box>

                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <CustomTypography
                                            text={<span>Once you verify your email address, you<br /> and your team can get started in finycs.</span>}
                                            sx={{
                                                opacity: 0.8,
                                                color: '#000000',
                                                fontSize: '18px',
                                                textAlign: 'center',
                                                fontWeight: '500',
                                                fontStyle: 'normal',
                                                lineHeight: '40.86px',
                                                fontFamily: "Noto Sans",
                                            }}
                                        />
                                        <Box sx={{ mt: 2 }}>

                                            <div className="emailButtons">
                                                <a style={{}} href="https://gmail.com/" className="emailButton" target="_blank" rel="noopener noreferrer" onClick={()=>{openEmailEventLog()}}>
                                                    <div className="emailButton-wrapper">
                                                        <img className="emailButton-img" src="https://luna1.co/e6bc0e.png" alt="Gmail button" />
                                                        <span className="emailButton-text">Open Gmail</span>
                                                    </div>
                                                </a>
                                                <a href="https://outlook.live.com/" className="emailButton" target="_blank" rel="noopener noreferrer" onClick={()=>{openEmailEventLog()}}>
                                                    <div className="emailButton-wrapper">
                                                        <img className="emailButton-img" src="https://luna1.co/baeaeb.png" alt="Outlook button" />
                                                        <span className="emailButton-text">Open Outlook</span>
                                                    </div>
                                                </a>
                                                <a href="https://mail.yahoo.com/" className="emailButton" target="_blank" rel="noopener noreferrer" onClick={()=>{openEmailEventLog()}}>
                                                    <div className="emailButton-wrapper">
                                                        <img className="emailButton-img" src="https://luna1.co/0cea52.png" alt="Yahoo! button" />
                                                        <span className="emailButton-text">Open Yahoo!</span>
                                                    </div>
                                                </a>
                                            </div>

                                        </Box>

                                        <Box sx={{ mt: 2, display: 'flex' }}>
                                            <CustomTypography
                                                text={<span>Didn't receive an email?</span>}
                                                sx={{
                                                    opacity: 0.8,
                                                    color: '#000000',
                                                    fontSize: '18px',
                                                    textAlign: 'center',
                                                    fontWeight: '500',
                                                    fontStyle: 'normal',
                                                    lineHeight: '40.86px',
                                                    fontFamily: "Noto Sans",
                                                }}
                                            />
                                            <CustomTypography
                                            id='resend_email_btn'
                                            dataTestId='resend_email_btn'
                                                text={<span>Resend email.</span>}
                                                onClick={() => counter ? {} : handleResend()}
                                                sx={{
                                                    ml: .5,
                                                    fontSize: '16px',
                                                    cursor: 'pointer',
                                                    fontWeight: '500',
                                                    lineHeight: '16px',
                                                    fontStyle: 'normal',
                                                    lineHeight: '40.86px',
                                                    fontFamily: 'Noto Sans',
                                                    color: counter ? '#646f79' : '#2464EB',
                                                    ":hover": { textDecoration: counter ? 'none' : 'underline' }
                                                }}
                                            />
                                            {
                                                counter ?
                                                    <CustomTypography
                                                        text={<span className="badge">{counter}</span>}
                                                        sx={{
                                                            ml: 2,
                                                            fontSize: '16px',
                                                            cursor: 'pointer',
                                                            fontWeight: '500',
                                                            lineHeight: '16px',
                                                            fontStyle: 'normal',
                                                            lineHeight: '40.86px',
                                                            fontFamily: 'Noto Sans',
                                                        }}
                                                    />
                                                    : ''
                                            }
                                        </Box>
                                    </React.Fragment>
                            }

                        </Box>
                    }

                </Box>
            </Box>
        </Box>
    )
}

export default OpenEmailScreen;
