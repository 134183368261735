import React from 'react';
import * as Custom from '../Custom/Custom';
import { useNavigate } from 'react-router-dom';
import { AppLogo } from '../../../../common/AppLogo';
import { routesName } from '../../../../../config/routesName';
import CustomButton from '../../../../custom/button/CustomButton';
import CustomTypography from '../../../../custom/typography/CustomTypography';


import {
    Box,
    AppBar,
    Toolbar,
} from '@mui/material';

const Header = ({
    navigateToLogin,
    navigateToSignup,
}) => {
    const navigate = useNavigate();

    return (
        <div>
            <AppBar sx={{ bgcolor: "#fafafa", boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px' }} elevation={0}>
                <Custom.Container>
                    <Toolbar disableGutters sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                        <Box sx={{ display: 'flex' }}  onClick={() => {navigate(routesName.landingPage.path); }}>
                            <CustomLogo />
                        </Box>

                        <Box sx={{ alignItems: 'center', display: { xs: 'none', md: 'flex' } }}>
                            <NavMenuItem />
                        </Box>

                        <Box sx={{ alignItems: 'center', pl: 5, display: { xs: 'none', md: 'flex' } }}>
                            <Buttons
                                navigateToLogin={navigateToLogin}
                                navigateToSignup={navigateToSignup}
                             />
                        </Box>

                    </Toolbar>
                </Custom.Container>
            </AppBar>
        </div>
    )
}

export default Header;


const CustomLogo = () => {

    return (
        <Box style={{ padding: 4, cursor: 'pointer' }} onClick={() => { }}>
            <AppLogo />
        </Box>
    )
}

const Buttons = ({
    navigateToLogin,
    navigateToSignup,
}) => {
    return (
        <Box>
            <CustomButton
                btnLabel='Login'
                variant="outlined"
                id={'landing_page_sign_in'}
                dataTestId={'landing_page_sign_in'}
                onClick={() => {navigateToLogin()}}
                sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '14px !important',
                    width: { xs: 'auto', sm: '120px' },
                    textTransform: 'uppercase !important',
                }}
            />
            <CustomButton
                btnLabel='Sign Up'
                variant="contained"
                id={'landing_page_sign_up'}
                dataTestId={'landing_page_sign_up'}
                onClick={() => {navigateToSignup()}}
                sx={{
                    ml: 1,
                    whiteSpace: 'nowrap',
                    fontSize: '14px !important',
                    width: { xs: 'auto', sm: '120px' },
                    textTransform: 'uppercase !important',
                }}
            />
        </Box>
    )
}


const NavMenuItem = () => {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <CustomTypography
                text={'Home'}
                onClick={() => {navigate(routesName.landingPage.path); }}
                sx={{
                    mr: 3,
                    fontWeight: 700,
                    cursor: 'pointer',
                    lineHeight: '26px',
                    letterSpacing: '0px',
                    fontSize: '16px !important',
                    color: "#2464EB",
                    '&:hover': {
                        color: '#2464EB',
                    },
                }}
            />
            {/* <CustomTypography
                text={'Analyze'}
                onClick={() => { }}
                sx={{
                    mr: 3,
                    fontWeight: 700,
                    cursor: 'pointer',
                    lineHeight: '26px',
                    letterSpacing: '0px',
                    fontSize: '16px !important',
                    color: '#141414',
                    '&:hover': {
                        color: '#2464EB',
                    },
                }}
            />
            <Dropdown
                options={[]}
                isGrid={true}
                title={'Features'}
                onNavigate={() => { }}
                getTextColor={"#141414"}
                onCloseHandler={() => { }}
            /> */}
        </React.Fragment>
    )
}