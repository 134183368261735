import React from 'react';
import Input from './Input';
import Loader from '../custom/loader/Loader';
import * as Actions from '../../state/Actions';
import { useNavigate } from 'react-router-dom';
import { SmsSettings } from './CommonPreviewShare';
import { screenDetails } from '../../utils/Constant';
import { LabelWithAsteriskMark } from './CommonLabel';
import CustomButton from '../custom/button/CustomButton';
import { getBusinessInfo } from '../../config/cookiesInfo';
import CustomTypography from '../custom/typography/CustomTypography';


import {
  setLoader,
  isFormValid,
  stateChangeManager,
} from '../../utils/Utils';

import {
  preview_sms_document,
  send_document_through_sms,
} from '../../api/urls';

import {
  apiAction,
} from '../../api/api';


import {
  Box,
  Grid,
  Slide,
  Drawer,
  Divider,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const CommonShareFileThroughSMS = ({ open, setOpen, id, party_id, document_type, document_number, retrieve_Url, }) => {
  let navigate = useNavigate();
  const dispatch = Actions.getDispatch(React.useContext);

  const [openDrawer, setOpenDrawer] = React.useState(false)

  const handleClose = () => {
    setOpen(false);
    setOpenDrawer(false);
  };

  const invoice_mail_data = {
    party_id: null,
    document_id: id,
    document_data: null,
    mobile_number: null,
    document_type: document_type,
    document_number: document_number,
    business_id: getBusinessInfo().id,
  }
  const [sendData, setSendData] = React.useState({ ...invoice_mail_data })

  const getData = async (id) => {
    let res = await apiAction({
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      url: retrieve_Url(id),
      data: { business_id: getBusinessInfo().id },
    })
    if (res?.success) {
      const document_data = { ...res?.result, payment_id: res?.result?.id, place_of_supply_id: res?.result?.place_of_supply?.id || null, terms_and_conditions_id: res?.result?.terms_and_conditions, payment_term_id: res?.result?.payment_term, };
      setSendData((previous) => ({ ...previous, document_data: document_data }))
      getDocument(res?.result[party_id])
    } else {
    }
  }

  const getDocument = async (id) => {
    let res = await apiAction({
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      url: preview_sms_document(),
      data: { business_id: getBusinessInfo().id, document_type: document_type, party_id: id, document_number: document_number, },
    })
    if (res?.success) {
      setOpenDrawer(true)
      setSendData((previous) => ({
        ...previous,
        party_id: id,
        document_type: document_type,
        text_msg: res?.result?.text_msg,
        document_number: document_number,
        mobile_number: res?.result?.mobile_number,
      }))
      setLoader(dispatch, Actions, false);
    } else {
      handleClose();
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, 'error', res?.status);
    }
  }

  const sendDocument = async () => {
    let validation_data = [
      { key: "mobile_number", message: 'Please enter the mobile number.' },
    ]
    const { isValid, message } = isFormValid(sendData, validation_data);
    if (isValid) {

      let res = await apiAction({
        method: 'post',
        navigate: navigate,
        dispatch: dispatch,
        url: send_document_through_sms(),
        data: { ...sendData, business_id: getBusinessInfo().id },
      })
      if (res?.success) {
        handleClose()
        stateChangeManager(dispatch, Actions, true, 'success', res?.status);
      } else {
        stateChangeManager(dispatch, Actions, true, 'error', res?.status);
      }
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', message);
    }
  }

  React.useEffect(() => {
    if (open && id) {
      getData(id)
    } else {
      setSendData({ ...invoice_mail_data });
    }
    // eslint-disable-next-line
  }, [id, open]);

  return (
    <Drawer
      elevation={5}
      anchor={'right'}
      open={openDrawer}
      onClose={handleClose}
      id={'common_sms_share_Dialog'}
      TransitionComponent={Transition}
      dataTestId={'common_sms_share_Dialog'}
      variant={openDrawer ? "persistent" : 'temporary'}

      PaperProps={{
        sx: {
          zIndex: 999999,
          backgroundColor: '#FFFFFF',
          width: {
            xs: '100vw',
            sm: '35vw'
          }
        }
      }}
    >

      <Box sx={{
        zIndex: 1,
        minHeight: '100vh',
        backgroundColor: '#FFFFFF',
        boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.04)',
      }}>
        <Box sx={{ height: '24px', p: 2, pt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <CustomTypography
            text={'SMS Preview'}
            sx={{
              pb: 2,
              fontSize: '16px',
              color: '#000000',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
          <IconButton size='small' id={'close_reminder_btn'} dataTestId={'close_reminder_btn'} onClick={() => handleClose()} sx={{ ":hover": { bgcolor: '#e0e0e0' } }}>
            <Close fontSize='small' />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ p: 2, height: 'calc(100vh - 170px)', }}>
          {sendData.mobile_number ?
            <Information
            data={sendData}
            type={document_type}
            setData={setSendData}
            />
            :
            <CustomTypography
              text={<Loader />}
              sx={{
                mt: '38vh',
                color: '#000000',
                fontSize: '14px',
                fontWeight: '700',
                textAlign: 'center',
                lineHeight: '18px',
                fontStyle: 'normal',
                fontFamily: "Noto Sans",
              }}
            />

          }

        </Box>
        <Divider />
        <Box sx={{ pb: 2, pr: 2, position: 'absolute', bottom: 0, left: 0, right: 0, }}>
          <Buttons onSave={() => { sendDocument() }} onCancel={() => { handleClose() }} />
        </Box>
      </Box>
    </Drawer >
  )
}

export default CommonShareFileThroughSMS;

const Information = ({ setData, data, type }) => {
  const pdfData = screenDetails.find((item) => item.value === type)
  return (
    <Box sx={{
      height: '76vh',
      overflow: 'scroll',
    }}>
      <Grid container>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={12}>
            <Input
              item={{
                type: 'text',
                placeholder: '',
                disabled: true,
                fullWidth: true,
                title: 'Mobile Number',
                value: data.mobile_number || '',
                onChange: (event) => {
                  setData((prev) => ({ ...prev, mobile_number: event.target.value }))
                }
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              id={'input_message'}
              dataTestId={'input_message'}
              item={{
                rows: 8,
                type: 'text',
                disabled: true,
                multiline: true,
                fullWidth: true,
                value: data?.text_msg || '',
                placeholder: 'Enter the Message',
                title: <LabelWithAsteriskMark label={'Message'} />,
                onChange: (event) => {
                  setData((prev) => ({ ...prev, text_msg: event.target.value }))
                },
              }}
            />
          </Grid>
          {type === 'invoice' && (
            <Grid item xs={12} sx={{ p: 0, }}>
              <SmsSettings is_send_sms={false} sx={{p:0}} data={data} setData={setData} type={pdfData?.name || ''} />
            </Grid>
          )
          }

        </Grid>
      </Grid>
    </Box>

  )
}

const Buttons = (props) => {
  const { onSave, onCancel } = props
  return (
    <Box component="form" noValidate sx={{}}>
      <Grid container>
        <Grid item xs={12} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
          <CustomButton id={'common_share_cancel_btn'} dataTestId={'common_share_cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
          <CustomButton id={'common_share_send_btn'} dataTestId={'common_share_send_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none', }} btnLabel={'Send'} onClick={onSave} />
        </Grid>
      </Grid>
    </Box>
  )
}