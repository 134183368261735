import React from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import Input from '../../../common/Input';
import PaymentTDSAndTCS from './PaymentTDSAndTCS';
import DateRange from '../../../common/DateRange';
import DatePicker from '../../../common/DatePicker';
import * as Actions from '../../../../state/Actions';
import { InputFile } from '../../../common/InputFile';
import AsyncDropdown from '../../../common/AsyncDropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiAction, apiFormData } from '../../../../api/api';
import { listPaymentMethod } from '../../../../utils/Constant';
import CustomButton from '../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import { NoDataComponent } from '../../../common/NoDataComponent';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import CommonPreviewShare from '../../../common/CommonPreviewShare';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import { eventsNames } from '../../../../firebase/firebaseAnalytics';
import CommonAsyncDropdown from '../../../common/CommonAsyncDropdown';
import CustomGroupButton from '../../../custom/button/CustomGroupButton';
import CustomTypography from '../../../custom/typography/CustomTypography';
import CustomDefaultDropdown from '../../../custom/dropdown/CustomDefaultDropdown';
import CustomDateRangePicker from '../../../custom/Datepicker/CustomDateRangePicker';
import { breadCrumbsStateChangeManager, getQueryParams } from '../../../../utils/Utils';

import {
    payment_made_number,
    payment_received_number,
} from '../../../../utils/Constant';

import {
    getNotesPreferences,
} from '../../setting/settings/Preferences/common/getPreferences';

import {
    CustomContainer,
    CustomButtonContainer,
} from '../../../custom/container/CustomContainer';


import {
    isNumber,
    setLoader,
    isFormValid,
    getDateFormat,
    getPaymentType,
    returnNumberOnly,
    stateChangeManager,
    limitDecimalPlaces,
} from '../../../../utils/Utils';

import {
    list_party,
    create_payment,
    update_payment,
    retrieve_payment,
    get_list_of_currency,
    export_payment_receipt,
    get_chart_of_account_nested_nodes
} from '../../../../api/urls';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';

import {
    Box,
    Grid,
    Alert,
    Paper,
    Dialog,
    Popper,
    Popover,
    Checkbox,
    Container,
    AlertTitle,
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import CommaSeparatedInputField from '../../../custom/input/CommaSeparatedInputField';




const Dropdown = DateRange;
const PaymentCreate = (props) => {
    const { AnalyticsEvent } = props;
    let navigate = useNavigate();
    let location = useLocation();
    const params = getQueryParams(location.search);
    const business_currency = getBusinessInfo().currency

    const dispatch = Actions.getDispatch(React.useContext);

    let payment_initial_data = {
        party_id: props.party_id,
        party_selected: props.party_selected,

        payment_id: props.payment_id,

        account_id: null,
        account_selected: null,

        exchange_rate: 1,
        currency_id: props.currency_id ? props.currency_id : business_currency.id,
        currency_code: props.currency_code ? props.currency_code : business_currency.currency_code,

        reference: null,
        payment_type: props.payment_type,
        payment_method: props.payment_method,

        business_id: getBusinessInfo().id,
        payment_date: moment().format('YYYY-MM-DD'),

        payment_for: props.payment_for,

        is_full_amount: false,

        note: getNotesPreferences(props.role === 'customer' ? 'payment_received' : 'payment_made'),
        attachment: null,

        total_amount: '',
        balance_amount: 0,
        refunded_amount: 0,
        total_payment_amount: 0,
        total_withholding_tax: 0,
        const_total_withholding_tax: 0,


        bank_charges: props.bank_charges,
        total_due_amount: props.total_due_amount,

        tds_account_id: null,
        is_tds_applicable: false,


    }

    // console.log('=====>payment_id', props.payment_id)

    const [isFormSubmitted, setFormSubmitted] = React.useState(false);
    const [isSaveSendAction, setIsSaveSendAction] = React.useState(false);
    const [paymentData, setPaymentData] = React.useState({ ...payment_initial_data });
    const [openInvoiceAlertDialog, setOpenInvoiceAlertDialog] = React.useState(false);
    const [openExcessPaymentDialog, setOpenExcessPaymentDialog] = React.useState(false);

    React.useEffect(() => {
        const apiResults = async (id) => {
            // let initial_payment_for_data = []
            setLoader(dispatch, Actions, true);
            breadCrumbsStateChangeManager(dispatch, 'Edit');
            let res = await apiAction({
                method: 'post',
                url: retrieve_payment(id),
                data: { business_id: getBusinessInfo().id },
            })

            if (res?.success) {
                setTimeout(() => {
                    setLoader(dispatch, Actions, false)
                    setPaymentData({
                        ...payment_initial_data, ...res?.result,
                        payment_id: res?.result.id,
                        payment_for: res?.result.payment_for.map((item, index) => {
                            item['const_amount_due'] = item.amount_due;
                            item['is_tcs_applied'] = item.tds_tcs_choice === 'tcs';
                            item['withholding_tax'] = item.tds_tcs_choice === 'tcs' ? null : item.withholding_tax;
                            item['const_with_holding_tax'] = item.tds_tcs_choice === 'tcs' ? null : item.withholding_tax;

                            return item;
                        }),

                        total_amount: res?.result.total_amount,
                        refunded_amount: res?.result.refunded_amount,
                        total_payment_amount: res?.result.total_payment_amount,

                        party_selected: { name: res?.result.party_display_name },
                        account_selected: { account_name: res?.result.account_name },

                        total_due_amount: Number(res?.result.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.amount_due), 0).toFixed(2)),
                        is_full_amount: res?.result.total_amount === Number(res?.result.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.amount_due), 0).toFixed(2)),
                        const_total_withholding_tax: Number(res?.result.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.withholding_tax), 0).toFixed(2)),

                    })
                }, 0)
            } else {
                setLoader(dispatch, Actions, false)
            }
        }
        if (params.payment_id) {
            apiResults(params.payment_id)
        }
        // eslint-disable-next-line
    }, [params.payment_id])

    function getUnsentInvoiceNumbers(invoices) {
        return invoices
            .filter(invoice => invoice.status === "unsent" && invoice.payment_amount)
            .map(invoice => invoice.reference_number);
    }

    const onSave = ({ is_save_send = false }) => {
        setFormSubmitted(true);

        const is_withholding_tax_greater = paymentData.payment_for?.some(item => item.withholding_tax > item.amount_due);
        const is_payment_amount_greater = paymentData.payment_for?.some(item => item.payment_amount > item.amount_due);

        const validation_data = [
            ...props.validation_data,
            { key: "", validation: is_withholding_tax_greater, message: 'Enter withholding tax cannot be greater than amount due' },
            { key: "", validation: is_payment_amount_greater, message: 'Enter payment cannot be greater than amount due' },
        ];

        const { isValid, message } = isFormValid(paymentData, validation_data);

        if (!isValid) {
            return handleValidationError(message);
        }

        if (paymentData.balance_amount < 0) {
            return handleNegativeBalanceAmount();
        }

        if (paymentData.balance_amount > 0) {
            return handleExcessPaymentAlert(is_save_send);
        }

        if (getUnsentInvoiceNumbers(paymentData.payment_for)?.length) {
            return handleInvoiceAlert(is_save_send);
        }

        if (paymentData.balance_amount === 0) {
            return onSaveAction(is_save_send);
        }

    };

    const handleValidationError = (message) => {
        stateChangeManager(dispatch, Actions, true, 'error', message);
    };

    const handleExcessPaymentAlert = (is_save_send) => {
        setOpenExcessPaymentDialog(true);
        setIsSaveSendAction(is_save_send);
    };
    const handleInvoiceAlert = (is_save_send) => {
        setOpenInvoiceAlertDialog(true);
        setIsSaveSendAction(is_save_send);
    };

    const handleNegativeBalanceAmount = () => {
        const errorMessage = getErrorMessage();
        stateChangeManager(dispatch, Actions, true, 'error', errorMessage);
    };

    const getErrorMessage = () => {
        if (props.role === 'customer') {
            return paymentData.refunded_amount
                ? 'Payment amount is lesser than the applied/refunded amount. Please enter a valid amount.'
                : 'The amount entered is more than the amount received for the selected invoices.';
        } else {
            return paymentData.refunded_amount
                ? 'Payment amount is lesser than the applied/refunded amount. Please enter a valid amount.'
                : 'The amount entered is more than the amount paid for the selected bills.';
        }
    };


    const analyticEvents = () => {
        if (params.payment_id) {
            AnalyticsEvent({ action: eventsNames.actions.EDIT })
        } else {
            AnalyticsEvent({ action: eventsNames.actions.CREATE })
        }
    }

    const onSaveAction = async (is_save_send) => {
        analyticEvents()
        setLoader(dispatch, Actions, true)
        let res = await apiFormData({
            method: 'post',
            data: paymentData,
            url: params.payment_id ? update_payment() : create_payment(),
        })
        if (res?.success) {
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            if (is_save_send) {
                sendPaymentReceipt(res?.result.id);
            } else {
                props.onClose(res?.result.id);
                setLoader(dispatch, Actions, false);
            }
        } else {
            setLoader(dispatch, Actions, false)
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    }

    const isFullPaid = (value) => {
        if (value) {
            paymentData.payment_for.map((item, index) => {
                paymentData.payment_for[index].payment_amount = item.amount_due
            })
            paymentData.balance_amount = 0
        } else {
            paymentData.payment_for.map((item, index) => {
                if (item.is_credit_debit_note_exists) {
                    paymentData.payment_for[index].payment_amount = item.payment_amount;
                } else {
                    paymentData.payment_for[index].payment_amount = 0
                }
            })
            paymentData.balance_amount = 0
        }

        setPaymentData({ ...paymentData })
    }

    const OnChangeTDSAction = (is_tds_applicable) => {
        paymentData.payment_for.map((item, index) => {
            paymentData.payment_for[index].amount_due = item.const_amount_due;
            paymentData.payment_for[index].withholding_tax = item.const_with_holding_tax;
        })
        paymentData.total_withholding_tax = Number(paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.const_with_holding_tax), 0).toFixed(2));
        isFullPaid(0);
        paymentData.balance_amount = 0;
        paymentData.is_full_amount = false;
        paymentData.is_tds_applicable = is_tds_applicable
        paymentData.total_amount = paymentData.update_lower_limit_amount ? paymentData.update_lower_limit_amount : 0;
        paymentData.total_payment_amount = paymentData.update_lower_limit_amount ? paymentData.update_lower_limit_amount : 0;
        paymentData.total_due_amount = Number(paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.const_amount_due), 0).toFixed(2));
        paymentData.const_total_withholding_tax = Number(paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.const_with_holding_tax), 0).toFixed(2));

        setPaymentData({ ...paymentData });
    }

    const sendPaymentReceipt = async (payment_id) => {
        setSendMail(true);
        setSendMailId(payment_id);
    };

    const [sendMail, setSendMail] = React.useState(false);
    const [sendMailId, setSendMailId] = React.useState(false);

    return (
        <div>
            <ExcessPaymentDialogs
                role={props.role}
                paymentData={paymentData}
                open={openExcessPaymentDialog}
                setOpen={setOpenExcessPaymentDialog}
                onSaveAction={() => onSaveAction(isSaveSendAction)}
            />
            <InvoiceAlertDialogs
                open={openInvoiceAlertDialog}
                setOpen={setOpenInvoiceAlertDialog}
                unSentList={getUnsentInvoiceNumbers(paymentData.payment_for)}

                onSaveAction={() => {
                    {
                        if (paymentData.balance_amount > 0) {
                            setOpenExcessPaymentDialog(true);
                        } else {
                            onSaveAction(isSaveSendAction)
                        }
                    }
                }}
            />

            <CommonPreviewShare
                open={sendMail}
                id={sendMailId}
                type={'payment'}
                retrieve_Url={retrieve_payment}
                export_Url={export_payment_receipt}
                setOpen={(value) => { setSendMail(value); setSendMailId(null); props.onClose(sendMailId); }}
                file_key={props.role === 'vendor' ? `${payment_made_number}-${sendMailId}` : `${payment_received_number}-${sendMailId}`}
            />
            {/* <CustomTitleContainer>
                <CustomTitle title={props.title} />
            </CustomTitleContainer> */}
            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 240px)' }}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '48px' }}>
                    <BasicInformation
                        role={props.role}
                        isFullPaid={isFullPaid}
                        dateText={props.dateText}
                        nameText={props.nameText}
                        amountText={props.amountText}
                        accountText={props.accountText}
                        isFormSubmitted={isFormSubmitted}
                        checkBoxText={props.checkBoxText}
                        disabled={props.party_disabled ? props.party_disabled : params.payment_id ? true : false}

                        paymentData={paymentData}
                        setPaymentData={setPaymentData}
                    />
                    {
                        props.role === 'customer' ?
                            <PaymentTDSAndTCS
                                is_tds_applicable={paymentData.is_tds_applicable}
                                set_is_tds_applicable={(value) => { OnChangeTDSAction(value) }}

                                tds_account_id={paymentData.tds_account_id}
                                set_tds_account_id={(value) => setPaymentData({ ...paymentData, tds_account_id: value ? value.id : null })}

                                set_default_tds_account_id={(value) => { setPaymentData({ ...paymentData, tds_account_id: value ? value.id : null }); }}

                            />
                            : null

                    }

                    {
                        paymentData.party_id ?
                            <PaymentInformation
                                role={props.role}
                                label={props.label}
                                apiData={props.apiData}
                                paymentData={paymentData}
                                amountText={props.amountText}
                                setPaymentData={setPaymentData}
                                disabled={props.party_disabled ? props.party_disabled : params.payment_id ? true : false}
                            />
                            : null
                    }
                </div>

            </CustomContainer>
            {
                paymentData.party_id ?
                    <CustomButtonContainer>
                        <Buttons onSave={onSave} onCancel={() => navigate(-1)} />
                    </CustomButtonContainer>

                    : null
            }
        </div>
    )
}

export default PaymentCreate;

const BasicInformation = (props) => {
    const { role, dateText, nameText, amountText, accountText, checkBoxText, paymentData, setPaymentData, disabled, isFormSubmitted, isFullPaid } = props

    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);
    const business_currency = getBusinessInfo().currency;

    const [open, setOpen] = React.useState(null);
    const [currencyResult, setCurrencyResult] = React.useState([])


    let location = useLocation();
    const params = getQueryParams(location.search);

    React.useEffect(() => {
        apiCurrencyResults();
        // eslint-disable-next-line
    }, [])
    const apiCurrencyResults = async () => {
        let res = await apiAction({
            navigate: navigate,
            dispatch: dispatch,
            url: get_list_of_currency(),
        })
        if (res?.success) {
            let results = res?.result.map((item) => {
                item['helperText'] = `${item.name} (${item.symbol})`;
                return item;
            }
            )
            setCurrencyResult(results);
        } else {

        }
    }

    const [creditNoteError, setCreditNoteError] = React.useState(false)

    return (
        <React.Fragment>
            {
                paymentData.update_lower_limit_amount > Number(paymentData.total_amount) && creditNoteError ?
                    <Box sx={{ mb: 2 }}>
                        <Alert severity="error">
                            <AlertTitle>
                                <strong>Something's not quite right</strong>
                            </AlertTitle>
                            {amountText} {<CurrencyFormatter currency={paymentData.currency_code} amount={paymentData.total_amount} />} can not less than lower limit amount {<CurrencyFormatter currency={paymentData.currency_code} amount={paymentData.update_lower_limit_amount} />} as {role === 'customer' ? 'credit note exist for invoice' : `debit note exist for bill`}.
                        </Alert>
                    </Box>
                    : null
            }

            <Grid container spacing={5}>
                <Grid container item xs={12} spacing={5}>

                    {/* /////////////////////////////////////////////////// Party details ///////////////////////////////// */}
                    <Grid item xs={12} sm={6}>
                        <CommonAsyncDropdown
                            id={'payment_party_dropdown'}
                            dataTestId={'payment_party_dropdown'}
                            autoSelect={false}
                            autoFocus={true}
                            disableClearable={false}
                            optionLabel="display_name"
                            noOptionsText={"No result found"}
                            placeholder={'Select ' + role.capitalize()}
                            validation={isFormSubmitted && !paymentData.party_id}

                            item={{
                                method: 'post',
                                url: list_party(1),
                                value: paymentData.party_selected,
                                disabled: disabled ? { disabled } : false,
                                sx: disabled ? { background: '#F1F3F4' } : {},
                                label: <LabelWithAsteriskMark label={nameText} />,
                                body: { is_inactive: false, business_id: getBusinessInfo().id, role: role },
                                onChange: (event, value) => {
                                    console.log('=====>value', value)
                                    if (value) {
                                        setPaymentData({ ...paymentData, currency_code: value.currency_code, currency_id: value.currency_id, exchange_rate: 1, party_selected: value, party_id: value.id, payment_method: value.preferred_payment_method ? value.preferred_payment_method : 'cash', payment_id: null, total_amount: '', balance_amount: 0, total_payment_amount: 0, total_due_amount: 0, is_full_amount: false })
                                    } else {
                                        setPaymentData({ ...paymentData, currency_code: business_currency.currency_code, currency_id: business_currency.id, exchange_rate: 1, party_selected: value, party_id: null, payment_method: 'cash', payment_id: null, total_amount: '', balance_amount: 0, total_payment_amount: 0, total_due_amount: 0, is_full_amount: false })
                                    }
                                }
                            }}
                        />
                    </Grid>

                    {/* /////////////////////////////////////////////////// Amount ///////////////////////////////// */}

                    <Grid item xs={12} sm={6} container>
                        <Grid container item xs={12} >
                            <Grid item xs={12} sm={4}>
                                <CustomTypography
                                    text={<LabelWithAsteriskMark label={amountText} />}
                                    sx={{
                                        fontSize: '14px',
                                        color: '#000000',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        textTransform: "none",
                                        fontFamily: "Noto Sans",
                                        textTransform: 'capitalize',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8} sx={{ mt: { xs: -1, sm: -3 }, textAlign: { xs: 'start', sm: 'right' } }}>
                                {
                                    paymentData.total_due_amount ?
                                        <React.Fragment>

                                            <FormControlLabel
                                                id={'full_amount_checkbox'}
                                                dataTestId={'full_amount_checkbox'}
                                                sx={{ mb: { xs: -1.8, sm: -5 }, textAlign: 'right', whiteSpace: "nowrap" }}
                                                label={<span style={{ fontSize: '14px', color: '#000000DE', fontFamily: 'Noto Sans', fontStyle: 'normal', fontWeight: '700', }}>{checkBoxText}({<CurrencyFormatter currency={paymentData.currency_code} amount={paymentData.total_due_amount} />})</span>}
                                                control={<Checkbox sx={{ textAlign: 'right', }} size='small' checked={paymentData.is_full_amount}
                                                    onClick={() => {
                                                        setOpen(null);
                                                        if (!paymentData.is_full_amount) {
                                                            isFullPaid(paymentData.total_due_amount)
                                                            setPaymentData({ ...paymentData, is_full_amount: !paymentData.is_full_amount, total_amount: paymentData.total_due_amount, total_payment_amount: paymentData.total_due_amount })
                                                        } else {
                                                            isFullPaid(0)
                                                            setPaymentData({ ...paymentData, is_full_amount: !paymentData.is_full_amount, total_amount: paymentData.update_lower_limit_amount ? paymentData.update_lower_limit_amount : 0, total_payment_amount: paymentData.update_lower_limit_amount ? paymentData.update_lower_limit_amount : 0 })

                                                        }
                                                    }}

                                                />}
                                            />
                                            <OnPopover amountText={amountText} open={open} setOpen={setOpen} paymentData={paymentData} setPaymentData={setPaymentData} />
                                        </React.Fragment>

                                        : null
                                }

                            </Grid>
                        </Grid>
                        <Grid container item xs={12} >
                            <Grid item xs={9} sx={{ alignItems: 'center' }}>
                                <CommaSeparatedInputField
                                    title={''}
                                    id={'input_total_amount'}
                                    dataTestId={'input_total_amount'}

                                    placeholder=''
                                    value={paymentData.total_amount}
                                    disabled={paymentData.is_full_amount}
                                    onChange={(value) => {
                                        setPaymentData({ ...paymentData, total_amount: value, balance_amount: (value || 0 - paymentData.total_payment_amount - paymentData.refunded_amount) })
                                    }}
                                    onBlur={(value, e) => {
                                        if (paymentData.update_lower_limit_amount > Number(value)) {
                                            setCreditNoteError(true)
                                        } else {
                                            setCreditNoteError(false)
                                            if (value) {
                                                setOpen(e.currentTarget)
                                            } else {
                                                setOpen(null)
                                            }
                                        }
                                    }}
                                    validation={isFormSubmitted && !paymentData.total_amount || paymentData.update_lower_limit_amount > Number(paymentData.total_amount) && creditNoteError}
                                />

                            </Grid>
                            <Grid item xs={3} sx={{ pl: 1, mt: 1, alignItems: 'center' }}>
                                <CustomDefaultDropdown
                                    maxHeight={250}
                                    fullWidth={true}
                                    inputDisabled={true}
                                    results={currencyResult}
                                    labelKey='currency_code'
                                    disabledCloseIcon={true}
                                    id={'currency_dropdown'}
                                    placeholder='Select Currency'
                                    valueLabelKey={'currency_code'}
                                    dataTestId={'currency_dropdown'}
                                    // inputDisabled={!paymentData.party_id}
                                    // sx={{ background: !paymentData.party_id ? '#F1F3F4' : '' }}
                                    value={paymentData.currency_id ? currencyResult.find((item) => item.id === paymentData.currency_id) : null}
                                    setValue={(selectedCurrency) => {
                                        if (selectedCurrency) {
                                            setPaymentData({ ...paymentData, currency_code: selectedCurrency.currency_code, currency_id: selectedCurrency.id, })
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <ExchangeRateComponent data={paymentData} setData={setPaymentData} />
                    </Grid>

                    {/* /////////////////////////////////////////////////// Date ///////////////////////////////// */}
                    <Grid item xs={12} sm={6}>
                        <DatePicker
                            id={'payment_date_select'}
                            dataTestId={'payment_date_select'}

                            disabledCloseIcon={false}
                            title={<LabelWithAsteriskMark label={dateText} />}
                            validation={isFormSubmitted && !paymentData.payment_date}
                            date={paymentData.payment_date ? moment(paymentData.payment_date) : null}
                            setDate={(date) => { setPaymentData({ ...paymentData, payment_date: date ? date.format('YYYY-MM-DD') : null }) }}
                        />
                    </Grid>

                    {/* /////////////////////////////////////////////////// Payment Mode ///////////////////////////////// */}
                    <Grid item xs={12} sm={6}>
                        <Dropdown
                            id={'payment_method_dropdown'}
                            dataTestId={'payment_method_dropdown'}
                            disableClearable={false}
                            results={listPaymentMethod}
                            label={<LabelWithAsteriskMark label={'Payment Mode'} />}
                            validation={isFormSubmitted && !paymentData.payment_method}
                            value={paymentData.payment_method ? getPaymentType(paymentData.payment_method) : null}
                            setValue={(event, selected) => { setPaymentData({ ...paymentData, payment_method: selected ? selected.value : null }) }}
                        />
                    </Grid>

                    {/* /////////////////////////////////////////////////// Account ///////////////////////////////// */}
                    <Grid item xs={12} sm={6}>
                        {/* <Dropdown
                            id={'account_selected_dropdown'}
                            dataTestId={'account_selected_dropdown'}
                            disableClearable={false}
                            results={accountsResult}
                            placeholder={'Account Type'}
                            label={<LabelWithAsteriskMark label={accountText} />}
                            validation={isFormSubmitted && !paymentData.account_id}
                            value={paymentData.account_id ? accountsResult.find((item) => item.id === paymentData.account_id) : null}
                            setValue={(event, selected) => { setPaymentData({ ...paymentData, account_selected: selected ? selected : null, account_id: selected ? selected.id : null }) }}
                        /> */}

                        <AsyncDropdown
                            sx={{}}
                            fullWidth={true}
                            autoFocus={false}
                            newResults={null}
                            disabledCloseIcon={false}
                            isGroupHeaderSticky={true}
                            id={'account_selected_dropdown'}
                            dataTestId={'account_selected_dropdown'}
                            title={<LabelWithAsteriskMark label={accountText} />}
                            validation={isFormSubmitted && !paymentData.account_id}

                            selectedValue={paymentData?.account_selected}
                            setSelectedValue={(value) => {
                                setPaymentData({
                                    ...paymentData,
                                    account_id: value?.id,
                                    account_selected: value,
                                })
                            }}

                            valueLabelKey='id'
                            uniqueOptionKey='id'
                            searchKey='account_name'
                            optionLabelKey='account_name'
                            placeholder='Select the Account'
                            playLoad={{ account_type: ['ASSET'] }}
                            optionGroupLabelKey='account_type'
                            URL={get_chart_of_account_nested_nodes(1)}
                        />
                    </Grid>

                    {/* /////////////////////////////////////////////////// Reference ///////////////////////////////// */}
                    <Grid item xs={12} sm={6}>
                        <Input
                            id={'input_reference'}
                            dataTestId={'input_reference'}
                            item={{
                                type: 'text',
                                required: true,
                                fullWidth: true,
                                title: 'Payment Reference',
                                placeholder: 'Enter Payment Reference',
                                value: paymentData.reference ? paymentData.reference : '',
                                onChange: (e) => { setPaymentData({ ...paymentData, reference: e.target.value }) },
                            }}
                        />
                    </Grid>

                </Grid>
            </Grid>
        </React.Fragment>

    )
}

const PaymentInformation = (props) => {
    const { label, apiData, paymentData, setPaymentData, role, disabled } = props
    const { url, party_key, reference_type, payment_type } = apiData
    const [filters, setFilters] = React.useState()
    React.useEffect(() => {
        const apiResults = async () => {
            let initial_payment_for_data = []
            let res = await apiAction({
                url: url,
                method: 'post',
                data: party_key === 'customer_id' ?
                    {
                        is_paid: false,
                        role: 'customer',
                        is_draft: false,
                        business_id: getBusinessInfo().id,
                        customer_id: paymentData.party_id,
                        ...filters
                    }
                    :
                    {
                        role: 'vendor',
                        is_paid: false,
                        is_draft: false,
                        vendor_id: paymentData.party_id,
                        business_id: getBusinessInfo().id,
                        ...filters
                    },
            })
            if (res?.success) {
                res?.result.result.map((item) => {
                    if (item.total_amount !== 0) {
                        initial_payment_for_data.push({
                            due_date: item.due_date,
                            payment_amount: '',

                            status: item.status,
                            currency_code: item.currency_code,

                            reference_type: reference_type,
                            amount: item[apiData.amount_key],

                            amount_due: item[apiData.amountDue_key],
                            const_amount_due: item[apiData.amountDue_key],

                            reference_number: item[apiData.number_key],
                            is_tcs_applied: item.tds_tcs_choice === 'tcs',
                            withholding_tax: item.tds_tcs_choice === 'tcs' ? null : item.tds_tcs_amount,
                            const_with_holding_tax: item.tds_tcs_choice === 'tcs' ? null : item[apiData.withholding_tax],

                            date: moment(item[apiData.date_key]).format('YYYY-MM-DD'),
                        })
                    }
                })
                setPaymentData({
                    ...paymentData,
                    total_amount: '',
                    balance_amount: 0,
                    is_full_amount: false,
                    total_payment_amount: 0,
                    total_withholding_tax: 0,
                    payment_type: payment_type,
                    payment_for: initial_payment_for_data,
                    total_due_amount: Number(res?.result.result.reduce((acc, item) => acc + returnNumberOnly(item[apiData.amountDue_key]), 0).toFixed(2)),
                    const_total_withholding_tax: Number(res?.result.result.reduce((acc, item) => acc + returnNumberOnly(item[apiData.withholding_tax]), 0).toFixed(2)),
                });
            } else {

            }
        }
        if (!disabled) {
            apiResults()
        }
        // eslint-disable-next-line
    }, [paymentData.party_id, filters])

    return (
        <>
            <CustomTableContainer>
                <Box sx={{ pb: 1, mt: 10, display: 'flex', borderBottom: '1px solid #e0e0e0' }}>
                    <Filters disabled={disabled} role={role === 'customer'} filters={filters} setFilters={setFilters} />
                </Box>
                <CustomTable>
                    <PaymentInformationHeader
                        label={label}
                        is_tds_applicable={paymentData.is_tds_applicable}
                    />
                    <PaymentInformationBody
                        role={role}
                        label={label}
                        apiData={apiData}
                        paymentData={paymentData}
                        setPaymentData={setPaymentData}
                        is_tds_applicable={paymentData.is_tds_applicable}
                    />
                </CustomTable>
                <Box sx={{ p: 2 }} >
                    <NoDataComponent left='0%' top='0%' position={'relative'} data={paymentData.payment_for} />
                </Box>
            </CustomTableContainer>

            <Grid container style={{ paddingBottom: '24px', paddingLeft: '20px', paddingRight: '20px', }}>
                <PaymentNotesAndAttachment
                    role={role}
                    paymentData={paymentData}
                    setPaymentData={setPaymentData}
                />
                <PaymentSummary
                    role={role}
                    paymentData={paymentData}
                />
            </Grid>

        </>
    )
}

const PaymentNotesAndAttachment = ({ role, paymentData, setPaymentData }) => {
    const handleNoteChange = (e) => {
        setPaymentData({ ...paymentData, note: e.target.value });
    };

    const handleFileUpload = (file) => {
        setPaymentData({ ...paymentData, attachment: file });
    };

    const handleFileRemove = () => {
        setPaymentData({ ...paymentData, attachment: null });
    };

    return (
        <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
                <span style={{ fontSize: '14px' }}>
                    **List contains only Unpaid {role === 'customer' ? 'invoices' : 'bills'}
                </span>
            </Grid>
            <Grid item xs={12} sm={9} sx={{ mt: 3 }}>
                <Input
                    item={{
                        rows: 4,
                        type: 'text',
                        fullWidth: true,
                        required: true,
                        multiline: true,
                        title: 'Notes',
                        placeholder: 'Enter your Notes..',
                        value: paymentData.note || '',
                        onChange: handleNoteChange
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={9} sx={{ mt: 3 }}>
                <InputFile
                    onFileUpload={handleFileUpload}
                    attachment={paymentData.attachment}
                    onFileRemove={handleFileRemove}
                />
            </Grid>
        </Grid>
    );
};

const PaymentSummary = ({ paymentData, role }) => {
    const boxStyles = {
        textAlign: '-webkit-right',
        fontFamily: "Noto Sans"
    };

    const summaryContainerStyles = {
        mt: 3,
        pb: 2,
        borderRadius: 1,
        background: paymentData.balance_amount === 0 ? '#EEEEEE' : '#f9e6c0',
        border: '1px dashed'
    };

    return (
        <Grid item xs={12} sm={6}>
            <Box style={boxStyles}>
                <Grid container item xs={12} sm={10}>
                    <Grid item xs={6} sx={{ pl: 1, textAlign: 'left', mt: 1 }}>
                        Total
                    </Grid>
                    <Grid item xs={6} sx={{ pr: 1, mt: 1, textAlign: 'right' }}>
                        <CurrencyFormatter currency={paymentData.currency_code} amount={paymentData.total_payment_amount} />
                    </Grid>

                    <Grid container item xs={12} sx={summaryContainerStyles}>
                        <Grid item xs={6} sx={{ pl: 1, textAlign: 'left' }}>
                            {role === 'customer' ? (
                                <p>Amount Received :</p>
                            ) : (
                                <p>Amount Paid :</p>
                            )}
                            <p>Amount used for Payments :</p>
                            <p>Amount Refunded :</p>
                            <p>Amount in Excess:</p>
                        </Grid>
                        <Grid item xs={6} sx={{ pr: 1, textAlign: 'right' }}>
                            <p><CurrencyFormatter currency={paymentData.currency_code} amount={paymentData.total_amount} /></p>
                            <p><CurrencyFormatter currency={paymentData.currency_code} amount={paymentData.total_payment_amount} /></p>
                            <p><CurrencyFormatter currency={paymentData.currency_code} amount={paymentData.refunded_amount} /></p>
                            <p><CurrencyFormatter currency={paymentData.currency_code} amount={paymentData.balance_amount} /></p>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
};

const PaymentInformationHeader = (props) => {
    const { label, is_tds_applicable } = props;
    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell style={{ width: 200 }} align='left'>{label.dateText}</CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 150 }} align='left'>{label.numberText}</CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} align='right'>{label.amountText}</CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} align='right'>{label.amountDueText}</CustomTableHeadCell>
                {is_tds_applicable &&
                    <CustomTableHeadCell style={{ width: 300 }} align='right'>{'Withholding Tax'}</CustomTableHeadCell>
                }
                <CustomTableHeadCell style={{ width: 300 }} align='right'>{label.paymentText}</CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 0 }} align='left' />
            </CustomTableRow>
        </CustomTableHead>
    )
}

const PaymentInformationBody = (props) => {
    const { paymentData, label, setPaymentData, is_tds_applicable, role } = props
    const dispatch = Actions.getDispatch(React.useContext);


    const handleClick = (value, message) => {
        if (value) {
            stateChangeManager(dispatch, Actions, true, 'error', message,);
        }
    };
    return (
        <CustomTableBody>
            {paymentData.payment_for.map((item, index) => {
                return (
                    <CustomTableRow key={index}>
                        <CustomTableBodyCell sx={{}} align='left' size='small'>
                            <p style={{ marginBottom: '5px' }}>{moment(item.date).format(getDateFormat())}</p>
                            <span style={{ fontSize: '10px', opacity: 0.6, }}>Due Date: {moment(item.due_date).format(getDateFormat())}</span>
                        </CustomTableBodyCell>
                        <CustomTableBodyCell sx={{}} align='left' size='small'>
                            <p style={{ marginBottom: '5px' }}>{item.reference_number}</p>
                            <span style={{ visibility: item.status === 'unsent' ? 'visible' : 'hidden', fontSize: '10px', opacity: 0.6, }}>Status: {item.status}</span>
                        </CustomTableBodyCell>
                        <CustomTableBodyCell sx={{}} align='right' size='small'>
                            <p style={{}}><CurrencyFormatter currency={item.currency_code} amount={item.amount} /></p>
                            <span style={{ visibility: 'hidden' }}>visibility</span>
                        </CustomTableBodyCell>
                        <CustomTableBodyCell sx={{}} align='right' size='small'>
                            <p style={{ marginBottom: '5px' }}>
                                <CurrencyFormatter currency={item.currency_code} amount={item.amount_due} />
                            </p>
                            {!is_tds_applicable && role === 'customer' && item.const_with_holding_tax ?
                                (
                                    !item.is_tcs_applied ?
                                        <span style={{ color: 'red', fontSize: '10px', opacity: 0.6, }}>Including TDS: {<CurrencyFormatter currency={paymentData.currency_code} amount={item.const_with_holding_tax} />}</span>
                                        :
                                        <span style={{ visibility: 'hidden' }}>visibility</span>
                                )
                                :
                                <span style={{ visibility: 'hidden' }}>visibility</span>
                            }
                        </CustomTableBodyCell>
                        {is_tds_applicable ?
                            <CustomTableBodyCell sx={{}} align='right' size='small'>

                                <CommaSeparatedInputField
                                    title={''}
                                    placeholder=''

                                    id={'input_withholding_tax'}
                                    dataTestId={'input_withholding_tax'}

                                    currency_code={item.currency_code}
                                    disabled={item.is_tcs_applied || item.is_credit_debit_note_exists}
                                    validation={paymentData.payment_for[index].withholding_tax > item.amount_due}
                                    value={item.is_tcs_applied ? '' : paymentData.payment_for[index].withholding_tax}
                                    onFocus={() => { if (item.withholding_tax > item.amount_due) { handleClick(true, `Enter withholding tax can not be greater than amount due`) } else { handleClick(null) } }}

                                    onBlur={(value) => {
                                        if (Number(value) <= item.amount_due) {
                                            paymentData.payment_for[index].withholding_tax = value;
                                            paymentData.payment_for[index].amount_due = Number(((item.const_amount_due + item.const_with_holding_tax) - Number(value)).toFixed(2));
                                        } else {
                                            paymentData.payment_for[index].withholding_tax = 0;
                                            paymentData.payment_for[index].amount_due = item.const_amount_due + item.const_with_holding_tax;
                                        }
                                        if (paymentData.payment_for[index].payment_amount > item.amount_due) {
                                            paymentData.payment_for[index].payment_amount = 0
                                        }
                                        paymentData.payment_for[index].editable = false;
                                        paymentData.total_due_amount = Number(paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.amount_due), 0).toFixed(2));
                                        paymentData.total_payment_amount = Number((paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.payment_amount), 0)).toFixed(2));
                                        paymentData.total_withholding_tax = Number(paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.withholding_tax), 0).toFixed(2));
                                        paymentData.balance_amount = Number((paymentData.total_amount - (paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.payment_amount), 0)) - returnNumberOnly(paymentData.refunded_amount)).toFixed(2));
                                        if (paymentData.is_full_amount) {
                                            paymentData.total_amount = Number(paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.amount_due), 0).toFixed(2));
                                        }
                                        setPaymentData({ ...paymentData, })
                                    }}

                                    onChange={(value, e) => {
                                        if (limitDecimalPlaces(value) > item.amount_due) { handleClick(e, `Enter withholding tax can not be greater than amount due`) } else { handleClick(null) }
                                        if (value) {
                                            paymentData.payment_for[index].withholding_tax = value;
                                            paymentData.payment_for[index].amount_due = Number(((item.const_amount_due + item.const_with_holding_tax) - Number(value)).toFixed(2));
                                        } else {
                                            paymentData.payment_for[index].withholding_tax = value;
                                            paymentData.payment_for[index].amount_due = Number((item.const_amount_due + item.const_with_holding_tax).toFixed(2));

                                        }

                                        paymentData.total_due_amount = Number(paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.amount_due), 0).toFixed(2));
                                        paymentData.total_payment_amount = Number((paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.payment_amount), 0)).toFixed(2));
                                        paymentData.total_withholding_tax = Number(paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.withholding_tax), 0).toFixed(2));
                                        paymentData.balance_amount = Number((paymentData.total_amount - (paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.payment_amount), 0)) - returnNumberOnly(paymentData.refunded_amount)).toFixed(2));
                                        setPaymentData({ ...paymentData, })
                                    }}
                                />
                                {
                                    item.is_tcs_applied ?
                                        <span style={{ color: 'red', fontSize: '10px', opacity: 0.6, marginTop: '5px' }}>TCS Applied</span>
                                        :
                                        <span style={{ fontSize: '10px', opacity: 0.6, marginTop: '5px' }}>Withholding Tax: {<CurrencyFormatter currency={item.currency_code} amount={item.const_with_holding_tax} />}</span>
                                }

                            </CustomTableBodyCell>
                            : null
                        }
                        <CustomTableBodyCell sx={{}} align='right' size='small'>


                            <CommaSeparatedInputField
                                title={''}
                                placeholder=''

                                id={'input_payment_amount'}
                                dataTestId={'input_payment_amount'}

                                currency_code={item.currency_code}
                                disabled={item.is_credit_debit_note_exists}
                                value={paymentData.payment_for[index].payment_amount}
                                validation={paymentData.payment_for[index].payment_amount > item.amount_due}
                                onFocus={() => { if (item.payment_amount > item.amount_due) { handleClick(true, `Enter payment can not be greater than amount due`) } else { handleClick(null) } }}

                                onBlur={(value) => {
                                    if (Number(value) <= item.amount_due) {
                                        paymentData.payment_for[index].payment_amount = value
                                    } else {
                                        paymentData.payment_for[index].payment_amount = ''
                                    }
                                    paymentData.payment_for[index].editable = false;
                                    paymentData.total_due_amount = Number(paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.amount_due), 0).toFixed(2));
                                    paymentData.total_payment_amount = Number((paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.payment_amount), 0)).toFixed(2));
                                    paymentData.total_withholding_tax = Number(paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.withholding_tax), 0).toFixed(2));
                                    paymentData.balance_amount = Number((paymentData.total_amount - (paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.payment_amount), 0)) - returnNumberOnly(paymentData.refunded_amount)).toFixed(2));
                                    setPaymentData({ ...paymentData, })
                                }}

                                onChange={(value, e) => {
                                    if (Number(value) > item.amount_due) { handleClick(e, `Enter payment can not be greater than amount due`) } else { handleClick(null) }

                                    paymentData.payment_for[index].payment_amount = value
                                    paymentData.total_due_amount = Number(paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.amount_due), 0).toFixed(2));
                                    paymentData.total_payment_amount = Number((paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.payment_amount), 0)).toFixed(2));
                                    paymentData.total_withholding_tax = Number(paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.withholding_tax), 0).toFixed(2));
                                    paymentData.balance_amount = Number((paymentData.total_amount - (paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.payment_amount), 0)) - returnNumberOnly(paymentData.refunded_amount)).toFixed(2));
                                    setPaymentData({ ...paymentData, })
                                }}
                            />
                            <CustomTypography
                                id={'pay_full_action'}
                                dataTestId={'pay_full_action'}
                                text={"Pay in Full"}
                                sx={{
                                    p: 0,
                                    mb: 0,
                                    mt: 3,
                                    color: '#2464EB',
                                    fontSize: '12px',
                                    display: 'inline',
                                    cursor: 'pointer',
                                    textTransform: 'none',
                                    ":hover": { textDecoration: 'underline' },
                                }}
                                onClick={() => {
                                    paymentData.payment_for[index].payment_amount = item.amount_due;
                                    paymentData.total_due_amount = Number(paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.amount_due), 0).toFixed(2));
                                    paymentData.total_payment_amount = Number((paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.payment_amount), 0)).toFixed(2));
                                    paymentData.total_withholding_tax = Number(paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.withholding_tax), 0).toFixed(2));
                                    paymentData.balance_amount = Number((paymentData.total_amount - (paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.payment_amount), 0)) - returnNumberOnly(paymentData.refunded_amount)).toFixed(2));
                                    setPaymentData({ ...paymentData, })
                                }}
                            />

                        </CustomTableBodyCell>
                        <CustomTableBodyCell sx={{}} align='left' size='small'>
                            <CustomTypography
                                id={'remove_action'}
                                dataTestId={'remove_action'}
                                text={<HighlightOffIcon />}
                                sx={{
                                    ml: -2.5,
                                    color: '#afacac',
                                    fontSize: '12px',
                                    display: 'inline',
                                    cursor: 'pointer',
                                    textTransform: 'none',
                                    ":hover": { color: 'red', opacity: 0.6, },
                                }}
                                onClick={() => {
                                    if (item.is_credit_debit_note_exists) {
                                        handleClick(true, `${item.reference_type === 'bill' ? 'Debit note' : 'Credit note'} has been issued against this ${item.reference_type === 'bill' ? 'bill' : 'invoice'}, therefore this payment cannot be remove.`)
                                    } else {
                                        paymentData.payment_for[index].payment_amount = 0;
                                        paymentData.total_due_amount = Number(paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.amount_due), 0).toFixed(2));
                                        paymentData.total_payment_amount = Number((paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.payment_amount), 0)).toFixed(2));
                                        paymentData.total_withholding_tax = Number(paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.withholding_tax), 0).toFixed(2));
                                        paymentData.balance_amount = Number((paymentData.total_amount - (paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.payment_amount), 0)) - returnNumberOnly(paymentData.refunded_amount)).toFixed(2));
                                        setPaymentData({ ...paymentData, })
                                    }

                                }}
                            />
                        </CustomTableBodyCell>
                    </CustomTableRow>

                )
            })}
        </CustomTableBody>
    )
}

const OnPopover = (props) => {
    const { amountText, open, setOpen, paymentData, setPaymentData } = props

    const onPressYes = () => {
        let amount = Number((paymentData.total_amount - (paymentData.update_lower_limit_amount ? paymentData.update_lower_limit_amount : 0)).toFixed(2));
        paymentData.payment_for.map((item, index) => {
            if (item.is_credit_debit_note_exists) {
                paymentData.payment_for[index].payment_amount = item.payment_amount;
            } else {
                if (amount >= item.amount_due) {
                    paymentData.payment_for[index].payment_amount = item.amount_due;
                    amount -= item.amount_due
                } else {
                    paymentData.payment_for[index].payment_amount = amount;
                    amount = 0
                }
            }
        })
        paymentData.total_payment_amount = Number((paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.payment_amount), 0)).toFixed(2));
        paymentData.balance_amount = Number((paymentData.total_amount - (paymentData.payment_for.reduce((acc, item) => acc + returnNumberOnly(item.payment_amount), 0)) - returnNumberOnly(paymentData.refunded_amount)).toFixed(2));
        handleClose()
        setPaymentData({ ...paymentData, })
    }

    const handleClose = () => {
        setOpen(null)
    }
    return (

        <React.Fragment>
            <Popover
                id={'full_amount_popover'}
                dataTestId={'full_amount_popover'}
                anchorEl={open}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        paddingBottom: 15,
                    },
                }}

                open={Boolean(open)}
            >
                <Container>
                    <p style={{
                        fontSize: '14px',
                        fontWeight: '700',
                        fontFamily: "Noto Sans",
                    }}>Would you like {<CurrencyFormatter currency={paymentData.currency_code} amount={paymentData.total_amount} />} to be reflected in the Payment field?</p>
                    <div style={{ textAlign: 'center', marginTop: '24px' }}>
                        <CustomButton
                            id={'no_btn'}
                            dataTestId={'no_btn'}
                            btnLabel='No'
                            variant="outlined"
                            onClick={handleClose}
                            sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }}
                        />
                        <CustomButton
                            id={'yes_btn'}
                            dataTestId={'yes_btn'}

                            btnLabel='Yes'
                            variant="contained"
                            onClick={onPressYes}
                            sx={{ ml: 2, textTransform: 'none', }}
                        />
                    </div>
                </Container>
            </Popover>
        </React.Fragment>
    )
}

const ExchangeRateComponent = (props) => {
    const { data, setData } = props;
    const { currency } = getBusinessInfo();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = Actions.getDispatch(React.useContext);
    const [validation, setValidation] = React.useState(false)


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        if (data.exchange_rate && data.exchange_rate !== 0) {
            setAnchorEl(null);
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'scroll-popover' : undefined;

    let current_currency_id = data.currency_id;
    let business_currency_id = currency && currency.id;
    return (
        current_currency_id && business_currency_id && (current_currency_id !== business_currency_id) &&
        <React.Fragment>
            <CustomTypography
                id={"total_with_exchange_rate"}
                dataTestId={"total_with_exchange_rate"}
                text={<span style={{ display: 'inline-flex' }}>Currency conversion: <CurrencyFormatter amount={data.total_amount * data.exchange_rate} currency={currency.currency_code} /> ({'INR'}) at {data.exchange_rate} <span className='hover-underline-class' style={{ color: '#2464EB', cursor: 'pointer' }}><ModeEditOutlineIcon onClick={handleClick} sx={{ ml: 0.5, fontSize: '18px' }} /></span> </span>}
                sx={{
                    mt: 0.5,
                    color: "#000000",
                    fontSize: "12px",
                    fontWeight: "700",
                    lineHeight: "14px",
                    fontStyle: "normal",
                    fontFamily: "Noto Sans",
                }}
            />

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        // maxHeight: 200,
                        // width: 200,
                    },
                }}
            >
                <Box sx={{ p: 2, pt: 0 }}>
                    <Input
                        id={"input_exchange_rate"}
                        isSearchableComponent={true}
                        dataTestId={"input_exchange_rate"}
                        item={{
                            type: "number",
                            required: true,
                            fullWidth: true,
                            placeholder: "",
                            title: "Order Number",
                            titleVisibility: "hidden",
                            validation: validation && !data.exchange_rate,
                            value: data.exchange_rate !== null ? data.exchange_rate : '',
                            onChange: (e) => {
                                setValidation(false);
                                if (isNumber(e.target.value)) {
                                    data.exchange_rate = limitDecimalPlaces(e.target.value, 6);
                                } else {
                                    data.exchange_rate = null;
                                }
                                setData({ ...data })
                            },
                            onBlur: (e) => {
                                if (isNumber(e.target.value)) {
                                    if (Number(e.target.value) === 0) {
                                        setValidation(true);
                                        stateChangeManager(dispatch, Actions, true, "error", 'Exchange rate can not be zero');
                                    }
                                    data.exchange_rate = limitDecimalPlaces(e.target.value, 6);
                                } else {
                                    setValidation(true);
                                    data.exchange_rate = null;
                                    stateChangeManager(dispatch, Actions, true, "error", 'Exchange rate can not be empty');
                                }
                                setData({ ...data })
                            },
                        }}
                        onKeyPress={(event) => {
                            if (
                                event?.key === "-" ||
                                event?.key === "+" ||
                                event?.key === "e" ||
                                event?.key === "E"
                            ) {
                                event.preventDefault();
                            }
                        }}
                    />
                </Box>
            </Popover>
        </React.Fragment>
    )
}


const BlurPopover = (props) => {
    const { anchorEl, handleClick, text } = props

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <Popper
            id={id}
            dataTestId={id}
            open={open}
            anchorEl={anchorEl}

        >
            <Box sx={{
                p: 1,
                mt: 1,
                maxWidth: 190,
                bgcolor: '#fdeded',
                borderRadius: '3px',
                border: '1px solid #f3817e',
            }}>
                <Grid container>
                    <Grid item xs={12}>
                        <p style={{ color: '#af5b30', marginTop: 0, marginBottom: 0, fontSize: '12px', lineHeight: 1.4 }}>{`Enter Payment amount can not be greater than amount due`}</p>
                    </Grid>
                </Grid>
            </Box>
        </Popper>
    )
}

const Filters = (props) => {
    const { disabled, role, filters, setFilters } = props
    const [value, setValue] = React.useState();

    const onDateRangeChangeInvoice = (dates) => {
        console.log('===>dates', filters, dates)
        if (dates) {
            setFilters({ ...filters, invoice_start_date: dates[0].format("YYYY-MM-DD"), invoice_end_date: dates[1].format("YYYY-MM-DD") })
        } else {
            delete filters.invoice_end_date
            delete filters.invoice_start_date
            setFilters({ ...filters })
        }

    }
    const onDateRangeChangeBill = (dates) => {
        if (dates) {
            setFilters({ ...filters, bill_start_date: dates[0].format("YYYY-MM-DD"), bill_end_date: dates[1].format("YYYY-MM-DD") })
        } else {
            delete filters.bill_end_date
            delete filters.bill_start_date
            setFilters({ ...filters })
        }

    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handelClear = () => {
        handleClose();
        if (value) {
            setValue(null);
            if (role) {
                onDateRangeChangeInvoice(null);
            } else {
                onDateRangeChangeBill(null);
            }
        }
    }
    const open = Boolean(anchorEl);
    const id = open ? 'filter_date_range' : undefined;

    return (
        <div>
            <div >
                <CustomTypography
                    text={role ? "Unpaid invoices" : 'Unpaid bills'}
                    sx={{
                        mb: 1,
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '700',
                        cursor: 'pointer',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                        display: 'inline-block',
                    }}
                />
                {!disabled &&
                    <React.Fragment>
                        <span style={{ margin: 6, color: '#dedede' }}>|</span>

                        <CustomTypography
                            id={'open'}
                            dataTestId={'open'}
                            text={<span>&#x1F4C5;Filter by Date Range <span>{open ? <span>▶</span> : <span>▼</span>}</span></span>}
                            sx={{
                                mb: 1,
                                opacity: 0.6,
                                fontSize: '14px',
                                color: '#000000',
                                fontWeight: '700',
                                cursor: 'pointer',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                                display: 'inline-block',
                            }}
                            onClick={handleClick}
                        />
                    </React.Fragment>
                }
            </div>
            <Popover
                id={id}
                dataTestId={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ p: 2, pr: 2, maxWidth: 600 }}>
                    {/* <CustomTypography
                        text={'Filter By Date Range'}
                        sx={{
                            pb: 3,
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}  
                    /> */}
                    <CustomDateRangePicker onDateRangeChange={setValue} dates={value ? value : [null, null]} />

                    <CustomTypography
                        text={role ? 'Note: List contains only Unpaid invoices based on apply date range filter.' : 'Note: List contains only Unpaid bills based on apply date range filter.'}
                        sx={{
                            mt: 5,
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />

                    <Grid container sx={{ mt: 5, mb: 2 }}>
                        <Grid item xs={8} sx={{ textAlign: 'left' }}>
                            <CustomButton
                                disabled={!value}
                                variant="contained"
                                id={'apply_filter_btn'}
                                btnLabel='Apply Filter'
                                dataTestId={'apply_filter_btn'}
                                sx={{ mr: 1, textTransform: 'capitalize' }}
                                onClick={() => { handleClose(); role ? onDateRangeChangeInvoice(value) : onDateRangeChangeBill(value) }}
                            />
                            <CustomButton
                                id={'cancel_apply_filter_btn'}
                                dataTestId={'cancel_apply_filter_btn'}
                                btnLabel='Cancel'
                                variant="outlined"
                                onClick={handleClose}
                                sx={{ ml: 1, textTransform: 'capitalize' }}
                            />
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: 'right' }}>
                            <CustomButton
                                id={'clear_apply_filter_btn'}
                                dataTestId={'clear_apply_filter_btn'}
                                btnLabel='Clear Selection'
                                variant="text"
                                sx={{ textTransform: 'capitalize' }}
                                onClick={() => { handelClear() }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Popover>
        </div>
    )
}


const ExcessPaymentDialogs = (props) => {
    const { role, open, setOpen, paymentData, onSaveAction } = props;

    const handleClicked = () => {
        onSaveAction();
        setOpen(false);
    };

    const renderBalanceAmountMessage = () => (

        role === 'customer' ? (
            <React.Fragment>
                <Typography gutterBottom sx={{
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    fontFamily: "Noto Sans",
                }}>
                    Would you like to store the excess amount of <CurrencyFormatter currency={paymentData.currency_code} amount={paymentData.balance_amount} /> as over payment from this customer?
                </Typography>
                <Typography gutterBottom sx={{
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    fontFamily: "Noto Sans",
                }}>
                    Note: The excess amount will be deposited in the <span style={{ color: '#2464EB' }}>Unearned Revenue</span>
                </Typography>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Typography gutterBottom sx={{
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    fontFamily: "Noto Sans",
                }}>
                    You’re about to record an excess payment of <CurrencyFormatter currency={paymentData.currency_code} amount={paymentData.balance_amount} />. This will be stored as credits for the vendor. Do you want to continue?
                </Typography>
                <Typography gutterBottom sx={{
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    fontFamily: "Noto Sans",
                }}>
                    Note: The excess amount will be deposited in the <span style={{ color: '#2464EB' }}>Prepaid Expenses</span>
                </Typography>
            </React.Fragment>
        )
    );

    return (
        <div>
            <BootstrapDialog open={open}>
                <BootstrapDialogTitle id="customized-dialog-title" dataTestId="customized-dialog-title" onClose={() => setOpen(false)}>
                    <CustomTypography
                        text={'Excess Payment'}
                        sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {renderBalanceAmountMessage()}
                    <div style={{ textAlign: 'center', marginTop: '24px' }}>

                        <CustomButton
                            btnLabel='Cancel'
                            variant='outlined'
                            onClick={() => setOpen(false)}
                            sx={{ textTransform: 'none', mr: 2, pl: 2, pr: 2, '&:hover': { backgroundColor: '#e8f3ff' } }}
                        />

                        <CustomButton
                            variant='contained'
                            btnLabel='Save to continue'
                            onClick={handleClicked}
                            sx={{ textTransform: 'none', mr: 2, pl: 2, pr: 2 }}
                        />
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
};

const InvoiceAlertDialogs = ({ open, setOpen, onSaveAction, unSentList = [] }) => {
    const handleClicked = () => {
        onSaveAction();
        setOpen(false);
    };

    const renderUnSentMessage = () => {
        const unsentInvoices = unSentList.join(', ');
        const invoiceText = unSentList.length === 1 ? 'invoice' : 'invoices';
        return (
            <Typography gutterBottom sx={{
                color: '#000000',
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '18px',
                fontStyle: 'normal',
                fontFamily: 'Noto Sans',
            }}>
                The status of {invoiceText} {unsentInvoices} will be changed to 'Sent' once the payment is recorded.
            </Typography>
        );
    };

    return (
        <BootstrapDialog open={open}>
            <BootstrapDialogTitle id="customized-dialog-title" data-testid="customized-dialog-title" onClose={() => setOpen(false)}>
                <CustomTypography
                    text="Invoice Alert"
                    sx={{
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: 'Noto Sans',
                    }}
                />
            </BootstrapDialogTitle>
            <DialogContent dividers>
                {renderUnSentMessage()}
                <div style={{ textAlign: 'center', marginTop: '24px' }}>
                    <CustomButton
                        variant="contained"
                        btnLabel="Ok & Continue"
                        onClick={handleClicked}
                        sx={{ textTransform: 'none', marginRight: 2, paddingLeft: 2, paddingRight: 2 }}
                    />
                </div>
            </DialogContent>
        </BootstrapDialog>
    );
};

const Buttons = (props) => {
    const { onSave, onCancel } = props
    let location = useLocation();
    const params = getQueryParams(location.search)
    return (
        <Grid container >
            <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}><CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} /></Grid>
            {/* <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'right', }}><CustomButton id={'save_btn'} dataTestId={'save_btn'} variant='contained' sx={{ textTransform: 'none', }} btnLabel={params.payment_id ? 'Update' : 'Save'} onClick={onSave} /></Grid> */}
            <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'right', }}>
                <CustomGroupButton
                    options={[
                        {
                            id: "save_btn",
                            dataTestId: "save_btn",
                            label: params.payment_id ? 'Update' : 'Save',
                            condition: () => { onSave({ is_save_send: false }); },
                        },
                        {
                            id: "save_send_btn",
                            dataTestId: "save_send_btn",
                            label: params.payment_id ? "Update and Send" : "Save and Send",
                            condition: () => { onSave({ is_save_send: true }); },
                        },
                    ]}
                />
            </Grid>
        </Grid>
    )
}

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

 



