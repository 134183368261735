import * as React from 'react';
import Input from '../../common/Input';
import VerifyEmail from './VerifyEmail';
import { apiAction } from '../../../api/api';
import { userSignUp } from '../../../api/urls';
import * as Actions from '../../../state/Actions';
import { routesName } from '../../../config/routesName';
import { useNavigate, useLocation } from 'react-router-dom';
import { LabelWithAsteriskMark } from '../../common/CommonLabel';
import CustomTypography from '../../custom/typography/CustomTypography';
import { eventsNames, AnalyticsEvent } from '../../../firebase/firebaseAnalytics';


import {
    parseJwt,
    setLoader,
    isFormValid,
    getQueryParams,
    verifyPassword,
    stateChangeManager,
} from '../../../utils/Utils';

import {
    setToken,
    setUserDetails,
} from '../../../config/cookiesInfo';

import {
    Box,
    Link,
    Grid,
    Button,
    Divider,
    Typography,
    IconButton,
    InputAdornment,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function SignUp() {
    const navigate = useNavigate();

    const location = useLocation();
    const params = getQueryParams(location.search);
    const dispatch = Actions.getDispatch(React.useContext);
    const [isFormSubmitted, setFormSubmitted] = React.useState(false);
    const [parseJwtData, setParseJwtData] = React.useState(false);

    React.useEffect(() => {
        if (params.token) {
            setParseJwtData(parseJwt(params.token))
        }
    }, []);

    const sign_up_initialData = {
        password: null,
        token: params.token,
        confirm_password: null,
        email: parseJwtData.email,
        last_name: parseJwtData.last_name,
        first_name: parseJwtData.first_name,
        name: parseJwtData.first_name + ' ' + parseJwtData.last_name,
    }

    const [data, setData] = React.useState({ ...sign_up_initialData })
    React.useEffect(() => {
        if (parseJwtData) {
            setData({
                ...data,
                token: params.token,
                email: parseJwtData.email,
                // last_name: parseJwtData.last_name,
                // first_name: parseJwtData.first_name,
                // name: parseJwtData.first_name + ' ' + parseJwtData.last_name,
            })
        }
    }, [parseJwtData]);

    const handleSubmit = async () => {
        setFormSubmitted(true)

        let validation_data = [
            { key: "first_name", message: 'First name field can not be empty' },
            { key: "last_name", message: 'Last name field can not be empty' },
            { key: "password", message: 'Create password field can not be empty' },
            { key: "confirm_password", message: 'Confirm password field can not be empty' },
            { key: "", validation: data.password !== data.confirm_password, message: 'Enter password not match!' },
            { key: "", validation: !verifyPassword(data.password), message: 'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character, and be at least 8 characters long.' },
        ]
        const { isValid, message } = isFormValid(data, validation_data);
        if (isValid) {
            AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: eventsNames.actions.userAuthentication.action.ACCOUNT_CREATION,method:eventsNames.actions.userAuthentication.method.EMAIL});

            setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'post',
                url: userSignUp(),
                data: { ...data, name: data.first_name + ' ' + data.last_name },
            })
            if (res?.success) {
                setUserDetails(res?.data.user);
                setToken(res?.data?.access_token);
                setLoader(dispatch, Actions, false);
                navigate("/auth" + routesName.businessCreate.path);
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                setLoader(dispatch, Actions, false)
                stateChangeManager(dispatch, Actions, true, 'error', res?.status)
            }
        } else {
            setLoader(dispatch, Actions, false)
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    };
    const [isShow, setIsShow] = React.useState({
        is_password: false,
        is_confirmed_password: false
    })
    const onPasswordVisibility = (data) => {
        if (data === 'is_password') {
            isShow.is_password = !isShow.is_password;
        } else {
            isShow.is_confirmed_password = !isShow.is_confirmed_password;
        }
        setIsShow({ ...isShow })
    }

    return (
        params.token ?
            <div>
                <Box sx={{ display: "flex" }}>
                    <Box className='m_login_left_box' sx={{ backgroundColor: '#F7F7F7', minHeight: '100%', width: '28%' }}>
                        <Box
                            sx={{
                                pt: 8,
                                minHeight: "100vh",
                                minWidth: '100vh',
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                }}
                            >
                                {/* <Img sx={{ pt: 1.5, ml: 30 }} alt={images.signup.alt} src={images.signup.src} /> */}
                            </Box>
                        </Box>

                    </Box>
                    <Box sx={{ height: '100%', width: '100%' }}>
                        <Box
                            sx={{
                                pt: 8,
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "center",
                                    flexDirection: 'column',
                                }}>
                                <Box component="form" noValidate>
                                    <CustomTypography
                                        text={'Welcome to Finycs!'}
                                        sx={{
                                            color: '#000000',
                                            fontSize: '30px',
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            fontStyle: 'normal',
                                            lineHeight: '40.86px',
                                            fontFamily: "Noto Sans",
                                        }}
                                    />
                                    <CustomTypography
                                        text={<span>You're signing up as {data.email}</span>}
                                        sx={{
                                            opacity: 0.8,
                                            color: '#000000',
                                            fontSize: '18px',
                                            fontWeight: '500',
                                            textAlign: 'center',
                                            fontStyle: 'normal',
                                            lineHeight: '40.86px',
                                            fontFamily: "Noto Sans",
                                        }}
                                    />

                                    <Box sx={{ mt: 2, }} >
                                        <Input
                                            autoFocus={true}
                                            id='input_first_name'
                                            dataTestId='input_first_name'
                                            item={{
                                                type: 'text',
                                                required: true,
                                                fullWidth: true,
                                                disabled: false,
                                                placeholder: 'Please Enter your first name',
                                                validation: isFormSubmitted && !data.first_name,
                                                title: <LabelWithAsteriskMark label={'First Name'} />,
                                                value: data.first_name ? data.first_name : '',
                                                onChange: (e) => setData({ ...data, first_name: e.target.value })
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ mt: 2, }} >
                                        <Input
                                            id='input_last_name'
                                            dataTestId='input_last_name'
                                            item={{
                                                type: 'text',
                                                required: true,
                                                fullWidth: true,
                                                disabled: false,
                                                placeholder: 'Please Enter your last name',
                                                value: data.last_name ? data.last_name : '',
                                                validation: isFormSubmitted && !data.last_name,
                                                title: <LabelWithAsteriskMark label={'Last Name'} />,
                                                onChange: (e) => setData({ ...data, last_name: e.target.value })
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ mt: 2, }}>
                                        <Input
                                            id='input_password'
                                            isKeyPressEnter={false}
                                            dataTestId='input_password'
                                            item={{
                                                required: true,
                                                fullWidth: true,
                                                placeholder: '',
                                                value: data.password ? data.password : '',
                                                type: isShow.is_password ? 'text' : 'password',
                                                title: <LabelWithAsteriskMark label={'Create Password'} />,
                                                validation: isFormSubmitted && !verifyPassword(data.password),
                                                onChange: (e) => setData((previous) => ({ ...previous, password: e.target.value })),
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                                                    <IconButton edge="end" size='small' sx={{ ml: .5, color: '#2464EB', cursor: 'pointer' }}
                                                        onClick={(event) => { event.preventDefault(); onPasswordVisibility('is_password') }} id='show_password_icon' dataTestId='show_password_icon'>
                                                        {
                                                            isShow.is_password ?
                                                                <VisibilityIcon fontSize='small' />
                                                                :
                                                                <VisibilityOffIcon fontSize='small' />
                                                        }
                                                    </IconButton>
                                                </InputAdornment>

                                            }}
                                        />
                                        <span style={{ fontSize: 12, opacity: 0.8 }}>Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character, and be at least 8 characters long.</span>

                                    </Box>
                                    <Box sx={{ mt: 2, }}>
                                        <Input
                                            isKeyPressEnter={false}
                                            id='input_confirm_password'
                                            dataTestId='input_confirm_password'
                                            item={{
                                                required: true,
                                                fullWidth: true,
                                                placeholder: '',
                                                type: isShow.is_confirmed_password ? 'text' : 'password',
                                                title: <LabelWithAsteriskMark label={'Confirm Password'} />,
                                                value: data.confirm_password ? data.confirm_password : '',
                                                validation: isFormSubmitted && !verifyPassword(data.confirm_password),
                                                onChange: (e) => setData({ ...data, confirm_password: e.target.value }),
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                                                    <IconButton edge="end" size='small' sx={{ ml: .5, color: '#2464EB', cursor: 'pointer' }}
                                                        onClick={(event) => { event.preventDefault(); onPasswordVisibility('is_confirmed_password') }} id='show_confirmed_password_icon' dataTestId='show_confirmed_password_icon'>
                                                        {
                                                            isShow.is_confirmed_password ?
                                                                <VisibilityIcon fontSize='small' />
                                                                :
                                                                <VisibilityOffIcon fontSize='small' />
                                                        }
                                                    </IconButton>
                                                </InputAdornment>

                                            }}
                                        />
                                    </Box>

                                    <Button
                                        fullWidth
                                        id='continue_btn'
                                        variant="contained"
                                        dataTestId='continue_btn'
                                        onClick={() => handleSubmit()}
                                        sx={{ mt: 4, mb: 2, textTransform: 'none' }}
                                    >Continue</Button>

                                    <Grid container spacing={2}>
                                        {/* <Grid item xs>
                                            <Link href={"/auth" + routesName.forgotPassword.path} variant="body2">
                                                Forgot password?
                                            </Link>
                                        </Grid> */}
                                        <Grid item sx={{ textAlign: 'end' }} sm={12}>
                                            <Link id='sign_in_link' dataTestId='sign_in_link' href={"/auth" + routesName.signIn.path} variant="body2">
                                                Already have an account? Sign in
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
            :
            <VerifyEmail />
    );
}


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link id='copy_right_link' dataTestId='copy_right_link' color="inherit" href="https://numbers.artdexandcognoscis.com/">
                www.numbers.artdexandcognoscis.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
