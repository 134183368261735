import * as React from 'react';
import validator from 'validator';
import Input from '../../common/Input';
import SocialLogin from './SocialLogin';
import { apiAction } from '../../../api/api';
import { useNavigate } from 'react-router-dom';
import * as Actions from '../../../state/Actions';
import { routesName } from '../../../config/routesName';
import { send_verify_registration_mail_view } from '../../../api/urls';
import CustomTypography from '../../custom/typography/CustomTypography';
import { eventsNames, AnalyticsEvent } from '../../../firebase/firebaseAnalytics';


import {
    setLoader,
    isFormValid,
    stateChangeManager,
} from '../../../utils/Utils';


import {
    Box,
    Link,
    Grid,
    Button,
    Divider,
    Checkbox,
    Typography,
} from '@mui/material';



const VerifyEmail = () => {
    const navigate = useNavigate();

    const dispatch = Actions.getDispatch(React.useContext);

    const sign_up_initialData = {
        email: null,
        last_name: null,
        first_name: null,
    }

    const [agree, setAgree] = React.useState(true);
    const [isFormSubmitted, setFormSubmitted] = React.useState(false);
    const [data, setData] = React.useState({ ...sign_up_initialData });
    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormSubmitted(true)

        let validation_data = [
            // { key: "first_name", message: 'First Name field can not be empty' },
            // { key: "last_name", message: 'Last Name field can not be empty' },
            { key: "email", message: 'Email field can not be empty' },
            { key: '', validation: (!data.email || !validator.isEmail(data.email)), message: 'Please enter valid email.' },
            { key: "", validation: !agree, message: "Please accept the terms and conditions." },
        ]
        const { isValid, message } = isFormValid(data, validation_data);
        if (isValid) {
            AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: eventsNames.actions.userAuthentication.action.EMAIL_VERIFICATION, method: eventsNames.actions.userAuthentication.method.EMAIL });

            setLoader(dispatch, Actions, true)
            let res = await apiAction({
                data: { ...data, is_privacy_policy_and_terms_accepted: agree },
                method: 'post',
                url: send_verify_registration_mail_view(),
            })
            if (res?.success) {
                setLoader(dispatch, Actions, false);
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
                navigate("/auth" + routesName.openEmailAccount.path, { state: { data: data, message: `Registration link send successfully.Please check your mail to complete registration.` } });
            } else {
                setLoader(dispatch, Actions, false);
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    };
    return (
        <Box sx={{ display: "flex" }} >
            <Box className='m_login_left_box' sx={{ backgroundColor: '#F7F7F7', minHeight: '100%', width: '28%' }}>
                <Box
                    sx={{
                        pt: 8,
                        minHeight: "100vh",
                        minWidth: '100vh',
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: "center",
                        }}
                    >
                        {/* <Img sx={{ pt: 1.5, ml: 30 }} alt={images.signup.alt} src={images.signup.src} /> */}
                    </Box>
                </Box>

            </Box>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Box
                    sx={{
                        pt: 8,
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: "center",
                            flexDirection: 'column',
                        }} >
                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <CustomTypography
                                text={'Sign up'}
                                sx={{
                                    mb: 4,
                                    color: '#000000',
                                    fontSize: '30px',
                                    textAlign: 'start',
                                    fontWeight: '700',
                                    fontStyle: 'normal',
                                    lineHeight: '40.86px',
                                    fontFamily: "Noto Sans",
                                }}
                            />

                            <Grid container spacing={2}>
                            </Grid>
                            <Box sx={{ mt: 2, }}>
                                <Input
                                    id={'input_email_address'}
                                    dataTestId={'input_email_address'}
                                    autoFocus={true}
                                    item={{
                                        type: 'text',
                                        required: true,
                                        fullWidth: true,
                                        title: 'Email Address',
                                        value: data.email ? data.email : '',
                                        placeholder: 'Enter Your Email Address',
                                        onChange: (e) => setData({ ...data, email: e.target.value }),
                                        validation: isFormSubmitted && (!data.email || !validator.isEmail(data.email)),
                                    }}
                                />
                            </Box>
                            <CustomTypography
                                text={<span><Checkbox size="small" sx={{ ml: -1.5, mt: -.01 }} checked={agree} onClick={() => { setAgree(!agree) }} /><span>By sign up, I agree to the finycs <Link href={routesName.privacyPolicy.path} variant="body2" target="_blank" rel="noreferrer">Privacy Policy</Link> and <Link href={routesName.termsAndServices.path} variant="body2" target="_blank" rel="noreferrer">Terms and Services</Link>.</span></span>}
                                sx={{
                                    mb: 2,
                                    mt: 1,
                                    color: '#000000',
                                    fontSize: '14px',
                                    textAlign: 'start',
                                    fontWeight: '500',
                                    fontStyle: 'normal',
                                    lineHeight: '40.86px',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            <Button
                                fullWidth
                                type="submit"
                                id={'sign_up_btn'}
                                variant="contained"
                                dataTestId={'sign_up_btn'}
                                sx={{ mt: 4, mb: 2, textTransform: 'none' }}
                            >Sign up</Button>

                            <Divider>
                                <CustomTypography
                                    text={'OR'}
                                    sx={{
                                        color: '#000000',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                        textTransform: 'capitalize',
                                    }}
                                />
                            </Divider>

                            <SocialLogin 
                            btnText='Sign up with Google'
                            AnalyticsEvent={()=>{AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: eventsNames.actions.SIGN_UP, method: eventsNames.actions.userAuthentication.method.GOOGLE });}}
                             />

                            <Grid container spacing={2}>
                                {/* <Grid item xs>
                                    <Link href={"/auth" + routesName.forgotPassword.path} variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid> */}
                                <Grid item sx={{ textAlign: 'end' }} sm={12}>
                                    <Link id='sign_in_link' dataTestId='sign_in_link' href={"/auth" + routesName.signIn.path} variant="body2">
                                        Already have an account? Sign In
                                    </Link>
                                </Grid>
                            </Grid>
                            {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
                        </Box>

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default VerifyEmail;

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://numbers.artdexandcognoscis.com/">
                www.numbers.artdexandcognoscis.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}