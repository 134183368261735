import React from 'react';
import moment from 'moment';
import { apiAction } from '../../../../api/api';
import ReportsTitle from '../common/ReportsTitle';
import * as Actions from '../../../../state/Actions';
import { getDateFormat } from '../../../../utils/Utils';
import { routesName } from '../../../../config/routesName';
import ReportsSettingButtons from '../ReportsSettingButtons';
import { date_range, setLoader } from '../../../../utils/Utils';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CommonCustomDateRange from '../../../common/CommonCustomDateRange';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { CustomContainer } from '../../../custom/container/CustomContainer';
import { get_trial_balance, export_trial_balance } from '../../../../api/urls';

import {
  Box,
  Grid,
} from '@mui/material';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../custom/table/CustomTable';
import { CustomLink } from '../../../custom/link/CustomLink';
import CurrencyFormatter from '../../../common/CurrencyFormatter';


const TrialBalance = () => {

  const stateAction = Actions.getState(React.useContext);
  const dispatch = Actions.getDispatch(React.useContext);

  const { selectedDateRange } = stateAction;
  let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
  const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

  const [dateRange, setDateRange] = React.useState(default_date_range)
  const [fromDate, setFromDate] = React.useState(moment())
  const [toDate, setToDate] = React.useState(moment())
  const [results, setResults] = React.useState([])
  const [date, setDate] = React.useState()


  const getApiResults = async (from_date, to_date, range_date) => {
    setLoader(dispatch, Actions, true)
    setDate(range_date)
    let res = await apiAction({
      method: 'post',
      url: get_trial_balance(),
      data: {
        business_id: getBusinessInfo().id,
        from_date: from_date,
        to_date: to_date
      },
    })
    if (res?.success) {
      // console.log('res', groupBy(res?.result,'account_type'))
      setResults(groupBy(res?.result, 'account_type'))
      setLoader(dispatch, Actions, false)
    } else {
    }
  }

  const groupBy = (data, key) => {
    let unique_keys = new Set(data.map(function (element) {
      return element[key];
    }));
    let result = [];
    for (const element of unique_keys) {
      let filtered_result = data.filter((value) => {
        return value[key] === element;
      });

      let total_net_movement_debit = 0;
      let total_net_movement_credit = 0;
      filtered_result.forEach(x => {
        if (x['net_movement_type'] === "CREDIT") {
          total_net_movement_credit += x['net_movement'];
        } else {
          total_net_movement_debit += x['net_movement'];
        }
      });
      result.push({ "account_type": element, "total_net_movement_credit": total_net_movement_credit, "total_net_movement_debit": total_net_movement_debit, "transactions": filtered_result, });
    }
    return result
  }

  React.useEffect(() => {
    if (dateRange) {
      if (dateRange.name !== "Custom") {
        getApiResults(dateRange.dates.from_date, dateRange.dates.to_date, dateRange.dates.date_range)
      } else {
        getApiResults(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), `${toDate.format(getDateFormat())}`)
      }
    }
    // eslint-disable-next-line
  }, [dateRange, toDate, fromDate])

  return (
    <div>
      <ReportsSettingButtons

        scheduleData={{
          report_name: 'trial_balance',
          report_title: 'Trial Balance',

        }}

        exportData={{ url: export_trial_balance(), data: dateRange.dates ? dateRange.dates : { to_date: toDate.format('YYYY-MM-DD'), from_date: fromDate.format('YYYY-MM-DD') } }}
      />
      <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
        <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Grid container spacing={0} sx={{ pb: 2 }} style={{
            alignItems: 'center',
            justifyItems: 'center',
            alignContent: 'space-evenly',
            justifyContent: 'space-evenly',
          }}>
            <Grid item xs={12} sm={4}>
              <ReportsTitle
                date={date}
                dateText={'As Of '}
                title={'Trial Balance'}
              />
            </Grid>
            <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
              <CommonCustomDateRange
                setPage={() => { }}
                date_range={date_range()}

                toDate={toDate}
                setToDate={setToDate}

                fromDate={fromDate}
                setFromDate={setFromDate}

                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </Grid>
          </Grid>
        </div>
        <CustomTableContainer>
          <CustomTable>
            <TrialBalanceHeader />
            <TrialBalanceBody
              data={results}
              dateRange={dateRange}
              toDate={toDate.format('YYYY-MM-DD')}
              fromDate={fromDate.format('YYYY-MM-DD')}
            />

            <caption style={{ fontFamily: 'Noto Sans', fontWeight: '500', fontSize: '10px', fontStyle: 'normal', lineHeight: '14px' }}>**Amount is displayed in your base currency <span style={{ backgroundColor: '#6ff795', paddingLeft: '5px', paddingRight: '5px', paddingBottom: '2px', paddingTop: '1px' }}>{getBusinessInfo().currency.currency_code}</span></caption>
          </CustomTable>
        </CustomTableContainer>
      </CustomContainer>
    </div>
  )
}

export default TrialBalance;

const TrialBalanceHeader = () => {
  return (
    <CustomTableHead>
      <CustomTableRow >
        <CustomTableHeadCell><span>Account</span></CustomTableHeadCell>
        <CustomTableHeadCell align='right'><span>Net Debit</span></CustomTableHeadCell>
        <CustomTableHeadCell align='right'><span>Net Credit</span></CustomTableHeadCell>
      </CustomTableRow>
    </CustomTableHead>
  )
}

const TrialBalanceBody = (props) => {
  const { data, toDate, fromDate, dateRange, } = props

  return (
    <CustomTableBody>
      {
        data.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <CustomTypography
                text={item.account_type.capitalize()}
                sx={{
                  pl: 2,
                  mt: 1,
                  color: '#000000',
                  fontSize: '14px',
                  fontWeight: '700',
                  lineHeight: '18px',
                  fontStyle: 'normal',
                  fontFamily: "Noto Sans",
                }}
              />
              {
                item.transactions.map((item, index) => {
                  return (
                    <CustomTableRow key={index}>
                      <CustomTableBodyCell><span>
                        <CustomLink
                          to={routesName.trialBalanceTransaction.path+'?id='+item.business_account_id}
                          state={{ dateRange: dateRange, toDate: toDate, fromDate: fromDate }}
                        >{item.business_account_name}</CustomLink>
                      </span>
                      </CustomTableBodyCell>
                      <CustomTableBodyCell align='right'>
                        {item.net_movement_type === "DEBIT" ?
                          <CurrencyFormatter amount={item.net_movement} currency={null} />
                          : '-'
                        }
                      </CustomTableBodyCell>
                      <CustomTableBodyCell align='right'>
                        {item.net_movement_type === "CREDIT" ?
                          <CurrencyFormatter amount={item.net_movement} currency={null} />
                          : '-'
                        }
                      </CustomTableBodyCell>
                    </CustomTableRow>
                  )
                })
              }
            </React.Fragment>
          )
        })
      }
      <CustomTableRow sx={{ background: '#FFF7DC', borderTop: '1px solid #FFF0C0', borderBottom: '1px solid #FFF0C0' }}>
        <CustomTableBodyCell>
          <CustomTypography
            text={'Total'}
            sx={{
              color: '#000000',
              fontSize: '14px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              textTransform: 'none',
              fontFamily: "Noto Sans",
            }}
          />
        </CustomTableBodyCell>
        <CustomTableBodyCell align='right'>
          <CurrencyFormatter amount={data.reduce((acc, item) => acc + item.total_net_movement_debit, 0).toFixed(2)} />
        </CustomTableBodyCell>
        <CustomTableBodyCell align='right'>
          <CurrencyFormatter amount={data.reduce((acc, item) => acc + item.total_net_movement_credit, 0).toFixed(2)} />
        </CustomTableBodyCell>
      </CustomTableRow>
    </CustomTableBody>
  )
}