import React from 'react';
import moment from 'moment';
import Input from '../../../common/Input';
import { apiAction } from '../../../../api/api';
import DateRange from '../../../common/DateRange';
import DatePicker from '../../../common/DatePicker';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import { genderTypeChoice } from '../../../../utils/Constant';
import CustomButton from '../../../custom/button/CustomButton';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';

import {
  CustomContainer,
  CustomTitleContainer,
  CustomButtonContainer,
} from '../../../custom/container/CustomContainer';

import {
  setUserDetails,
  getBusinessInfo,
} from '../../../../config/cookiesInfo';

import {
  setLoader,
  isFormValid,
  stateChangeManager,
  validateCityOrPostalCode
} from '../../../../utils/Utils';

import {
  get_countries,
  get_list_of_state,
  update_user_details,
} from '../../../../api/urls';

import {
  Box,
  Grid,
} from '@mui/material';

const Dropdown = DateRange;

const UpdateUserProfile = (props) => {
  const { result, onCancel } = props
  const dispatch = Actions.getDispatch(React.useContext);

  const initial_item_data = {
    name: result ? result.name : null,
    city: result ? result.city : null,
    gender: result ? result.gender : null,
    zip_code: result ? result.zip_code : null,
    last_name: result ? result.last_name : null,
    first_name: result ? result.first_name : null,
    date_of_birth: result ? result.date_of_birth : null,
    mobile_number: result ? result.mobile_number : null,
    state_id: result && result.state ? result.state.id : null,
    country_id: result && result.country ? result.country.id : null,
  }

  const [data, setData] = React.useState({ ...initial_item_data })
  const [isFormSubmitted, setFormSubmitted] = React.useState(false);

  const onSave = async () => {
    console.log('===>data',data)

    setFormSubmitted(true);
    let validation_data = [
      { key: "first_name", message: 'First name field can not be empty' },
      { key: "last_name", message: 'Last name field can not be empty' },
      // { key: "mobile_number", message: 'Mobile number field can not be empty' },
      // { key: '', validation: mobile_number && !(mobile_number && isValidPhoneNumber(mobile_number)), message: 'Please Enter Valid Mobile Number' },
      // { key: '', validation: !isNaN(zip_code) && zip_code && !(zip_code && validator.isLength(String(zip_code), { min: 6, max: 6 })), message: 'Please Enter Valid Area Pin Code.' },
    ]
    const { isValid, message } = isFormValid(data, validation_data)
    if (isValid) {
      setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: update_user_details(),
        data: { ...data, name: data.first_name + ' ' + data.last_name },
      })
      if (res?.success) {
        onCancel();
        setUserDetails(res?.result);
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, 'success', res?.status);

      } else {
        setLoader(dispatch, Actions, false)
        stateChangeManager(dispatch, Actions, true, 'error', res?.status)
      }
    } else {
      setLoader(dispatch, Actions, false)
      stateChangeManager(dispatch, Actions, true, 'error', message);
    }
  }
  return (
    <div>
      <CustomTitleContainer sx={{ pt: 1.2, pb: 1.25 }}>
        <CustomTitle title={'Edit Profile'} />
      </CustomTitleContainer>

      <CustomContainer maxWidth={"400"} sx={{height: 'calc(100vh - 202px)',}}>
        <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '16px', }}>

          <UserInfo
            data={data}
            result={result}
            setData={setData}
            isFormSubmitted={isFormSubmitted}
          />
        </div>

      </CustomContainer>
      
      <CustomButtonContainer>
        <Buttons onSave={() => { onSave() }} onCancel={() => onCancel()} />
      </CustomButtonContainer>
    </div>
  )
}

export default UpdateUserProfile;

const UserInfo = (props) => {
  const { data, setData, isFormSubmitted } = props;

  const onChange = (key_name, value) => {
    setData({ ...data, [key_name]: value })
  }

  const [countries, setCountries] = React.useState([])
  React.useEffect(() => {
    const getCountries = async () => {
      let res = await apiAction({
        method: 'get',
        url: get_countries(),
      })
      if (res?.success) {
        setCountries(res?.result)
      } else {

      }
    }
    getCountries()
    // eslint-disable-next-line
  }, [])

  const [states, setStates] = React.useState([]);
  React.useEffect(() => {
    const getStates = async (country_id) => {
      let res = await apiAction({
        method: 'post',
        url: get_list_of_state(),
        data: { business_id: getBusinessInfo().id,country_id:country_id }
      })
      if (res?.success) {
        setStates(res?.result.sort((a, b) => -b.name.localeCompare(a.name)))

        let selected=res?.result.find((item) => item.id === data.state_id);
        setData((previous)=>({ ...previous, state_id: selected ? selected.id : null }))
      } else {

      }
    }
    if (data.country_id) {
      getStates(data.country_id)
    }else{
      setStates([]);
    }
    // eslint-disable-next-line
  }, [data.country_id]);

  return (
    <Box sx={{ pt: 1 }}>
      <Grid container item xs={8} spacing={3}>
        <Grid item xs={6}>
          <Input
            autoFocus={true}
            id={'input_first_name'}
            dataTestId={'input_first_name'}
            item={{
              type: 'text',
              fullWidth: true,
              placeholder: 'Enter First Name',
              value: data.first_name ? data.first_name : '',
              validation: isFormSubmitted && !data.first_name,
              title: <LabelWithAsteriskMark label={'First Name'} />,
              onChange: (e) => { onChange('first_name', e.target.value !== '' ? e.target.value : null) }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            id={'input_last_name'}
            dataTestId={'input_last_name'}
            item={{
              type: 'text',
              fullWidth: true,
              placeholder: 'Enter Last Name',
              value: data.last_name ? data.last_name : '',
              validation: isFormSubmitted && !data.last_name,
              title: <LabelWithAsteriskMark label={'Last Name'} />,
              onChange: (e) => { onChange('last_name', e.target.value !== '' ? e.target.value : null) }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            id={'gender_dropdown'}
            dataTestId={'gender_dropdown'}
            label='Select Gender'
            results={genderTypeChoice}
            placeholder='Select the Gender'
            setValue={(event, selected) => { onChange('gender', selected ? selected.value : null) }}
            value={data.gender ? genderTypeChoice.find((item) => item.value === data.gender) : null}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id={'dob_data_select'}
            dataTestId={'dob_data_select'}
            no_minDate={true}
            maxDate={moment()}
            title={'Date of Birth'}
            date={data.date_of_birth ? moment(data.date_of_birth) : null}
            setDate={(date) => { onChange('date_of_birth', date ? date.format('YYYY-MM-DD') : null) }}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <Input
            id={'input_email'}
            dataTestId={'input_email'}
            item={{
              type: 'text',
              disabled: true,
              fullWidth: true,
              value: result ? result.email : '',
              title: <LabelWithAsteriskMark label={'Email'} />,
            }}
          />
        </Grid> */}
        {/* <Grid item xs={6}>
          <Input
            id={'input_mobile_number'}
            dataTestId={'input_mobile_number'}
            item={{
              type: 'text',
              fullWidth: true,
              // inputProps: { maxLength: 10 },
              placeholder: 'Enter Mobile Number',
              value: data.mobile_number ? data.mobile_number : '',
              title: <LabelWithAsteriskMark label={'Mobile Number'} />,
              // onChange: (e) => { onChange("mobile_number", isNumber(e.target.value) ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) : null) },
              // validation: isFormSubmitted && data.mobile_number && !(data.mobile_number && validator.isLength(String(data.mobile_number), { min: 10, max: 10 })),

              onChange: (e) => { onChange("mobile_number",e.target.value)},
              validation: isFormSubmitted && data.mobile_number && !(data.mobile_number &&isValidPhoneNumber(data.mobile_number)),

            }}
            onKeyPress={(event) => {
              if (event?.key === 'e' || event?.key === 'E') {
                event.preventDefault();
              }
            }}
          />
        </Grid> */}

        <Grid item xs={6}>
          <Dropdown
            id={'country_dropdown'}
            dataTestId={'country_dropdown'}
            results={countries}
            label='Select Country'
            placeholder='Select the Country'
            value={data.country_id ? countries.find((item) => item.id === data.country_id) : null}
            setValue={(event, selected) => { onChange('country_id', selected ? selected.id : null) }}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            id={'state_dropdown'}
            dataTestId={'state_dropdown'}

            results={states}
            label='Select State'
            placeholder='Select the State'
            value={data.state_id ? states.find((item) => item.id === data.state_id) : null}
            setValue={(event, selected) => { onChange('state_id', selected ? selected.id : null) }}
          />
        </Grid>

        <Grid item xs={6}>
          <Input
            id={'input_city'}
            dataTestId={'input_city'}

            item={{
              type: 'text',
              fullWidth: true,
              title: 'City/Town',
              placeholder: 'Enter City/Town',
              value: data.city ? data.city : '',
              onChange: (e) => {
                const re = /^[a-zA-Z\u0080-\u024F]+(?:. |-| |')*([1-9a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  onChange('city', e.target.value !== '' ? e.target.value : null)
                }

              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            id={'input_pin_code'}
            dataTestId={'input_pin_code'}
            item={{
              type: 'text',
              fullWidth: true,
              title: 'Pin Code',
              placeholder: 'Enter Pin Code',
              value: data.zip_code ? data.zip_code : '',
              // validation: isFormSubmitted && data.zip_code && validateCityOrPostalCode(data.zip_code),
              // onChange: (e) => { onChange('zip_code', isNumber(e.target.value) ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 6) : null) },
              onChange: (e) => {
                if (e.target.value === "" || validateCityOrPostalCode(e.target.value)) {
                  onChange('zip_code', e.target.value !== '' ? e.target.value : null)
                }
              }
            }}

            // onKeyPress={(event) => {
            //   if (event?.key === '-' || event?.key === '+' || event?.key === 'e' || event?.key === 'E') {
            //     event.preventDefault();
            //   }
            // }}
          />
        </Grid>

      </Grid>
    </Box>
  )
}

const Buttons = (props) => {
  const { onSave, onCancel } = props
  return (
    <Box component="form" noValidate  >
      <Grid container sx={{ p: 1 }}>
        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', mr: 1, '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
          <CustomButton id={'save_btn'} dataTestId={'save_btn'} variant='contained' sx={{ textTransform: 'none', }} btnLabel={'Save'} onClick={onSave} />
        </Grid>
      </Grid>
    </Box>
  )
}