import React from 'react';
import DateRange from '../../common/DateRange';
import { useNavigate } from 'react-router-dom';
import CustomTitle from '../../common/CustomTitle';
import { reports_list } from '../../../utils/Constant';
import CustomTypography from '../../custom/typography/CustomTypography';
import { eventsNames,AnalyticsEvent } from '../../../firebase/firebaseAnalytics'; 

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../custom/container/CustomContainer';

import {
    Box,
    Grid,
} from '@mui/material';

const Dropdown = DateRange;
const ReportsList = () => {
    const navigate = useNavigate();

    const getFlatListData = (data = [], result = []) => {
        for (const section of data) {
            const { title, item } = section;
            for (const itemData of item) {
                const newItemData = { ...itemData, parent_account_name: title };
                result.push(newItemData);
            }
        }
        return result;
    }

    const analyticsEventActions=(reportType)=>{
        AnalyticsEvent(eventsNames.categories.REPORT,{action:eventsNames.actions.VIEW, type:eventsNames.actions.reports[reportType]})

    }
    return (
        <div>
            <CustomTitleContainer sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <CustomTitle title={'Reports'} />
                <Box sx={{ width: 300 }}>
                    <Dropdown
                        value={null}
                        placeholder='Search reports'
                        id={'reports_search_dropdown'}
                        dataTestId={'reports_search_dropdown'}
                        results={getFlatListData(reports_list)}
                        setValue={(event, value) => {
                            if (value) {
                                navigate(value.path);
                                analyticsEventActions(value.eventName)
                            }
                        }}
                    />
                </Box>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 138px)', maxHeight:{xs:'calc(100vh - 190px)',sm:'100%'},background: '#FAFAFA' }}>
                <Grid container spacing={3}>
                    {
                        reports_list.map((item, index) => {
                            return (
                                <Grid key={index} item xs={12} sm={6} sx={{}}>
                                    <ReportsBlock
                                        data={item}
                                        id={'action_item_' + index}
                                        dataTestId={'action_item_' + index}
                                        analyticsEventActions={(reportType)=>analyticsEventActions(reportType)}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </CustomContainer>
        </div>
    )
}

export default ReportsList;
const ReportsBlock = (props) => {
    const { data, id, dataTestId,analyticsEventActions } = props;
    const navigate = useNavigate();

    return (
        <Box sx={{ width: '100%', height: '100%', background: '#FFFFFF', boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.04)' }}>
            <Box sx={{ p: 2 }}>
                <CustomTypography
                    text={data.title}
                    sx={{
                        mb: 1,
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                    }}
                />

                {
                    data.item.map((item, index) => {
                        return (
                            <Box key={index} sx={{ pb: 0.5, pl: 0.5 }}>
                                <CustomTypography
                                    id={id + '_clicked_' + index}
                                    dataTestId={dataTestId + '_clicked_' + index}
                                    text={item.name}
                                    sx={{
                                        color: '#2464EB',
                                        display: 'inline',
                                        fontSize: '12px',
                                        cursor: 'pointer',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                        ":hover": { textDecoration: 'underline' }
                                    }}
                                    onClick={() => { 
                                        navigate(item.path)
                                        analyticsEventActions(item.eventName)
                                     }}
                                />
                            </Box>
                        )
                    })
                }

            </Box>
        </Box>
    )
}