import React from 'react'
import moment from 'moment';
import { Grid, Box, Divider } from '@mui/material';
import DateRange from '../../../../common/DateRange';
import AsyncDropdown from '../../../../common/AsyncDropdown';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { get_chart_of_account_nested_nodes } from '../../../../../api/urls';
import CustomDateRangePicker from '../../../../custom/Datepicker/CustomDateRangePicker';
import { ApplyDefaultFilterCheckBox, DownloadButtons, DownloadRadioButtons } from '../../../../common/CommonDownloadLayout';

const Dropdown = DateRange;
const DownloadJournalEntry = (props) => {
    const { setOpen, url, defaultFilters } = props;

    const [isDefaultFiltersApplied, setIsDefaultFiltersApplied] = React.useState(false);
    const [filters, setFilters] = React.useState({
        "export_to": "csv",
        "business_id": getBusinessInfo().id,
        "source": null,
        "is_transaction_account_id" : false
    });
    const [timePeriod, setTimePeriod] = React.useState("all");

    const journalEntrySourceList = [
        { name: 'All', value: null },
        { name: 'Manual', value: 'manual' },
    ]

    React.useEffect(() => {
        if (isDefaultFiltersApplied) {
            const from_datetime = defaultFilters?.filter_start_date ? moment(defaultFilters?.filter_start_date)?.format("YYYY/MM/DD HH:mm:ss") : null;
            const to_datetime = defaultFilters?.filter_end_date ? moment(defaultFilters?.filter_end_date)?.format("YYYY/MM/DD HH:mm:ss") : null;
            setFilters({
                ...filters,
                to_datetime: to_datetime,
                from_datetime: from_datetime,
                dates: defaultFilters?.dates,
                account_id: defaultFilters?.transaction_account_id ? defaultFilters?.transaction_account_id : null,
                selectedAccount: defaultFilters?.selectedAccount ? defaultFilters?.selectedAccount : null,
            })
            if (defaultFilters?.filter_start_date) {
                setTimePeriod("specificTimePeriod");
            } else if (!defaultFilters?.filter_start_date) {
                setTimePeriod("all");
            }
        } else {
            setFilters({ ...filters })
        }
    }, [isDefaultFiltersApplied])

    React.useEffect(() => {
        if (timePeriod === "all") {
            setFilters({ ...filters, from_datetime: null, to_datetime: null, dates: [] })
        }
        if (defaultFilters?.filter_start_date && timePeriod === "all") {
            setIsDefaultFiltersApplied(false);
        }
    }, [timePeriod])

    const onDateRangeChange = (dates) => {
        if (dates) {
            setFilters({
                ...filters,
                from_datetime: dates[0].format("YYYY/MM/DD HH:mm:ss"),
                to_datetime: dates[1].format("YYYY/MM/DD HH:mm:ss"),
                dates: dates
            })
            if (isDefaultFiltersApplied) {
                setIsDefaultFiltersApplied(false);
            }
        } else {
            delete filters?.to_datetime
            delete filters?.from_datetime
            filters.dates = null
            setFilters({ ...filters })
            setIsDefaultFiltersApplied(false);
        }
    }

    return (
        <Box>
            <Grid container spacing={1}>
                <ApplyDefaultFilterCheckBox
                    isDefaultFiltersApplied={isDefaultFiltersApplied}
                    setIsDefaultFiltersApplied={setIsDefaultFiltersApplied}
                />
                <Grid item container xs={11} spacing={1} sx={{ mb: 2 }}>
                    <Grid item xs={10} sm={6}>
                        <AsyncDropdown
                            fullWidth={true}
                            autoFocus={false}
                            validation={false}
                            disabledCloseIcon={false}
                            isGroupHeaderSticky={false}
                            id={'account_dropdown'}
                            dataTestId={'account_dropdown'}

                            title='Account'
                            valueLabelKey='id'
                            uniqueOptionKey='id'
                            searchKey='account_name'
                            optionLabelKey='account_name'
                            placeholder='Select the Account'
                            optionGroupLabelKey='parent_account_name'
                            URL={get_chart_of_account_nested_nodes(1)}

                            selectedValue={filters.selectedAccount}
                            setSelectedValue={(value) => {
                                if (value) {
                                    setFilters({ ...filters, account_id: value.id, selectedAccount: value })
                                    isDefaultFiltersApplied && setIsDefaultFiltersApplied(false);
                                } else {
                                    delete filters.account_id
                                    delete filters.selectedAccount
                                    setFilters({ ...filters });
                                    setIsDefaultFiltersApplied(false);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={10} sm={6}>
                        <Dropdown
                            id={'journalEntrySOurce_dropdown'}
                            dataTestId={'journalEntrySOurce_dropdown'}
                            label="Journal Entry Source"
                            placeholder='Select Source'
                            results={journalEntrySourceList}
                            value={journalEntrySourceList.find((item) => item?.value === filters?.source)}
                            setValue={(event, value) => {
                                if (value) {
                                    filters['source'] = value.value;
                                } else {
                                    delete filters?.source
                                }
                                setFilters({ ...filters })
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={15} sm={12} sx={{ mb: 2 }}>
                    <DownloadRadioButtons
                        timePeriod={timePeriod}
                        setTimePeriod={setTimePeriod}
                        allButtonLabel={"All Journal Entries"}
                    />
                    {timePeriod === "specificTimePeriod" &&
                        <CustomDateRangePicker dates={filters?.dates} onDateRangeChange={onDateRangeChange} />
                    }
                </Grid>
            </Grid>

            <Divider />
            <DownloadButtons
                onClose={setOpen}
                url={url}
                filters={filters}
                setOpen={setOpen}
                timePeriod={timePeriod}
            />
        </Box>
    )
}

export default DownloadJournalEntry;
