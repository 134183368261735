import React, { useState } from 'react';
import * as Custom from '../Custom/Custom';
import backgroundImage from '../assets/svg/BG.svg';
import userImage from '../../../../../assets/image/profile.png';
import { ReactComponent as StarIcon } from '../assets/svg/star.svg';
import { ReactComponent as RightArrowIcon } from '../assets/svg/right-arrow.svg';
// import { ReactComponent as LeftArrowIcon } from '../assets/svg/left-arrow.svg';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import {
    Box,
    Stack
} from '@mui/material';

const Reviews = () => {
    const reviewContent = [
        {
            message: "Application has helped us reduce time spent on creating journal entries, their rule based entry creation is very simple to use and saves time",
            name: "Swathi R",
            place: "",
        },
        {
            message: "Finycs has helped us receive right information at right time to decide on matters related to business, we very rarely used to focus on financial indicators so much before we had this software. We are now loving this new discipline of keeping an eye on the fundamental ratios of our company.",
            name: "Tejas Londhe - Business Owner",
            place: "",
        }
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNextReview = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % reviewContent.length);
    };

    const handlePreviousReview = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? reviewContent.length - 1 : prevIndex - 1
        );
    };

    return (
        <Custom.BackgroundImageContainer sx={styles.bannerContainer}>
            <Custom.Container>
                <Box sx={styles.reviewWrapper}>
                    <ReviewItem
                        review={reviewContent[currentIndex]}
                        onNext={handleNextReview}
                        onPrev={handlePreviousReview}
                    />
                </Box>
            </Custom.Container>
        </Custom.BackgroundImageContainer>
    );
};

const ReviewItem = ({ review, onNext, onPrev }) => (
    <Box sx={styles.reviewItemContainer}>
        <LeftArrow onPrev={onPrev} />
        <UserImage />
        <ReviewContent review={review} />
        <RightArrow onNext={onNext} />
    </Box>
);

const UserImage = () => (
    <Box sx={styles.imageContainer}>
        <Box component="img" sx={styles.userImage} src={userImage} alt="User" />
    </Box>
);

const ReviewContent = ({ review }) => (
    <Box sx={styles.reviewContent}>
        <ReviewStars />
        <CustomTypography text={review.message} sx={styles.reviewText} />
        <CustomTypography text={review.name} sx={styles.reviewerName} />
        {review.place && (
            <CustomTypography text={review.place} sx={styles.reviewerLocation} />
        )}
    </Box>
);

const ReviewStars = () => (
    <Stack direction="row" spacing={1} sx={{justifyContent:{xs:'center',sm:'start'}}}>
        {[...Array(5)].map((_, index) => (
            <StarIcon key={index} />
        ))}
    </Stack>
);

const LeftArrow = ({ onPrev }) => (
    <Box sx={styles.arrowContainer} onClick={onPrev} role="button" tabIndex={0} aria-label="Previous Review">
        {/* <LeftArrowIcon /> */}
    </Box>
);

const RightArrow = ({ onNext }) => (
    <Box sx={styles.arrowContainer} onClick={onNext} role="button" tabIndex={0} aria-label="Next Review">
        <RightArrowIcon />
    </Box>
);

export default Reviews;

// Define reusable styles
const styles = {
    bannerContainer: {
        width: '100%',
        display: 'flex',
        minHeight: '360px',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundImage: `url(${backgroundImage})`,
    },
    reviewWrapper: {
        p: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    reviewItemContainer: {
        width: '100%',
        display: 'flex',
        maxWidth: '1200px',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
    },
    imageContainer: {
        mr: 3,
        flexShrink: 0,
    },
    userImage: {
        objectFit: 'cover',
        borderRadius: '50%',
        width: { xs: '65px', sm: '164px' },
        height: { xs: '65px', sm: '164px' },
    },
    reviewContent: {
        flexGrow: 0.7,
        color: '#FFF',
    },
    reviewText: {
        mt: 2,
        color: '#FFF',
        fontWeight: 700,
        lineHeight: '32px',
        letterSpacing: '0px',
        textAlign:{xs:'center',sm:'start'},
        fontSize: { xs: '16px', sm: '24px' },
    },
    reviewerName: {
        mt: 2,
        mb: 2,
        color: '#FFF',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0px',
        textAlign:{xs:'center',sm:'start'},
    },
    reviewerLocation: {
        mt: 1,
        color: '#FFF',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0px',
    },
    arrowContainer: {
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer', // Add pointer to indicate it's clickable
        mx: 2, // Add some margin to separate arrows from content
    },
};
