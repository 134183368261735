import React from 'react';
import * as Custom from '../Custom/Custom';
import CustomTypography from '../../../../custom/typography/CustomTypography';

import {
    Box,
    Divider,
} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const Footer = () => {
    return (
        <Custom.Container>
            <Box sx={{
                p: 2, pt: 6, pb: 6,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Box sx={{flexGrow:1, maxWidth: '1200px' }}>
                    <Box sx={{ display: 'flex', pb: 4, flexWrap: 'wrap' }}>
                        <Box sx={{ flexGrow: 0.2, }}>
                            <ContactList />
                        </Box>
                        <Box sx={{ flexGrow: 0.8, }}>
                            <FeatureListItem />
                        </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ pt: 2 }}>
                        <ContactDetails />
                    </Box>
                </Box>
            </Box>
        </Custom.Container>
    )
}

export default Footer;


const ContactList = () => {
    return (
        <React.Fragment>
            <CustomTypography
                text={<strong>{'Finycs'}</strong>}
                sx={{
                    pb: 3,
                    fontWeight: 700,
                    color: '#141414',
                    fontSize: '24px',
                    lineHeight: 'auto',
                    letterSpacing: '0px',
                }}
            />
            <CustomText
                text={<span>903, Vashi Infotech Park,<br />Sector 30A, Vashi, Navi Mumbai.<br />Maharashtra 400703.</span>}
            />
        </React.Fragment>
    )
}

const FeatureListItem = () => {
    const list = [
        {
            text1: 'Dashboard',
            text2: 'Invoice',
        },
        {
            text1: 'Expenses',
            text2: 'Accounting',
        },
        {
            text1: 'Reports',
            text2: 'GST',
        },
        {
            text1: 'Inventory',
            text2: 'Tally Integration',
        },
    ]
    return (
        <React.Fragment>
            <CustomTypography
                text={'Features'}
                sx={{
                    pb: 3,
                    pt: { xs: 3, sm: 0 },
                    fontWeight: 700,
                    color: '#141414',
                    fontSize: '20px',
                    lineHeight: 'auto',
                    letterSpacing: '0px',
                }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', }}>
                {
                    list?.map((item, index) => {
                        return (
                            <Box key={index}>
                                <CustomText xs={{ mb: 2 }} text={<span style={{ display: 'flex', alignItems: 'center' }}><KeyboardArrowRightIcon sx={{ color: '#141414' }} /><span>{item.text1}</span></span>} />
                                <CustomText xs={{ mb: 2 }} text={<span style={{ display: 'flex', alignItems: 'center' }}><KeyboardArrowRightIcon sx={{ color: '#141414' }} /><span>{item.text2}</span></span>} />
                            </Box>
                        )
                    })
                }
            </Box>
        </React.Fragment>
    )
}

const ContactDetails = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <CustomText xs={{ lineHeight: '0px', pr: { xs: 0, sm: 2 } }} text={<span style={{ display: 'flex', alignItems: 'center' }}><MailIcon sx={{ pr: 0.5, fontSize: '18px', color: '#141414' }} /><span>{'contact@artdexandcognoscis.com'}</span></span>} />
            <Divider orientation="vertical" flexItem sx={{ visibility: { xs: 'hidden', sm: 'visible' } }} />
            <CustomText xs={{ pt: { xs: 2, sm: 0 }, lineHeight: '0px', pl: { xs: 0, sm: 2 } }} text={<span style={{ display: 'flex', alignItems: 'center' }}><PhoneIcon sx={{ pr: 0.5, fontSize: '18px', color: '#141414' }} /><span>{'+91 22 49742883'}</span></span>} />
        </Box>
    )
}


const CustomText = ({ text, xs }) => {
    return (
        <CustomTypography
            text={text}
            sx={{
                opacity: 0.7,
                fontWeight: 400,
                color: '#141414',
                fontSize: '16px',
                lineHeight: '26px',
                letterSpacing: '0px',
                ...xs,
            }}
        />
    )
}