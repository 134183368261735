import {
    Box,
    Container,
} from '@mui/material';
import {
    styled,
} from '@mui/material';


export const CustomContainer = styled((props) =>
    <Container {...props} />
)(({ theme }) => ({
    padding: '0px',
    minWidth:'100%',
    overflow: 'scroll',
    overflowX: 'hidden',
    background: '#FFFFFF',
    height: 'calc(100vh - 191px)',
    paddingLeft: '0px !important',
    paddingTop: '16px !important',
    paddingRight: '0px !important',
    maxWidth: 'calc(100vw - 270px)',
    boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.04)',
}));


export const CustomTitleContainer = styled(Box)({
    minHeight:'34px',
    paddingTop: '8px ',
    marginBottom: '8px',
    marginLeft: '-16px',
    paddingLeft: '16px',
    paddingBottom: '8px',
    paddingRight: '16px',
    marginRight: '-16px',
    borderBottom: '2px solid #F2F2F2',
});
export const CustomButtonContainer = styled((props)=><Box {...props}/>)(({ theme }) => ({
    padding: '16px',
    marginTop: '8px',
    paddingTop: '10px',
    marginRight: '-16px',
    marginLeft: '-16px',
    paddingBottom: '0px',
    // marginBottom: '-8px',
    maxHeight: '50px !important',
    borderTop: '2px solid #F2F2F2',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

