import React from 'react';
import moment from 'moment';
import { apiAction } from '../../../../../api/api';
import * as Actions from '../../../../../state/Actions';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesName } from '../../../../../config/routesName';
import { CustomLink } from '../../../../custom/link/CustomLink';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import ReportsSettingButtons from '../../../reports/ReportsSettingButtons';
import CommonCustomDateRange from '../../../../common/CommonCustomDateRange';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import { generate_journal_entry_backup_file, getSingleGeneralLedger } from '../../../../../api/urls';

import {
    setLoader,
    date_range,
    getDateFormat,
    getQueryParams,
    stateChangeManager,
    convertSlugToString,
    breadCrumbsStateChangeManager,
} from '../../../../../utils/Utils';
import {
    Box,
    Grid,
} from '@mui/material';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';

import CustomButton from '../../../../custom/button/CustomButton';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { CustomContainer } from '../../../../custom/container/CustomContainer';
import { ReactComponent as CalendarIcon } from '../../../../../assets/svg/calendar.svg';


const GeneralLedger = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const params = getQueryParams(location.search);
    let url = location.pathname;


    const stateAction = Actions.getState(React.useContext);
    const dispatch = Actions.getDispatch(React.useContext);

    const { selectedDateRange } = stateAction;
    let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
    const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

    const [dateRange, setDateRange] = React.useState(default_date_range)
    const [fromDate, setFromDate] = React.useState(moment())
    const [toDate, setToDate] = React.useState(moment())
    const [account_value, setAccount_Value] = React.useState(null)
    const [results, setResults] = React.useState([])
    const [date, setDate] = React.useState()
    const [id, setId] = React.useState(params.id)
    const [disabledDataExport, setDisabledDataExport] = React.useState(false);

    const [page, setPage] = React.useState(1);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    })

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    });

    React.useEffect(() => {
        if (location.state) {
            setDateRange(location.state.dateRange)
            setToDate(moment(location.state.toDate))
            setFromDate(moment(location.state.fromDate))
        }
    }, [location])


    const getApiResults = async (id, from_date, to_date, range_date) => {
        setLoader(dispatch, Actions, true)
        setDate(range_date)
        setDisabledDataExport(false);

        let res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            url: getSingleGeneralLedger(id, page),
            data: {
                business_id: getBusinessInfo().id,
                from_date: from_date,
                to_date: to_date
            },
        })

        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.entries.next,
                count: res?.result.entries.count,
                previous: res?.result.entries.previous,
                number_of_pages: res?.result.entries.number_of_pages,
            })
            setResults(res?.result);
            setLoader(dispatch, Actions, false);
            if (res?.result?.entries?.count === 0) { setDisabledDataExport(true) } else { setDisabledDataExport(false) }
            breadCrumbsStateChangeManager(dispatch, res?.result.business_account_name);
            setAccount_Value({ account_name: res?.result.business_account_name });
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    const onDownloadDataClick = async () => {
        let res = await apiAction({
            method: "post",
            url: generate_journal_entry_backup_file(),
            data: {
                export_to: "csv",
                business_id: getBusinessInfo().id,
                is_transaction_account_id: true,
                from_datetime: dateRange.name !== "Custom" ? moment(dateRange.dates.from_date).format("YYYY/MM/DD HH:mm:ss") : fromDate.format('YYYY/MM/DD HH:mm:ss'),
                to_datetime: dateRange.name !== "Custom" ? moment(dateRange.dates.to_date).format("YYYY/MM/DD HH:mm:ss") : toDate.format('YYYY/MM/DD HH:mm:ss'),
                account_id: id,
                source: null,
                is_posted: true,
            }
        })
        if (res?.success) {
            stateChangeManager(dispatch, Actions, true, 'success', res?.status)
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status)
        }
    }

    React.useEffect(() => {
        if (params.id) {
            if (dateRange.name !== "Custom") {
                getApiResults(id, dateRange.dates.from_date, dateRange.dates.to_date, dateRange.dates.date_range)
            } else {
                getApiResults(id, fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), `${fromDate.format(getDateFormat())} - ${toDate.format(getDateFormat())}`)
            }
        }
        // eslint-disable-next-line
    }, [id, dateRange, toDate, fromDate, page])

    const viewHandler = (id) => {
        setState({
            id: id,
            open: true,
            maxWidth: 'sm',
            fullWidth: true,
            condition: 'view',
            title: 'View Journal Entry',
        })
    }

    ///////////////////////////////////////////////Api Call for the dropdown of accounts////////////////////////////////////////////////////////////////
    const onAccountChangeHandler = (value) => {
        setAccount_Value(value)
        if (value) {
            setId(value.id)
        } else {
            navigate(routesName.generalLedgerList.path, {
                state: { dateRange: dateRange, toDate: toDate.format('YYYY-MM-DD'), fromDate: fromDate.format('YYYY-MM-DD') }
            })
        }
    }

    let isFromReport = location.pathname.includes('reports');

    return (
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />
            {isFromReport && <ReportsSettingButtons />}
            <CustomContainer maxWidth={"400"} sx={{ height: isFromReport ? 'calc(100vh - 190px)' : 'calc(100vh - 120px)' }}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                    <Grid container spacing={0} sx={{ pb: 2 }} style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        justifyContent: 'space-evenly',
                        alignContent: 'space-evenly',
                    }}>
                        <Grid item xs={12} sm={4} >
                            <GeneralTitle date={date} />
                        </Grid>
                        <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end' }} >
                            <CommonCustomDateRange
                                setPage={setPage}
                                date_range={date_range()}

                                toDate={toDate}
                                setToDate={setToDate}

                                fromDate={fromDate}
                                setFromDate={setFromDate}

                                dateRange={dateRange}
                                setDateRange={setDateRange}

                                isAccountDropdown={true}
                                account_value={account_value}
                                onAccountChangeHandler={onAccountChangeHandler}
                            />
                        </Grid>
                    </Grid>
                    <Grid sx={{ pb: 2 }} justifyContent='flex-end' width='100%' display='flex' >
                        <CustomButton
                            variant="contained"
                            btnLabel='Download CSV'
                            id={'download_ledger_btn'}
                            disabled={disabledDataExport}
                            dataTestId={'download_ledger_btn'}
                            onClick={() => {
                                onDownloadDataClick();
                            }}
                        />
                    </Grid>
                </div>
                <CustomTableContainer>
                    <CustomTable>
                        <GeneralHeader />
                        <AccountsHeader
                            id={results.business_account_id}
                            net_movement={results.net_movement}
                            title={results.business_account_name}
                            subTitle={results.parent_account_name}
                            account_number={results.business_account_number}
                            type={results.net_movement_type === "DEBIT" ? ' ( Dr. )' : ' ( Cr. )'}
                        />
                    </CustomTable>
                    <Entries
                        url={url}
                        page={page}
                        setPage={setPage}
                        dateRange={dateRange}
                        data={results.entries}
                        pagination={pagination}
                        viewHandler={viewHandler}
                        toDate={toDate.format('YYYY-MM-DD')}
                        fromDate={fromDate.format('YYYY-MM-DD')}
                    />
                </CustomTableContainer>
            </CustomContainer>
            <CustomPagination
                page={page}
                count={pagination.number_of_pages}
                onChange={(event, newValue) => setPage(newValue)}
            />
        </div>
    )
}


const GeneralTitle = (props) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            <CustomTypography
                text={getBusinessInfo().legal_name}
                sx={{
                    mb: 1,
                    color: '#A3A3A3',
                    fontSize: '12px',
                    fontWeight: '800',
                    lineHeight: '16px',
                    fontStyle: 'normal',
                    letterSpacing: '0.5px',
                    fontFamily: "Noto Sans",
                    textTransform: 'uppercase',
                }}
            />
            <CustomTypography
                text={'General Ledger'}
                sx={{
                    color: '#141414',
                    fontSize: '20px',
                    fontWeight: '700',
                    lineHeight: '27px',
                    fontStyle: 'normal',
                    textTransform: 'none',
                    fontFamily: "Noto Sans",
                }}
            />
            <CustomTypography
                text={'Account Transaction'}
                sx={{
                    mb: 1,
                    color: '#141414',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '27px',
                    fontStyle: 'normal',
                    textTransform: 'none',
                    fontFamily: "Noto Sans",
                }}
            />

            <CustomTypography
                text={<span><CalendarIcon />{props.date}</span>}
                sx={{
                    mb: 1,
                    color: '#A3A3A3',
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '16px',
                    fontStyle: 'normal',
                    textTransform: 'none',
                    fontFamily: "Noto Sans",
                }}
            />
        </Box>
    )
}

const GeneralHeader = (props) => {
    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell><span>Account</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Account Code</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Net Movement</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const AccountsHeader = (props) => {
    const {
        title,
        subTitle,
        net_movement,
        account_number,
    } = props

    return (
        <CustomTableBody>
            <CustomTableRow>
                <CustomTableBodyCell >
                    <CustomTypography
                        text={title}
                        sx={{
                            mb: .5,
                            fontSize: '12px',
                            color: '#000000',
                            fontWeight: '700',
                            lineHeight: '16px',
                            fontStyle: 'normal',
                            textDecoration: 'none',
                            fontFamily: 'Noto Sans',
                        }}
                    />
                    <CustomTypography
                        text={subTitle}
                        sx={{
                            fontSize: '10px',
                            color: '#A3A3A3',
                            fontWeight: '500',
                            lineHeight: '14px',
                            fontStyle: 'normal',
                            textDecoration: 'none',
                            fontFamily: 'Noto Sans',
                        }}
                    />
                </CustomTableBodyCell>
                <CustomTableBodyCell align='right'>{account_number}</CustomTableBodyCell>
                <CustomTableBodyCell align='right'>
                    <CurrencyFormatter amount={net_movement} />{props.type}
                </CustomTableBodyCell>
            </CustomTableRow>
        </CustomTableBody>
    )
}

const Entries = (props) => {
    const { data, toDate, fromDate, dateRange, url, pagination, page, setPage, viewHandler } = props
    return (
        <div>
            <CustomTable  >
                <CustomTableHead>
                    <CustomTableRow >
                        <CustomTableHeadCell style={{ width: 50 }}><span>Sr. No.</span></CustomTableHeadCell>
                        <CustomTableHeadCell><span>Date</span></CustomTableHeadCell>
                        <CustomTableHeadCell><span>Description</span></CustomTableHeadCell>
                        <CustomTableHeadCell><span>Transaction Type</span></CustomTableHeadCell>
                        <CustomTableHeadCell align='right'><span>Debit</span></CustomTableHeadCell>
                        <CustomTableHeadCell align='right'><span>Credit</span></CustomTableHeadCell>
                    </CustomTableRow>
                </CustomTableHead>

                <CustomTableBody>
                    {
                        data.result && data.result.map((item, index) => {
                            return (
                                <CustomTableRow key={index}>
                                    <CustomTableBodyCell sx={{}} ><span>{(index + 1)}</span></CustomTableBodyCell>
                                    <CustomTableBodyCell sx={{}} ><span>{moment(item.transaction_date).utc().format(getDateFormat())}</span></CustomTableBodyCell>
                                    <CustomTableBodyCell sx={{}} >
                                        <CustomTypography
                                            id={'viewHandler_' + index}
                                            dataTestId={'viewHandler_' + index}

                                            text={item.description}
                                            onClick={() => viewHandler(item.journal_entry_id)}
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                display: 'inline',
                                                color: '#2464EB',
                                                cursor: 'pointer',
                                                lineHeight: '16px',
                                                fontStyle: 'normal',
                                                textDecoration: 'none',
                                                fontFamily: 'Noto Sans',
                                                ":hover": { textDecoration: 'underline' },
                                            }}
                                        />
                                        {
                                            item.related_accounts && item.related_accounts.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <span>
                                                            <CustomLink
                                                                to={url + item.business_account_id}
                                                                state={{ dateRange: dateRange, toDate: toDate, fromDate: fromDate }}
                                                            >{item.business_account}</CustomLink>
                                                        </span><br />

                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </CustomTableBodyCell>
                                    <CustomTableBodyCell sx={{}}><span>{convertSlugToString(item.transaction_source, '_').capitalize()}</span></CustomTableBodyCell>
                                    <CustomTableBodyCell sx={{}} align='right'>
                                        <CustomTypography
                                            text={item.transaction_type === "DEBIT" ? <CurrencyFormatter amount={item.amount} currency={item.currency_code} /> : '-'}
                                            sx={{
                                                color: '#000000',
                                                fontWeight: '700',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                lineHeight: '14px',
                                                fontFamily: 'Noto Sans',
                                            }}
                                        />

                                    </CustomTableBodyCell>
                                    <CustomTableBodyCell sx={{}} align='right'>
                                        <CustomTypography
                                            text={item.transaction_type === "CREDIT" ? <CurrencyFormatter amount={item.amount} currency={item.currency_code} /> : '-'}
                                            sx={{
                                                color: '#000000',
                                                fontWeight: '700',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                lineHeight: '14px',
                                                fontFamily: 'Noto Sans',
                                            }}
                                        />
                                    </CustomTableBodyCell>
                                </CustomTableRow>
                            )
                        })
                    }
                </CustomTableBody>
            </CustomTable>

        </div>
    )
}


export default GeneralLedger