import React from "react";
import { isNumeric } from "../../../../../utils/Utils";

export const splitExpression = (expression) => {
  // Define a regular expression to match operators and other delimiters
  const operatorRegex = /[+\-*/(){}\[\]<>[\r\n]/g;

  // Split the expression based on operators and spaces
  const parts = expression && expression.split(operatorRegex).map((item) => item.trim().split(/\s+/));

  // Flatten the array and filter out empty strings and strings containing only whitespace
  const flattenedParts = parts && parts.flat().filter((item) => item !== '' && item.trim() !== '');

  return flattenedParts;
};


// Function to generate random data within a specified range
export const generateRandomData = (min = 1, max = 100) => {
  return Math.random() * (max - min) + min;
}

export const generateRandomDataForAccounts = (accounts = []) => {
  const data = {};
  let min = 1000;
  let max = 5000;
  for (const account of accounts) {
    // Generate random data for the account within its range
    data[account] = generateRandomData(min, max);
  }
  return data;
}

export const validateAndEvaluateEquation = (equation, data) => {

  // Split the equation into components
  const equationComponents = splitExpression(equation);
  // console.log('===>equationComponents',equationComponents)

  // Check if all components are present in the data object
  const allComponentsInData = equationComponents.every((component) => data[component] !== undefined);
  // console.log('===>allComponentsInData',allComponentsInData)

  if (allComponentsInData) {
    // console.log('===>data',data)
    // console.log('===>equation',equation)

    // Replace components with their values and evaluate the expression

    const evaluatedExpression = equation.replace(/\w+/g, (match) => {
      // console.log('===>match',match)
      const value = data[match.trim()];
      return isNaN(value) ? match : value;
    });

    // console.log('===>evaluatedExpression',evaluatedExpression)
    try {
      const result = eval(evaluatedExpression);
      return result;
    } catch (error) {
      return "Error in evaluating the expression";
    }
  } else {
    return "Not all components in the equation are present in the data object.";
  }
};

export const isEquationValid = (equation) => {
  const randomData = generateRandomDataForAccounts(splitExpression(equation));

  const validationResult = validateAndEvaluateEquation(equation, randomData)
  if (typeof validationResult === "number") {
    // console.log("Result: " + validationResult);
    return true;
  } else {
    // console.log("error: " + validationResult);
    return false;
  }
}

export function areObjectsEqual(obj1, obj2) {
  // Implement your own logic to compare objects here
  // This example assumes that the objects are equal if their stringified versions are the same.
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function getUniqueObjects(list) {
  const uniqueObjects = [];

  for (const obj of list) {
    if (obj) {
      let isUnique = true;
      for (const uniqueObj of uniqueObjects) {
        if (areObjectsEqual(obj, uniqueObj)) {
          isUnique = false;
          break;
        }
      }
      if (isUnique) {
        if (obj.account_type === 'composite') {
          obj.name = `${obj.name.replace(' - Composite', '')} - Composite`
        }
        uniqueObjects.push(obj);
      }
    }
  }

  return uniqueObjects;
}

export const getFinalEquation = (equation, accounts = []) => {
  // Loop through the accounts and replace names with slug_names in the equation
  // console.log('====>getFinalEquation_equation',equation)
  // console.log('====>getFinalEquation_accounts',accounts)
  for (const account of accounts) {
    if (account) {
      const regex = new RegExp(account.name, 'g');
      equation = equation.replace(regex, account.slug_name);
    }
  }

  return equation;
}

export const getEquation = (equation, accounts = []) => {
  // Loop through the accounts and replace names with slug_names in the equation
  // console.log('====>getEquation_equation',equation)
  // console.log('====>getEquation_accounts',accounts)
  for (const account of accounts) {
    if (account) {
      if (account.account_type === 'composite') {
        account.name = `${account.name.replace(' - Composite', '')} - Composite`
      }
      const regex = new RegExp(account.slug_name, 'g');
      equation = equation.replace(regex, account.name);
    }
  }

  return equation;
}

export function getNextCopy(inputString) {
  // Check if the input string already contains "copy"
  if (inputString.toLowerCase().includes('copy')) {
    const regex = / copy (\d+)$/i;
    const matches = inputString.match(regex);

    if (matches && matches[1]) {
      const currentCopyNumber = parseInt(matches[1]);
      const nextCopyNumber = currentCopyNumber + 1;
      return `${inputString.replace(regex, '')} Copy ${nextCopyNumber}`;
    } else {
      return `${inputString} 1`;
    }
  } else {
    return `${inputString} - Copy`;
  }
}

export function findIndexAndCompare(array, itemToFind) {
  // Find the index of the item in the array
  const index = array.indexOf(itemToFind);
  // Compare the index with the length of the array
  if (index === array.length - 2) {
    return true;
  }
  return false;
}

export function findCommonWords(sentence1, sentence2) {
  // Split the sentences into words
  const words1 = sentence1 && sentence1.split(' ');
  const words2 = sentence2 && sentence2.split(' ');

  // Create sets for each sentence
  const set1 = new Set(words1);
  const set2 = new Set(words2);

  // Find the intersection of the sets
  const commonWords = [...set1].filter(word => set2.has(word));

  return commonWords;
}

export const accountNotInSelectedAccount = (equation, array2 = []) => {
  let array1 = splitExpression(equation) ? [...splitExpression(equation)] : []
  const notPresentItems = array1.filter(item1 => {
    if (isNumeric(item1)) {
      return false; // Exclude numbers
    } else {
      return !array2?.some(item2 => item2.slug_name === item1);
    }
  });
  return notPresentItems
}

// Function to compare two arrays
export const isEqual = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
};

// Use to create the equation based on the textAreaValue
export const replaceStringWithSlug = (equation, accounts = []) => {
  for (const account of accounts) {
    if (account) {
      // Escape square brackets in the regular expression pattern
      const escapedName = account.name.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      const regex = new RegExp(`@\\[${escapedName}]\\(${account.id}\\)`, 'g');
      equation = equation.replace(regex, account.slug_name);
    }
  }

  return equation;
}


// Use to create the textAreaValue based on the equation
export const replaceSlugWithString = (equation, accounts = []) => {
  for (const account of accounts) {
    if (account) {
      if (account.account_type === 'composite') {
        account.name = `${account.name.replace(' - Composite', '')} - Composite`
      }
      const regex = new RegExp(`\\b${account.slug_name}\\b`, 'g');
      equation = equation.replace(regex, `@[${account.name}](${account.id})`);
    }
  }

  return equation;
}

export function cleanString(inputString) {
  // Remove leading symbols, spaces, and numbers
  let cleanedString = inputString.trim();
  cleanedString = cleanedString.replace(/^[^\w\s]+|\d+/g, '');

  // Remove trailing symbols, spaces, and numbers
  cleanedString = cleanedString.replace(/[^\w\s]+|\d+$/g, '');

  // Remove extra spaces between words
  cleanedString = cleanedString.replace(/\s+/g, ' ');

  return cleanedString.trim(); // Trim any remaining leading or trailing spaces
}


export const useForceRerender = () => {
  const [, setValue] = React.useState(0); // we don't need the value of this state.
  return () => setValue(value => value + 1);
};

export const useIsElementVisible = (target, options = undefined) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const forceUpdate = useForceRerender();

  React.useEffect(() => {
    forceUpdate(); // to ensure that ref.current is attached to the DOM element
  }, []);

  React.useEffect(() => {
    if (!target) return;

    const observer = new IntersectionObserver(handleVisibilityChange, options);
    observer.observe(target);

    return () => observer.unobserve(target);
  }, [target, options]);

  const handleVisibilityChange = ([entry]) =>
    setIsVisible(entry.isIntersecting);

  return isVisible;
};

