import React from 'react'
import moment from 'moment';
import { Grid, Box, Divider } from '@mui/material';
import DateRange from '../../../../common/DateRange';
import { list_vendor } from '../../../../../api/urls';
import { purchaseOrderStatus } from '../../../../../utils/Constant';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CommonAsyncDropdown from '../../../../common/CommonAsyncDropdown';
import CustomDateRangePicker from '../../../../custom/Datepicker/CustomDateRangePicker';
import { ApplyDefaultFilterCheckBox, DownloadButtons, DownloadRadioButtons } from '../../../../common/CommonDownloadLayout';

const Dropdown = DateRange;
const DownloadPurchaseOrderData = (props) => {
    const { setOpen, url, defaultFilters } = props;

    const [isDefaultFiltersApplied, setIsDefaultFiltersApplied] = React.useState(false);
    const [filters, setFilters] = React.useState({
        "export_to": "csv",
        "business_id": getBusinessInfo().id,
    });
    const [timePeriod, setTimePeriod] = React.useState("all");

    React.useEffect(() => {
        if (isDefaultFiltersApplied) {
            const from_datetime = defaultFilters?.purchase_order_start_date ? moment(defaultFilters?.purchase_order_start_date)?.format("YYYY/MM/DD HH:mm:ss") : null;
            const to_datetime = defaultFilters?.purchase_order_end_date ? moment(defaultFilters?.purchase_order_end_date)?.format("YYYY/MM/DD HH:mm:ss") : null;
            setFilters({
                ...filters,
                to_datetime: to_datetime,
                from_datetime: from_datetime,
                dates: defaultFilters?.dates,
                status: defaultFilters?.status ? defaultFilters?.status : null,
                vendor_id: defaultFilters?.vendor_id ? defaultFilters?.vendor_id : null,
                selectedVendor: defaultFilters?.selectedVendor ? defaultFilters?.selectedVendor : null,
            })
            if (defaultFilters?.purchase_order_start_date) {
                setTimePeriod("specificTimePeriod");
            } else if (!defaultFilters?.purchase_order_start_date) {
                setTimePeriod("all");
            }
        } else {
            setFilters({ ...filters })
        }
    }, [isDefaultFiltersApplied])


    React.useEffect(() => {
        if (timePeriod === "all") {
            setFilters({ ...filters, from_datetime: null, to_datetime: null, dates: [] })
        }
        if (defaultFilters?.purchase_order_start_date && timePeriod === "all") {
            setIsDefaultFiltersApplied(false);
        }
    }, [timePeriod])

    const onDateRangeChange = (dates) => {
        if (dates) {
            setFilters({
                ...filters,
                from_datetime: dates[0].format("YYYY/MM/DD HH:mm:ss"),
                to_datetime: dates[1].format("YYYY/MM/DD HH:mm:ss"),
                dates: dates
            })
            if (isDefaultFiltersApplied) {
                setIsDefaultFiltersApplied(false);
            }
        } else {
            delete filters?.to_datetime
            delete filters?.from_datetime
            filters.dates = null
            setFilters({ ...filters })
            setIsDefaultFiltersApplied(false);
        }
    }

    return (
        <Box>
            <Grid container spacing={1}>
                <ApplyDefaultFilterCheckBox
                    isDefaultFiltersApplied={isDefaultFiltersApplied}
                    setIsDefaultFiltersApplied={setIsDefaultFiltersApplied}
                />
                <Grid item container xs={11} spacing={1} sx={{ mb: 2 }}>
                    <Grid item xs={10} sm={6}>
                        <Dropdown
                            id={'download_status_dropdown'}
                            dataTestId={'download_status_dropdown'}
                            label="Status"
                            placeholder='Select Status'
                            results={purchaseOrderStatus}
                            value={purchaseOrderStatus.find((item) => item?.value === filters?.status)}
                            setValue={(event, value) => {
                                if (value) {
                                    filters['status'] = value.value;
                                    if (isDefaultFiltersApplied) {
                                        setIsDefaultFiltersApplied(false);
                                    }
                                } else {
                                    delete filters?.status;
                                    setIsDefaultFiltersApplied(false);
                                }
                                setFilters({ ...filters })
                            }}
                        />
                    </Grid>
                    <Grid item xs={10} sm={6}>
                        <CommonAsyncDropdown
                            id={'download_vendor_dropdown'}
                            dataTestId={'download_vendor_dropdown'}
                            autoSelect={false}
                            disableClearable={false}
                            optionLabel="display_name"
                            placeholder='Select Vendor'
                            noOptionsText={"No result found"}
                            // options={[{ name: "All Customers", id: null }]}
                            item={{
                                method: 'post',
                                label: 'Vendors',
                                value: filters?.selectedVendor ? filters?.selectedVendor : null,
                                url: list_vendor(1),
                                body: { is_inactive: false, business_id: getBusinessInfo().id, role: 'vendor' },
                                onChange: (event, value) => {
                                    if (value) {
                                        setFilters({ ...filters, vendor_id: value.id, selectedVendor: value })
                                        isDefaultFiltersApplied && setIsDefaultFiltersApplied(false);
                                    } else {
                                        delete filters.vendor_id
                                        delete filters.selectedVendor
                                        setFilters({ ...filters })
                                        setIsDefaultFiltersApplied(false);
                                    }
                                },
                            }}
                            addButton={false}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={15} sm={12} sx={{ mb: 2 }}>
                    <DownloadRadioButtons
                        timePeriod={timePeriod}
                        setTimePeriod={setTimePeriod}
                        allButtonLabel={"All Purchase Orders"}
                    />
                    {timePeriod === "specificTimePeriod" &&
                        <CustomDateRangePicker dates={filters?.dates} onDateRangeChange={onDateRangeChange} />
                    }
                </Grid>
            </Grid>

            <Divider />
            <DownloadButtons
                onClose={setOpen}
                url={url}
                filters={filters}
                setOpen={setOpen}
                timePeriod={timePeriod}
            />
        </Box>
    )
}

export default DownloadPurchaseOrderData;