import React from 'react';
import Input from '../../../common/Input';
import {useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '../../../custom/button/CustomButton';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { eventsNames,AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';

import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import { routesName } from '../../../../config/routesName'; 
import { update_password_with_otp } from '../../../../api/urls';

import {
    setLoader,
    isFormValid,
    verifyPassword,
    stateChangeManager,
} from '../../../../utils/Utils';

import {
    Box,
    Link,
    Grid,
    Divider,
    IconButton,
    InputAdornment,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;

    const dispatch = Actions.getDispatch(React.useContext);

    const sign_up_initialData = {
        email: null,
        new_password: null,
        confirm_password: null,
    }

    const [isFormSubmitted, setFormSubmitted] = React.useState(false);
    const [data, setData] = React.useState({ ...sign_up_initialData });

    React.useEffect(() => {
        if (state?.data) {
            setData((prev)=>({...prev,...state?.data}));
        }
    }, [state])

    const handleSubmit = async (event) => {
        event.preventDefault();

        setFormSubmitted(true)

        let validation_data = [
            { key: "new_password", message: 'New password field can not be empty' },
            { key: "confirm_password", message: 'Confirm password field can not be empty' },
            { key: "", validation: data.new_password !== data.confirm_password, message: 'New password and confirm password does not match' },
            { key: "", validation: !verifyPassword(data.new_password), message: 'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character, and be at least 8 characters long.' },

        ]
        const { isValid, message } = isFormValid(data, validation_data)
        if (isValid) {
            AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: eventsNames.actions.RESET_PASSWORD, state: 'email_sent' });
            setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'post',
                data: { ...data },
                url: update_password_with_otp(),
            })
            if (res?.success) {
                setLoader(dispatch, Actions, false);
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
                navigate("/auth" + routesName.signIn.path);
            } else {
                setLoader(dispatch, Actions, false);
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    };


    const [isShow, setIsShow] = React.useState({
        is_password: false,
        is_confirmed_password: false
    })
    const onPasswordVisibility = (data) => {
        if (data === 'is_password') {
            isShow.is_password = !isShow.is_password;
        } else {
            isShow.is_confirmed_password = !isShow.is_confirmed_password;
        }
        setIsShow({ ...isShow })
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Box className="m_login_left_box" sx={{ backgroundColor: '#F7F7F7', minHeight: '100%', width: '28%', display: 'block' }} >
                <Box sx={{ pt: 8, minHeight: "100vh", minWidth: '100vh', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Box className='m_login_container' sx={{ pt: 8, alignItems: "center", justifyContent: "center", }}>
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: "center", flexDirection: 'column', }}>
                        <Box component="form" onSubmit={handleSubmit} noValidate>

                            <React.Fragment>
                                <CustomTypography
                                    text={'Reset your password'}
                                    sx={{
                                        color: '#000000',
                                        fontSize: '30px',
                                        textAlign: 'start',
                                        fontWeight: '700',
                                        fontStyle: 'normal',
                                        lineHeight: '40.86px',
                                        fontFamily: "Noto Sans",
                                    }}
                                />


                                <Box sx={{ mt: 2, }}>
                                    <Input
                                        autoFocus={true}
                                        id='input_email_address'
                                        dataTestId='input_email_address'
                                        item={{
                                            type: 'text',
                                            disabled: true,
                                            required: true,
                                            fullWidth: true,
                                            validation: false,
                                            title: 'Email Address',
                                            value: data.email ? data.email : '',
                                            placeholder: 'Enter Your Email Address',
                                            onChange: (e) => setData({ ...data, email: e.target.value })
                                        }}
                                    />
                                </Box>
                                <Box sx={{ mt: 3, }}>
                                    <Input
                                        id='input_new_password'
                                        dataTestId='input_new_password'
                                        item={{
                                            required: true,
                                            fullWidth: true,
                                            title: 'New Password',
                                            placeholder: 'Enter New Password',
                                            type: isShow.is_password ? 'text' : 'password',
                                            value: data.new_password ? data.new_password : '',
                                            validation: isFormSubmitted && !verifyPassword(data.new_password),
                                            onChange: (e) => {e.preventDefault(); setData({ ...data, new_password: e.target.value })}
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                                                <IconButton edge="end" size='small' sx={{ ml: .5, color: '#2464EB', cursor: 'pointer' }}
                                                    onClick={(e) => {e.preventDefault(); onPasswordVisibility('is_password') }} id='show_password_icon' dataTestId='show_password_icon'>
                                                    {
                                                        isShow.is_password ?
                                                            <VisibilityIcon fontSize='small' />
                                                            :
                                                            <VisibilityOffIcon fontSize='small' />
                                                    }
                                                </IconButton>
                                            </InputAdornment>

                                        }}
                                    />
                                    <span style={{ fontSize: 12, opacity: 0.8 }}>Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character<br /> and be at least 8 characters long.</span>
                                </Box>

                                <Box sx={{ mt: 2, }}>
                                    <Input
                                        id='input_confirm_password'
                                        dataTestId='input_confirm_password'
                                        item={{
                                            required: true,
                                            fullWidth: true,
                                            title: 'Confirm Password',
                                            placeholder: 'Enter Confirm Password',
                                            type: isShow.is_confirmed_password ? 'text' : 'password',
                                            value: data.confirm_password ? data.confirm_password : '',
                                            validation: isFormSubmitted && !verifyPassword(data.confirm_password),
                                            onChange: (e) => {e.preventDefault();setData({ ...data, confirm_password: e.target.value })},
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                                                <IconButton edge="end" size='small' sx={{ ml: .5, color: '#2464EB', cursor: 'pointer' }}
                                                    onClick={() => { onPasswordVisibility('is_confirmed_password') }} id='show_confirmed_password_icon' dataTestId='show_confirmed_password_icon'>
                                                    {
                                                        isShow.is_confirmed_password ?
                                                            <VisibilityIcon fontSize='small' />
                                                            :
                                                            <VisibilityOffIcon fontSize='small' />
                                                    }
                                                </IconButton>
                                            </InputAdornment>

                                        }}
                                    />
                                </Box>
                            </React.Fragment>

                            <CustomButton
                                fullWidth
                                type="submit"
                                id="sign_in_btn"
                                variant="contained"
                                sx={{ mt: 4, mb: 3 }}
                                onClick={handleSubmit}
                                btnLabel={`Change Password`}
                            />
                            <Grid container spacing={4} sx={{ pt: 1, }}>
                                <Grid item xs={12} sm={6} sx={{visibility: 'hidden'}}>
                                    <Link id='sign_up_link' dataTestId='sign_up_link' href={"/auth" + routesName.signUp.path} variant="body2">
                                        {"Don't have an account? Sign up"}
                                    </Link>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ textAlign: 'end' }} >
                                    <Link id='sign_in_link' dataTestId='sign_in_link' href={"/auth" + routesName.signIn.path} variant="body2">
                                        {'Already have an account? Sign in'}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ResetPassword;
