import React from 'react';
import { Alert, Box } from '@mui/material';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { NoDataComponent } from '../../../common/NoDataComponent';
import { opening_balance_adjustments } from '../../../../utils/Constant';
import CustomTypography from '../../../custom/typography/CustomTypography';
import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../custom/table/CustomTable';
import { calculateTotalBalances } from './helper';
import moment from 'moment';
import { getDateFormat } from '../../../../utils/Utils';

const CreateOpeningBalanceAdjustment = ({ results = [] }) => {
  const [transactions, setTransactions] = React.useState([]);
  // Update destructuring to match the keys returned from calculateTotalBalances
  const { totalDebit, totalCredit, totalAmount, adjustmentAmount } = calculateTotalBalances(results);

  React.useEffect(() => {
    const allTransactions = getFlatListWithCreditAndDebit(results);
    setTransactions(allTransactions)
  }, [results]);

  const getFlatListWithCreditAndDebit = (data) => {
    const filterList = [...new Set(data.flatMap(account => account.transactions))];
    return filterList?.filter(transaction => transaction.hasChanged || transaction.journal_entry_id);
  };

  return (
    <div style={{ paddingLeft: '24px', paddingRight: '24px', paddingBottom: '20px' }}>
      <InformationSelector />
      <CustomTableContainer>
        <CustomTable>
          <CreateOpeningBalanceAdjustmentHeader />
          <CreateOpeningBalanceAdjustmentBody data={transactions} totalDebit={totalDebit} totalCredit={totalCredit} adjustmentAmount={adjustmentAmount} totalAmount={totalAmount} />
        </CustomTable>
        <Box sx={{ pt: !transactions.length ? 10 : 0 }}>
          <NoDataComponent left='0%' top='0%' position='relative' data={transactions} />
        </Box>
      </CustomTableContainer>
    </div>
  );
};

const CreateOpeningBalanceAdjustmentHeader = () => (
  <CustomTableHead>
    <CustomTableRow>
      <CustomTableHeadCell style={{ width: 200 }}><span>As of Date</span></CustomTableHeadCell>
      <CustomTableHeadCell style={{ width: 200 }}>Accounts</CustomTableHeadCell>
      <CustomTableHeadCell style={{ width: 200 }} align='right'>Debit</CustomTableHeadCell>
      <CustomTableHeadCell style={{ width: 200 }} align='right'>Credit</CustomTableHeadCell>
    </CustomTableRow>
  </CustomTableHead>
);

const CreateOpeningBalanceAdjustmentBody = ({ data, totalDebit, totalCredit, adjustmentAmount, totalAmount }) => (
  <CustomTableBody>
    {data.map((item, index) => (
      <BalanceAdjustment
        key={index}
        debitAmount={item.debit}
        creditAmount={item.credit}
        accountName={item.account_name}
        currencyCode={item.currency_code}
        asOfDate={item.opening_balance_date}
      />
    ))}
    {adjustmentAmount && (
      <BalanceAdjustment
        asOfDate={null}
        currencyCode={null}
        accountName={opening_balance_adjustments}
        debitAmount={totalDebit < totalCredit ? adjustmentAmount : 0}
        creditAmount={totalCredit < totalDebit ? adjustmentAmount : 0}
      />
    )}
    {data.length > 0 && (
      <CustomTableRow sx={{ background: '#FFF7DC', borderTop: '1px solid #FFF0C0', borderBottom: '1px solid #FFF0C0' }}>
        <CustomTableBodyCell />
        <CustomTableBodyCell >
          <CustomTypography
            text='Total'
            sx={{
              color: '#000000',
              fontSize: '14px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              textTransform: 'none',
              fontFamily: "Noto Sans",
            }}
          />
        </CustomTableBodyCell>
        <CustomTableBodyCell align='right'>
          <CurrencyFormatter amount={totalAmount} />
        </CustomTableBodyCell>
        <CustomTableBodyCell align='right'>
          <CurrencyFormatter amount={totalAmount} />
        </CustomTableBodyCell>
      </CustomTableRow>
    )}
  </CustomTableBody>
);

export default CreateOpeningBalanceAdjustment;

const BalanceAdjustment = ({ asOfDate, accountName, debitAmount, creditAmount, currencyCode }) => {

  return (
    <CustomTableRow>
      <CustomTableBodyCell><span>{asOfDate ? moment(asOfDate).format(getDateFormat()) : '-'}</span></CustomTableBodyCell>
      <CustomTableBodyCell><span>{accountName}</span></CustomTableBodyCell>
      <CustomTableBodyCell align='right'>
        {debitAmount ? <CurrencyFormatter amount={debitAmount} currency={currencyCode} /> : '-'}
      </CustomTableBodyCell>
      <CustomTableBodyCell align='right'>
        {creditAmount ? <CurrencyFormatter amount={creditAmount} currency={currencyCode} /> : '-'}
      </CustomTableBodyCell>
    </CustomTableRow>
  )
}

const InformationSelector = () => (
  <div style={{}}>
    <Alert sx={{ opacity: 0.8, color: '#000', fontSize: '16px', fontWeight: 700, background: '#fff', fontFamily: 'Noto Sans', display: 'flex', alignItems: 'center' }} severity="info">
      The As of Date cannot be changed once the opening balance is set.
    </Alert>
  </div>
);