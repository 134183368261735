import React from 'react';
import moment from 'moment';
import * as Common from '../../common/Common';
import Status from '../../../../common/Status';
import * as Actions from "../../../../../state/Actions";
import CustomTitle from '../../../../common/CustomTitle';
import CommonSearch from '../../../../common/CommonSearch';
import { useNavigate, useLocation } from 'react-router-dom';
import { routesName } from '../../../../../config/routesName';
import ListItemActions from '../../../../common/ListItemActions';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { NoDataComponent } from '../../../../common/NoDataComponent';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import CommonPreviewShare from '../../../../common/CommonPreviewShare';
import { HeadingWithSortable } from '../../../../common/SortableHeading';
import CommonAsyncDropdown from '../../../../common/CommonAsyncDropdown';
import CommonDownloadLayout from '../../../../common/CommonDownloadLayout';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import {eventsNames, AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';
import CustomDateRangePicker from '../../../../custom/Datepicker/CustomDateRangePicker';

import {
    ActionTextLinkBtn,
    CreditNoteViewLink,
} from '../../common/CommonLinks';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
    apiAction,
    apiBlobResponse,
    apiHandleDownload,
} from '../../../../../api/api';

import {
    setLoader,
    getDateFormat,
    setFilterMessage,
    isFiltersApplied,
    stateChangeManager,
} from '../../../../../utils/Utils';

import {
    list_party,
    update_credit_note,
    delete_credit_note,
    export_credit_note,
    retrieve_credit_note,
    mark_credit_note_sent,
    get_list_of_credit_note,
} from '../../../../../api/urls';


import {
    Box,
    Grid,
} from '@mui/material';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';
import { CommonWebSocket } from '../../../../../api/webSocket';
import CurrencyFormatterWithExchangeRate from '../../common/CurrencyFormatterWithExchangeRate';



const ListCreditNote = () => {
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [page, setPage] = React.useState(1);
    const [results, setResults] = React.useState([])
    const [reLoad, setReLoad] = React.useState(false)
    const [disabledDataExport, setDisabledDataExport] = React.useState(false);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    })


    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })

    const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage) => {
        setState({
            url: url,
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            deleteMessage: deleteMessage,
            onDeleteAction: () => { getApiResults(body, page); }

        })
    }

    const [filters, setFilters] = React.useState({
        sort_by: 'credit_note_date',
        sort_order: 'D'
    })


    const webSocketResponse = CommonWebSocket();
    React.useEffect(() => {
        if (webSocketResponse) {
            let webSocketData = JSON.parse(webSocketResponse.data)
            if (webSocketData.document_type === "credit_note") {
                let index = results.findIndex((item) => item.id === webSocketData.object_id);

                if (webSocketData.event === "send_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        results[index]['status'] = webSocketData.object_status
                        setResults([...results])
                        stateChangeManager(dispatch, Actions, true, "success", `Email delivered successfully for ${webSocketData.object_number}`);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Email delivery failed for ${webSocketData.object_number}`);
                    }
                }

                if (webSocketData.event === "delete_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Credit Note ${webSocketData.object_number} deleted successfully`);
                        getApiResults(body, page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to delete Credit Note ${webSocketData.object_number}`);
                    }
                }

                if (webSocketData.event === "update_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Credit Note ${webSocketData.object_number} updated successfully`);
                        getApiResults(body, page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to update Credit Note ${webSocketData.object_number}`);
                    }
                }
            }
        }
    }, [webSocketResponse])

    let body = { role: "customer", business_id: getBusinessInfo().id, ...filters }
    React.useEffect(() => {
        getApiResults(body, page);
        // eslint-disable-next-line
    }, [filters, page, reLoad])

    const getApiResults = async (body, page) => {
        setLoader(dispatch, Actions, true);
        setFilterMessage(dispatch, Actions, null);
        setDisabledDataExport(false);
        let res = await apiAction({
            data: body,
            method: 'post',
            dispatch: dispatch,
            navigate: navigate,
            url: get_list_of_credit_note(page),
        })

        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.next,
                count: res?.result.count,
                previous: res?.result.previous,
                number_of_pages: res?.result.number_of_pages,
            });
            setResults(res?.result.result);
            setLoader(dispatch, Actions, false);
            if (res?.result?.count === 0) { setDisabledDataExport(true) }
            if (isFiltersApplied(filters)) { setFilterMessage(dispatch, Actions, `No credit notes found for your current filters. Verify your filters and try again.`) };
        } else {
            setLoader(dispatch, Actions, false);

        }
    }

    const [sendMail, setSendMail] = React.useState(false);
    const [sendMailId, setSendMailId] = React.useState(false);
    const [isDownload, setIsDownload] = React.useState(false);

    React.useEffect(() => {
        const statusMapping = {
            sent: eventsNames.actions.creditNote.filter_type.SENT,
            draft: eventsNames.actions.creditNote.filter_type.DRAFT,
            unsent: eventsNames.actions.creditNote.filter_type.UNSENT,
            closed: eventsNames.actions.creditNote.filter_type.CLOSED,
            partially_utilized: eventsNames.actions.creditNote.filter_type.PARTIALLY_UTILIZED,
        };
    
        const filter_type = filters.status 
            ? statusMapping[filters.status] 
            : eventsNames.actions.creditNote.filter_type.ALL;
    
        AnalyticsEvent(eventsNames.categories.CREDIT_NOTES, {action: eventsNames.actions.CLICK,filter_type:filter_type});
    }, [filters.status]);

    return (
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <CommonPreviewShare
                open={sendMail}
                id={sendMailId}
                type={'credit_note'}
                file_key={'credit_note_number'}
                export_Url={export_credit_note}
                retrieve_Url={retrieve_credit_note}
                setOpen={(value) => { setSendMail(value); setReLoad(!reLoad); setSendMailId(null) }}
            />
            <CommonDownloadLayout
                open={isDownload}
                setOpen={setIsDownload}
                filters={filters}
                componentType={"credit_note"}
            />
            <CustomTitleContainer>

                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                        <CustomTitle title={'All Credit Notes'} />
                    </Grid>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', gap : 1}}>
                        <CustomButton
                            id={'create_credit_note_btn'}
                            dataTestId={'create_credit_note_btn'}
                            variant="contained"
                            btnLabel='Create Credit Note'
                            sx={{ textTransform: 'none' }}
                            onClick={() => {
                                navigate(routesName.invoicingCreditNotesAdd.path)
                                AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.NEW})
                            }}
                        />
                        <CustomButton
                            disabled={disabledDataExport}
                            id={'download_credit_note_btn'}
                            dataTestId={'download_credit_note_btn'}
                            variant="contained"
                            btnLabel='Download CSV'
                            sx={{ textTransform: 'none' }}
                            onClick={() => {
                                setIsDownload(true);
                                AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.DOWNLOAD})
                            }}
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{maxHeight:{xs:'calc(100vh - 240px)',sm:'100%'}}}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '24px', display: '-webkit-flex', }}>
                    <Filters stateChangeHandler={stateChangeHandler} filters={filters} setFilters={setFilters} setPage={setPage} />
                </div>

                <CustomTableContainer>
                    <CustomTable sx={{}}>
                        <ListCreditNoteHeaders filters={filters} setFilters={setFilters} />
                        <ListCreditNoteBody setSendMail={setSendMail} setSendMailId={setSendMailId} data={results} stateChangeHandler={stateChangeHandler} reload={() => setReLoad(!reLoad)} />
                    </CustomTable>
                    <Box sx={{ pt: !results.length ? 10 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>

            <CustomPagination
                page={page}
                count={pagination.number_of_pages}
                onChange={(event, newValue) => setPage(newValue)}
            />
        </div>
    )
}

const ListCreditNoteHeaders = (props) => {
    const { filters, setFilters } = props

    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell style={{ width: 100 }} align='center' ><span style={{ cursor: '' }}>Status </span></CustomTableHeadCell>
                <CustomTableHeadCell ><HeadingWithSortable heading={'Date'} sortableKey={'credit_note_date'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell ><HeadingWithSortable heading={'Number'} sortableKey={'credit_note_number'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell ><span style={{ cursor: '' }}>Invoice# </span></CustomTableHeadCell>
                <CustomTableHeadCell ><HeadingWithSortable heading={'Customer'} sortableKey={'customer_display_name'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><HeadingWithSortable heading={'Amount'} sortableKey={'total_amount'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><HeadingWithSortable heading={'Balance'} sortableKey={'credit_remaining'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><span>Actions </span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const ListCreditNoteBody = (props) => {
    const { data, stateChangeHandler, reload, setSendMailId, setSendMail } = props

    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const onSelectAction = (action, item) => {
        setLoader(dispatch, Actions, true)
        if (action === "edit") {
            navigate(routesName.invoicingCreditNotesAdd.path + "?id=" + item.id)
        }
        if (action === "approve") {
            approveCreditNotes(item.id)
            AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.APPROVE})
        }
        if (action === "send") {
            sendCreditNotes(item.id)
            AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.SEND})
        }
        if (action === "mark_sent") {
            markSent(item.id)
            AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.MARK_SENT})
        }
        if (action === "print") {
            printCreditNotes(item.id)
            AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.PRINT})
        }
        if (action === "download") {
            downloadCreditNotes(item.id)
            AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.DOWNLOAD})
        }
        if (action === "refund") {
            setLoader(dispatch, Actions, true)
            navigate(routesName.invoicingCreditNotesRefund.path + "?id=" + item.id)
        }
        if (action === "view") {
            setLoader(dispatch, Actions, true)
            navigate(routesName.invoicingCreditNotesView.path + "?id=" + item.id)
            AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.VIEW})
        }
    }


    const sendCreditNotes = async (id) => {
        setSendMail(true);
        setSendMailId(id);
        // const sendCreditNotesRes = await apiAction({ url: send_credit_note_mail(), method: 'POST', data: { business_id: getBusinessInfo().id, credit_note_id: id } })
        // if (sendCreditNotesRes.success) {
        //     reload(true);
        //     stateChangeManager(dispatch, Actions, true, 'success', sendCreditNotesRes.status);
        // } else {
        //     setLoader(dispatch, Actions, false);
        //     stateChangeManager(dispatch, Actions, true, 'error', sendCreditNotesRes.status);
        // }
    }

    const markSent = async (id) => {
        const sentRes = await apiAction({ url: mark_credit_note_sent(id), method: 'POST', data: { business_id: getBusinessInfo().id } })
        if (sentRes.success) {
            stateChangeManager(dispatch, Actions, true, 'success', sentRes.status);
        }else {
            stateChangeManager(dispatch, Actions, true, 'error', sentRes.status);
        }
    }
    const approveCreditNotes = async (id) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_credit_note(id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            const approveCreditNotesRes = await apiAction({ url: update_credit_note(id), method: 'POST', data: { is_draft: false, customer_id: data.result.customer_id, against_invoice_id: data.result.against_invoice_id, business_id: getBusinessInfo().id } })
            if (approveCreditNotesRes.success) {
                reload(true)
                stateChangeManager(dispatch, Actions, true, 'success', approveCreditNotesRes.status);
            }
            else {
                reload(false)
                stateChangeManager(dispatch, Actions, true, 'error', approveCreditNotesRes.status);
            }
        }
    }
    const printCreditNotes = async (id) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_credit_note(id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            apiBlobResponse({
                url: export_credit_note(), data: { ...data.result, export_type: "pdf" }, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }
    const downloadCreditNotes = async (id) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_credit_note(id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            apiHandleDownload({
                url: export_credit_note(), data: { ...data.result, export_type: "pdf" }, filename: data.result.credit_note_number, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }


    const toActionText = (status) => {
        let text = ''
        if (status === 'draft') {
            text = 'Approve'
        } else if (status === 'unsent') {
            text = 'Send'
        } else if (status === 'closed') {
            text = 'View'
        } else if (status === 'sent' || status === 'partially_utilized') {
            text = 'Refund'
        }
        return text
    }

    const onClickActionText = (status, item) => {
        if (status === 'draft') {
            onSelectAction("approve", item);
        } else if (status === 'unsent') {
            onSelectAction("send", item);
        } else if (status === 'closed') {
            onSelectAction("view", item);
        } else if (status === 'sent' || status === 'partially_utilized') {
            if (item.total_amount === 0) {
                stateChangeManager(dispatch, Actions, true, 'error', `Refund is not possible for credit note with zero amount`);
            } else {
                onSelectAction("refund", item);
            }

        }
    }


    return (
        <CustomTableBody>
            {
                data.map((item, index) => {
                    return (
                        <CustomTableRow key={index}>
                            <CustomTableBodyCell sx={{}} align='center' ><Status status={item.status} /></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}}  ><span>{moment(item.credit_note_date).format(getDateFormat())}</span></CustomTableBodyCell>
                            {/* <CustomTableBodyCell sx={{}}  ><span>{item.credit_note_number}</span></CustomTableBodyCell> */}
                            <CustomTableBodyCell sx={{}}  ><CreditNoteViewLink id={item.id} title={item.credit_note_number} /></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}}  ><span>{item.against_invoice_number}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}}  ><span>{item.customer_display_name}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>
                                <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />

                            </CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>
                                <span>{<CurrencyFormatter amount={item.credit_remaining} currency={item.currency_code} />}</span><br />
                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.credit_remaining} exchange_rate={item.exchange_rate} />

                            </CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <ActionTextLinkBtn
                                        index={index}
                                        toActionText={toActionText(item.status)}
                                        onClickActionText={() => onClickActionText(item.status, item)} />
                                    <ListItemActions
                                        index={index}
                                        actions={
                                            [
                                                item.status !== 'closed' && { name: 'View', onClick: () => { onSelectAction("view", item) } },
                                                item.status !== 'closed' && { name: 'Edit', onClick: () => { onSelectAction("edit", item) } },
                                                item.status !== 'draft' & item.status !== 'unsent' && { name: 'Resend', onClick: () => { onSelectAction("send", item) } },
                                                { name: 'Print', onClick: () => { onSelectAction("print", item) } },
                                                item.status === 'unsent' && { name: 'Mark as sent', onClick: () => { onSelectAction("mark_sent", item);} },
                                                { name: 'Export as PDF', onClick: () => { onSelectAction("download", item) } },
                                                { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Credit Note', 'delete', 'sm', delete_credit_note(item.id), `The credit note will be deleted and can not be retrieved later.\n Are you sure about deleting it?`);AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.DELETE});} }
                                            ]
                                        }
                                    />
                                </Box>
                            </CustomTableBodyCell>
                        </CustomTableRow>
                    )
                })
            }
        </CustomTableBody>
    )
}
export default ListCreditNote;

const Filters = (props) => {
    const { setPage, setFilters, filters } = props

    let location = useLocation();
    let [dates, setDate] = React.useState();
    const [customer, setCustomer] = React.useState(null);

    //This code block is use when we pass the state through navigate
    const setLocationData = (customer, customer_id, from_date, to_date) => {

        let end_date = to_date ? to_date : null;
        let start_date = from_date ? from_date : null;

        setCustomer(customer);
        filters.customer_id = customer_id;
        filters.credit_note_end_date = end_date;
        filters.credit_note_start_date = start_date;
        setDate([moment(start_date), moment(end_date)]);
    }

    React.useEffect(() => {
        if (location.state) {
            if (location.state.action === 'sales_by_customer') {
                setLocationData(location.state.customer, location.state.customer_id, location.state.from_date, location.state.to_date);
            }

        }
        setFilters({ ...filters })
    }, [location.state]);


    const onDateRangeChange = (dates) => {
        setPage(1);
        if (dates) {
            setFilters({ ...filters, credit_note_start_date: dates[0].format("YYYY-MM-DD"), credit_note_end_date: dates[1].format("YYYY-MM-DD"), dates : dates })
        } else {
            delete filters.credit_note_start_date
            delete filters.credit_note_end_date
            delete filters.dates
            setFilters({ ...filters })
        }

    }

    return (
        <Grid item xs={12} container spacing={1}>
            <Grid item xs={6} sm={2.5} >
            <Common.FilterStatus
                filters={filters}
                setPage={setPage}
                setFilters={setFilters}
                dataKey={'creditNoteStatus'}
            />
            </Grid>

            <Grid item xs={6} sm={2.5}>
                <CommonAsyncDropdown
                    id={'customer_dropdown'}
                    dataTestId={'customer_dropdown'}
                    autoSelect={false}
                    disableClearable={false}
                    optionLabel="display_name"
                    placeholder='Select Customer'
                    noOptionsText={"No result found"}

                    item={{
                        method: 'post',
                        value: customer,
                        label: 'Customers',
                        url: list_party(1),
                        body: { is_inactive: false, business_id: getBusinessInfo().id, role: 'customer' },
                        onChange: (event, value) => {
                            setPage(1);
                            if (value) {
                                setFilters({ ...filters, customer_id: value.id, selectedCustomer : value })
                            } else {
                                delete filters.customer_id
                                delete filters.selectedCustomer
                                setFilters({ ...filters })
                            }

                            setCustomer(value)
                        },
                    }}
                />
            </Grid>
            
            <Grid item xs={12} sm={4.5} container>
                <CustomDateRangePicker dates={dates} onDateRangeChange={onDateRangeChange} />
            </Grid>

            <Grid item xs={12} sm={2.5}>

                <CommonSearch
                    id={'input_search_number'}
                    dataTestId={'input_search_number'}
                    title={'Search'}
                    fullWidth={true}
                    titleVisibility={'hidden'}
                    placeholder={'Enter Credit Note Number #'}
                    onSearchValue={filters.credit_note_number ? filters.credit_note_number : ''}
                    onSearch={(search_text) => {
                        setPage(1);
                        setFilters({ ...filters, credit_note_number: search_text })
                    }} />
            </Grid>
        </Grid>
    )
}
