import React from "react";
import NotFound from "../NotFound";
import { landingRoutes } from "../../config/routes";
import { routesName } from "../../config/routesName";
import { isAuthenticated } from "../../config/cookiesInfo";
import Headers from "../../components/pages/landing/pages/Headers";
import Footer from "../../components/pages/landing/pages/Footer";

import {
  matchRoute,
} from "../../utils/Utils";

import {
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";

export default function LandingLayout() {
  // console.log('LandingLayout=>')
  const location = useLocation();

  const currentPath = location.pathname;
  console.log('==>currentPath',currentPath)
  const isRouteMatched = matchRoute(landingRoutes,currentPath,'/landing');

  const ProtectedRoute = ({ children }) => {
    if (isAuthenticated()) {
      return <Navigate to={routesName.dashboard.path} replace />;
    } else {
      return children;
    }
  };

  return (
    <>
      {!isAuthenticated() && isRouteMatched && <Headers />}
      <Routes>
        {landingRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <ProtectedRoute>{route.component}</ProtectedRoute>
            }
          />
        ))}
        {/* Catch-all route for landing */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      {!isAuthenticated() && isRouteMatched && <Footer />}
    </>
  );
}


