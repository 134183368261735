import moment from 'moment';
import * as XLSX from "xlsx";
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import HorizontalLinearStepper from "./Stepper";
import DateRange from '../../../common/DateRange';
import { images } from "../../../../utils/Constant";
import * as Actions from '../../../../state/Actions';
import React, { useContext, useEffect } from "react";
import CustomLabel from "../../../common/CustomLabel.js";
import { routesName } from "../../../../config/routesName";
import { MetaData } from "../../../custom/tooltip/CustomTooltip";
import CustomButton from "../../../custom/button/CustomButton";
import { getBusinessInfo } from "../../../../config/cookiesInfo";
import { NoDataComponent } from "../../../common/NoDataComponent";
import { apiAction, apiHandleDownload } from "../../../../api/api";
import { HtmlTooltip } from '../../../custom/tooltip/CustomTooltip';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomDropdown from "../../../custom/dropdown/CustomDropdown.js";
import CustomTypography from '../../../custom/typography/CustomTypography';
import { CustomContainer } from "../../../custom/container/CustomContainer";
import { stateChangeManager, getJsDateFormat } from "../../../../utils/Utils";
import CustomDefaultDropdown from '../../../custom/dropdown/CustomDefaultDropdown';
import { sourced_transaction, time_format_ref, date_format_ref } from "../../../../utils/Constant"
import { CustomRadio, CustomHelperText } from '../../setting/settings/Preferences/common/CustomElement';
import { get_fields_for_mapping, get_sourced_transactions_fields, date_format_list, get_sample_datetime_parsed_result } from "../../../../api/urls";
import { CustomTable, CustomTableBody, CustomTableBodyCell, CustomTableContainer, CustomTableHead, CustomTableHeadCell, CustomTableRow } from "../../../custom/table/CustomTable";

import {
    Box,
    Grid,
    Dialog,
    Divider,
    Popover,
    IconButton,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Input = styled('input')({
    display: 'none',
});

const Dropdown = DateRange;

const FileUpload = (props) => {
    const { sample_file_download_url, nextButton, nextPagePath = routesName.mappings.path, model_fields_url = get_sourced_transactions_fields(), mapping_for = sourced_transaction } = props
    const navigate = useNavigate()
    const state = Actions.getState(useContext);
    const dispatch = Actions.getDispatch(useContext);
    // const [amountFieldColumnType, setAmountFieldColumnType] = React.useState();
    const { amountFieldColumnType, mappings, date_format_dict, is_date_format_dict, is_date_format_res, selectDateTimeFormat, dateTimeFormatList } = state
    const { rows, fileName, columns } = mappings

    const setAmountFieldColumnType = (value) => {
        dispatch(Actions.stateChange("amountFieldColumnType", value));
    }

    const getDateFormat = async () => {
        let res = await apiAction({ url: date_format_list(), navigate: navigate, dispatch: dispatch, })
        if (res && res?.success) {

            dispatch(Actions.stateChange("timeFormatList", res?.time_formats ? res?.time_formats.map((time_format) => {
                return { time_format: time_format, name: `${time_format}  -  [ ${moment().format(time_format)} ]` }

            }) : []))
            dispatch(Actions.stateChange("dateFormatList", res?.result ? res?.result.map((date_format) => {
                return { date_format: date_format, name: `${date_format}  -  [ ${moment().format(getJsDateFormat(date_format))} ]` }
            }) : []))

            let initial_date_time = []
            res?.result.map((date_format) => {
                let parent_account = { represent_date: date_format, selectDateTimeFormat: date_format_ref[date_format], parent_account_name: date_format, date_format: date_format, time_format: null, name: `${date_format}  -  [ ${moment().format(getJsDateFormat(date_format))} ]` }
                let child_account = res?.time_formats.map((time_format) => {
                    return { represent_date: date_format + ' ' + time_format, selectDateTimeFormat: date_format_ref[date_format] + ' ' + time_format_ref[time_format], parent_account_name: date_format, date_format: date_format, time_format: time_format, name: `${date_format + ' ' + time_format}  -  [ ${moment().format(getJsDateFormat(date_format) + ' ' + time_format)} ]` }
                })
                initial_date_time.push(parent_account, ...child_account)
            })
            dispatch(Actions.stateChange("dateTimeFormatList", initial_date_time));
        }
    }
    const getDBFields = async () => {
        let res = await apiAction({ url: mapping_for === sourced_transaction ? get_fields_for_mapping(mapping_for) + `&amount_column_type=${amountFieldColumnType}` : get_fields_for_mapping(mapping_for), navigate: navigate, dispatch: dispatch, })
        if (res && res?.success) {
            dispatch(Actions.stateChange("model_fields", res?.result))
        }
    }
    useEffect(() => {
        if (mapping_for === sourced_transaction && amountFieldColumnType) {
            getDBFields();
        } else if (mapping_for !== sourced_transaction) {
            getDBFields();
        }
        dispatch(Actions.stateChange("activeStep", 0));
        // eslint-disable-next-line
    }, [amountFieldColumnType])

    useEffect(() => {
        if (mapping_for === sourced_transaction) {
            getDateFormat();
        }
        // eslint-disable-next-line
    }, [])

    const onFileUpload = (event) => {
        setAmountFieldColumnType();
        const file = event.target.files[0]
        const extension = file.name.split(".")[file.name.split(".").length - 1]
        if (extension === "csv" || extension === "xls" || extension === "xlsx") {
            //Reset the save state data 
            setDate({});
            setDateResult({});
            dispatch(Actions.stateChange("date_format_dict", {}));
            dispatch(Actions.stateChange("is_date_format_dict", {}));
            dispatch(Actions.stateChange("is_date_format_res", {}));


            let fileSize = (file.size / 1024 / 1024)
            if (fileSize > 3) {
                stateChangeManager(dispatch, Actions, true, 'error', "Maximum file size allowed is 3 MB. Please try with different file.")
                return
            }
            if (extension === "csv") {
                Papa.parse(file, {
                    complete: (result) => {
                        result.uploadedFile = file;
                        result.extension = extension;
                        updateData(result);
                    },
                    header: true,
                    skipEmptyLines: true,
                });
            } else if (extension === "xls" || extension === "xlsx") {
                const reader = new FileReader();

                reader.onload = (evt) => {

                    var filedata = new Uint8Array(evt.target.result);

                    const wb = XLSX.read(filedata, { type: 'array', cellDates: true, });

                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];

                    const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false, raw: false, dateNF: 'YYYY-MM-DD' });

                    let headers = data[0]

                    let rows = data.map((item, i) => {
                        return Object.assign({}, ...item.map((x, index) => ({ [headers[index]]: x, })));
                    })
                    updateData({ data: rows, extension: extension, uploadedFile: file })
                };
                reader.readAsArrayBuffer(file);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', "Incorrect file type. Please try with different file in csv or xlsx format.")
        }

    }


    function isValidDate(str) {
        var date = moment(str, dateTimeFormatList.map(dict => dict.represent_date));
        if (date.isValid()) {
            return true;
        } else {
            return false;
        }
    }
    function hasValidDates(array, key) {
        let isExecuted = false;
        for (const obj of array) {
            let numberValue = obj[key] && obj[key].replace(/[,.]/g, '');
            if (obj[key] && isNaN(numberValue) && obj[key] !== '') {
                if (!isExecuted && isValidDate(obj[key])) {
                    getSampleDateResult(key, obj[key]);
                    isExecuted = true;
                    return true;
                } else {
                    isExecuted = true;
                    return false;
                }
            }
        }
        return false;
    }

    const [date, setDate] = React.useState({});
    const [dateResult, setDateResult] = React.useState({});
    const getSampleDateResult = async (key, dateStr, dateFormat) => {
        let res = await apiAction({ url: get_sample_datetime_parsed_result(dateStr, dateFormat), navigate: navigate, dispatch: dispatch, })
        if (res && res?.success) {
            let item = res?.result;
            setDateResult((prevDate) => ({ ...prevDate, [key]: item }));
            setDate((prevDate) => ({ ...prevDate, [key]: dateStr }));
            if (dateFormat) {
                let selected_date = dateTimeFormatList.find((item) => item.selectDateTimeFormat === dateFormat)
                stateChangeManager(dispatch, Actions, true, 'success', `Date format "${selected_date ? selected_date.represent_date : ''}"  selected`)
                dispatch(Actions.stateChange('date_format_dict', { ...date_format_dict, [key]: dateFormat }))
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res && res?.status)
        }
    }

    useEffect(() => {
        if (Object.keys(date)) {
            dispatch(Actions.stateChange('is_date_format_dict', { ...is_date_format_dict, ...date }))
        }
        if (Object.keys(dateResult)) {
            dispatch(Actions.stateChange('is_date_format_res', { ...is_date_format_res, ...dateResult }))
        }
    }, [date, dateResult])

    const updateData = (result) => {
        var data = result.data;
        // console.log('===>data',result.data)
        let _mappings = { ...mappings }
        let firstElement = result.extension === "csv" ? data[0] : data.shift();

        if (data.length) {
            _mappings.rows = data
            _mappings.uploadedFile = result.uploadedFile
            _mappings.file_headers = Object.keys(firstElement)
            if (mapping_for === sourced_transaction) {
                _mappings.columns = Object.keys(firstElement).map((key) => { return { isDateField: hasValidDates(data, key), field: key, headerName: key, editable: false, sortable: false, flex: 1, minWidth: 200, fontWeight: key === 'Description' ? 500 : 700 } })
            } else {
                _mappings.columns = Object.keys(firstElement).map((key) => { return { field: key, headerName: key, editable: false, sortable: false, flex: 1, minWidth: 200, fontWeight: key === 'Description' ? 500 : 700 } })
            }
            dispatch(Actions.stateChange("filterMessage", null))
        } else {
            dispatch(Actions.stateChange("filterMessage", `The uploaded file does't contain any data, please check that the file is not empty`))
        }
        _mappings.fileExtenstion = result.extension
        _mappings.fileName = result.uploadedFile.name
        dispatch(Actions.stateChange("mappings", _mappings))
    }


    const handleClick = event => {
        const { target = {} } = event || {};
        target.value = "";
    };


    const onDownloadFile = async (export_type) => {
        apiHandleDownload({
            method: 'get',
            formate: export_type,
            url: sample_file_download_url(),
            filename: mapping_for + '_sample_file',
            data: { business_id: getBusinessInfo().id },
            onSuccess: () => { console.log('====>file_downloaded') }
        })
    }

    const column_type_list = [
        { name: 'Debit and Credit Amount Column', helperText: `Different Column for Debit amount and Credit Amount`, value: `debit_and_credit_amount_columns` },
        { name: 'Amount and Transaction Type Column', helperText: `Different Column for Amount and Transaction Type`, value: `amount_and_transaction_type_columns` },
        { name: 'Amount with Transaction Type Column', helperText: `Same Column for amount and Transaction Type`, value: `amount_with_transaction_type_column` },
        { name: 'Amount with Negative Values', helperText: `Single Column for amount without Transaction Type but with Negative Values for Debit amount`, value: `amount_with_negative_value_column` },
    ]
    return (
        <Box style={{ height: "100%",mt:'5px' }}>
            <Grid container style={{ backgroundColor: 'white', padding: 10, marginBottom: 10 }}>
                <Grid xs={12} item >
                    <HorizontalLinearStepper />
                </Grid>
            </Grid>

            <Box sx={{ background: '#FFFFFF', }}>

                    {/* File Upload Section */}
                <Grid container spacing={1} sx={{ padding: '16px' }} alignItems={'center'}> 
                        {/* Upload Button */}
                        <Grid item xs={6} sm={10} order={{xs:1,sm:1}}>
                            <Stack direction="column" spacing={2}>
                                <label htmlFor="contained-button-file" style={{ display: 'table', width: 'fit-content' }}>
                                    <Input
                                        type="file"
                                        onChange={onFileUpload}
                                        accept=".csv,.xlsx,.xls"
                                        id="contained-button-file"
                                        sx={{ border: '5px solid black' }}
                                        dataTestId="contained-button-file"
                                        onClick={(event) => event.target.value = null}
                                    />
                                    <CustomButton
                                        component="span"
                                        variant="contained"
                                        onClick={handleClick}
                                        id="contained_file_btn"
                                        sx={{ textTransform: 'none' }}
                                        dataTestId="contained_file_btn"
                                        btnLabel="Upload csv or xlsx File"
                                    />
                                </label>
                            </Stack>
                        </Grid>

                        {/* Clear and Next Button Section */}
                        <Grid item xs={fileName?6:1} sm={1} order={fileName?{xs:4,sm:2}:{xs:2,sm:2}}>
                                <CustomButton
                                    fullWidth={true}
                                    btnLabel="Clear"
                                    variant="outlined"
                                    id="file_upload_clear_btn"
                                    dataTestId="file_upload_clear_btn"
                                    disabled={!mappings.uploadedFile}
                                    sx={{ Width: {xs:'100%',sm:'100px'}, height: '33px', visibility: mappings.uploadedFile ? 'visible' : 'hidden' }}
                                    onClick={() => {
                                        setDate({});
                                        setDateResult({});
                                        dispatch(Actions.stateChange("date_format_dict", {}));
                                        dispatch(Actions.stateChange("is_date_format_dict", {}));
                                        dispatch(Actions.stateChange("is_date_format_res", {}));
                                        dispatch(Actions.stateChange("mappings", {
                                            file_headers: [],
                                            uploadedFile: null,
                                            rows: [],
                                            fileName: "",
                                            fileExtenstion: "",
                                            columns: [],
                                            mapped_headers: []
                                        }));
                                    }}
                                />
                            </Grid>

                            <Grid item xs={fileName?6:5} sm={1} order={fileName?{xs:5,sm:3}:{xs:2,sm:3}}>
                                <CustomButton
                                    btnLabel="Next"
                                    fullWidth={true}
                                    variant="contained"
                                    id="file_upload_next_btn"
                                    dataTestId="file_upload_next_btn"
                                    disabled={!mappings.uploadedFile || (mapping_for === sourced_transaction && !amountFieldColumnType)}
                                    sx={{ Width: {xs:'100%',sm:'100px'}, height: '33px', marginLeft: 1 }}
                                    onClick={() => {
                                        if (nextButton) {
                                            nextButton();
                                        } else {
                                            navigate(nextPagePath);
                                        }
                                        dispatch(Actions.stateChange("mapping", null));
                                        dispatch(Actions.stateChange("activeStep", 1));
                                    }}
                                />
                            </Grid>


                    {/* File Upload Status or Instructions */}
                    <Grid item xs={fileName?6:12} order={fileName?{xs:2,sm:4}:{xs:2,sm:4}}>
                        {fileName ? (
                            <p style={{ fontSize: 13, fontFamily: 'Noto Sans', marginTop: 8 }}>
                                <AttachFileIcon sx={{ ml: -1, mb: -1, color: '#000000' }} />
                                {fileName}
                            </p>
                        ) : (
                            <>
                                <p style={{ fontSize: 13, fontFamily: 'Noto Sans', marginTop: 8 }}>
                                    csv or xlsx columns will be displayed here once you upload the csv or xlsx file.
                                </p>
                                {sample_file_download_url && (
                                    <p style={{ fontSize: 13, fontFamily: 'Noto Sans', marginTop: 10, lineHeight: '20px' }}>
                                        Download a <span className="hover-underline-class" onClick={() => onDownloadFile('.xlsx')} style={{ color: '#2464eb', cursor: 'pointer' }}>sample xls file</span> and compare it to your import file to ensure you have the perfect file for the import.
                                    </p>
                                )}
                            </>
                        )}
                    </Grid>

                    {/* Conditional Amount Column Section */}
                    {fileName && mapping_for === sourced_transaction && (
                        <Grid item xs={12} sm={6} container alignItems={'center'} justifyContent={{xs:'space-between',sm:"flex-end"}} order={{xs:3,sm:5}}>
                            <Grid item xs={6} sm={6} sx={{ textAlign: {xs:'start',sm:'right'} }}>
                                <CustomTypography
                                    text={
                                        <span>
                                            <LabelWithAsteriskMark label="Amount Column Type" style={{ whiteSpace: 'wrap'}} />
                                            <AmountColumnInfo amountFieldColumnType={amountFieldColumnType} setAmountFieldColumnType={setAmountFieldColumnType} />
                                        </span>
                                    }
                                    sx={{
                                        color: '#000000',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontFamily: "Noto Sans"
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} sm={6} >
                                <CustomDefaultDropdown
                                    fullWidth
                                    placeholder=""
                                    labelKey="name"
                                    disabledCloseIcon
                                    valueLabelKey="name"
                                    sx={{marginLeft: '5px'}}
                                    results={column_type_list}
                                    id="amount_column_info_dropdown"
                                    validation={!amountFieldColumnType}
                                    dataTestId="amount_column_info_dropdown"
                                    value={column_type_list.find(item => item.value === amountFieldColumnType)}
                                    setValue={(selectedData) => setAmountFieldColumnType(selectedData?.value)}
                                />
                            </Grid>
                            </Grid>
                    )}

                </Grid>

                <Divider />
                <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 230px)', maxHeight: { xs: 'calc(100vh - 355px)', sm: '100%' } }}>
                    <CustomTableContainer sx={{ maxWidth: 'calc(100vw - 270px)', minWidth: '100%' }}>
                        <CustomTable>
                            <ItemsListHeaders columns={columns} rows={rows} mapping_for={mapping_for} date={is_date_format_dict} getSampleDateResult={getSampleDateResult} date_format_dict={date_format_dict} />
                            <ItemsListBody columns={columns} rows={rows} mapping_for={mapping_for} selectDateTimeFormat={selectDateTimeFormat} dateResult={is_date_format_res} />
                        </CustomTable>
                        <Box sx={{ pt: !rows.length ? 10 : 0 }} >
                            <NoDataComponent left='0%' top='0%' position={'relative'} data={rows} image={images.stateMent_upload} className="m_statement_upload" />
                        </Box>
                    </CustomTableContainer>
                </CustomContainer>
            </Box>
        </Box>
    )
}

export default FileUpload

const ItemsListHeaders = ({ columns, rows, mapping_for, date, getSampleDateResult, date_format_dict }) => {
    return (
        columns.length ?
            <CustomTableHead sx={{}}>
                <CustomTableRow>
                    <CustomTableHeadCell style={{ whiteSpace: 'nowrap' }}><div style={{ marginTop: -10, alignItems: 'center', display: 'flex' }}><span>{'Sr No.'}</span></div></CustomTableHeadCell>
                    {columns.map((column, index) => {
                        return (
                            <React.Fragment key={index}>
                                {
                                    (sourced_transaction === mapping_for && column.isDateField) ?
                                        <CustomTableHeadCell style={{ whiteSpace: 'nowrap' }}>
                                            <div style={{ marginTop: -10, alignItems: 'center', display: 'flex' }}><span>{column.headerName}</span>
                                                <DateFormateSetting date_key={column.field} date={date} getSampleDateResult={(dateFormate) => { dateFormate ? getSampleDateResult(column.field, date[column.field], dateFormate) : getSampleDateResult(column.field, date[column.field]) }} date_format_dict={date_format_dict} />
                                            </div>
                                        </CustomTableHeadCell>
                                        :
                                        <CustomTableHeadCell style={{ whiteSpace: 'nowrap' }}><div style={{ marginTop: -10, alignItems: 'center', display: 'flex' }}><span>{column.headerName}</span></div></CustomTableHeadCell>
                                }
                            </React.Fragment>
                        )
                    })}
                </CustomTableRow>
            </CustomTableHead>
            : null
    )
}

const ItemsListBody = ({ columns, rows, mapping_for, dateResult }) => {

    return (
        <CustomTableBody>
            {rows.map((item, index) => {
                return (
                    <CustomTableRow key={index}>
                        <CustomTableBodyCell sx={{}}>
                            <span>{index + 1}</span>
                        </CustomTableBodyCell>
                        {columns.map((column, i) => {
                            if (mapping_for === sourced_transaction) {
                                const isFirstNonEmptyRow = rows.findIndex((row) => row[column.field] && row[column.field] !== "") === index;
                                const dataItem = dateResult[column.field]
                                return (
                                    <CustomTableBodyCell key={i} sx={{ fontWeight: column.fontWeight }}>
                                        <span style={{ whiteSpace: 'nowrap', paddingBottom: '5px' }}>{item[column.field]}</span><br />
                                        {isFirstNonEmptyRow && dataItem ?
                                            <HtmlTooltip placement={'bottom'} arrow title={
                                                `Day(D): ${dataItem.day}, Month(M): ${dataItem.month}, Year(Y): ${dataItem.year}, Hour(H): ${dataItem.hour}, Minutes(M): ${dataItem.minutes} ${dataItem.am_pm}`
                                            } sx={{ maxWidth: 100 }}>
                                                <span style={{ background: 'GrayText', color: 'white', fontSize: '10px', fontWeight: 100, whiteSpace: 'nowrap', cursor: 'pointer', paddingRight: '8px', paddingLeft: '10px', borderRadius: '10px', paddingTop: '1px', paddingBottom: '1px', }}>
                                                    {`D(${dataItem.day}), M(${dataItem.month}), Y(${dataItem.year}), H(${dataItem.hour}), M(${dataItem.minutes}), ${dataItem.am_pm}`}
                                                </span>
                                            </HtmlTooltip>
                                            : null}
                                    </CustomTableBodyCell>
                                );
                            } else {
                                return (
                                    <CustomTableBodyCell key={i} sx={{ fontWeight: column.fontWeight }}>
                                        <span>{item[column.field]}</span>
                                    </CustomTableBodyCell>
                                );
                            }
                        })}
                    </CustomTableRow>
                );
            })}
        </CustomTableBody>

    )
}

const DateFormateSetting = (props) => {
    const { date, date_format_dict, date_key, getSampleDateResult } = props
    const state = Actions.getState(React.useContext);
    const dispatch = Actions.getDispatch(React.useContext);

    const { dateTimeFormatList } = state;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedData, setSelectedData] = React.useState({ ...date_format_dict })

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickHandler = () => {
        getSampleDateResult(selectedData[date_key]);

    }

    React.useEffect(() => {
        if (date_format_dict[date_key]) {
            handleClose()
        }
    }, [date_format_dict])

    const open = Boolean(anchorEl);
    let minHeight = open ? '55vh' : '';
    const id = open ? 'simple-popover' : undefined;


    React.useEffect(() => {
        if (!open) {
            setSelectedData({ ...date_format_dict })
        }
    }, [open])

    return (
        <React.Fragment>
            <SettingsIcon sx={{ fontSize: '14px', ml: 0.5, cursor: 'pointer' }} onClick={handleClick} />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        zIndex: 100,
                        width: {
                            sm: 500,
                            xs: '100%',
                        },
                        maxHeight: '60vh',
                    }
                }}
            >
                <Box sx={{ p: 2, minHeight: minHeight }}>
                    <Box>
                        <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <CustomLabel title={`Select ${date_key} Format`} />
                            <IconButton onClick={() => { handleClose() }}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Box>

                        <Box sx={{}}>
                            <CustomDropdown
                                disableClearable={true}
                                results={dateTimeFormatList}
                                id={"value_date_format_dropdown"}
                                placeholder="Select Value Date Format"
                                dataTestId={"value_date_format_dropdown"}
                                value={dateTimeFormatList.find((item) => item.selectDateTimeFormat === selectedData[date_key])}
                                onChange={(event, selectedFormat) => {
                                    if (selectedFormat) {
                                        setSelectedData((previous) => ({ ...previous, [date_key]: selectedFormat.selectDateTimeFormat }));
                                    } else {
                                        setSelectedData((previous) => ({ ...previous, [date_key]: null }));
                                    }
                                }}
                            />
                        </Box>
                        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', }}>
                            <CustomButton
                                btnLabel={'Reset to Default'}
                                variant='outlined'
                                id={"file_upload_next_btn"}
                                dataTestId={"file_upload_next_btn"}
                                sx={{ textTransform: 'none', height: '33px', }}
                                onMouseDown={(event) => { event.preventDefault(); }}
                                onClick={() => { handleClose(); dispatch(Actions.stateChange('date_format_dict', { ...date_format_dict, [date_key]: null })) }}
                            />

                            <CustomButton
                                fullWidth
                                btnLabel={'Select'}
                                variant='contained'
                                id={"file_upload_next_btn"}
                                disabled={!selectedData[date_key]}
                                dataTestId={"file_upload_next_btn"}
                                onClick={() => { onClickHandler() }}
                                onMouseDown={(event) => { event.preventDefault(); }}
                                sx={{ width: '100px', height: '33px', marginLeft: 1 }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Popover>
        </React.Fragment>

    )
}

const AmountColumnInfo = (props) => {
    const { id, dataTestId, amountFieldColumnType, setAmountFieldColumnType } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <InfoOutlinedIcon id={'info_icon_btn'} dataTestId={'info_icon_btn'} fontSize='small' sx={{ mb: -0.5, cursor: 'pointer', pl: 0.5, fontSize: '16px', color: '#939393', ":hover": { color: '#2098ff' } }}
                onClick={() => setOpen(true)}
            />
            <BootstrapDialog
                open={open}
                // maxWidth={'100px'}
                fullWidth={true}
                PaperProps={{
                    sx: {
                        width: "100%",
                        maxWidth: "960px!important",
                    },
                }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" dataTestId="customized-dialog-title" onClose={() => setOpen(false)}>
                    <CustomTypography
                        text={'Amount Column Type'}
                        sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </BootstrapDialogTitle>
                <DialogContent dividers>

                    <DebitCreditColumn value={amountFieldColumnType === 'debit_and_credit_amount_columns'} setValue={() => { setOpen(false); setAmountFieldColumnType('debit_and_credit_amount_columns') }} />
                    <AmountTransactionTypeColumn value={amountFieldColumnType === 'amount_and_transaction_type_columns'} setValue={() => { setOpen(false); setAmountFieldColumnType('amount_and_transaction_type_columns') }} />
                    <AmountWithTransactionTypeColumn value={amountFieldColumnType === 'amount_with_transaction_type_column'} setValue={() => { setOpen(false); setAmountFieldColumnType('amount_with_transaction_type_column') }} />
                    <AmountWithNegativeAmountColumn value={amountFieldColumnType === 'amount_with_negative_value_column'} setValue={() => { setOpen(false); setAmountFieldColumnType('amount_with_negative_value_column') }} />

                    <DialogActions>
                    </DialogActions>
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    )
}

const DebitCreditColumn = (props) => {
    const { value, setValue } = props
    const headers = ["Transaction Date", "Value Date", "Description", "Ref No./Cheque No.", "Debit Amount", "Credit Amount", "Balance Amount",]
    const columns = [
        { txn_date: '2023-12-12', val_date: '2023-12-12', disc: 'Travel Expense', ref_no: '0001', dr_amt: '1000', cr_amt: '', bal: '1000' },
        { txn_date: '2023-12-12', val_date: '2023-12-12', disc: 'Rent Income', ref_no: '0002', dr_amt: '', cr_amt: '7500', bal: '8500' },
    ]
    return (
        <Box sx={{ p: 2 }}>
            <CustomRadio id={'debit_and_credit_amount_columns_radio_btn'} dataTestId={'debit_and_credit_amount_columns_radio_btn'} checked={value} onClickedHandler={(value) => { setValue() }} label={`Debit and Credit Amount Column`} />
            <CustomHelperText sx={{ ml: 3.2 }} label={`Different Column for Debit Amount and Credit Amount`} />
            <CustomTableContainer sx={{ mt: 1, border: '1px solid #000000', borderLeft: 'none' }}>
                <CustomTable>
                    <CustomTableHead>
                        {headers.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <CustomTableHeadCell size='small' sx={{ bgcolor: '#d7d7d7', width: 100, whiteSpace: 'nowrap', color: '#000000', borderLeft: '1px solid #000000' }}>{item}</CustomTableHeadCell>
                                </React.Fragment>
                            )
                        })}
                    </CustomTableHead>
                    <CustomTableBody>
                        {columns.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <CustomTableRow>
                                        <CustomTableBodyCell size='small' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.txn_date}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.val_date}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.disc}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.ref_no}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' align='right' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.dr_amt}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' align='right' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.cr_amt}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' align='right' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.bal}</CustomTableBodyCell>
                                    </CustomTableRow>
                                </React.Fragment>
                            )
                        })}
                    </CustomTableBody>
                </CustomTable>
            </CustomTableContainer>
        </Box>
    )
}
const AmountTransactionTypeColumn = (props) => {
    const { value, setValue } = props

    const headers = ["Transaction Date", "Value Date", "Description", "Ref No./Cheque No.", "Amount", "Transaction Type", "Balance Amount",]
    const columns = [
        { txn_date: '2023-12-12', val_date: '2023-12-12', disc: 'Travel Expense', ref_no: '0001', amount: '1000', type: 'Dr', bal: '1000' },
        { txn_date: '2023-12-12', val_date: '2023-12-12', disc: 'Rent Income', ref_no: '0002', amount: '7500', type: 'Cr', bal: '8500' },
    ]
    return (
        <Box sx={{ p: 2 }}>
            <CustomRadio id={'amount_and_transaction_type_columns_radio_btn'} dataTestId={'amount_and_transaction_type_columns_radio_btn'} checked={value} onClickedHandler={(value) => { setValue() }} label={`Amount and Transaction Type Column`} />
            <CustomHelperText sx={{ ml: 3.2 }} label={`Different Column for Amount and Transaction Type`} />
            <CustomTableContainer sx={{ mt: 1, border: '1px solid #000000', borderLeft: 'none' }}>
                <CustomTable>
                    <CustomTableHead>
                        {headers.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <CustomTableHeadCell size='small' sx={{ bgcolor: '#d7d7d7', width: 100, whiteSpace: 'nowrap', color: '#000000', borderLeft: '1px solid #000000' }}>{item}</CustomTableHeadCell>
                                </React.Fragment>
                            )
                        })}
                    </CustomTableHead>
                    <CustomTableBody>
                        {columns.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <CustomTableRow>
                                        <CustomTableBodyCell size='small' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.txn_date}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.val_date}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.disc}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.ref_no}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' align='right' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.amount}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' align='right' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.type}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' align='right' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.bal}</CustomTableBodyCell>
                                    </CustomTableRow>
                                </React.Fragment>
                            )
                        })}
                    </CustomTableBody>
                </CustomTable>
            </CustomTableContainer>
        </Box>
    )
}
const AmountWithTransactionTypeColumn = (props) => {
    const { value, setValue } = props

    const headers = ["Transaction Date", "Value Date", "Description", "Ref No./Cheque No.", "Transaction Amount", "Balance Amount",]
    const columns = [
        { txn_date: '2023-12-12', val_date: '2023-12-12', disc: 'Travel Expense', ref_no: '0001', txn_amount: '1000 Dr', bal: '1000' },
        { txn_date: '2023-12-12', val_date: '2023-12-12', disc: 'Rent Income', ref_no: '0002', txn_amount: '7500 Cr', bal: '8500' },
    ]
    return (
        <Box sx={{ p: 2 }}>
            <CustomRadio id={'amount_with_transaction_type_column_radio_btn'} dataTestId={'amount_with_transaction_type_column_radio_btn'} checked={value} onClickedHandler={(value) => { setValue() }} label={`Amount with Transaction Type Column`} />
            <CustomHelperText sx={{ ml: 3.2 }} label={`Same Column for Amount and Transaction Type`} />
            <CustomTableContainer sx={{ mt: 1, border: '1px solid #000000', borderLeft: 'none' }}>
                <CustomTable>
                    <CustomTableHead>
                        {headers.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <CustomTableHeadCell size='small' sx={{ bgcolor: '#d7d7d7', width: 100, whiteSpace: 'nowrap', color: '#000000', borderLeft: '1px solid #000000' }}>{item}</CustomTableHeadCell>
                                </React.Fragment>
                            )
                        })}
                    </CustomTableHead>
                    <CustomTableBody>
                        {columns.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <CustomTableRow>
                                        <CustomTableBodyCell size='small' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.txn_date}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.val_date}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.disc}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.ref_no}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' align='right' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.txn_amount}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' align='right' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.bal}</CustomTableBodyCell>
                                    </CustomTableRow>
                                </React.Fragment>
                            )
                        })}
                    </CustomTableBody>
                </CustomTable>
            </CustomTableContainer>
        </Box>
    )
}
const AmountWithNegativeAmountColumn = (props) => {
    const { value, setValue } = props

    const headers = ["Transaction Date", "Value Date", "Description", "Ref No./Cheque No.", "Transaction Amount", "Balance Amount",]
    const columns = [
        { txn_date: '2023-12-12', val_date: '2023-12-12', disc: 'Travel Expense', ref_no: '0001', txn_amount: '-1000', bal: '1000' },
        { txn_date: '2023-12-12', val_date: '2023-12-12', disc: 'Rent Income', ref_no: '0002', txn_amount: '7500', bal: '8500' },
    ]
    return (
        <Box sx={{ p: 2 }}>
            <CustomRadio id={'amount_with_negative_value_column_radio_btn'} dataTestId={'amount_with_negative_value_column_radio_btn'} checked={value} onClickedHandler={(value) => { setValue() }} label={`Amount with Negative Values`} />
            <CustomHelperText sx={{ ml: 3.2 }} label={`Single Column for Amount without Transaction Type but with Negative Values for Debit Amount`} />
            <CustomTableContainer sx={{ mt: 1, border: '1px solid #000000', borderLeft: 'none' }}>
                <CustomTable>
                    <CustomTableHead>
                        {headers.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <CustomTableHeadCell size='small' sx={{ bgcolor: '#d7d7d7', width: 100, whiteSpace: 'nowrap', color: '#000000', borderLeft: '1px solid #000000' }}>{item}</CustomTableHeadCell>
                                </React.Fragment>
                            )
                        })}
                    </CustomTableHead>
                    <CustomTableBody>
                        {columns.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <CustomTableRow>
                                        <CustomTableBodyCell size='small' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.txn_date}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.val_date}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.disc}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.ref_no}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' align='right' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.txn_amount}</CustomTableBodyCell>
                                        <CustomTableBodyCell size='small' align='right' sx={{ whiteSpace: 'nowrap', borderLeft: '1px solid #000000', borderTop: '1px solid #000000' }}>{item.bal}</CustomTableBodyCell>
                                    </CustomTableRow>
                                </React.Fragment>
                            )
                        })}
                    </CustomTableBody>
                </CustomTable>
            </CustomTableContainer>
        </Box>
    )
}

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
