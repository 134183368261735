import React from 'react';
import { ws_export_file_status } from './socketUrl';
import { getBusinessInfo } from '../config/cookiesInfo';

export const DownloadStatusWebSocket = () => {
    const [lastMessage, setLastMessage] = React.useState(null);
    const [connectionStatus, setConnectionStatus] = React.useState('connecting'); // Initialize with 'connecting'

    React.useEffect(() => {

        const socket = new WebSocket(ws_export_file_status());

        socket.onopen = () => {
            setConnectionStatus('open');
        };

        socket.onmessage = (event) => {
            setLastMessage(event);
        };

        socket.onclose = () => {
            setConnectionStatus('closed');
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            setConnectionStatus('error');
        };

        return () => {
            socket.close();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getBusinessInfo()?.id]);

    // console.log('connectionStatus for DOWNLOAD:', connectionStatus);
    return lastMessage;
};

