import React from 'react';
import PropTypes from 'prop-types';
import { apiAction } from '../../api/api';
import { styled } from '@mui/material/styles';
import * as Actions from '../../state/Actions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { stateChangeManager } from '../../utils/Utils';
import CustomButton from '../custom/button/CustomButton';
import CustomTypography from '../custom/typography/CustomTypography';
import DownloadPaymentData from '../pages/invoicing/payment/DownloadPaymentData';
import DownloadBillsData from '../pages/invoicing/purchase/bills/DownloadBillsData';
import DownloadInvoiceData from '../pages/invoicing/sales/invoices/DownloadInvoiceData';
import DownloadEstimateData from '../pages/invoicing/sales/estimate/DownloadEstimateData';
import DownloadVendorsData from '../pages/invoicing/purchase/vendors/DownloadVendorsData';
import DownloadExpenseData from '../pages/invoicing/purchase/expense/DownloadExpenseData';
import DownloadSalesOrderData from '../pages/invoicing/sales/order/DownloadSalesOrderData';
import DownloadCustomersData from '../pages/invoicing/sales/customers/DownloadCustomersData';
import DownloadCreditNoteData from '../pages/invoicing/sales/creditNote/DownloadCreditNoteData';
import DownloadDebitNoteData from '../pages/invoicing/purchase/debitNote/DownloadDebitNoteData';
import DownloadDataBackupFile from '../pages/setting/settings/DataBackup/DownloadDataBackupFile';
import DownloadPurchaseOrderData from '../pages/invoicing/purchase/order/DownloadPurchaseOrderData';
import DownloadJournalEntry from '../pages/accounting/journal/downloadJournalEntry/DownloadJournalEntry';
import DownloadDeliveryChallanData from '../pages/invoicing/sales/deliveryChallan/DownloadDeliveryChallanData';

import {
    generate_bill_backup_file,
    generate_party_backup_file,
    generate_expense_backup_file,
    generate_invoice_backup_file,
    generate_payment_backup_file,
    generate_complete_backup_file,
    generate_estimates_backup_file,
    generate_debit_note_backup_file,
    generate_credit_note_backup_file,
    generate_sales_order_backup_file,
    generate_journal_entry_backup_file,
    generate_purchase_order_backup_file,
    generate_delivery_challan_backup_file,
} from '../../api/urls';

import {
    Box,
    Grid,
    Radio,
    Dialog,
    Checkbox,
    RadioGroup,
    DialogTitle,
    DialogContent,
    FormControlLabel,
} from '@mui/material';


const CommonDownloadLayout = (props) => {
    const { open, setOpen, filters, componentType, payment_type = "" } = props;

    const filterData = {
        customers: {
            heading: "Download Customers Data",
            subHeading: "Once Downloaded you can view the file in Downloads Section.",
            component: <DownloadCustomersData
                setOpen={setOpen}
                url={generate_party_backup_file}
                defaultFilters={filters}
            />,
        },
        estimate: {
            heading: "Download Estimates Data",
            subHeading: "Once Downloaded you can view the file in Downloads Section.",
            component: <DownloadEstimateData
                setOpen={setOpen}
                url={generate_estimates_backup_file}
                defaultFilters={filters}
            />,
        },
        sales_order: {
            heading: "Download Sales Order Data",
            subHeading: "Once Downloaded you can view the file in Downloads Section.",
            component: <DownloadSalesOrderData
                setOpen={setOpen}
                url={generate_sales_order_backup_file}
                defaultFilters={filters}
            />,
        },
        delivery_challan: {
            heading: "Download Delivery Chalan Data",
            subHeading: "Once Downloaded you can view the file in Downloads Section.",
            component: <DownloadDeliveryChallanData
                setOpen={setOpen}
                url={generate_delivery_challan_backup_file}
                defaultFilters={filters}
            />,
        },
        invoice: {
            heading: "Download Invoices Data",
            subHeading: "Once Downloaded you can view the file in Downloads Section.",
            component: <DownloadInvoiceData
                setOpen={setOpen}
                url={generate_invoice_backup_file}
                defaultFilters={filters}
            />,
        },
        payment: {
            heading: "Download Payments Data",
            subHeading: "Once Downloaded you can view the file in Downloads Section.",
            component: <DownloadPaymentData
                setOpen={setOpen}
                url={generate_payment_backup_file}
                defaultFilters={filters}
                payment_type={payment_type}
            />,
        },
        credit_note: {
            heading: "Download Credit Note Data",
            subHeading: "Once Downloaded you can view the file in Downloads Section.",
            component: <DownloadCreditNoteData
                setOpen={setOpen}
                url={generate_credit_note_backup_file}
                defaultFilters={filters}
                payment_type={payment_type}
            />,
        },
        vendor: {
            heading: "Download Vendors Data",
            subHeading: "Once Downloaded you can view the file in Downloads Section.",
            component: <DownloadVendorsData
                setOpen={setOpen}
                url={generate_party_backup_file}
                defaultFilters={filters}
            />,
        },
        expense: {
            heading: "Download Expense Data",
            subHeading: "Once Downloaded you can view the file in Downloads Section.",
            component: <DownloadExpenseData
                setOpen={setOpen}
                url={generate_expense_backup_file}
                defaultFilters={filters}
            />,
        },
        purchase_order: {
            heading: "Download Purchase Orders Data",
            subHeading: "Once Downloaded you can view the file in Downloads Section.",
            component: <DownloadPurchaseOrderData
                setOpen={setOpen}
                url={generate_purchase_order_backup_file}
                defaultFilters={filters}
            />,
        },
        bills: {
            heading: "Download Bills Data",
            subHeading: "Once Downloaded you can view the file in Downloads Section.",
            component: <DownloadBillsData
                setOpen={setOpen}
                url={generate_bill_backup_file}
                defaultFilters={filters}
            />,
        },
        debit_note: {
            heading: "Download Debit Notes Data",
            subHeading: "Once Downloaded you can view the file in Downloads Section.",
            component: <DownloadDebitNoteData
                setOpen={setOpen}
                url={generate_debit_note_backup_file}
                defaultFilters={filters}
            />,
        },
        journal_entry: {
            heading: "Download Journal Entry Data",
            subHeading: "Once Downloaded you can view the file in Downloads Section.",
            component: <DownloadJournalEntry
                setOpen={setOpen}
                url={generate_journal_entry_backup_file}
                defaultFilters={filters}
            />,
        },
        backup_data: {
            heading: "Backup Your Data",
            subHeading: "Once Downloaded you can view the file in Downloads Section.",
            component: <DownloadDataBackupFile
                setOpen={setOpen}
                url={generate_complete_backup_file}
                defaultFilters={filters}
            />,
        }
    }

    const currentComponent = filterData[componentType]?.component;
    const heading = filterData[componentType]?.heading
    const subHeading = filterData[componentType]?.subHeading

    // console.log("filters in common page", filters);

    return (
        <BootstrapDialog
            open={open}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                <CustomTypography
                    text={heading}
                    sx={{
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                        pl: 2
                    }}
                />
            </BootstrapDialogTitle>

            <DialogContent dividers>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ pl: 2 }}>
                        <CustomTypography
                            text={subHeading}
                            sx={{
                                mb: 4,
                                fontSize: '16px',
                                color: '#000000',
                                fontWeight: '500',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                textTransform: "none",
                                fontFamily: "Noto Sans",
                            }}
                        />
                        {currentComponent}
                    </Box>
                </Box>
            </DialogContent>


        </BootstrapDialog>
    )
}

export default CommonDownloadLayout;

export const ApplyDefaultFilterCheckBox = ({ isDefaultFiltersApplied, setIsDefaultFiltersApplied }) => {
    return (
        <Grid item xs={12} sx={{ mt: -2, alignSelf: "end" }}>
            <FormControlLabel
                id={'previous_Filters_checkbox'}
                disabled={false}
                label={<span style={{ color: '#141414', textTransform: 'none', fontFamily: 'Noto Sans', fontStyle: 'normal', fontWeight: '700', lineHeight: '19px', fontSize: '14px' }}>
                    {"Export with currently applied filters"}
                </span>}
                control={<Checkbox
                    style={{
                        color: "#2464EB",
                    }}
                    checked={isDefaultFiltersApplied}
                    onChange={(e) => { setIsDefaultFiltersApplied(!isDefaultFiltersApplied); }} />}
            />
        </Grid>
    )
}

export const DownloadRadioButtons = ({ timePeriod, setTimePeriod, allButtonLabel }) => {

    return (
        <RadioGroup sx={{ mb: 1, mt: -1 }}>
            <FormControlLabel
                id={'all_radio_btn'}
                value="all"
                control={<Radio size="small" checked={timePeriod === 'all'} onChange={(e) => { setTimePeriod(e.target.value) }} />}
                label={
                    <span style={{
                        color: '#000000',
                        fontSize: '14px',
                        display: 'inline',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                        fontWeight: timePeriod === 'all' ? '600' : '400',
                    }}>{allButtonLabel}</span>
                }
            />
            <FormControlLabel
                id={'specificTimePeriod_radio_btn'}
                value="specificTimePeriod"
                control={<Radio size="small" checked={timePeriod === 'specificTimePeriod'} onChange={(e) => { setTimePeriod(e.target.value) }} />}
                label={
                    <span style={{
                        color: '#000000',
                        fontSize: '14px',
                        display: 'inline',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                        fontWeight: timePeriod === 'specificTimePeriod' ? '600' : '400',
                    }}>Specific Period</span>
                }
            />
        </RadioGroup>
    )
}

export const DownloadButtons = ({ onClose, url, filters, setOpen, timePeriod }) => {
    const dispatch = Actions.getDispatch(React.useContext);

    // console.log("filters ===>", filters)
    const onSaveAction = async () => {
        if (timePeriod === "specificTimePeriod" && !filters.from_datetime) {
            stateChangeManager(dispatch, Actions, true, 'error', "Please select a valid date range.")
            return
        }
        let res = await apiAction({
            method: "post",
            url: url(),
            data: filters,
        })
        if (res?.success) {
            stateChangeManager(dispatch, Actions, true, 'success', res?.status)
            setOpen(false);
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status)
        }
    }

    return (

        <div style={{ textAlign: 'center', marginTop: '24px', marginBottom: '24px' }}>
            <CustomButton
                btnLabel='Cancel'
                variant='outlined'
                id={'export_cancel_btn'}
                onClick={() => onClose(false)}
                dataTestId={'export_cancel_btn'}
                sx={{ textTransform: 'none', height: '35px', mr: 2, pl: 2, pr: 2, '&:hover': { backgroundColor: '#e8f3ff' } }}
            />
            <CustomButton
                btnLabel='Export'
                variant='contained'
                id={'export_export_btn'}
                dataTestId={'export_export_btn'}
                onClick={() => onSaveAction()}
                sx={{ textTransform: 'none', height: '35px', mr: 2, pl: 2, pr: 2, }}
            />
        </div>
    )
}

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

