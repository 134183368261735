import React from 'react';
// import Loader from '../custom/loader/Loader';
import * as Actions from '../../state/Actions';
import CustomTypography from '../custom/typography/CustomTypography';
import { Box } from '@mui/material';


export const NoDataComponent = (props) => {
  const { data = [], image, top = "40%", left = "45%", className = "", position = 'absolute',is_report=false } = props
  const state = Actions.getState(React.useContext);
  
  const loader = state.loader;
  const filterMessage = state.filterMessage;

  //Use to hide the NoDataComponent before api call
  const [loading,setLoading] = React.useState(false)
  React.useEffect(()=>{
    setTimeout(()=>{setLoading(true)},1000)
  },[])
  return (
    loading && !loader && data && data.length === 0 && <Box className={className} style={{ top: top, left: left, position: position, display: 'flex', justifyContent: 'center', }}>
      <Box>
        <Box

          style={{ paddingLeft: '10%', textAlign: 'center' }}
          component="img"
          sx={{
            height: 300,
            width: 200,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          alt=""
          src={image ? image.src : require('../../assets/image/undraw_credit_card_payments_re_qboh 1@3x.png')}

        />
        <Box style={{ paddingLeft: '0%', fontWeight: 'bold', textAlign: 'center' }}>
          <CustomTypography
            text={image ? image.message.text1 : 'No data to display'}
            sx={{
              mt: 2,
              fontSize: '18px',
              color: '#000000',
              fontWeight: 700,
              fontStyle: 'normal',
              lineHeight: '25px',
            }}
          />
        </Box>
        <Box style={{ textAlign: 'center' }}>
          <CustomTypography
            text={
              is_report?
              `There are no transactions during the selected date range.`
              :filterMessage ? filterMessage : image ? image.message.text2 : 'Once you add the data you will be able to view it here.'}
            sx={{
              mt: 1,
              fontSize: '12px',
              color: '#2F2F2E',
              fontWeight: 400,
              fontStyle: 'normal',
              lineHeight: '20px',
            }}
          />
        </Box>
      </Box>

    </Box>
  )
}