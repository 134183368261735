import React from 'react';
import Setup from './Setup';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import ViewAdjustedAccount from './ViewAdjustedAccount';
import CustomButton from '../../../custom/button/CustomButton';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { setLoader, stateChangeManager } from '../../../../utils/Utils';
import CustomTypography from '../../../custom/typography/CustomTypography';
import CreateOpeningBalanceAdjustment from './CreateOpeningBalanceAdjustment';
import { BootstrapDialog } from '../../bulkStatementUpload/mappings/Transactions';
import { BootstrapDialogTitle } from '../../bulkStatementUpload/mappings/Transactions';

import {
  CustomContainer,
  CustomTitleContainer,
  CustomButtonContainer,
} from '../../../custom/container/CustomContainer';

import {
  createTransactionBody,
  calculateTotalBalances,
} from './helper';

import {
  delete_opening_balance,
  manual_opening_balance_adjustment,
} from '../../../../api/urls';

import {
  Box,
  Grid,
  Typography,
  DialogActions,
  DialogContent,
} from '@mui/material';

import OpeningBalanceMapping from './OpeningBalanceFileMapping';
import ImportOpeningBalanceFile from './ImportOpeningBalanceFile';
import OpeningBalanceMappingPreview from './OpeningBalanceMappingPreview';




const OpeningBalanceAdjustment = (props) => {
  const { initial_state } = props;
  React.useEffect(() => { setCondition('view') }, [initial_state])

  const navigate = useNavigate();

  const [results, setResults] = React.useState([]);
  const dispatch = Actions.getDispatch(React.useContext);
  const [condition, setCondition] = React.useState('view');

  const [state, setState] = React.useState({
    id: '',
    title: '',
    open: false,
    condition: '',
    maxWidth: 'lg',
    fullWidth: true,
  })
  const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage) => {
    setState({
      url: url,
      open: true,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      deleteMessage: deleteMessage,
    })
  }
  const [open, setOpen] = React.useState(false);

  const onConfirmed = async () => {

    const data = {
      business_id: getBusinessInfo()?.id,
      transactions: createTransactionBody(results),
    }
    console.log('====>data',data)
    setLoader(dispatch, Actions, true)
    let res = await apiAction({
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      data: { ...data },
      url: manual_opening_balance_adjustment(),
      onError: (error) => {
        setLoader(dispatch, Actions, false);
      }
    })

    if (res?.success) {
      setResults([]);
      setCondition('view');
      setLoader(dispatch, Actions, false);
      // navigate(routesName.openingBalanceAdjustment.path);
      stateChangeManager(dispatch, Actions, true, 'success', res?.status);
    } else {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, 'error', res?.status);
    }
  }


  // Update destructuring to match the keys returned from calculateTotalBalances
  const { totalAmount, adjustmentAmount } = calculateTotalBalances(results);

  return (
    condition === 'file_upload' ?
      <ImportOpeningBalanceFile nextButton={() => { setCondition('mapping_file') }} /> :
      condition === 'mapping_file' ?
        <OpeningBalanceMapping nextButton={() => { setCondition('mapping_preview') }} /> :
        condition === 'mapping_preview' ?
          <OpeningBalanceMappingPreview onErrorButton={() => { setCondition('file_upload') }} onCancelButton={() => { setCondition('mapping_file') }} nextButton={() => { setCondition('view') }} />
          :
          <div>
            <CustomizedDialogs
              open={open}
              setOpen={setOpen}
              adjusted_amount={adjustmentAmount}
              statementUpload={() => setCondition('create')}
            />
            <CustomDialog
              state={state}
              setState={setState}
            />
            <CustomTitleContainer>
              <Grid container spacing={0} style={{
                alignItems: 'center',
                justifyItems: 'center',
                alignContent: 'space-evenly',
                justifyContent: 'space-evenly',
              }}>
                <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                  <CustomTitle title={'Opening Balances'} />
                </Grid>
                <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                  {
                    condition === 'setup' ?
                      <CustomButton
                        id={'import_btn'}
                        variant="contained"
                        dataTestId={'import_btn'}
                        sx={{ textTransform: 'none', }}
                        btnLabel='Import Opening Balances'
                        onClick={() => {
                          setCondition('file_upload')
                          Actions.resetFileImports(dispatch)
                          // navigate(routesName.openingBalanceImport.path)
                          dispatch(Actions.stateChange("migration_date", getBusinessInfo()?.migration_date))
                        }}
                      />
                      :
                      null
                  }

                  {
                    condition === 'view' ?
                      <>
                        <CustomButton
                          id={'edit_btn'}
                          dataTestId={'edit_btn'}
                          btnLabel={'Edit'}
                          variant="contained"
                          sx={{ ml: 1, textTransform: 'none', }}
                          onClick={() => { setCondition('setup') }}
                        />
                        {/* <CustomButton
                          id={'delete_btn'}
                          dataTestId={'delete_btn'}
                          variant="contained"
                          btnLabel={'Delete'}
                          sx={{ ml: 1, textTransform: 'none', }}
                          onClick={() => { stateChangeHandler('Delete Opening Balances', 'delete', 'sm', delete_opening_balance(getBusinessInfo().id), `Do you want to delete the entered opening balances?`) }}
                        /> */}
                      </>
                      : null
                  }
                </Grid>

              </Grid>
            </CustomTitleContainer>

            {
              condition === 'view' ?
                <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)', maxHeight: { xs: 'calc(100vh - 180px)', sm: '100%' } }}>
                  <ViewAdjustedAccount refresh={state.open} setCondition={setCondition} setAccounts={()=>{setResults([])}} deleteAction={(id,message)=>{stateChangeHandler('Delete Opening Balances', 'delete', 'sm', delete_opening_balance(id), message)}} />
                </CustomContainer>
                : condition === 'setup' ?
                  <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 202px)', maxHeight: { xs: 'calc(100vh - 240px)', sm: '100%' } }} key={condition}>
                    <Setup accounts={results} setAccounts={(data) => setResults(data)} />
                  </CustomContainer>

                  : condition === 'create' ?
                    <CustomContainer maxWidth={"400"} sx={{ maxHeight: { xs: 'calc(100vh - 230px)', sm: '100%' } }}>
                      <CreateOpeningBalanceAdjustment results={results} />
                    </CustomContainer>

                    : null
            }

            {condition === 'setup' || condition === 'create' ?
              <CustomButtonContainer>
                <Buttons
                  updateMigrationDate={() => {
                    if (totalAmount) {
                      if (adjustmentAmount) {
                        setOpen(true);
                      } else {
                        setCondition('create');
                      }
                    } else {
                      stateChangeManager(dispatch, Actions, true, 'error', `Please enter the opening balances for the accounts to continue.`);
                    }
                  }
                  } condition={condition} setCondition={setCondition} onCancel={() => setCondition('view')} onConfirmed={() => onConfirmed()} hideCancelBtn={true} />
              </CustomButtonContainer>

              : null
            }
          </div>
  )
}

export default OpeningBalanceAdjustment;

const Buttons = (props) => {
  const { hideCancelBtn, updateMigrationDate, condition, setCondition, onCancel, onConfirmed } = props;
  return (
    <Box component="form" noValidate  >
      <Grid container>
        <Grid item xs={4} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
          {hideCancelBtn &&
            <CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={() => { onCancel() }} />
          }
        </Grid>
        <Grid item xs={8} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
          {condition === 'setup' ?
            <CustomButton id={'continue_btn'} dataTestId={'continue_btn'} variant='contained' sx={{ textTransform: 'none', }} btnLabel={'Continue'} onClick={() => { updateMigrationDate(); }} />
            :
            <>
              <CustomButton id={'setup_btn'} dataTestId={'setup_btn'} variant='contained' sx={{ textTransform: 'none', }} btnLabel={'Back'} onClick={() => { setCondition('setup') }} />
              <CustomButton id={'confirm_btn'} dataTestId={'confirm_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none', }} btnLabel={'Confirm'} onClick={() => { onConfirmed() }} />
            </>
          }
        </Grid>
      </Grid>
    </Box>
  )
}

const CustomizedDialogs = (props) => {
  const { open, setOpen, statementUpload, adjusted_amount } = props

  const handleClicked = () => {
    statementUpload();
    setOpen(false);
  };
  return (
    <div>
      <BootstrapDialog
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
          <CustomTypography
            text={'Note'}
            sx={{
              color: '#000000',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom sx={{
            opacity: 0.8,
            wordSpacing: 2,
            lineHeight: 1.5,
            color: '#000000',
            fontSize: '14px',
            fontWeight: '700',
            fontStyle: 'normal',
            fontFamily: "Noto Sans",
          }}
          >
            The total debits and credits differ by {<CurrencyFormatter amount={adjusted_amount} />}. You can go back and adjust the balances to remove the difference, or you can continue and the difference will be transferred to the Opening Balance Adjustment account.
          </Typography>

          <div style={{ textAlign: 'center', marginTop: '24px' }}>
            <CustomButton
              id={'go_back_btn'}
              dataTestId={'go_back_btn'}
              variant='outlined'
              btnLabel='Go Back'
              onClick={() => { setOpen(false); }}
              sx={{ textTransform: 'none', mr: 1, pl: 2, pr: 2, '&:hover': { backgroundColor: '#e8f3ff' } }}
            />
            <CustomButton
              id={'go_continue_btn'}
              dataTestId={'go_continue_btn'}
              variant='contained'
              btnLabel='Continue'
              onClick={() => handleClicked()}
              sx={{ textTransform: 'none', mr: 2, pl: 2, pr: 2, }}
            />
          </div>

          <DialogActions>
          </DialogActions>
        </DialogContent>
      </BootstrapDialog>
    </div>
  )
}
