import React from 'react';
import validator from 'validator';
import Input from '../../../../common/Input';
import { apiAction } from '../../../../../api/api';
import DateRange from '../../../../common/DateRange';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import CustomButton from '../../../../custom/button/CustomButton';
import { LabelWithAsteriskMark } from '../../../../common/CommonLabel';
import CustomTypography from '../../../../custom/typography/CustomTypography';

import {
    CustomContainer,
    CustomTitleContainer,
    CustomButtonContainer,
} from '../../../../custom/container/CustomContainer';

import {
    getBusinessInfo,
} from '../../../../../config/cookiesInfo';

import {
    setLoader,
    isFormValid,
    stateChangeManager
} from '../../../../../utils/Utils';

import {
    send_invitation_mail,
} from '../../../../../api/urls';

import {
    Box,
    Grid,
    Radio,
    Switch,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';


const Dropdown = DateRange;
const InviteNewUser = (props) => {
    const { id, onCancel } = props;
    const dispatch = Actions.getDispatch(React.useContext);


    const initial_item_data = {
        role: null,
        to_email: null,
        username: null,
        last_name: null,
        first_name: null,
        send_type: 'normal',
        business_id: getBusinessInfo().id,

    }
    const [data, setData] = React.useState({ ...initial_item_data });
    const [isFormSubmitted, setFormSubmitted] = React.useState(false);

    const onSave = async () => {
        setFormSubmitted(true);
        let validation_data = [
            { key: "first_name", message: 'First name field can not be empty' },
            { key: "last_name", message: 'Last name field can not be empty' },
            { key: "to_email", message: 'Email field can not be empty' },
            { key: '', validation: (!data.to_email || !validator.isEmail(data.to_email)), message: 'Please enter valid email.' },
            { key: "role", message: 'please select position' },
        ]
        const { isValid, message } = isFormValid(data, validation_data)
        if (isValid) {
            setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'post',
                url: send_invitation_mail(),
                data: { ...data, username: data.first_name + ' ' + data.last_name },
            })
            if (res?.success) {
                onCancel();
                setLoader(dispatch, Actions, false);
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);

            } else {
                setLoader(dispatch, Actions, false)
                stateChangeManager(dispatch, Actions, true, 'error', res?.status)
            }
        } else {
            setLoader(dispatch, Actions, false)
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }

    }
    return (
        <div>
            <CustomTitleContainer sx={{ pt: 1.2, pb: 1.25 }}>
                <CustomTitle title={id ? 'Invite User' : 'Invite New User'} />
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 216px)', }}>
                <Grid container sx={{ pb: 2, height: '100%' }}>
                    <Grid item xs={12} sm={7} sx={{ borderRight: '2px solid #F5F5F5' }}>
                        <UserInformation
                            data={data}
                            setData={setData}
                            isFormSubmitted={isFormSubmitted}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={5}>
                        <PermissionInformation />
                    </Grid> */}
                </Grid>
            </CustomContainer>

            <CustomButtonContainer>
                <Buttons onSave={() => { onSave() }} onCancel={() => onCancel()} />
            </CustomButtonContainer>

        </div>
    )
}

export default InviteNewUser;

const UserInformation = (props) => {
    const { data, setData, isFormSubmitted } = props;


    const [roleType, setRoleType] = React.useState('view')

    const positionList = [
        { name: 'Partner/Co-owner', value: 'partner_owner' },
        { name: 'Accountant/Bookkeeper', value: 'accountant' },
        // { name: 'Family Member' },
        { name: 'Sales Person', value: 'sales_person' },
        { name: 'Assistant', value: 'assistant' },
        // { name: 'Block Advisors Tax Pro' },
        { name: 'Other', value: 'other' },
    ]

    const roleList = [
        { label: 'Admin', value: 'admin' },
        { label: 'Editor', value: 'editor' },
        { label: 'Payroll Manager', value: 'payroll_manager' },
        { label: 'Viewer', value: 'view' },
    ]
    const onChange = (key_name, value) => {
        setData({ ...data, [key_name]: value })
    }
    return (
        <Box sx={{ p: 2, pt: 0 }}>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Input
                        autoFocus={true}
                        id={'input_user_first_name'}
                        dataTestId={'input_user_first_name'}

                        item={{
                            type: 'text',
                            fullWidth: true,
                            placeholder: 'Enter First Name',
                            value: data.first_name ? data.first_name : '',
                            validation: isFormSubmitted && !data.first_name,
                            title: <LabelWithAsteriskMark label={'First Name'} />,
                            onChange: (e) => { onChange('first_name', e.target.value !== '' ? e.target.value : null) }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        id={'input_user_last_name'}
                        dataTestId={'input_user_last_name'}

                        item={{
                            type: 'text',
                            fullWidth: true,
                            placeholder: 'Enter Last Name',
                            value: data.last_name ? data.last_name : '',
                            validation: isFormSubmitted && !data.last_name,
                            title: <LabelWithAsteriskMark label={'Last Name'} />,
                            onChange: (e) => { onChange('last_name', e.target.value !== '' ? e.target.value : null) }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        id={'input_user_email'}
                        dataTestId={'input_user_email'}

                        item={{
                            type: 'text',
                            fullWidth: true,
                            placeholder: 'Enter Email',
                            value: data.to_email ? data.to_email : '',
                            title: <LabelWithAsteriskMark label={'Email'} />,
                            validation: isFormSubmitted && (!data.to_email || !validator.isEmail(data.to_email)),
                            onChange: (e) => { onChange('to_email', e.target.value !== '' ? e.target.value : null) }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Dropdown
                        results={positionList}
                        id={'position_dropdown'}
                        dataTestId={'position_dropdown'}
                        placeholder='Position at the business'
                        validation={isFormSubmitted && !data.role}
                        label={<LabelWithAsteriskMark label={'Position'} />}
                        value={positionList.find((item) => item.value === data.role)}
                        setValue={(event, selected) => {
                            if (selected) {
                                data.role = selected.value;
                            } else {
                                data.role = null;
                            }
                            setData({ ...data })
                        }}
                    />
                </Grid>
                {/* <Grid item xs={12} container>
                    <Grid item xs={12}>
                        <CustomTypography
                            text={<LabelWithAsteriskMark label={'Select Role'} />}
                            sx={{
                                mb: 1,
                                color: '#000000',
                                fontSize: '14px',
                                fontWeight: '700',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                textTransform: "none",
                                fontFamily: "Noto Sans",
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ borderRight: '2px solid #F5F5F5' }}>
                        <RadioGroup>
                            {
                                roleList.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <FormControlLabel value={item.value}
                                                id={item.value}
                                                dataTestId={item.value}
                                                label={<span style={{
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    lineHeight: '18px',
                                                    fontStyle: 'normal',
                                                    fontFamily: "Noto Sans",
                                                    fontWeight: roleType === item.value ? '600' : '400',
                                                }}>{item.label}</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={roleType === item.value} onChange={(e) => { setRoleType(e.target.value) }} />} />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={8}>
                        <Box sx={{ p: 3 }}>
                            {roleType === 'editor' ?
                                <React.Fragment>
                                    <FormControlLabel
                                        label={<span style={{
                                            color: '#000000',
                                            fontWeight: '600',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                        }}>{'Send Invoice'}</span>}
                                        control={<Switch defaultChecked />} />
                                    <CustomTypography
                                        text={`Able to send invoices from Owner's`}
                                        sx={{
                                            mb:2,
                                            mt: 0.5,
                                            color: "#7A7A7A",
                                            fontSize: "12px",
                                            fontWeight: "500",
                                            lineHeight: "18px",
                                            fontStyle: "normal",
                                            fontFamily: "Noto Sans",
                                        }}
                                    />
                                </React.Fragment>
                                : null
                            }
                            <FormControlLabel
                                label={<span style={{
                                    color: '#000000',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}>{'Manage Payroll'}</span>}
                                control={<Switch defaultChecked />} />
                            <CustomTypography
                                text={'Full access to payroll'}
                                sx={{
                                    mt: 0.5,
                                    color: "#7A7A7A",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    lineHeight: "18px",
                                    fontStyle: "normal",
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid> */}
            </Grid>
        </Box>
    )

}

const PermissionInformation = (props) => {
    const { select_role = 'view' } = props;


    const permissionList = {
        view: {
            title: "Viewer Permission",
            subTitle: "Best for anyone who needs view-only access",
            list: [
                { text1: 'Sales', text2: 'View Only' },
                { text1: 'Purchase', text2: 'View Only' },
                { text1: 'Accounting', text2: 'View Only' },
                { text1: 'Banking', text2: 'No Access' },
                { text1: 'Payroll', text2: 'Optional' },
                { text1: 'Reports', text2: 'View Only' },
                { text1: 'Integrations', text2: 'No Access' },
                { text1: 'User Management', text2: 'View Only' },
                { text1: 'Data Export', text2: 'No Access' },
            ]
        }
    }

    const data = permissionList[select_role]
    return (
        <Box sx={{ p: 2, pt: 0 }}>
            <Box sx={{ mb: 2 }}>
                <CustomTypography
                    text={data.title}
                    sx={{
                        color: "#000000",
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "18px",
                        fontStyle: "normal",
                        fontFamily: "Noto Sans",
                    }}
                />
                <CustomTypography
                    text={data.subTitle}
                    sx={{
                        mt: 0.5,
                        color: "#7A7A7A",
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "18px",
                        fontStyle: "normal",
                        fontFamily: "Noto Sans",
                    }}
                />
            </Box>

            <Grid container item xs={12}>
                {
                    data.list.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Grid item xs={6} sx={{ pt: 1, pb: 1.5, borderTop: '2px solid #F5F5F5' }}>
                                    <CustomTypography
                                        text={item.text1}
                                        sx={{
                                            opacity: 0.8,
                                            color: "#000000",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            lineHeight: "18px",
                                            fontStyle: "normal",
                                            fontFamily: "Noto Sans",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sx={{ pt: 1, pb: 1.5, borderTop: '2px solid #F5F5F5', textAlign: 'right' }}>
                                    <CustomTypography
                                        text={item.text2}
                                        sx={{
                                            color: "#7A7A7A",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            lineHeight: "18px",
                                            fontStyle: "normal",
                                            fontFamily: "Noto Sans",
                                        }}
                                    />
                                </Grid>
                            </React.Fragment>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}


const Buttons = (props) => {
    const { onSave, onCancel } = props
    return (
        <Box component="form" noValidate  >
            <Grid container sx={{ p: 1 }}>
                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                    <CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', mr: 1, '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
                    <CustomButton id={'save_btn'} dataTestId={'save_btn'} variant='contained' sx={{ textTransform: 'none', }} btnLabel={'Invite'} onClick={onSave} />
                </Grid>
            </Grid>
        </Box>
    )
}