import React from 'react';
import { Box, Grid } from '@mui/material';
import CustomButton from '../../../custom/button/CustomButton';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { ReactComponent as PlayStoreIcon } from '../../../../assets/svg/Play_store.svg';
import { ReactComponent as AppleStoreIcon } from '../../../../assets/svg/apple_store.svg';

const Platform = () => {
    return (
        <Box
            sx={{
                pb: 6,
                pt: 6,
            }}
        >
            <Grid container>
                <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box >
                        <CustomTypography
                            text="Finycs on Web and android"
                            // text="Finycs on Web android and iOS"
                            sx={{
                                mt: 2,
                                color: '#FFFF',
                                fontWeight: 700,
                                fontSize: '40px',
                                lineHeight: '56px',
                                letterSpacing: '0px',
                            }}
                        />
                        <CustomTypography
                            text={<span>Finycs is available on Web and Android platforms.<br /> This allows businesses to access their financial data on any <br />device and stay on top of their finances on the go.</span>}
                            // text={<span>Finycs is available on Web, Android, and iOS platforms.<br /> This allows businesses to access their financial data on any <br />device and stay on top of their finances on the go.</span>}
                            sx={{
                                mb: 2,
                                mt: 2,
                                color: '#FFFF',
                                fontSize: '20px',
                                fontWeight: 400,
                                lineHeight: '32px',
                                fontStyle: 'normal',
                                letterSpacing: '0.2px',
                            }}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ActionButtons />
                </Grid>
            </Grid>
        </Box>
    );
};

const ActionButtons = () => {
    return (
        <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
            <CustomButton

                sx={{
                    mb: 3,
                    '&:hover svg': {
                        transform: 'scale(1.1)',
                        transition: 'transform 0.3s ease-in-out',
                    }
                }}
                variant="text"
                target='_blank'
                btnLabel={<PlayStoreIcon />}
                id="landing_create_free_account"
                dataTestId="landing_create_free_account"
                href='https://play.google.com/store/apps/details?id=com.anctech.finycs'
            />
            {/* <CustomButton
                sx={{
                    '&:hover svg': {
                        transform: 'scale(1.1)',
                        transition: 'transform 0.3s ease-in-out',
                    }
                }}
                variant="text"
                btnLabel={<AppleStoreIcon />}
                id="landing_book_free_demo"
                dataTestId="landing_book_free_demo"
            // Uncomment the line below when the Apple Store link is available
            // onClick={() => { navigate("/auth" + routesName.signIn.path) }}
            /> */}
        </Box>
    );
};

export default Platform;
