import React from 'react';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../../api/api';
import DateRange from '../../../../common/DateRange';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import { VendorViewLink } from '../../common/CommonLinks';
import CommonSearch from '../../../../common/CommonSearch';
import CommonExport from '../../../../common/CommonExport';
import { routesName } from '../../../../../config/routesName';
import { CustomLink } from '../../../../custom/link/CustomLink';
import ListItemActions from '../../../../common/ListItemActions';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { NoDataComponent } from '../../../../common/NoDataComponent';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import { HeadingWithSortable } from '../../../../common/SortableHeading';
import CommonDownloadLayout from '../../../../common/CommonDownloadLayout';
import ViewPageActionButtons from '../../../../common/ViewPageActionButtons';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';
import { CustomContainer, CustomTitleContainer } from '../../../../custom/container/CustomContainer';

import {
  setLoader,
  setFilterMessage,
  isFiltersApplied,
  stateChangeManager,
  returnTransactionType,
} from '../../../../../utils/Utils';

import {
  list_vendor,
  export_party,
  delete_vendor,
  mark_party_inactive,
} from '../../../../../api/urls';

import {
  Box,
  Grid,
} from '@mui/material';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../../custom/table/CustomTable';


const Dropdown = DateRange;

const VendorsList = () => {
  let navigate = useNavigate();

  const stateData = Actions.getState(React.useContext);
  const dispatch = Actions.getDispatch(React.useContext);

  const { sort_by } = stateData;
  const remove_default_Sort_by = () => { dispatch(Actions.stateChange("sort_by", null)) }

  const [page, setPage] = React.useState(1);
  const [results, setResults] = React.useState([]);
  const [disabledDataExport, setDisabledDataExport] = React.useState(true);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: undefined,
    previous: undefined,
    number_of_pages: undefined,
  })
  const [filters, setFilters] = React.useState({
    sort_order: 'A',
    is_inactive: false,
    sort_by: 'display_name',

  })
  const [state, setState] = React.useState({
    id: '',
    title: '',
    open: false,
    condition: '',
    maxWidth: 'lg',
    fullWidth: true,
  })


  const getApiResults = async () => {
    setLoader(dispatch, Actions, true);
    setFilterMessage(dispatch, Actions, null);
    let res = await apiAction({
      method: 'post',
      dispatch: dispatch,
      navigate: navigate,
      url: list_vendor(page),
      data: { role: "vendor", business_id: getBusinessInfo().id, ...filters },
    })

    if (res?.success) {
      setPagination({
        ...pagination,
        next: res?.result.next,
        count: res?.result.count,
        previous: res?.result.previous,
        number_of_pages: res?.result.number_of_pages,
      });
      setResults(res?.result.result);
      setLoader(dispatch, Actions, false);
      if (disabledDataExport && res?.result.count !== 0) { setDisabledDataExport(false) }
      if (isFiltersApplied(filters)) { setFilterMessage(dispatch, Actions, `No vendors found for your current filters. Verify your filters and try again.`) };
    } else {
      setLoader(dispatch, Actions, false);

    }
  }

  React.useEffect(() => {
    getApiResults();
    // eslint-disable-next-line
  }, [filters, page])

  React.useEffect(()=>{
    AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.CLICK,filter_type:eventsNames.actions.vendor.filter_type.ACTIVE}); 
},[])

  const stateChangeHandler = (title, condition, maxWidth, id, item, url) => {
    setState({
      id: id,
      url: url,
      item: item,
      open: true,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      onDeleteAction: () => { getApiResults(); }
    })
  }


  const markPartyActiveInactive = async (id, isActive) => {
    let res = await apiAction({
      method: 'post',
      url: mark_party_inactive(id),
      data: { business_id: getBusinessInfo().id, is_inactive: isActive },
    })
    if (res?.success) {
      getApiResults();
      stateChangeManager(dispatch, Actions, true, 'success', res?.status);
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', res?.status);
    }
  }

  const [isExport, setIsExport] = React.useState(false);
  const [isDownload,setIsDownload] = React.useState(false);

  return (
    <div>
      <CustomDialog
        state={state}
        setState={setState}
      />
      <CommonExport
        open={isExport}
        role={'vendor'}
        url={export_party}
        setOpen={setIsExport}
        fileName={`vendors`}
        title={`You can export your vendors from finycs in CSV or XLSX format.`}
      />
      <CommonDownloadLayout
        open={isDownload}
        setOpen={setIsDownload}
        filters={filters}
        componentType={"vendor"}
      />
      <CustomTitleContainer>
        <Grid container spacing={0} style={{
          alignItems: 'center',
          justifyItems: 'center',
          alignContent: 'space-evenly',
          justifyContent: 'space-evenly',
        }}>
          <Grid item xs={6} sm={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
            <CustomTitle title={'Vendors'} />
          </Grid>
          <Grid item xs={6} sm={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
            <CustomButton
              variant="contained"
              id={'add_vendor_btn'}
              btnLabel='Add a Vendor'
              dataTestId={'add_vendor_btn'}
              sx={{ ml: 2, textTransform: 'none', }}
              onClick={() => {
                navigate(routesName.invoicingPurchaseVendorAdd.path)
                AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.NEW})
              }}
            />

            <ViewPageActionButtons
              isPrint={false}
              isDownload={false}
              actionsList={
                [
                  {
                    name: 'Import Vendors', onClick: () => {
                      Actions.resetFileImports(dispatch)
                      navigate(routesName.invoicingPurchaseVendorImportFile.path)
                      AnalyticsEvent(eventsNames.categories.VENDORS, { action: eventsNames.actions.IMPORT });

                    }
                  },
                  {
                    disabled: disabledDataExport,
                    name: 'Export Vendors', onClick: () => { 
                      setIsExport(true);
                      AnalyticsEvent(eventsNames.categories.VENDORS, { action: eventsNames.actions.EXPORT });
                     }
                  },
                  {
                    disabled: disabledDataExport,
                    name: 'Download CSV', onClick: () => { 
                      setIsDownload(true);
                      AnalyticsEvent(eventsNames.categories.VENDORS, { action: eventsNames.actions.DOWNLOAD });
                     }
                  },
                ]
              }
            />
          </Grid>
        </Grid>
      </CustomTitleContainer>

      <CustomContainer maxWidth={"400"} sx={{maxHeight:{xs:'calc(100vh - 240px)',sm:'100%'}}}>
        <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '24px', display: '-webkit-flex', }}>
          <Filters filters={filters} setFilters={setFilters} setPage={setPage} />
        </div>

        <CustomTableContainer>
          <CustomTable>
            <VendorsListHeaders filters={filters} setFilters={setFilters} remove_default_Sort_by={remove_default_Sort_by} />
            <VendorsListBody data={results} stateChangeHandler={stateChangeHandler} markPartyActiveInactive={markPartyActiveInactive} setDisabledDataExport={setDisabledDataExport} />
          </CustomTable>
          <Box sx={{ pt: !results.length ? 10 : 0 }} >
            <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
          </Box>
        </CustomTableContainer>
      </CustomContainer>

      <CustomPagination
        page={page}
        count={pagination.number_of_pages}
        onChange={(event, newValue) => setPage(newValue)}
      />
    </div >
  )
}


const VendorsListHeaders = (props) => {
  const { filters, setFilters, remove_default_Sort_by } = props


  const columns = [
    {
      label: "Name",
      sortable: true,
      width: { width: 200 },
      field: "display_name",
    },
    {
      field: "gstin",
      label: "GSTIN",
      sortable: false,
      width: { width: 200 },
    },
    {
      field: "email",
      label: "Email",
      sortable: false,
      width: { width: 180 },
    },
    {
      label: "Phone",
      sortable: false,
      width: { width: 150 },
      field: "phone_number",
    },
    {
      align: 'right',
      sortable: false,
      label: "Currency",
      width: { width: 50 },
      field: "currency.currency_code",
    },
    {
      align: 'right',
      sortable: false,
      width: { width: 150 },
      label: "Opening Balance",
      field: "opening_balance",
    },
    {
      align: 'right',
      sortable: false,
      width: { width: 150 },
      label: "Due Amount",
      field: "due_amount",
    },
    {
      align: 'right',
      sortable: false,
      width: { width: 150 },
      label: "Unused Credit",
      field: "unused_credit",
    },
    {
      field: "",
      align: 'right',
      sortable: false,
      label: "Actions",
      width: { width: 100 },
    },
  ]

  return (
    <CustomTableHead>
      <CustomTableRow >
        {
          columns.map((header, index) => {
            return (
              <React.Fragment key={index}>
                {
                  header.sortable ?
                    <CustomTableHeadCell style={header.width} align={header.align}><HeadingWithSortable heading={header.label} sortableKey={header.field} filters={filters} setFilters={setFilters} remove_default_Sort_by={() => remove_default_Sort_by()} /></CustomTableHeadCell>
                    :
                    <CustomTableHeadCell style={header.width} align={header.align}><span>{header.label}</span></CustomTableHeadCell>
                }
              </React.Fragment>
            )
          })
        }
      </CustomTableRow>
    </CustomTableHead>
  )
}

const VendorsListBody = (props) => {
  const { data, stateChangeHandler, markPartyActiveInactive, setDisabledDataExport } = props
  let navigate = useNavigate();

  return (
    <CustomTableBody>
      {
        data && data.map((item, index) => {
          return (
            <CustomTableRow key={index}>
              <CustomTableBodyCell sx={{ color: item.is_inactive ? '#7A7A7A' : '#000000', }}  ><VendorViewLink style={{ color: item.is_inactive ? '#7A7A7A' : '#2464EB', }} id={item.id} title={item.display_name} /></CustomTableBodyCell>
              <CustomTableBodyCell sx={{ color: item.is_inactive ? '#7A7A7A' : '#000000', }} ><span>{item.gstin ? item.gstin.toUpperCase() : '-'}</span></CustomTableBodyCell>
              <CustomTableBodyCell sx={{ color: item.is_inactive ? '#7A7A7A' : '#000000', }} ><span>{item.email ? item.email : '-'}</span></CustomTableBodyCell>
              <CustomTableBodyCell sx={{ color: item.is_inactive ? '#7A7A7A' : '#000000', }} ><span>{item.phone_number ? item.phone_number : '-'}</span></CustomTableBodyCell>
              <CustomTableBodyCell sx={{ color: item.is_inactive ? '#7A7A7A' : '#000000', }} align='right'><span>{item.currency_code}</span></CustomTableBodyCell>
              <CustomTableBodyCell sx={{ color: item.is_inactive ? '#7A7A7A' : '#000000', }} align='right'><span style={{ whiteSpace: 'nowrap' }}>{<CurrencyFormatter amount={item.opening_balance} currency={item.currency_code} />}{item.opening_balance ? returnTransactionType(item.opening_balance_transaction_type) : ''}</span></CustomTableBodyCell>
              <CustomTableBodyCell sx={{ color: item.is_inactive ? '#7A7A7A' : '#000000', }} align='right'><span>  {<CurrencyFormatter amount={item.due_amount} currency={item.currency_code} />}</span></CustomTableBodyCell>
              <CustomTableBodyCell sx={{ color: item.is_inactive ? '#7A7A7A' : '#000000', }} align='right'><span>{<CurrencyFormatter amount={item.unused_credit} currency={item.currency_code} />}</span></CustomTableBodyCell>
              <CustomTableBodyCell sx={{ color: item.is_inactive ? '#7A7A7A' : '#000000', }} align='right'>
                <ListItemActions
                  index={index}
                  actions={
                    [
                      { name: 'View', onClick: () => { navigate(routesName.invoicingPurchaseVendorView.path + "?id=" + item.id, { replace: false }); AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.VIEW}) } },
                      !item.is_inactive && { name: 'Edit', onClick: () => { navigate(routesName.invoicingPurchaseVendorAdd.path + "?id=" + item.id, { replace: false }); } },
                      !item.is_inactive && { name: 'Create Bill', onClick: () => { navigate(routesName.invoicingPurchaseBillAdd.path + "?vendor_id=" + item.id, { replace: false }); AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.vendor.action.CREATE_BILL}) } },
                      { name: item.is_inactive ? 'Mark as Active' : 'Mark as Inactive', onClick: () => { markPartyActiveInactive(item.id, !item.is_inactive);  AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.CLICK,switch:item.is_inactive ? eventsNames.actions.vendor.switch.MARK_ACTIVE:eventsNames.actions.vendor.switch.MARK_INACTIVE}); } },
                      { name: 'Delete', showDivider: true, onClick: () => { setDisabledDataExport(true); stateChangeHandler('Delete Vendor', 'delete', 'sm', item.id, item, delete_vendor(item.id)); AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.DELETE}) } }
                    ]
                  }
                />
              </CustomTableBodyCell>
            </CustomTableRow>
          )
        })
      }
    </CustomTableBody>
  )
}


export default VendorsList;

const Filters = (props) => {
  const { setPage, setFilters, filters } = props
  const statusList = [
    { name: 'Active Vendors', value: false },
    { name: 'Inactive Vendors', value: true },
    { name: 'All Vendors', value: null },
  ]
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} sm={2.5}>
        <Dropdown
          id={'status_dropdown'}
          dataTestId={'status_dropdown'}
          label="Status"
          results={statusList}
          disableClearable={true}
          titleVisibility={'hidden'}
          placeholder='Select Status'
          value={statusList.find((item) => item.value === filters.is_inactive)}
          setValue={(event, value) => {
            setPage(1);
            if (value) {
              filters['is_inactive'] = value.value
              if (value.value) {
                AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.CLICK,filter_type:eventsNames.actions.vendor.filter_type.INACTIVE});
            }else if(value.value === null){
                AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.CLICK,filter_type:eventsNames.actions.vendor.filter_type.ALL});
            }else{
                AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.CLICK,filter_type:eventsNames.actions.vendor.filter_type.ACTIVE});
            }
            } else {
              delete filters.is_inactive
            }
            setFilters({ ...filters })
          }}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <CommonSearch
          id={'input_search_name'}
          dataTestId={'input_search_name'}
          title={'Name'}
          fullWidth={true}
          titleVisibility={'hidden'}
          placeholder={'Search by Name'}
          onSearchValue={filters.display_name ? filters.display_name : ''}
          onSearch={(search_text) => {
            setPage(1);
            if (search_text) {
              filters.display_name = search_text
            }
            else {
              delete filters.display_name
            }
            setFilters({ ...filters, })
          }} />
      </Grid>

    </Grid>
  )
}
