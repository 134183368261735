import React from 'react'
import { Grid, Box, Divider } from '@mui/material';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CustomDateRangePicker from '../../../../custom/Datepicker/CustomDateRangePicker';
import { DownloadButtons, DownloadRadioButtons } from '../../../../common/CommonDownloadLayout';

const DownloadDataBackupFile = (props) => {
    const { setOpen, url } = props;

    const [filters, setFilters] = React.useState({
        "export_to": "csv",
        "business_id": getBusinessInfo().id,
    });
    const [timePeriod, setTimePeriod] = React.useState("all");

    React.useEffect(() => {
        if (timePeriod === "all") {
            setFilters({ ...filters, from_datetime: null, to_datetime: null })
        }
    }, [timePeriod])

    const onDateRangeChange = (dates) => {
        if (dates) {
            setFilters({
                ...filters,
                from_datetime: dates[0].format("YYYY/MM/DD HH:mm:ss"),
                to_datetime: dates[1].format("YYYY/MM/DD HH:mm:ss"),
            })

        } else {
            delete filters.to_datetime
            delete filters.from_datetime
            setFilters({ ...filters })
        }
    }

    return (
        <Box sx={{}}>
            <Grid container spacing={1}>
                <Grid item xs={15} sm={12} sx={{ mb: 2 }}>
                    <DownloadRadioButtons
                        timePeriod={timePeriod}
                        setTimePeriod={setTimePeriod}
                        allButtonLabel={"Backup All Data"}
                    />
                    {timePeriod === "specificTimePeriod" &&
                        <CustomDateRangePicker onDateRangeChange={onDateRangeChange} />
                    }
                </Grid>
            </Grid>

            <Divider />
            <DownloadButtons
                onClose={setOpen}
                url={url}
                filters={filters}
                setOpen={setOpen}
            />
        </Box>
    )
}

export default DownloadDataBackupFile
