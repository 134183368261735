import React from "react";
import SearchBar from "./SearchBar";
import NavBarUserProfile from "./NavBarUserProfile";
import * as Actions from '../../../../state/Actions';
import { routesName } from "../../../../config/routesName";
import { _Sidebar } from "../sideBar/common/CustomComponent";
import AccountCircle from '@mui/icons-material/AccountCircle';
import CustomTypography from "../../typography/CustomTypography";
import CustomBreadCrumbs from "../breadcrumbs/CustomBreadcrumbs";
import { getBusinessInfo } from "../../../../config/cookiesInfo";
import { eventsNames, AnalyticsEvent } from "../../../../firebase/firebaseAnalytics";
import {
  clearCookie,
} from "../../../../config/cookiesInfo";

import {
  setLoader,
  stateChangeManager,
} from "../../../../utils/Utils";

import {
  Box,
  Toolbar,
  Popover,
  IconButton,
} from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { DownloadStatusWebSocket } from "../../../../api/downloadStatusWebSocket";


const Navbar = (props) => {
  const { navigate, } = props
  const dispatch = Actions.getDispatch(React.useContext)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [forMobile, setForMobile] = React.useState(false);


  const logout = () => {
    clearCookie()
    navigate(`/auth${routesName.signIn.path}`)
    setLoader(dispatch, Actions, false)
  }

  const myAccount = () => {
    navigate(`${routesName.settings.path}?action=${'personal_information'}`)
    // navigate(routesName.settings.path+'?active=personal_information&tab=0')
  }


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    // AnalyticsEvent(eventsNames.categories.NAV_BAR,{action:eventsNames.actions.CLICK,state})

  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const webSocketResponse = DownloadStatusWebSocket();
  React.useEffect(() => {
    if (webSocketResponse) {
      let webSocketData = JSON.parse(webSocketResponse.data);
      if (webSocketData?.event_status) {
        // console.log('webSocketData===>', webSocketData?.event_message);
        stateChangeManager(dispatch, Actions, true, 'success', webSocketData?.event_message);
      }
    }
  }, [webSocketResponse])

  return (
    <div style={{ background: '#FFFFFF', borderBottom: '1px solid #EBEBEB' }}>
      <Toolbar disableGutters sx={{ alignItems: 'center',background:'transparent' }}>
        <Box style={{ padding: 8, paddingTop: 14, display: "none", }} onClick={() => setForMobile(!forMobile)} className="sidebar_open_close_icon" id={'sidebar_open_close_icon'} dataTestId={'sidebar_open_close_icon'}>
          <DehazeIcon />
        </Box>
        <Box sx={{ alignItems: 'center', flexGrow: 1, display: { xs: 'flex',} }}><CustomBreadCrumbs /></Box>
        <Box sx={{ alignItems: 'center', display: { xs: 'flex', } }}><SearchBar /></Box>
        <Box sx={{ alignItems: 'center', fontSize: '24px', color: '#757575',marginTop:'-4px',opacity:0.8,ml:0.5,mr:0.5,background:'transparent' }}>|</Box>

        <Box sx={{ alignItems: 'center', display: { xs: 'flex', } }}>

          <IconButton
            disableRipple
            sx={{
              p:0,
              mr: 1,
            }}
            size="medium"
            onClick={handleMenu}
            id={'my_account_icon_btn'}
            dataTestId={'my_account_icon_btn'}
          >
            <CustomTypography
              text={<span>{getBusinessInfo()?.legal_name}</span>}
              sx={{
                mr: 1,
                zIndex:999999,
                color: '#000000',
                maxWidth: '150px',
                fontSize: '14px',
                fontWeight: '700',
                cursor: 'pointer',
                overflow: 'hidden',
                lineHeight: '18px',
                fontStyle: 'normal',
                whiteSpace: 'nowrap',
                fontFamily: "Noto Sans",
                textOverflow: 'ellipsis',
              }}
            />
            <AccountCircle fontSize={"medium"} />
          </IconButton>
          <NavBarUserProfile
            logout={logout}
            anchorEl={anchorEl}
            navigate={navigate}
            myAccount={myAccount}
            handleClose={handleClose}
          />
        </Box>
      </Toolbar>

      {forMobile && <Popover
        open={forMobile}
        marginThreshold={0}
        className="sidebar_popover"
        id={'popover_side_bar_mobile'}
        dataTestId={'popover_side_bar_mobile'}
        onClose={() => setForMobile(!forMobile)}
      >
        <div className="m_sidebar" style={{ display: 'none', width: '100%', minHeight: '100vh' }}>
          <_Sidebar {...props} open={forMobile} setOpen={setForMobile} setForMobile={setForMobile} height={'84vh'} CustomTooltip={'span'} />
        </div>
      </Popover>
      }
    </div>
  )
}
export default Navbar;



