import React from 'react';
import { getDateFormat } from '../../utils/Utils';
import CustomTypography from '../custom/typography/CustomTypography';
import CustomDatePicker from '../custom/Datepicker/CustomDatePicker';




const DatePicker = (props) => {
    const { id, dataTestId, isTime,dateTimeFormat,inputFormat=getDateFormat(), minDate, no_minDate, maxDate, date, setDate, label, sx, placeholder, title, validation, disabledCloseIcon, disabledDatePicker, titleVisibility = "visible" } = props

    const handleChange = (newValue) => {
        setDate(newValue);
    };

    return (
        <div style={{ textAlign: 'start' }}>
            <CustomTypography
                text={title ? title : label}
                sx={{
                    mb: 1,
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: 'none',
                    fontFamily: "Noto Sans",
                    visibility: titleVisibility,
                }}
            />
            <CustomDatePicker
                id={id}
                sx={sx}
                value={date}
                isTime={isTime}
                minDate={minDate}
                maxDate={maxDate}
                dataTestId={dataTestId}
                no_minDate={no_minDate}
                validation={validation}
                onChange={handleChange}
                placeholder={placeholder}
                inputFormat={getDateFormat()}
                dateTimeFormat={dateTimeFormat}
                disabledCloseIcon={disabledCloseIcon}
                disabledDatePicker={disabledDatePicker}
                // inputFormat={placeholder ? date ? moment(date).format(inputFormat) : placeholder : getDateFormat()}
                {...props}
            />
        </div>
    )
}

export default DatePicker