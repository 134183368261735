import React from 'react';
import moment from "moment";
import '../../../assets/css/otp.css';
import Logo from "../../common/Logo";
import { apiAction } from '../../../api/api';
import { useNavigate } from 'react-router-dom';
import { images } from "../../../utils/Constant";
import * as Actions from '../../../state/Actions';
import CustomTitle from '../../common/CustomTitle';
import { routesName } from '../../../config/routesName';
import CustomButton from '../../custom/button/CustomButton';
import CustomTypography from '../../custom/typography/CustomTypography';

import {
    isNumber,
    setLoader,
    stateChangeManager,
} from '../../../utils/Utils';

import {
    clearCookie,
    getUserDetails,
} from '../../../config/cookiesInfo';

import {
    CustomContainer,
    CustomButtonContainer,
    CustomTitleContainer
} from '../../custom/container/CustomContainer';


import {
    BootstrapDialog,
    BootstrapDialogTitle,
} from '../bulkStatementUpload/mappings/Transactions';

import {
    delete_customer_account,
    get_customer_businesses,
    send_otp_for_account_deletion,
    verify_otp_for_account_deletion,
} from '../../../api/urls';

import {
    Box,
    Grid,
    DialogContent,
} from '@mui/material';
import BusinessSharpIcon from "@mui/icons-material/BusinessSharp";
import { OtpInput } from './onBoarding/VerifyOtp';


const styles = {
    title: {
        color: '#000000',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '18px',
        fontStyle: 'normal',
        fontFamily: "Noto Sans",
    },
    confirmMessage: {
        mb: 1.5,
        fontSize: '18px',
        color: '#000000',
        fontWeight: '700',
        lineHeight: '18px',
        fontStyle: 'normal',
        textTransform: "none",
        fontFamily: "Noto Sans",
    },
    confirmDescription: {
        mb: 1,
        color: '#000000',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        fontStyle: 'normal',
        fontFamily: "Noto Sans",
    }
};

const getTitle = (action) => action === 'otp' ? 'Verify OTP' : 'Delete Account';

const DeleteAccount = ({ onClose }) => {
    const navigation = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [otp, setOtp] = React.useState();
    const [open, setOpen] = React.useState(true);
    const [action, setAction] = React.useState('confirm');
    const [disabledBtn, setDisabledBtn] = React.useState(true);

    const onDeleteAccount = async () => {
        const res = await apiAction({
            method: 'delete',
            data: { otp: otp },
            dispatch: dispatch,
            navigate: navigation,
            url: delete_customer_account()
        });
        if (res?.success) {
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            onDelete();
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    };

    const onDelete = () => {
        setOtp();
        clearCookie();
        setOpen(true);
        setOpen('confirm');
        setLoader(dispatch, Actions, false);
        navigation(`/auth${routesName.signIn.path}`)
    }
    return (
        <>
            <CustomTitleContainer sx={{ pt: 1.7, pb: 1.7 }}>
                <CustomTitle title={'Delete Account'} />
            </CustomTitleContainer>
            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 202px)' }}>
                <DeleteAccountVerification onClose={onClose} open={open} setOpen={setOpen} action={action} setAction={setAction} getEnteredOTP={(value) => { setOtp(value) }} onDelete={onDeleteAccount} />
                <CustomerBusinesses open={open} action={action} setAction={setAction} disabledDeleteBtn={(value) => setDisabledBtn(value)} />
            </CustomContainer>
            {!open &&
                <CustomButtonContainer>
                    <Box sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                        <CustomButton
                            btnLabel="Delete"
                            variant="contained"
                            disabled={disabledBtn}
                            sx={{ minWidth: '110px' }}
                            onClick={() => { setAction('delete'); setOpen(true); }}
                        />
                    </Box>
                </CustomButtonContainer>
            }
        </>
    );
};

const DeleteAccountVerification = ({ action, setAction, open, onClose, setOpen, onDelete, getEnteredOTP }) => {
    return (
        <BootstrapDialog fullWidth open={open} maxWidth={'sm'}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                <CustomTypography text={getTitle(action)} sx={styles.title} />
            </BootstrapDialogTitle>
            <DialogContent dividers>
                {action === 'delete' && <ConfirmationScreen onClose={onClose} onDelete={() => onDelete()} />}
                {action === 'confirm' && <ConfirmationScreen onClose={onClose} setAction={() => setAction('otp')} />}
                {action === 'otp' && <OtpScreen onClose={onClose} setOpen={() => { setOpen(false); setAction('confirm') }} getEnteredOTP={getEnteredOTP} />}
            </DialogContent>
        </BootstrapDialog>
    )
}

const CustomerBusinesses = ({ open, disabledDeleteBtn }) => {
    const dispatch = Actions.getDispatch(React.useContext);

    const [results, setResults] = React.useState([]);

    React.useEffect(() => {
        if (!open) {
            getCustomerBusinesses()
        }
    }, [open]);


    const getCustomerBusinesses = async () => {

        const res = await apiAction({ url: get_customer_businesses() });
        if (res?.success) {
            setResults(res?.result);
            disabledDeleteBtn(!res?.result?.length)
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    };

    return (
        !open &&
        <Box>
            <CustomTypography
                text="The Organizations you use are listed below. When you delete account, all the data associated with these organizations will be deleted."
                sx={{ ...styles.confirmDescription, p: 2 }}
            />

            {
                results.length ?
                    results.map((item, index) => {
                        return (
                            <Box key={index}>
                                <BusinessList item={item} />
                            </Box>
                        );
                    })
                    :
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
                        <CustomTypography text="There is no business associated with your account." sx={{ ...styles.confirmMessage, opacity: 0.7 }} />
                    </Box>
            }
        </Box>
    )
}

const ConfirmationScreen = ({ onClose, setAction, onDelete }) => (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ maxWidth: 450 }}>
            <CustomTypography text="Are you sure?" sx={styles.confirmMessage} />
            <CustomTypography
                text="All your organizations created will be deleted by this action"
                sx={styles.confirmDescription}
            />
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                <CustomButton variant="contained" onClick={
                    () => {
                        if (onDelete) {
                            onDelete();
                        } else {
                            setAction();
                        }
                    }} btnLabel='Yes' sx={{ mr: 2 }} />
                <CustomButton variant="outlined" onClick={onClose} btnLabel='No' />
            </Box>
        </Box>
    </Box>
);

const OtpScreen = ({ setOpen, getEnteredOTP }) => {
    const dispatch = Actions.getDispatch(React.useContext);
    const [otp, setOpt] = React.useState('');
    const [isSend, setIsSend] = React.useState(false);
    const [clearEnterOtp, setClearEnterOtp] = React.useState(false);

    const getOTP = async () => {
        const res = await apiAction({ url: send_otp_for_account_deletion() });
        if (res?.success) {
            setIsSend(true);
            setClearEnterOtp(!clearEnterOtp);
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            setIsSend(false);
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    };

    const verifyOTP = async (enteredOtp) => {
        const res = await apiAction({ url: verify_otp_for_account_deletion(enteredOtp), method: "GET" });
        if (res?.success) {
            setOpen();
            getEnteredOTP(enteredOtp);
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    };



    const handleSubmit = () => {
        verifyOTP(otp)
    };
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ maxWidth: 400 }}>
                <CustomTypography text="Verify your identity" sx={styles.confirmMessage} />
                <CustomTypography
                    text="Since you're trying to perform a sensitive operation, we need re-verification to let you proceed."
                    sx={styles.confirmDescription}
                />
                {isSend ? (
                    <>

                        <OtpInput
                            id='email'
                            autoFocus={true}
                            isOTPVerified={false}
                            clearEnterOtp={clearEnterOtp}
                            handleResend={() => { getOTP() }}
                            getEnterOtp={(value) => { setOpt(value) }}
                            title={<span style={{}}>Enter the OTP send to <span style={{ color: '#646f79' }}> {getUserDetails()?.email}</span></span>}
                        />

                        <CustomButton
                            btnLabel="Verify"
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={String(otp).length !== 6}
                            sx={{ mt: 3, minWidth: '110px' }}
                        />
                    </>
                ) : (
                    <CustomButton
                        variant="contained"
                        btnLabel="Verify using OTP"
                        onClick={getOTP}
                        sx={{ mt: 3, }}
                    />
                )}
            </Box>
        </Box>
    );
};


const BusinessList = (props) => {
    const { item } = props;
    return (
        <Box
            // className={getBusinessInfo().id === item.id ? "border-left-success" : ""}
            sx={{
                borderBottom: "2px solid #F5F5F5",
                cursor: "pointer",
                ":hover": { backgroundColor: "#f5f5f5" },
            }}
        >
            <Grid container>
                <Grid item xs={8}>
                    <Box
                        sx={{
                            p: 1,
                            display: "flex",
                        }}
                    >
                        <Box sx={{ width: 200 }}>
                            {item.logo_url ? (
                                <Logo image={item.logo_url} />
                            ) : (
                                <BusinessSharpIcon fontSize="large" />
                            )}
                        </Box>
                        <Box sx={{ alignSelf: "center" }}>
                            <CustomTypography
                                text={item.legal_name}
                                sx={{
                                    mb: 1,
                                    color: "#000000",
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    lineHeight: "18px",
                                    fontStyle: "normal",
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            <CustomTypography
                                text={item.industry_name}
                                sx={{
                                    mb: 0.5,
                                    color: "#7A7A7A",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    lineHeight: "18px",
                                    fontStyle: "normal",
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            <CustomTypography
                                text={
                                    "Organization created on : " +
                                    moment(item.created_at).format("DD-MMM-YYYY")
                                }
                                sx={{
                                    mb: 0.5,
                                    color: "#7A7A7A",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    lineHeight: "18px",
                                    fontStyle: "normal",
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={2} sx={{ textAlign: "end", alignSelf: "center" }}>
                    {item.integration_mode === "tally" ? (
                        <Box
                            component="img"
                            sx={{ p: 1, height: 40 }}
                            alt={images.tallyLogo.alt}
                            src={images.tallyLogo.src}
                        />
                    ) : null}
                </Grid>
            </Grid>
        </Box>
    );
};

export default DeleteAccount;
