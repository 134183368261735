import React from 'react';
import { Box, Container } from '@mui/material';

export const CustomContainer = ({ children,sx }) => {
    return (
        <Container maxWidth={false} disableGutters sx={{ pl: { xs: 0, sm: 14 }, pr: { xs: 0, sm: 14 },...sx }}>
            {children}
        </Container>
    )
}

export const BackgroundImageContainer = ({ children, sx }) => {
    return (
        <Box sx={{
            backgroundSize: 'cover',
            ...sx
        }}>
            {children}
        </Box>
    )
}
