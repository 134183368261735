import React from 'react';
import * as Custom from '../Custom/Custom';
import bannerBackground from '../assets/svg/banner.svg';
import { ReactComponent as OfferIcon } from '../assets/svg/Frame 59930.svg';
import CustomTypography from '../../../../custom/typography/CustomTypography';

import { ReactComponent as Heart1Icon } from '../assets/svg/Vector.svg';
import { ReactComponent as Heart2Icon } from '../assets/svg/mage_dashboard-fill.svg';
import { ReactComponent as Heart3Icon } from '../assets/svg/majesticons_calculator.svg';

import { Box } from '@mui/material';

// Refactored Banner component with mobile responsiveness
const Banner = ({ navigateToSignup }) => {

    const bannerText = <span>From invoicing to complete accounting,<br /> all in one platform</span>;
    const subText = <span>Plan starting from <strong>₹ 500/month</strong></span>;

    const cardData = [
        {icon:Heart1Icon, background: '#00ADE7', mainText: <span>Fast and easy<br/>interface</span>},
        {icon:Heart2Icon, background: '#EF6F0D', mainText: <span>Clear financial<br/>overview</span>},
        {icon:Heart3Icon, background: '#6448FF', mainText: <span>simplified<br/>accounting</span>},
    ];

    return (
        <Box sx={styles.root}>
            <Custom.BackgroundImageContainer sx={styles.bannerContainer}>
                <Custom.Container>
                    <Box sx={styles.contentBox}>
                        <BannerText text={bannerText} />

                        <OfferIcon style={{ maxHeight: '120px', width: '100%' }} />

                        <BannerButton text="Signup now for free" variant="gradient" navigateToSignup={navigateToSignup} />

                        <BannerText text={subText} sx={styles.subText} />
                    </Box>

                    <Box sx={styles.cardsContainer}>
                        <Box
                        sx={{
                            gap: '20px',
                            width: '100%',
                            padding:'10px',
                            display: 'flex',
                            maxWidth: '100%',
                            alignItems: 'center',
                            justifyContent:{xs:'space-evenly',sm:'center'} ,
                        }}
                        >
                        {cardData.map((card, index) => (
                            <BannerCard key={index} background={card.background} mainText={card.mainText} icon={card.icon}/>
                        ))}
                        </Box>
                    </Box>
                </Custom.Container>
            </Custom.BackgroundImageContainer>
        </Box>
    );
};

// Reusable BannerText component
const BannerText = ({ text, sx }) => (
    <CustomTypography text={text} sx={{ ...styles.typography, ...sx }} />
);

// Reusable BannerButton component with variant handling
const BannerButton = ({ text, variant, navigateToSignup }) => {
    const buttonStyle = variant === 'gradient' ? styles.gradientButton : styles.plainButton;
    return (
        <Box sx={{ ...styles.button, ...buttonStyle }} onClick={navigateToSignup}>
            {text}
        </Box>
    );
};

// Reusable BannerCard component
const BannerCard = ({icon:Icon, background, mainText }) => (
    <Box sx={{ ...styles.card, background }}>
        <Icon />
        <BannerText text={mainText} sx={styles.cardText} />
    </Box>
);

// Define reusable styles
const styles = {
    root: {
        maxHeight:'800px',
        position: 'relative',
        height: { xs: 'calc(100vh - 70px)', sm: 'calc(100vh - 0px)' }
    },
    bannerContainer: {
        top: '75px',
        width: '100%',
        maxHeight:'800px',
        position: 'absolute',
        backgroundSize: 'cover',
        height: 'calc(100% - 100px)',
        backgroundImage: `url(${bannerBackground})`,
    },
    contentBox: {
        left: '50%',
        width: '100%',
        display: 'flex',
        textAlign: 'center',
        position: 'absolute',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        top: { xs: '220px', sm: '300px' },
        transform: 'translate(-50%, -50%)',
    },
    cardsContainer: {
        zIndex: 1,
        left: '0px',
        bottom: '-90px',
        overflow: 'auto',
        position: 'absolute',
        width: 'calc(100% - 0px)',
    },
    card: {
        width: '100%',
        padding: '16px',
        display: 'flex',
        color: '#FFFFFF',
        minWidth: '220px',
        maxWidth: '260px',
        minHeight: '110px',
        borderRadius: '8px',
        flexDirection: 'column',
    },
    cardText: {
        paddingTop: '10px',
        whiteSpace: 'nowrap',
        paddingBottom: '10px',
        letterSpacing: '1.2px',
        textTransform: 'uppercase',
        fontSize: { xs: '20px', sm: '24px' },
        lineHeight: { xs: '32px', sm: '36px' },
    },
    typography: {
        m: 1,
        fontWeight: 700,
        color: '#ffffff',
        letterSpacing: '0px',
        fontSize: { xs: '24px', sm: '40px' },
        lineHeight: { xs: '32px', sm: '50px' },
    },
    subText: {
        fontWeight: 400,
        fontSize: { xs: '14px', sm: '16px' },
        lineHeight: 'auto',
        letterSpacing: '0.2px',
    },
    button: {
        margin: 1,
        width: '100%',
        height: '56px',
        display: 'flex',
        maxWidth: '290px',
        cursor: 'pointer',
        borderRadius: '50px',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: { xs: '18px', sm: '22px' }, 
    },
    gradientButton: {
        color: '#ffffff',
        background: 'linear-gradient(to bottom, #96B8FF, #0952EB)',
    },
    plainButton: {
        color: '#000000',
        backgroundColor: '#ffffff',
    },
};

export default Banner;
