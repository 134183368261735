import React from 'react';
import moment from 'moment';
import Logo from '../../../common/Logo';
import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import UpdateBusinessProfile from './UpdateBusinessProfile';
import CustomButton from '../../../custom/button/CustomButton';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
   CustomContainer,
   CustomTitleContainer,
   } from '../../../custom/container/CustomContainer';

import {
  setLoader,
  getAddress,
  getDateFormat,
  getQueryParams,
} from '../../../../utils/Utils';

import {
  retrieve_business,
} from '../../../../api/urls';

import {
  Box,
  Grid,
} from '@mui/material';
import BusinessSharpIcon from '@mui/icons-material/BusinessSharp';

const ViewBusinessProfile = (props) => {
  const { id, onCancel } = props;
  const dispatch = Actions.getDispatch(React.useContext);
  const [edit, setEdit] = React.useState(false);

  const [result, setResult] = React.useState();
  React.useEffect(() => {
    const getBusiness = async (id) => {
      setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'get',
        url: retrieve_business(id),
      })
      if (res?.success) {
        setResult(res?.result)
        setLoader(dispatch, Actions, false)
      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (id) {
      getBusiness(id)
    }
    // eslint-disable-next-line
  }, [id, edit])


  return (
    edit ?
      <UpdateBusinessProfile result={result} onCancel={() => setEdit(false)} />
      :
      result &&
      <div>
      <CustomTitleContainer>
        <Grid container spacing={0} sx={{}} style={{
          alignItems: 'center',
          justifyItems: 'center',
          alignContent: 'space-evenly',
          justifyContent: 'space-evenly',
        }}>
          <Grid item xs={6}>
            <CustomTitle title={result.legal_name} />
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'end' }}>
            <CustomButton
            sx={{}}
            id={'back_btn'}
            btnLabel='Back'
            variant="outlined"
            dataTestId={'back_btn'}
            onClick={() => { onCancel() }}
            />
            <CustomButton
            sx={{ml:2 }}
            id={'edit_btn'}
            btnLabel='Edit'
            variant="contained"
            dataTestId={'edit_btn'}
            onClick={() => { setEdit(true) }}
            />
          </Grid>
        </Grid>
        </CustomTitleContainer>
        <CustomContainer maxWidth={"400"} sx={{height: 'calc(100vh - 134px)',maxHeight:{xs:'calc(100vh - 180px)',sm:'100%'}}}>

          <BasicInfo
            result={result}
          />
          <div style={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: '16px', paddingBottom: '16px', }}>
            <DetailsInfo
              result={result}
            />
          </div>
        </CustomContainer>
      </div>
  )
}

export default ViewBusinessProfile;

const BasicInfo = (props) => {
  const { result } = props
  return (
    <Box sx={{ borderBottom: '2px solid #F5F5F5' }}>
      <Box sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'row',
      }}>
        {
          result.logo ?
            <Logo image={result.logo} />
            :
            <BusinessSharpIcon fontSize='large' />
        }
        <Box sx={{ alignSelf: 'center', ml: 2.5 }}>

          <CustomTypography
            text={result.legal_name}
            sx={{
              mb: 1,
              color: '#000000',
              fontSize: '14px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
          <CustomTypography
            text={'— ' + result.industry.name}
            sx={{
              mb: 0.5,
              color: '#7A7A7A',
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}


const DetailsInfo = (props) => {
  const { result } = props


  const list_details = [
    // { title: 'Organization Name', value: result.legal_name ? result.legal_name : null,isFull:true },
    // { title: 'Industry', value: result.industry ? result.industry.name : null },
    { title: 'Address', value: result.address ? getAddress(result.address) : null, isFull: true },
    { title: 'Country', value: result.country ? result.country.name : null },
    { title: 'Currency', value: result.currency ? `${result.currency.name} (${result.currency.currency_code})` : null },
    { title: 'Fiscal Year', value: result.fiscal_year ? result.fiscal_year.month_range : null },
    { title: 'Financial Year Last Date', value: result.financial_year_last_day ? moment(result.financial_year_last_day).format(getDateFormat()) : null },
    { title: 'Migration Date', value: result.migration_date ? moment(result.migration_date).format(getDateFormat()) : null },
    { title: 'Time Zone', value: result.time_zone ? result.time_zone.time_zone : null },
    { title: 'Date Format', value: result.date_format ? result.date_format.date_format : null },
    { title: 'Phone Number', value: result.phone_number ? result.phone_number : null },
    { title: 'PAN Number', value: result.pan_number ? result.pan_number : null },
    { title: 'GST Registered', value: result.is_business_registered ? 'Yes' : 'No' },
    { title: 'GSTIN', value: result.gstin_number ? result.gstin_number : null,isFull:result.integration_mode.toLowerCase() !== "tally"},
    result.integration_mode.toLowerCase() === "tally"&&{ title: 'Integration Mode', value: result.integration_mode ? result.integration_mode.capitalize() : null, },

  ]
  return (
    <Box sx={{ pt: 1 }}>
      <Grid container item xs={10} spacing={3}>
        {
          list_details.map((item, index) => {
            return (
              item&&
              <Grid item xs={item.isFull ? 12 : 6} key={index}>
                <TitleData item={item} />
              </Grid>
            )
          })
        }

      </Grid>
    </Box>
  )
}


const TitleData = (props) => {
  const { item } = props
  return (
    <>
      <Grid item xs={12}>
        <CustomTypography
          text={item.title ? item.title : '-'}
          sx={{
            mb: 2,
            color: '#000000',
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '18px',
            fontStyle: 'normal',
            fontFamily: "Noto Sans",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTypography
          text={item.value ? item.value : '-'}
          sx={{
            mb: 2,
            color: '#7A7A7A',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '18px',
            fontStyle: 'normal',
            fontFamily: "Noto Sans",
          }}
        />
      </Grid>
    </>
  )
}