import React from 'react';
import moment from 'moment';
import InviteNewUser from './InviteNewUser';
import { apiAction } from '../../../../../api/api';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import ListItemActions from '../../../../common/ListItemActions';
import CustomButton from '../../../../custom/button/CustomButton';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import { HtmlTooltip } from '../../../../custom/tooltip/CustomTooltip';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { getBusinessInfo, getUserDetails } from '../../../../../config/cookiesInfo';
import { CustomContainer, CustomTitleContainer } from '../../../../custom/container/CustomContainer';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableBodyCell,
    CustomTableHeadCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';

import {
    setLoader,
    stateChangeManager,
} from "../../../../../utils/Utils";


import {
    delete_user_invite,
    list_of_user_invite,
    resend_user_invitation,
    list_users_in_business,
} from '../../../../../api/urls';

import {
    Box,
    Grid,
} from '@mui/material';

const UserList = ({ initial_state, onManageProfile }) => {
    const { legal_name } = getBusinessInfo();
    const [isInviting, setIsInviting] = React.useState(false);

    React.useEffect(() => { setIsInviting(false); }, [initial_state]);

    const toggleInviteUser = () => setIsInviting(prev => !prev);

    return (
        <div>
            {isInviting ? (
                <InviteNewUser onCancel={toggleInviteUser} />
            ) : (
                <UserListContent legalName={legal_name} onInviteClick={toggleInviteUser} onManageProfile={onManageProfile} isInviting={isInviting} />
            )}
        </div>
    );
};


const UserListContent = ({ legalName, onInviteClick, onManageProfile, isInviting }) => {
    return (
        <Box>
            <CustomTitleContainer>
                <Grid container spacing={0} alignItems="center" justifyContent="space-between">
                    <Grid item xs={6}>
                        <CustomTitle title={`${legalName} Users`} />
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'end' }}>
                        <CustomButton variant="contained" id="invite_user_btn" btnLabel="Invite User" onClick={onInviteClick} dataTestId="invite_user_btn" />
                    </Grid>
                </Grid>
            </CustomTitleContainer>
            <CustomContainer maxWidth="400" sx={{ pt: '0px !important', height: 'calc(100vh - 134px)', maxHeight: { xs: 'calc(100vh - 180px)', sm: '100%' } }}>

                <Box sx={{ borderBottom: '2px solid #F5F5F5' }}>
                    <CustomTypography
                        text={
                            <span>
                                You can invite a new user to access your <strong>Finycs Account</strong>. Only give access to people you trust, since users can see your transactions and other business information.
                            </span>
                        }
                        sx={typographyStyle}
                    />
                </Box>

                <Box sx={{}}>
                    <AcceptedUsers onManageProfile={onManageProfile} isInviting={isInviting} />
                </Box>

                <Box sx={{ pb: 6 }}>
                    <InvitedUsers onManageProfile={onManageProfile} isInviting={isInviting} />
                </Box>

            </CustomContainer>
        </Box>
    );
};


const AcceptedUsers = ({ }) => {
    const dispatch = Actions.getDispatch(React.useContext);
    const loginUserId = getUserDetails().id || getUserDetails().pk;

    const [users, setUsers] = React.useState([]);

    const fetchUsers = async () => {
        setLoader(dispatch, Actions, true);
        let response = await apiAction({ method: 'get', url: list_users_in_business() });

        if (response?.success) {
            const sortedUsers = sortUsers(response.result || [], loginUserId);
            setUsers(sortedUsers);
        }

        setLoader(dispatch, Actions, false);
    };

    React.useEffect(() => {
        fetchUsers();
    }, []);


    const joinedUsers_column = [
        {
            label: 'User',
            align: "left",
            field: 'fullname',
            onRender: (item) => {
                return (
                    <Box sx={{ alignSelf: 'left' }}>
                        <CustomTypography text={item['fullname']} sx={userNameStyle} />
                        <CustomTypography text={item['email']} sx={userEmailStyle} />
                    </Box>
                )
            },
        },

        {
            align: "center",
            label: 'Joined On',
            field: 'joined_on',
            onRender: (item) => {
                return (
                    <span>
                        {moment(item['joined_on']).format('DD-MM-YYYY')}
                    </span>
                )
            },
        },
        {
            align: "center",
            label: 'Status',
            field: 'Joined',
            onRender: (item) => {
                return (
                    <HtmlTooltip title={`Inviter: ${item.inviter_email || "Not found"}`} placement={"top"}>
                        <span className="status_badge approve">Accepted</span>
                    </HtmlTooltip>
                )
            },
        },
        {
            label: 'Role',
            field: 'role',
            align: "center",
            onRender: (item) => {
                return (
                    <span>
                        {item['role'].replace('_', '/').capitalize()}
                    </span>
                )
            },
        },
        { onRender: (item) => { }, label: '', field: '', align: "left" },
    ]
    return (
        <Box sx={{ borderTop: '2px solid #F5F5F5' }}>
            {users.length > 0 ? (
                    <CustomTableContainer>
                    <CustomTable>
                        <ItemsListHeaders column={joinedUsers_column} />
                        <ItemsListBody
                            users={users}
                            column={joinedUsers_column}
                        />
                    </CustomTable>
                </CustomTableContainer>
            ) : (
                <CustomTypography
                    text={"No Users found"}
                    sx={{
                        height: "300px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                />
            )}
        </Box>
    )
};

const InvitedUsers = ({ isInviting }) => {
    const dispatch = Actions.getDispatch(React.useContext);

    const [dialogState, setDialogState] = React.useState();
    const [users, setUsers] = React.useState([]);
    const [counter, setCounter] = React.useState(0);

    React.useEffect(() => {
        if (counter > 0) {
            setTimeout(() => setCounter(counter - 1), 1000);
        }
    }, [counter]);

    const fetchUsers = async () => {
        setLoader(dispatch, Actions, true);
        const response = await apiAction({ method: 'get', url: list_of_user_invite() });
        if (response?.success) {
            setUsers(response.result || []);
        }
        setLoader(dispatch, Actions, false);
    };

    React.useEffect(() => {
        if (!isInviting) {
            fetchUsers();
        }
    }, [dispatch, isInviting]);

    const handleSent = async (invite_id) => {
        setLoader(dispatch, Actions, true);
        const response = await apiAction({
            method: 'post',
            url: resend_user_invitation(),
            data: {
                invite_id: invite_id,
                business_id: getBusinessInfo()?.id,
            }
        });
        if (response?.success) {
            stateChangeManager(dispatch, Actions, true, 'success', response?.status);
        }
        setLoader(dispatch, Actions, false);
    };

    const handleDelete = (id) => {
        setDialogState({
            open: true,
            maxWidth: 'sm',
            fullWidth: true,
            condition: 'delete',
            title: 'Delete invitation',
            url: delete_user_invite(id),
            onDeleteAction: () => setUsers(prev => prev.filter(user => user.id !== id)),
            deleteMessage: `The sent invitation will be deleted and cannot be retrieved later.\n Are you sure you want to delete it?`,
        });
    };



    const invitedUsers_column = [
        {
            label: 'User',
            align: "left",
            field: 'first_name',
            onRender: (item) => {
                return (
                    <Box sx={{ alignSelf: 'left' }}>
                        <CustomTypography text={item['first_name'] + ' ' + item['last_name']} sx={userNameStyle} />
                        <CustomTypography text={item['email']} sx={userEmailStyle} />
                    </Box>
                )
            },
        },
        {
            align: "center",
            label: 'Invited On',
            field: 'invited_on',
            onRender: (item) => {
                return (
                    <span>
                        {moment(item['invited_on']).format('DD-MM-YYYY')}
                    </span>
                )
            },
        },
        {
            align: "center",
            label: 'Status',
            field: 'Pending',
            onRender: (item) => {
                return (
                    <span className="status_badge pending">Pending</span>
                )
            },
        },
        {
            label: 'Role',
            field: 'role',
            align: "center",
            onRender: (item) => {
                return (
                    <span>
                        {item['role'].replace('_', '/').capitalize()}
                    </span>
                )
            },
        },
        {
            field: '',
            align: "right",
            label: 'Actions',
            onRender: (item) => {
                return (
                    <ListItemActions
                        index={item.id}
                        actions={
                            [
                                { disabled: counter, name: <div style={{ display: 'flex', justifyContent: 'space-between' }}><span>Resend</span><span>{counter || ''}</span></div>, onClick: () => { if (!counter) { handleSent(item.id); setCounter(60) } } },
                                { name: 'Delete', showDivider: true, onClick: () => handleDelete(item.id) }
                            ]
                        }
                    />
                )
            },
        },
    ]


    return (
        <Box>
            <CustomDialog state={dialogState} setState={setDialogState} />
            <CustomTypography text={'Invitations'} sx={{ ...userNameStyle, pl: 2, mt: 2, borderBottom: '2px solid #F5F5F5', pb: 2 }} />
            {users.length > 0 ? (
                    <CustomTableContainer>
                    <CustomTable>
                        <ItemsListHeaders column={invitedUsers_column} />
                        <ItemsListBody
                            users={users}
                            column={invitedUsers_column}
                        />
                    </CustomTable>
                    </CustomTableContainer>
            ) : (
                <CustomTypography
                    text={"Invited Users will be shown here"}
                    sx={{
                        height: "300px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                />
            )}
        </Box>
    )
}

const ItemsListHeaders = ({ column }) => {

    return (
        <CustomTableHead>
            <CustomTableRow>
                {column.map((item) => {
                    return (
                        <CustomTableHeadCell style={{ width: 200 }} align={item.align}>
                            <span>{item.label}</span>
                        </CustomTableHeadCell>
                    )
                })}
            </CustomTableRow>
        </CustomTableHead>
    )
}


const ItemsListBody = ({ users, column }) => {
    return (
        <CustomTableBody>
            {
                users?.map((user) => {

                    return (
                        <CustomTableRow index={user.id} sx={{}}>
                            {
                                column?.map((item) => (<CustomTableBodyCell sx={{whiteSpace:'nowrap'}} align={item.align}>{item.onRender(user)}</CustomTableBodyCell>))
                            }
                        </CustomTableRow>
                    )
                })
            }
        </CustomTableBody>
    )
}

const sortUsers = (users, currentUserId) => {
    const currentUser = users.find(user => user.id === currentUserId);
    const otherUsers = users.filter(user => user.id !== currentUserId);
    return [currentUser, ...otherUsers].filter(Boolean);
};

// Styling objects
const typographyStyle = {
    p: 2,
    pt: 1,
    color: "#7A7A7A",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
    fontStyle: "normal",
    fontFamily: "Noto Sans",
};

const userNameStyle = {
    mb: 1,
    color: '#000000',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '18px',
    fontStyle: 'normal',
    fontFamily: "Noto Sans",
};

const userEmailStyle = {
    mb: 0.5,
    color: '#7A7A7A',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '18px',
    fontStyle: 'normal',
    fontFamily: "Noto Sans",
};

export default UserList;
