import * as React from 'react';
import CustomInput from '../input/CustomInput';
import CustomButton from '../button/CustomButton';
import CustomTypography from '../typography/CustomTypography';

import {
    Box,
    Chip,
    Stack,
    styled,
    Divider,
    TextField,
    IconButton,
    Autocomplete,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function DropdownWithMultipleValue(props) {
    const {
        setValue,
        addButton,
        value = [],
        isChildren,
        isLastGroup,
        validation,
        results = [],
        matchValueKey = '',
        optionLabel = '',
        groupByLabel = '',
    } = props;

    const onChangeHandler = (event, values) => {
        setValue(values);
    }
    const optionsLastElementIndex = results.length - 1
    const PaperComponentCustom = options => {
        const { key, group, containerProps, children } = options;
        let isLastGroupElement = optionsLastElementIndex === key;
        return (
            <React.Fragment key={isChildren ? children : key}>

                {isLastGroup && isLastGroupElement && addButton ? (
                    <>
                        <CustomButton
                            fullWidth
                            variant='text'
                            btnLabel={<b>+ Add new</b>}
                            onClick={addButton.onClick}
                            onMouseDown={(event) => { event.preventDefault(); }}
                            sx={{ mt: -1, textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }}
                        />
                        <Divider />
                    </>
                ) : null
                }
                <Box {...containerProps} sx={{
                    mb: -1,
                    margin: '0px',
                    color: '#141414',
                    fontWeight: 400,
                    fontSize: '12px',
                    background: '#FFFF',
                    lineHeight: '16px',
                    fontStyle: 'normal',
                    fontFamily: 'Noto Sans',
                }}>
                    {!isLastGroup && addButton ? (
                        <>
                            <CustomButton
                                fullWidth
                                variant='text'
                                btnLabel={<b>+ Add new</b>}
                                onClick={addButton.onClick}
                                onMouseDown={(event) => { event.preventDefault(); }}
                                sx={{ mt: -1, textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }}
                            />
                            <Divider />
                        </>
                    ) : null
                    }
                    <CustomTypography
                        text={group}
                        sx={{
                            pl: 1,
                            pt: key ? 1 : 0,
                            color: '#141414',
                            fontWeight: 700,
                            fontSize: '12px',
                            lineHeight: '16px',
                            fontStyle: 'normal',
                            fontFamily: 'Noto Sans',
                        }}
                    />
                    {children}
                </Box>
            </React.Fragment>
        );
    };


    // const getSelectValues = () => {
    //     return results.filter(o1 => value?.some(o2 => o1[matchValueKey] === o2[matchValueKey]))
    // }
    const getSelectValues = () => {
        const valueSet = new Set(value.map(o => o[matchValueKey]));
        return results.filter(o => valueSet.has(o[matchValueKey]));
    }
    return (
        <Stack spacing={3} sx={{ width: '100%' }}>
            <Autocomplete
                multiple
                id="tags-outlined"
                options={results}
                disableClearable={true}
                onChange={onChangeHandler}
                popupIcon={<ExpandMoreIcon />}
                value={[...getSelectValues()]}
                defaultValue={[...getSelectValues()]}
                groupBy={(option) => option[groupByLabel]}
                getOptionLabel={(option) => option[optionLabel]}
                renderGroup={(option) => <PaperComponentCustom {...option} />}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            size="small"
                            variant="outlined"
                            sx={{
                                borderRadius: 1,
                                cursor: 'pointer',
                                bgcolor: '#f5f5f5',
                                ":hover": { bgcolor: '#fafafa' }
                            }}
                            deleteIcon={<IconButton size='small' sx={{ "&:hover": { color: '#ef5350' } }}><CloseIcon sx={{}} fontSize='inherit' /></IconButton>}
                            label={option[optionLabel]}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                renderInput={(params) => (
                    <CustomInput
                        {...params}
                        validation={validation}
                    // placeholder={`Select Accounts`}
                    // minRows={3}
                    // multiline={true}

                    />
                )}
            />
        </Stack>
    );
}