import React from 'react';
import validator from 'validator';
import SocialLogin from '../SocialLogin';
import { AgreeTerms } from './SigninForm';
import Input from '../../../common/Input';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../custom/button/CustomButton';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomTypography from '../../../custom/typography/CustomTypography';

import { apiAction } from '../../../../api/api';
import { send_otp } from '../../../../api/urls';
import * as Actions from '../../../../state/Actions';
import { routesName } from '../../../../config/routesName';
import { eventsNames, AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';

import {
    setLoader,
    isFormValid,
    isValidPhoneNumber,
    stateChangeManager,
} from '../../../../utils/Utils';

import {
    Box,
    Link,
    Grid,
    Divider,
    Checkbox,
} from '@mui/material';

const sign_up_initialData = {
    email: '',
    mobile_number: '',
    otp_for: 'sign_up',
};

const SignupForm = () => {
    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [agree, setAgree] = React.useState(true);
    const [data, setData] = React.useState({ ...sign_up_initialData });
    const [isFormSubmitted, setFormSubmitted] = React.useState(false);

    const handleInputChange = (key, value) => {
        setData((prevData) => ({ ...prevData, [key]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormSubmitted(true);

        const validationData = getValidationData();
        const { isValid, message } = isFormValid(data, validationData);

        if (isValid) {
            setLoader(dispatch, Actions, true);
            const res = await submitSignUpData();

            if (res?.success) {
                onSuccess(res);
            } else {
                onFailure(res);
            }
        } else {
            onError(message);
        }
    };

    const getValidationData = () => [
        { key: 'email', message: 'Please enter a email address' },
        {
            key: '',
            validation: !validator.isEmail(data.email),
            message: 'Please enter a valid email address',
        },
        { key: 'mobile_number', message: 'Please enter a mobile number' },
        {
            key: '',
            validation: !isValidPhoneNumber(data.mobile_number),
            message: 'Please enter a valid mobile number',
        },
        {
            key: '',
            validation: !agree,
            message: 'Please accept the terms and conditions',
        },
    ];

    const submitSignUpData = () =>
        apiAction({
            method: 'post',
            url: send_otp(),
            data: { ...data, is_privacy_policy_and_terms_accepted: agree },
        });

    const onSuccess = (res) => {
        setFormSubmitted(false);
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        navigate("/auth" + routesName.verifyOtp.path, {
            state: { data: { ...data, session_id: res?.session_id } },
        });
    };

    const onFailure = (res) => {
        setFormSubmitted(false);
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, 'error', res?.status);
    };

    const onError = (message) => {
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, 'error', message);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <Box className="m_login_left_box" sx={{ backgroundColor: '#F7F7F7', minHeight: '100%', width: '28%', display: 'block' }} >
                <Box sx={{ pt: 8, minHeight: "100vh", minWidth: '100vh', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Box className='m_login_container' sx={{ pt: 8, alignItems: "center", justifyContent: "center", }}>
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: "center", flexDirection: 'column', }}>

                        <Box noValidate component="form" onSubmit={handleSubmit}>
                            <CustomTypography
                                text="Sign up"
                                sx={{
                                    mb: 4,
                                    color: '#000000',
                                    fontSize: '30px',
                                    textAlign: 'start',
                                    fontWeight: '700',
                                    lineHeight: '40.86px',
                                    fontFamily: 'Noto Sans',
                                }}
                            />

                            {/* Email Input */}
                            <Box sx={{ mt: 3 }}>
                                <Input
                                    id="input_email_address"
                                    dataTestId="input_email_address"
                                    autoFocus
                                    item={{
                                        type: 'text',
                                        required: true,
                                        fullWidth: true,
                                        value: data.email,
                                        placeholder: 'Enter Your Email Address',
                                        title: <LabelWithAsteriskMark label="Email Address" />,
                                        onChange: (e) => handleInputChange('email', e.target.value),
                                        validation: isFormSubmitted && !validator.isEmail(data.email),
                                    }}
                                />
                            </Box>

                            {/* Phone Number Input */}
                            <Box sx={{ mt: 3 }}>
                                <Input
                                    id="input_mobile_number"
                                    dataTestId="input_mobile_number"
                                    item={{
                                        type: 'number',
                                        required: true,
                                        fullWidth: true,
                                        value: data.mobile_number,
                                        placeholder: 'Enter Your Mobile Number',
                                        title: <LabelWithAsteriskMark label="Mobile Number" />,
                                        onChange: (e) => handleInputChange('mobile_number', e.target.value),
                                        validation: isFormSubmitted && !isValidPhoneNumber(data.mobile_number),
                                    }}
                                />
                            </Box>

                            {/* Terms and Conditions */}
                            <AgreeTerms agree={agree} setAgree={setAgree} />

                            {/* Sign Up Button */}
                            <CustomButton
                                fullWidth
                                type="submit"
                                id="next_btn"
                                btnLabel="Next"
                                variant="contained"
                                onClick={handleSubmit}
                                sx={{ mt: 3, mb: 3, }}
                                dataTestId="next_btn"
                            />

                            <Divider>
                                <CustomTypography
                                    text={'OR'}
                                    sx={{
                                        color: '#000000',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                        textTransform: 'capitalize',
                                    }}
                                />
                            </Divider>

                            <SocialLogin
                                btnText='Sign up with Google'
                                AnalyticsEvent={() => { AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: eventsNames.actions.SIGN_UP, method: eventsNames.actions.userAuthentication.method.GOOGLE }); }}
                            />

                            <Grid container spacing={2} sx={{ pt: 3, fontSize: { xs: '12px', sm: '16px' } }}>
                                <Grid item sx={{ textAlign: 'end' }}>
                                    <Link id='sign_in_link' dataTestId='sign_in_link' href={"/auth" + routesName.signIn.path}>Already have an account? Sign in</Link>
                                </Grid>
                            </Grid>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </Box>

    );
};

export default SignupForm;
