import React from 'react';
import * as Custom from '../Custom/Custom';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { ReactComponent as EasyAuditTrailIcon } from '../assets/svg/easyAuditTrail.svg';
import { ReactComponent as EasyGstReportsIcon } from '../assets/svg/easyGstReports.svg';
import { ReactComponent as SimpleGstExportIcon } from '../assets/svg/simpleGstExport.svg';

import { Box } from '@mui/material';

const AuditCompliant = () => (
    <Custom.Container>
        <Box sx={styles.container}>
            <Title text="Stay 100% GST and audit compliant" sx={styles.title} />
            <Box sx={styles.cardsContainer}>
                {cardData.map((card, index) => (
                    <BannerCard
                        key={index}
                        icon={card.icon}
                        subText={card.subText}
                        mainText={card.mainText}
                        background={card.background}
                    />
                ))}
            </Box>
        </Box>
    </Custom.Container>
);

export default AuditCompliant;

// Reusable Title component
const Title = ({ text, sx }) => (
    <CustomTypography text={text} sx={{ ...styles.typography, ...sx }} />
);

// Card data array moved outside of component for clarity
const cardData = [
    { icon: EasyGstReportsIcon, background: '#049735', mainText: 'Easy GST Reports', subText: 'Generate accurate GST reports with just a few clicks, keeping your business tax-ready without any hassle.' },
    { icon: EasyAuditTrailIcon, background: '#473BF0', mainText: 'Easy Audit Trail', subText: 'Keep track of all changes, so you’re always ready for an audit without any stress.' },
    { icon: SimpleGstExportIcon, background: '#FF626C', mainText: 'Simple GST Export', subText: 'Quickly export your GST data in JSON format, making tax filing smooth and easy.' },
];

// Reusable BannerCard component
const BannerCard = ({ icon: Icon, background, mainText, subText }) => (
    <Box sx={{ ...styles.card, background }}>
        <Box sx={styles.iconWrapper}>
            <Icon />
        </Box>
        <Title text={mainText} sx={styles.cardText} />
        <Title text={subText} sx={styles.cardSubText} />
    </Box>
);

// Define reusable styles
const styles = {
    container: {
        pt: 4,
        pb: 8,
        height: '100%',
        borderRadius: '32px',
    },
    title: {
        pt: 2,
        pb: 8,
        textAlign: 'center',
    },
    typography: {
        fontWeight: 700,
        color: '#141414',
        fontSize: '40px',
        lineHeight: '66px',
        letterSpacing: '0px',
    },
    cardsContainer: {
        gap: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap:{xs:'wrap',sm:'nowrap'},
    },
    card: {
        padding: 3,
        width: '100%',
        borderRadius: 1,
        display: 'flex',
        maxWidth: '300px',
        minHeight: '420px',
        alignItems: 'center',
        flexDirection: 'column',
    },
    iconWrapper: {
        pt: 2,
        display: 'flex',
        justifyContent: 'center',
    },
    cardText: {
        color: '#FFFFFF',
        fontSize: '28px',
        paddingTop: '25px',
        textAlign: 'center',
        paddingBottom: '25px',
    },
    cardSubText: {
        fontWeight: 400,
        fontSize: '16px',
        color: '#FFFFFF',
        lineHeight: '22px',
        textAlign: 'center',
        letterSpacing: '0.2px',
    },
};
