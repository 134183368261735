import Header from "./Header";
import Footer from "./Footer";
import Banner from "./Banner";
import Reviews from "./Reviews";
import Pricing from "./Pricing";
import Benefits from "./Benefits";
import KeyFeatures from "./KeyFeatures";
import AuditCompliant from "./AuditCompliant";
import OverduePayment from "./OverduePayment";


export {
    Header,
    Footer,
    Banner,
    Pricing,
    Reviews,
    Benefits,
    KeyFeatures,
    AuditCompliant,
    OverduePayment,

}