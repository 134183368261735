import { HOST } from "../config/config";
import {getToken, getBusinessInfo } from "../config/cookiesInfo";

const hostName = HOST.replace('https', 'wss').replace('http', 'ws');

export const ws_document_status = (id) => {
    return `${hostName}/ws/document_status/?business_id=${getBusinessInfo()?.id}&token=${getToken()}`
}

export const ws_export_file_status = (id) => {
    return `${hostName}/ws/export_file_status/?business_id=${getBusinessInfo()?.id}&token=${getToken()}`
}
