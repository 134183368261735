import React from 'react';
import moment from 'moment/moment';
import * as Common from '../../common/Common';
import { useNavigate } from 'react-router-dom';
import Status from '../../../../common/Status';
import { apiAction } from '../../../../../api/api';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import { routesName } from '../../../../../config/routesName';
import { CustomLink } from '../../../../custom/link/CustomLink';
import ListItemActions from '../../../../common/ListItemActions';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { RecurringExpenseViewLink } from '../../common/CommonLinks';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import { NoDataComponent } from '../../../../common/NoDataComponent';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';
import CurrencyFormatterWithExchangeRate from '../../common/CurrencyFormatterWithExchangeRate';

import {
    setLoader,
    getDateFormat,
    setFilterMessage,
    isFiltersApplied,
    to_check_repeat_every,
} from '../../../../../utils/Utils';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
    update_recurring_expense,
    delete_recurring_expense,
    get_list_of_recurring_expense,
} from '../../../../../api/urls';

import {
    Box,
    Grid,
} from '@mui/material';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';

const ListRecurringExpense = () => {
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [page, setPage] = React.useState(1);
    const [results, setResults] = React.useState([]);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    });

    const [filters, setFilters] = React.useState({
    });

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    });

    const stateChangeHandler = (title, condition, maxWidth, url) => {
        setState({
            url: url,
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            onDeleteAction: () => { getApiResults(page); }
        })
    }

    React.useEffect(() => {
        getApiResults(page)
        // eslint-disable-next-line
    }, [page, filters]);

    let body = { business_id: getBusinessInfo().id, ...filters }
    const getApiResults = async (page) => {
        setLoader(dispatch, Actions, true);
        setFilterMessage(dispatch, Actions, null);
        let res = await apiAction({
            data: body,
            method: 'post',
            dispatch: dispatch,
            navigate: navigate,
            url: get_list_of_recurring_expense(page),
        })
        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.next,
                count: res?.result.count,
                previous: res?.result.previous,
                number_of_pages: res?.result.number_of_pages,
            })
            setResults(res?.result.result);
            setLoader(dispatch, Actions, false);
            if (isFiltersApplied(filters)) { setFilterMessage(dispatch, Actions, `No recurring Expense found for your current filters. Verify your filters and try again.`) };
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    const manageStatus = async (id, is_active) => {

        let actionType=is_active?eventsNames.actions.recurringExpense.action.ACTIVATE:eventsNames.actions.recurringExpense.action.DEACTIVATE
        AnalyticsEvent(eventsNames.categories.RECURRING_EXPENSES,{action:actionType})
        

        setLoader(dispatch, Actions, true);
        let res = await apiAction({
            method: 'post',
            dispatch: dispatch,
            navigate: navigate,
            url: update_recurring_expense(id),
            data: { business_id: getBusinessInfo().id, is_active: is_active },
        })
        if (res?.success) {
            getApiResults(page)
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }


    return (
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <CustomTitleContainer>
                <Grid container style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                        <CustomTitle title={'All Recurring Expenses'} />
                    </Grid>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                        <CustomButton
                            id={'create_recurring_expense_btn'}
                            dataTestId={'create_recurring_expense_btn'}
                            variant="contained"
                            sx={{ textTransform: 'none', }}
                            btnLabel={'Create New Recurring Expense'}
                            onClick={() => {
                                navigate(routesName.invoicingRecurringExpenseAdd.path)
                                AnalyticsEvent(eventsNames.categories.RECURRING_EXPENSES,{action:eventsNames.actions.NEW})
                            }}
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{maxHeight:{xs:'calc(100vh - 240px)',sm:'100%'}}}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '24px', display: '-webkit-flex', }}>
                    <Filters stateChangeHandler={stateChangeHandler} filters={filters} setFilters={setFilters} setPage={setPage} />
                </div>
                <CustomTableContainer>
                    <CustomTable sx={{}}>
                        <RecurringExpenseHeader />
                        <RecurringExpenseBody results={results} stateChangeHandler={stateChangeHandler} manageStatus={manageStatus} />
                    </CustomTable>
                    <Box sx={{ pt: !results.length ? 10 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>

            <CustomPagination
                page={page}
                count={pagination.number_of_pages}
                onChange={(event, newValue) => setPage(newValue)}
            />
        </div>
    )
}

export default ListRecurringExpense;


const RecurringExpenseHeader = () => {
    return (
        <CustomTableHead>
            <CustomTableRow>
                <CustomTableHeadCell style={{ width: 100 }} align='center' ><span>Status</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Vendor Name</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Profile Name</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Frequency</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Last Expense Date</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Next Expense Date</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Amount</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Actions</span></CustomTableHeadCell>

            </CustomTableRow>
        </CustomTableHead>
    )
}

const RecurringExpenseBody = (props) => {
    const { manageStatus, results, stateChangeHandler } = props;
    let navigate = useNavigate();

    return (
        <CustomTableBody>
            {
                results.map((item, index) => {
                    // console.log('item', )
                    return (
                        <CustomTableRow key={index}>
                            <CustomTableBodyCell align='center'><Status status={item.status} /></CustomTableBodyCell>
                            <CustomTableBodyCell><span>{item.vendor_display_name}</span></CustomTableBodyCell>
                            <CustomTableBodyCell><RecurringExpenseViewLink id={item.periodic_task_id} title={item.profile_name} /></CustomTableBodyCell>
                            <CustomTableBodyCell><span>{to_check_repeat_every(item.repeat_frequency, item.repeat_every) ? to_check_repeat_every(item.repeat_frequency, item.repeat_every).name : `Every ${item.repeat_every} ${item.repeat_frequency.capitalize()}s`}</span></CustomTableBodyCell>
                            <CustomTableBodyCell><span>{item.last_expense_date ? moment(item.last_expense_date).format(getDateFormat()) : '-'}</span></CustomTableBodyCell>
                            <CustomTableBodyCell><span>{item.status === 'Expired' ? '-' : item.next_expense_date ? moment(item.next_expense_date).format(getDateFormat()) : '-'}</span></CustomTableBodyCell>
                            <CustomTableBodyCell align='right'>
                                <span><CurrencyFormatter amount={item.amount} currency={item.currency_code} /></span><br />
                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.amount} exchange_rate={item.exchange_rate} />
                            </CustomTableBodyCell>
                            <CustomTableBodyCell align='right'>
                                <ListItemActions
                                    index={index}
                                    actions={
                                        [
                                            { name: 'View', onClick: () => { navigate(routesName.invoicingRecurringExpenseView.path + "?id=" + item.periodic_task_id, { replace: false }); AnalyticsEvent(eventsNames.categories.RECURRING_EXPENSES,{action:eventsNames.actions.VIEW}) } },
                                            { name: 'Edit', onClick: () => { navigate(routesName.invoicingRecurringExpenseAdd.path + "?recurring_id=" + item.periodic_task_id, { replace: false });} },
                                            item.status !== 'Expired' && { name: item.status === 'Active' ? 'Deactivate' : 'Activate', onClick: () => { manageStatus(item.periodic_task_id, item.status === 'Active' ? false : true) } },
                                            { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Recurring Expense', 'delete', 'sm', delete_recurring_expense(item.periodic_task_id)); AnalyticsEvent(eventsNames.categories.RECURRING_EXPENSES,{action:eventsNames.actions.DELETE}) } }
                                        ]
                                    }
                                />
                            </CustomTableBodyCell>
                        </CustomTableRow>
                    )
                })
            }
        </CustomTableBody>
    )
}

const Filters = (props) => {
    const { setPage, stateChangeHandler, setFilters, filters } = props
    return (
        <Grid item xs={12} container spacing={1}>
            <Grid item xs={12} sm={2.5} >
                <Common.FilterStatus
                    filters={filters}
                    setPage={setPage}
                    setFilters={setFilters}
                    dataKey={'recurringExpenseStatus'}
                />
            </Grid>
        </Grid>
    )
}
