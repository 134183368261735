import React from 'react';
import Status from '../../../common/Status';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import DateRange from '../../../common/DateRange';
import { ItemViewLink } from '../common/CommonLinks';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import CommonSearch from '../../../common/CommonSearch';
import CommonExport from '../../../common/CommonExport';
import CustomTabs from '../../../custom/tabs/CustomTabs';
import { routesName } from '../../../../config/routesName';
import ListItemActions from '../../../common/ListItemActions';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import CustomButton from '../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import { NoDataComponent } from '../../../common/NoDataComponent';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { HeadingWithSortable } from '../../../common/SortableHeading';
import ViewPageActionButtons from '../../../common/ViewPageActionButtons';
import CustomPagination from '../../../custom/pagination/CustomPagination';
import { eventsNames, AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';

import {
    list_items,
    export_item,
    delete_item,
} from '../../../../api/urls';
import {
    setLoader,
    isFiltersApplied,
    setFilterMessage,
} from '../../../../utils/Utils';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../custom/container/CustomContainer';

import {
    Box,
    Grid,
} from '@mui/material';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';




const Dropdown = DateRange
const ItemsList = () => {

    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [tab, setTab] = React.useState(0)
    const tabs = [
        { AnalyticsEvent: () => { AnalyticsEvent(eventsNames.categories.ITEMS, {action:eventsNames.actions.CLICK, filter_type: eventsNames.actions.item.filter_type.ALL }) }, name: <span>All</span>, },
        { AnalyticsEvent: () => { AnalyticsEvent(eventsNames.categories.ITEMS, {action:eventsNames.actions.CLICK, filter_type: eventsNames.actions.item.filter_type.GOODS }) }, name: <span>Goods</span>, },
        { AnalyticsEvent: () => { AnalyticsEvent(eventsNames.categories.ITEMS, {action:eventsNames.actions.CLICK, filter_type: eventsNames.actions.item.filter_type.SERVICES }) }, name: <span>Services</span>, },
    ]

    const [page, setPage] = React.useState(1);
    const [results, setResults] = React.useState([]);
    const [isExport, setIsExport] = React.useState(false);
    const [disabledDataExport, setDisabledDataExport] = React.useState(true);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    })

    const [filters, setFilters] = React.useState({
        sort_by: 'created_at',
        sort_order: 'D',
    })

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })

    const stateChangeHandler = (title, condition, maxWidth, id, item, url) => {
        setState({
            id: id,
            url: url,
            open: true,
            item: item,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            onDeleteAction: () => { getApiResults(page); }
        })
    }


    const getApiResults = async (page) => {
        setLoader(dispatch, Actions, true);
        setFilterMessage(dispatch, Actions, null);
        let res = await apiAction({
            method: 'post',
            dispatch: dispatch,
            navigate: navigate,
            url: list_items(page),
            data: { role: "customer", business_id: getBusinessInfo().id, ...filters },
        })

        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.next,
                count: res?.result.count,
                previous: res?.result.previous,
                number_of_pages: res?.result.number_of_pages,
            });
            setResults(res?.result.result);
            setLoader(dispatch, Actions, false);
            if (disabledDataExport && res?.result.count !== 0) { setDisabledDataExport(false) }
            if (isFiltersApplied(filters)) { setFilterMessage(dispatch, Actions, `No items found for your current filters. Verify your filters and try again.`) };
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    React.useEffect(() => {
        getApiResults(page);
        // eslint-disable-next-line
    }, [filters, page]);

    React.useEffect(() => {
        if (tabs[tab].AnalyticsEvent) {
            tabs[tab].AnalyticsEvent()
        }
    }, [tab])

    const handleChange = (event, tab) => {
        setPage(1);
        setTab(tab);
        if (tab === 0) {
            delete filters.item_type

        }
        if (tab === 1) {
            filters['item_type'] = 'goods';
        }
        if (tab === 2) {
            delete filters.status;
            filters['item_type'] = 'service';
        }
        setFilters({ ...filters })
    }

    return (
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <CommonExport
                open={isExport}
                url={export_item}
                setOpen={setIsExport}
                fileName={`products & services`}
                title={`You can export your products & services from finycs in CSV or XLSX format.`}
            />
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={5} sm={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                        <CustomTitle title={'Goods & Services'} />
                    </Grid>
                    <Grid item xs={7} sm={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>

                        <CustomButton
                            sx={{}}
                            variant="contained"
                            btnLabel='Barcode Generator'
                            id={'barcode_generator_btn'}
                            dataTestId={'barcode_generator_btn'}
                            onClick={() => {
                                navigate(routesName.invoicingItemsBarcode.path);
                                AnalyticsEvent(eventsNames.categories.BARCODE_GENERATOR, { action: eventsNames.actions.NEW });
                            }}
                        />

                        <CustomButton
                            variant="contained"
                            btnLabel='Add Goods or Service'
                            id={'add_a_product_or_service_btn'}
                            sx={{ ml: 1,textTransform: 'none', }}
                            dataTestId={'add_a_product_or_service_btn'}
                            onClick={() => {
                                navigate(routesName.invoicingItemsAdd.path)
                                AnalyticsEvent(eventsNames.categories.ITEMS, { action: eventsNames.actions.NEW });
                            }}
                        />
                        <ViewPageActionButtons
                            isPrint={false}
                            isDownload={false}
                            actionsList={
                                [
                                    {
                                        name: 'Import Goods or service', onClick: () => {
                                            Actions.resetFileImports(dispatch);
                                            navigate(routesName.invoicingItemsFileUpload.path);
                                            AnalyticsEvent(eventsNames.categories.ITEMS,{action:eventsNames.actions.IMPORT});
                                        }
                                    },
                                    {
                                        disabled: disabledDataExport,
                                        name: 'Export Goods or service', onClick: () => { 
                                            setIsExport(true);
                                            AnalyticsEvent(eventsNames.categories.ITEMS,{action:eventsNames.actions.EXPORT})
                                         }
                                    },
                                ]
                            }
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{maxHeight:{xs:'calc(100vh - 240px)',sm:'100%'}}} >
                <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '16px', display: '-webkit-flex', }}>
                    <Filters filters={filters} setFilters={setFilters} setPage={setPage} />
                </div>
                <CustomTabs
                    id={'tabs_'}
                    dataTestId={'tabs_'}
                    tabs={tabs}
                    value={tab}
                    onChange={handleChange}
                />
                <CustomTableContainer>
                    <CustomTable>
                        <ItemsListHeaders filters={filters} setFilters={setFilters} />
                        <ItemsListBody data={results} stateChangeHandler={stateChangeHandler} setDisabledDataExport={setDisabledDataExport} />
                    </CustomTable>
                    <Box sx={{ pt: !results.length ? 10 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>

            <CustomPagination
                page={page}
                count={pagination.number_of_pages}
                onChange={(event, newValue) => setPage(newValue)}
            />
        </div>
    )
}


const ItemsListHeaders = (props) => {
    const { filters, setFilters } = props

    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell style={{ width: 100 }} align='center'><span style={{ cursor: '' }}>Status </span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }}><HeadingWithSortable heading={'Name'} sortableKey={'name'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 150 }}><span>HSN/SAC</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 100 }}><span>Type</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 100 }}><span>SKU Code</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 100 }}><span>Quantity</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 150 }} align='right'><span>Sales Price</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 150 }} align='right'><span>Cost Price</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 100 }} align='right'><span>Actions</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const ItemsListBody = (props) => {
    const { data, stateChangeHandler, setDisabledDataExport } = props
    let navigate = useNavigate();
    return (
        <CustomTableBody>
            {
                data.map((item, index) => {
                    return (
                        <CustomTableRow key={index}>
                            <CustomTableBodyCell sx={{}} align='center'>{<Status status={item.status} />}</CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><ItemViewLink id={item.id} title={item.name} /></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><span>{item.hsn_sac_code ? item.hsn_sac_code : '-'}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><span>{item.item_type.capitalize()}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><span>{item.stock_item_code ? item.stock_item_code : '-'}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><span>{item.current_stock ? item.current_stock : '-'}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'><span>{item.selling_price ? <CurrencyFormatter amount={item.selling_price} currency={getBusinessInfo().currency.currency_code} /> : '-'}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'><span>{item.cost_price ? <CurrencyFormatter amount={item.cost_price} currency={getBusinessInfo().currency.currency_code} /> : '-'}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>

                                <ListItemActions
                                    index={index}
                                    actions={
                                        [
                                            { name: 'View', onClick: () => { navigate(routesName.invoicingItemsView.path + "?item_id=" + item.id, { replace: false }); AnalyticsEvent(eventsNames.categories.ITEMS, { action: eventsNames.actions.VIEW }); } },
                                            { name: 'Edit', onClick: () => { navigate(routesName.invoicingItemsAdd.path + "?item_id=" + item.id, { replace: false })} },
                                            { name: 'Delete', showDivider: true, onClick: () => { setDisabledDataExport(true); stateChangeHandler('Delete Product or Service', 'delete', 'sm', item.id, item, delete_item(item.id)); AnalyticsEvent(eventsNames.categories.ITEMS, { action: eventsNames.actions.DELETE }); } }
                                        ]
                                    }
                                />
                            </CustomTableBodyCell>
                        </CustomTableRow>
                    )
                })
            }
        </CustomTableBody>
    )
}


export default ItemsList;

const Filters = (props) => {
    const { setPage, setFilters, filters } = props
    const statusList = [
        { name: 'In Stock', value: 'in_stock' },
        { name: 'No Stock', value: 'no_stock' },
        { name: 'Low Stock', value: 'low_stock' },
    ]
    return (
        <Grid item container spacing={1}>
            <Grid item xs={6} sm={2.5}>
                <Dropdown
                    id={'status_dropdown'}
                    dataTestId={'status_dropdown'}
                    label="Status"
                    results={statusList}
                    placeholder='Select Status'
                    item={{ disabled: filters.item_type === 'service', }}
                    value={filters.status ? statusList.find((item) => item.value === filters.status) : null}
                    setValue={(event, value) => {
                        setPage(1);
                        if (value) {
                            filters['status'] = value.value
                        } else {
                            delete filters.status
                        }
                        setFilters({ ...filters })
                    }}
                />
            </Grid>
            <Grid item xs={6} sm={3}>
                <CommonSearch
                    id={'input_search_name'}
                    dataTestId={'input_search_name'}

                    title={'Name'}
                    fullWidth={true}
                    placeholder={'Search by Name'}
                    onSearchValue={filters.name ? filters.name : ''}
                    onSearch={(search_text) => {
                        setPage(1);
                        if (search_text) {
                            filters.name = search_text;
                        } else {
                            delete filters.name
                        }
                        setFilters({ ...filters })
                        AnalyticsEvent("search_items_by_name", { module: "Items", screen: "Items List view", search_term: search_text })
                    }} />
            </Grid>

        </Grid>
    )
}
