
export const routesName = {
    landingPage: {
        path: '/',
        name: 'Landing Page',
        activeRoute: 'Landing Page',
    },
    MarketingLandingPage: {
        name: 'Landing Page',
        activeRoute: 'Landing Page',
        path: '/offers/september2024',
    },
    privacyPolicy: {
        path: '/app/web/privacy-policy',
        name: 'Privacy Policy'
    },
    termsAndServices: {
        path: '/app/web/terms',
        name: 'Terms of Service'
    },
    demoPage: {
        path: '/demo-page',
        name: 'Book Demo',
        activeRoute: 'Book Demo',
    },
    verify: {
        path: '/verify',
        name: 'Verify',
        activeRoute: 'Verify',
    },
    connect: {
        path: '/connect',
        name: 'Connect',
        activeRoute: 'Connect',

    },
    signUp: {
        path: '/sign-up',
        name: 'Sign Up',
        activeRoute: 'Sign Up',

    },
    signupWithPhone: {
        name: 'Sign Up',
        path: '/sign-up/phone',
        activeRoute: 'Sign Up',

    },
    verifyOtp: {
        name: 'Sign Up',
        activeRoute: 'Sign Up',
        path: '/sign-up/verify-otp',

    },
    otpBasedSignup: {
        name: 'Sign Up',
        activeRoute: 'Sign Up',
        path: '/otp-based-signup',

    },
    invitedUser: {
        name: 'User Invitation',
        path: '/user-invitation',
        activeRoute: 'User Invitation',
    },
    restPassword: {
        path: '/reset-password',
        name: 'Reset Password',
        activeRoute: 'Reset Password',

    },
    forgotPassword: {
        name: 'Forgot Password',
        path: '/forgot-password',
        activeRoute: 'Forgot Password',

    },
    openEmailAccount: {
        path: '/open-email-account',
        name: 'Open Email Account',
        activeRoute: 'Open Email Account',

    },
    changePassword: {
        path: '/change-password',
        name: 'Change Password',
        activeRoute: 'Change Password',

    },
    signIn: {
        path: '/login',
        name: 'Sign In',
        activeRoute: 'Sign In',

    },
    myAccount: {
        name: 'My Account',
        path: 'home/my-account',
        activeRoute: 'My Account',
    },
    settings: {
        name: 'Settings',
        path: '/home/settings',
        activeRoute: 'Settings',
    },
    support: {
        name: 'Support',
        path: '/home/support',
        activeRoute: 'Support',
    },
    myProfile: {
        name: 'User Profile',
        activeRoute: 'My Account',
        path: 'home/my-account/user-profile',

    },
    myBusiness: {
        activeRoute: 'My Account',
        name: 'Organization Profile',
        path: 'home/my-account/organization-profile',
    },
    myBusinessView: {
        name: 'View',
        activeRoute: 'My Account',
        path: 'home/my-account/organization-profile/view',
    },
    businessCreate: {
        path: '/company-details',
        name: 'Company Details',
        activeRoute: 'Company Details',
    },
    dashboard: {
        name: 'Dashboard',
        path: '/home/dashboard',
        activeRoute: 'Dashboard',
    },
    insight: {
        name: 'Insight',
        path: '/home/insight',
        activeRoute: 'Insight',
    },
    chartsAccountList: {
        name: 'Chart of Accounts',
        path: '/accounting/accounts',
        activeRoute: 'Chart of Accounts',

    },
    chartsAccountTransaction: {
        name: 'General Ledgers',
        activeRoute: 'Chart of Accounts',
        path: '/accounting/accounts/view',
    },
    chartsAccountAdd: {
        name: 'Add',
        activeRoute: 'Chart of Accounts',
        path: '/accounting/accounts/add',
    },
    parentAccount: {
        name: 'View Parent Account',
        activeRoute: 'Chart of Accounts',
        path: '/accounting/accounts/view-parent-account',
    },
    parentAccountAdd: {
        name: 'Add Parent Account',
        activeRoute: 'Chart of Accounts',
        path: '/accounting/accounts/add-parent-account',
    },
    mappings: {
        name: 'Mappings',
        activeRoute: 'Statement Upload',
        path: '/fileupload/statement-upload/mappings',
    },
    transactions: {
        name: 'Transactions',
        activeRoute: 'Statement Upload',
        path: '/fileupload/statement-upload/mappings/transactions',
    },
    fileUpload: {
        name: 'Statement Upload',
        activeRoute: 'Statement Upload',
        path: '/fileupload/statement-upload',
    },
    sourceTransactions: {
        name: 'Source Transactions',
        activeRoute: 'Source Transactions',
        path: '/fileupload/source-transaction',
    },
    journalEntryCreate: {
        name: 'New Journal Entry',
        activeRoute: 'Journal Entry',
        path: '/accounting/journal-entry/add',
    },
    journalEntryList: {
        name: 'Journal Entry',
        activeRoute: 'Journal Entry',
        path: '/accounting/journal-entry',
    },
    journalEntryTransaction: {
        name: 'General Ledgers',
        activeRoute: 'Journal Entry',
        path: '/accounting/journal-entry/view',
    },
    openingBalanceAdjustment: {
        name: 'Opening Balances',
        activeRoute: 'Opening Balances',
        path: '/accounting/opening-balances',
    },
    openingBalanceImport: {
        name: 'File Upload',
        activeRoute: 'Opening Balances',
        path: '/accounting/opening-balances/file-upload',
    },
    openingBalanceMapping: {
        name: 'Mappings',
        activeRoute: 'Opening Balances',
        path: '/accounting/opening-balances/file-upload/mapping',
    },
    openingBalancePreview: {
        name: 'Opening Balance Preview',
        activeRoute: 'Opening Balances',
        path: '/accounting/opening-balances/file-upload/mapping/preview',
    },
    reportsList: {
        name: 'Reports',
        path: '/home/reports',
        activeRoute: 'Reports',
    },
    profitLoss: {
        activeRoute: 'Reports',
        name: 'Profit and Loss',
        path: '/home/reports/profit-loss',
    },
    horizontalProfitLoss: {
        activeRoute: 'Reports',
        name: 'Horizontal Profit and Loss',
        path: '/home/reports/horizontal-profit-loss',
    },
    balanceSheet: {
        name: 'Balance Sheet',
        activeRoute: 'Reports',
        path: '/home/reports/balance-sheet',
    },
    horizontalBalanceSheet: {
        activeRoute: 'Reports',
        name: 'Horizontal Balance Sheet',
        path: '/home/reports/horizontal-balance-sheet',
    },
    movementEquityReports: {
        activeRoute: 'Reports',
        name: 'Movement of Equity',
        path: '/home/reports/movement-of-equity',
    },
    businessPerformanceRatiosReports: {
        activeRoute: 'Reports',
        name: 'Business Performance Ratios',
        path: '/home/reports/business-performance-ratios',
    },
    journalReports: {
        activeRoute: 'Reports',
        name: 'Journal Report',
        path: '/home/reports/journal-report',
    },
    timeToGetPaidReports: {
        activeRoute: 'Reports',
        name: 'Time To Get Paid',
        path: '/home/reports/time-to-get-paid',
    },
    cashFlow: {
        name: 'Cash Flow',
        activeRoute: 'Reports',
        path: '/home/reports/cash-flow',
    },
    trialBalanceTransaction: {
        name: 'Trial Balance',
        activeRoute: 'Reports',
        path: '/home/reports/trial-balance/view',
    },
    trialBalance: {
        name: 'Trial Balance',
        activeRoute: 'Reports',
        path: '/home/reports/trial-balance',
    },
    accountTransactionReports: {
        activeRoute: 'Reports',
        name: 'Account Transactions',
        path: '/home/reports/account-transactions',
    },
    dayBookReports: {
        name: 'Day Book',
        activeRoute: 'Reports',
        path: '/home/reports/day-book',
    },
    generalLedgerList: {
        activeRoute: 'Reports',
        name: 'General Ledgers',
        path: '/home/reports/general-ledger',
    },
    generalLedgerTransaction: {
        activeRoute: 'Reports',
        name: 'General Ledgers',
        path: '/home/reports/general-ledger/view',
    },
    salesByCustomer: {
        activeRoute: 'Reports',
        name: 'Sales By Customer',
        path: '/home/reports/sales-by-customer',
    },
    salesByItem: {
        name: 'Sales By Item',
        activeRoute: 'Reports',
        path: '/home/reports/sales-by-item',
    },
    salesBySalesPerson: {
        activeRoute: 'Reports',
        name: 'Sales By Sales Person',
        path: '/home/reports/sales-by-sales-person',
    },
    salesBySalesPersonView: {
        activeRoute: 'Reports',
        name: 'Sales By Sales Person',
        path: '/home/reports/sales-by-sales-person/view',
    },
    incomeByCustomer: {
        activeRoute: 'Reports',
        name: 'Income By Customer',
        path: '/home/reports/income-by-customer',
    },
    purchaseByVendor: {
        activeRoute: 'Reports',
        name: 'Purchase By Vendor',
        path: '/home/reports/purchase-by-vendor',
    },
    customerBalancesReports: {
        activeRoute: 'Reports',
        name: 'Customer Balances',
        path: '/home/reports/customer-balances',
    },
    arAgingSummaryReports: {
        activeRoute: 'Reports',
        name: 'AR Aging Summary',
        path: '/home/reports/AR-aging-summary',
    },
    apAgingSummaryReports: {
        activeRoute: 'Reports',
        name: 'AP Aging Summary',
        path: '/home/reports/AP-aging-summary',
    },
    arDetailedReports: {
        activeRoute: 'Reports',
        name: 'AR Aging Details',
        path: '/home/reports/AR-aging-details',
    },
    apDetailedReports: {
        activeRoute: 'Reports',
        name: 'AP Aging Details',
        path: '/home/reports/AP-aging-details',
    },
    vendorBalancesReports: {
        activeRoute: 'Reports',
        name: 'Vendor Balances',
        path: '/home/reports/vendor-balances',
    },
    purchaseByItem: {
        activeRoute: 'Reports',
        name: 'Purchase By Item',
        path: '/home/reports/item',
    },
    taxSummary: {
        name: 'Tax Summary',
        activeRoute: 'Reports',
        path: '/home/reports/tax-summary',
    },
    tcsSummaryReports: {
        name: 'Form No. 27EQ',
        activeRoute: 'Reports',
        path: '/home/reports/form-no.-27EQ',
    },
    tdsSummaryReports: {
        name: 'TDS Summary',
        activeRoute: 'Reports',
        path: '/home/reports/TDS-summary',
    },
    tdsDetailsSectionReports: {
        activeRoute: 'Reports',
        name: 'TDS section Details',
        path: '/home/reports/TDS-summary/section',
    },
    paymentsReceivedReports: {
        activeRoute: 'Reports',
        name: 'Payments Received',
        path: '/home/reports/payments-received',
    },
    paymentsMadeReports: {
        name: 'Payments Made',
        activeRoute: 'Reports',
        path: '/home/reports/payments-made',
    },
    creditNoteDetailsReports: {
        activeRoute: 'Reports',
        name: 'Credit Note Details',
        path: '/home/reports/credit-note-details',
    },
    debitNoteDetailsReports: {
        activeRoute: 'Reports',
        name: 'Debit Note Details',
        path: '/home/reports/debit-note-details',
    },
    estimateDetailsReports: {
        activeRoute: 'Reports',
        name: 'Estimate Details',
        path: '/home/reports/estimate-details',
    },
    salesOrderDetailsReports: {
        activeRoute: 'Reports',
        name: 'Sales Order Details',
        path: '/home/reports/sales-order-details',
    },
    invoiceDetailsReports: {
        activeRoute: 'Reports',
        name: 'Invoice Details',
        path: '/home/reports/invoice-details',
    },
    recurringInvoiceDetailsReports: {
        activeRoute: 'Reports',
        name: 'Recurring Invoice Details',
        path: '/home/reports/recurring-invoice-details',
    },
    deliveryChallanDetailsReports: {
        activeRoute: 'Reports',
        name: 'Delivery Challan Details',
        path: '/home/reports/delivery-challan-details',
    },
    purchaseOrderDetailsReports: {
        activeRoute: 'Reports',
        name: 'Purchase Order Details',
        path: '/home/reports/purchase-order-details',
    },
    expenseDetailsReports: {
        name: 'Expense Details',
        activeRoute: 'Expense Details',
        path: '/home/reports/expense-details',
    },
    recurringExpenseDetailsReports: {
        activeRoute: 'Reports',
        name: 'Recurring Expense Details',
        path: '/home/reports/recurring-expense-details',
    },
    billDetailsReports: {
        name: 'Bill Details',
        activeRoute: 'Reports',
        path: '/home/reports/bill-details',
    },
    activityAuditTrailReports: {
        activeRoute: 'Reports',
        name: 'Activity Logs',
        path: '/home/reports/activity-logs',
    },
    customersRefundReports: {
        activeRoute: 'Reports',
        name: 'Customers Refund',
        path: '/home/reports/customers-refund',
    },
    vendorsRefundReports: {
        activeRoute: 'Reports',
        name: 'Vendors Refund',
        path: '/home/reports/vendors-refund',
    },
    itemStockReports: {
        name: 'Item Stock',
        activeRoute: 'Reports',
        path: '/home/reports/item-stock',
    },
    downloadList: {
        name: 'Downloads',
        activeRoute: 'Downloads',
        path: '/home/downloads',
    },
    invoicingItems: {
        name: 'Items',
        activeRoute: 'Items',
        path: '/invoicing/items',

    },
    invoicingItemsBarcode: {
        name: 'Barcode Generator',
        activeRoute: 'Items',
        path: '/invoicing/items/barcode-generator',

    },
    invoicingItemsAdd: {
        name: 'Add',
        activeRoute: 'Items',
        path: '/invoicing/items/add',
    },
    invoicingItemsView: {
        name: 'View',
        activeRoute: 'Items',
        path: '/invoicing/items/view',
    },
    invoicingItemsFileUpload: {
        name: 'View',
        activeRoute: 'Items',
        path: '/invoicing/items/import',
    },
    invoicingItemsMapping: {
        name: 'Mapping',
        activeRoute: 'Items',
        path: '/invoicing/items/file-upload/mapping',
    },
    invoicingItemsPreview: {
        name: 'View',
        activeRoute: 'Items',
        path: '/invoicing/items/file-upload/mapping/preview',
    },
    invoicingSalesCustomer: {
        name: 'Customers',
        activeRoute: 'Customers',
        path: '/sales/customers',

    },
    invoicingSalesCustomerAdd: {
        name: 'Add',
        activeRoute: 'Customers',
        path: '/sales/customers/add',
    },
    invoicingSalesCustomerView: {
        name: 'View',
        activeRoute: 'Customers',
        path: '/sales/customers/view',
    },
    invoicingSalesEstimate: {
        name: 'Estimates',
        activeRoute: 'Estimates',
        path: '/sales/estimates',
    },
    invoicingSalesEstimateAdd: {
        name: 'Add',
        activeRoute: 'Estimates',
        path: '/sales/estimates/add',
    },
    invoicingSalesEstimateView: {
        name: 'View',
        activeRoute: 'Estimates',
        path: '/sales/estimates/view',
    },
    invoicingSalesCustomersImportFile: {
        name: 'File Upload',
        activeRoute: 'Customers',
        path: '/sales/customers/import',
    },
    invoicingSalesCustomersImportMapping: {
        name: 'Mapping',
        activeRoute: 'Customers',
        path: '/sales/customers/file-upload/mapping',
    },
    invoicingSalesCustomersImportPreview: {
        name: 'Mapping',
        activeRoute: 'Customers',
        path: '/sales/customers/file-upload/mapping/preview',
    },
    invoicingSalesInvoice: {
        name: 'Invoices',
        activeRoute: 'Invoices',
        path: '/sales/invoices',

    },
    invoicingSalesInvoiceAdd: {
        name: 'Add',
        activeRoute: 'Invoices',
        path: '/sales/invoices/add',
    },
    invoicingSalesInvoiceView: {
        name: 'View',
        activeRoute: 'Invoices',
        path: '/sales/invoices/view',
    },
    invoicingSalesRecurringInvoice: {
        activeRoute: 'Recurring Invoices',
        name: 'Recurring Invoices',
        path: '/sales/recurring-invoices',
    },
    invoicingSalesRecurringInvoiceAdd: {
        name: 'Add',
        activeRoute: 'Recurring Invoices',
        path: '/sales/recurring-invoices/add',
    },
    invoicingSalesRecurringInvoiceView: {
        name: 'View',
        activeRoute: 'Recurring Invoices',
        path: '/sales/recurring-invoices/view',
    },
    invoicingSalesOrder: {
        name: 'Sales Orders',
        activeRoute: 'Sales Orders',
        path: '/sales/sales-orders',

    },
    invoicingSalesOrderAdd: {
        name: 'Add',
        activeRoute: 'Sales Orders',
        path: '/sales/sales-orders/add',
    },
    invoicingSalesOrderView: {
        name: 'View',
        activeRoute: 'Sales Orders',
        path: '/sales/sales-orders/view',
    },
    invoicingDeliveryChallan: {
        name: 'Delivery Challans',
        activeRoute: 'Delivery Challans',
        path: '/sales/delivery-challans',
    },
    invoicingDeliveryChallanAdd: {
        name: 'Add',
        activeRoute: 'Delivery Challans',
        path: '/sales/delivery-challans/add',
    },
    invoicingDeliveryChallanView: {
        name: 'View',
        activeRoute: 'Delivery Challans',
        path: '/sales/delivery-challans/view',
    },
    invoicingPaymentsReceived: {
        name: 'Add',
        activeRoute: 'Payments Received',
        path: '/sales/payments-received/add',
    },
    invoicingPaymentsReceivedView: {
        name: 'View',
        activeRoute: 'Payments Received',
        path: '/sales/payments-received/view',
    },
    invoicingPaymentsReceivedRefund: {
        name: 'Refund',
        activeRoute: 'Payments Received',
        path: '/sales/payments-received/refund',
    },
    invoicingPaymentsReceivedList: {
        name: 'Payments Received',
        activeRoute: 'Payments Received',
        path: '/sales/payments-received',
    },

    invoicingCreditNotesList: {
        name: 'Credit Notes',
        path: '/sales/credit-notes',
        activeRoute: 'Credit Notes',

    },
    invoicingCreditNotesAdd: {
        name: 'Add',
        activeRoute: 'Credit Notes',
        path: '/sales/credit-notes/add',
    },
    invoicingCreditNotesRefund: {
        name: 'Refund',
        activeRoute: 'Credit Notes',
        path: '/sales/credit-notes/refund',
    },
    invoicingCreditNotesView: {
        name: 'View',
        activeRoute: 'Credit Notes',
        path: '/sales/credit-notes/view',
    },
    invoicingPaymentsMade: {
        name: 'Add',
        activeRoute: 'Payments Made',
        path: '/sales/payments-made/add',
    },
    invoicingPaymentsMadeView: {
        name: 'View',
        activeRoute: 'Payments Made',
        path: '/sales/payments-made/view',
    },
    invoicingPaymentsMadeRefund: {
        name: 'Refund',
        activeRoute: 'Payments Made',
        path: '/sales/payments-made/refund',
    },
    invoicingPaymentsMadeList: {
        name: 'Payments Made',
        activeRoute: 'Payments Made',
        path: '/sales/payments-made',
    },

    invoicingPurchaseVendor: {
        name: 'Vendors',
        activeRoute: 'Vendors',
        path: '/purchase/vendors',
    },

    invoicingPurchaseVendorImportFile: {
        name: 'File Upload',
        activeRoute: 'Vendors',
        path: '/purchase/vendors/file-upload',
    },

    invoicingPurchaseVendorImportMapping: {
        name: 'Mapping',
        activeRoute: 'Vendors',
        path: '/purchase/vendors/file-upload/mapping',
    },

    invoicingPurchaseVendorImportPreview: {
        name: 'Transactions',
        activeRoute: 'Vendors',
        path: '/purchase/vendors/file-upload/mapping/preview',
    },

    invoicingPurchaseVendorAdd: {
        name: 'Add',
        activeRoute: 'Vendors',
        path: '/purchase/vendors/add',
    },
    invoicingPurchaseVendorView: {
        name: 'View',
        activeRoute: 'Vendors',
        path: '/purchase/vendors/view',
    },
    invoicingPurchaseBill: {
        name: 'Bills',
        activeRoute: 'Bills',
        path: '/purchase/bills',

    },
    invoicingPurchaseBillAdd: {
        name: 'Add',
        activeRoute: 'Bills',
        path: '/purchase/bills/add',
    },
    invoicingPurchaseBillView: {
        name: 'View',
        activeRoute: 'Bills',
        path: '/purchase/bills/view',
    },
    invoicingExpense: {
        name: 'Expenses',
        activeRoute: 'Expenses',
        path: '/purchase/expenses',
    },
    invoicingExpenseAdd: {
        name: 'Add',
        activeRoute: 'Expenses',
        path: '/purchase/expenses/add',
    },
    invoicingExpenseView: {
        name: 'View',
        activeRoute: 'Expenses',
        path: '/purchase/expenses/view',
    },
    invoicingRecurringExpense: {
        name: 'Recurring Expenses',
        activeRoute: 'Recurring Expenses',
        path: '/purchase/recurring-expenses',
    },
    invoicingRecurringExpenseAdd: {
        name: 'Add',
        activeRoute: 'Recurring Expenses',
        path: '/purchase/recurring-expenses/add',
    },
    invoicingRecurringExpenseView: {
        name: 'View',
        activeRoute: 'Recurring Expenses',
        path: '/purchase/recurring-expenses/view',
    },
    invoicingPurchaseOrder: {
        name: 'Purchase Orders',
        activeRoute: 'Purchase Orders',
        path: '/purchase/purchase-orders',
    },
    invoicingPurchaseOrderAdd: {
        name: 'Add',
        activeRoute: 'Purchase Orders',
        path: '/purchase/purchase-orders/add',
    },
    invoicingPurchaseOrderView: {
        name: 'View',
        activeRoute: 'Purchase Orders',
        path: '/purchase/purchase-orders/view',
    },
    invoicingDebitNotesList: {
        name: 'Debit Notes',
        activeRoute: 'Debit Notes',
        path: '/purchase/debit-notes',
    },
    invoicingDebitNotesAdd: {
        name: 'Add',
        activeRoute: 'Debit Notes',
        path: '/purchase/debit-notes/add',
    },
    invoicingDebitNotesRefund: {
        name: 'Refund',
        activeRoute: 'Debit Notes',
        path: '/purchase/debit-notes/refund',
    },
    invoicingDebitNotesView: {
        name: 'View',
        activeRoute: 'Debit Notes',
        path: '/purchase/debit-notes/view',
    },
    gstFilingComponent: {
        name: 'GST Filing',
        path: '/home/GST-filing',
        activeRoute: 'GST Filing',

    },
    gstr3BFilingComponent: {
        name: 'GSTR-3B Summary',
        activeRoute: 'GST Filing',
        path: '/home/GST-filing/GSTR-3B-summary',
    },
    gstr1FilingComponent: {
        name: 'GSTR-1',
        activeRoute: 'GST Filing',
        path: '/home/GST-filing/GSTR-1',
    },
    remindersComponent: {
        name: 'Reminders',
        path: '/home/reminders',
        activeRoute: 'Reminders',

    },
    remindersComponentAdd: {
        name: 'Add',
        activeRoute: 'Reminders',
        path: '/home/reminders/add',
    },


    customerInvoice:{
        path: '/inv',
        name: 'Customer Invoice',
        activeRoute: 'Customer Invoice',
    }

}

