import React from "react";
import NotFound from "../NotFound";
import { matchRoute } from "../../utils/Utils";
import { mainRoutes } from "../../config/routes";
import { routesName } from "../../config/routesName";
import Sidebar from "../../components/custom/navigation/sideBar/Sidebar";
import Navbar from "../../components/custom/navigation/navBar/Navbar";

import {
  getToken,
  isAuthenticated,
} from "../../config/cookiesInfo";

import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";

import {
  Box,
} from "@mui/material";

function MainLayout() {
  // console.log('MainLayout=>')

  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname;
  const isRouteMatched = matchRoute(mainRoutes, currentPath);

  const ProtectedRoute = ({ children }) => {
    if (!isAuthenticated()) {
      if (getToken()) {
        return <Navigate to={"/auth" + routesName.businessCreate.path} replace />;
      } else {
        return <Navigate to={"/auth" + routesName.signIn.path} replace />;
      }
    }
    return children;
  };

  return (
    <Box sx={{ display: 'flex', position: 'fixed', width: "100%" }}>
      {/* Only render Sidebar and Navbar if the current path matches a defined route */}
      {isAuthenticated() && isRouteMatched && <Sidebar navigate={navigate} />}
      <Box className="main-container" sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
        {/* Only render Navbar if the current path matches a defined route */}
        {isAuthenticated() && isRouteMatched && (
          <Box sx={{ height: '60px', backgroundColor: 'white', alignItems: 'center' }}>
            <Navbar navigate={navigate} />
          </Box>
        )}

        <Box sx={{ margin: '16px', mt: '5px', height: '100vh' }}>
          <Routes>
            {mainRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <ProtectedRoute>
                    {route.component}
                  </ProtectedRoute>
                }
              />
            ))}
            {/* Catch-all route for main, only render 404 when no routes match */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}

export default MainLayout;




