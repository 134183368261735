import React from 'react';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import { setLoader } from '../../../../utils/Utils';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import { routesName } from '../../../../config/routesName';
import CustomButton from '../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
    list_bill_reminder,
    update_bill_reminder,
    // delete_bill_reminder,
    // create_bill_reminder,
    list_invoice_reminder,
    update_invoice_reminder,
    // delete_invoice_reminder,
    // create_invoice_reminder,
} from '../../../../api/urls';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../custom/container/CustomContainer';

import {
    Box,
    Grid,
    IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CreateNewReminder from './CreateNewReminder';

const RemindersPage = (props) => {
    const { initial_state } = props;
    React.useEffect(() => { setEdit(false) }, [initial_state])

    const [edit, setEdit] = React.useState(false);
    const [data, setData] = React.useState()

    return (
        edit ?
            <CreateNewReminder
                locationState={{
                    type: edit,
                    data: data,
                    createURL: edit === 'invoice' ? 'create_invoice_reminder' : 'create_bill_reminder',
                    updateURL: edit === 'invoice' ? 'update_invoice_reminder' : 'update_bill_reminder',
                    deleteURL: edit === 'invoice' ? 'delete_invoice_reminder' : 'delete_bill_reminder',
                }}
                onCancel={() => { setEdit(false); setData(null) }}

            />
            :
            <div>
                <CustomTitleContainer sx={{ pt: 1.7, pb: 1.7 }}>
                    <CustomTitle title={'Reminders'} />
                </CustomTitleContainer>
                <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)',maxHeight:{xs:'calc(100vh - 180px)',sm:'100%'} }}>
                    <Grid container spacing={2} sx={{ pt: 2 }}>
                        <Grid item xs={12} sm={6}>
                            <CommonData
                                onClickAction={(value) => setEdit(value)}
                                getDataAction={(item) => setData(item)}
                                id={'invoice_'}
                                dataTestId={'invoice_'}
                                listURL={list_invoice_reminder}
                                updateURL={update_invoice_reminder}

                                type={'invoice'}
                                editURL={'update_invoice_reminder'}
                                deleteURL={'delete_invoice_reminder'}
                                createURL={'create_invoice_reminder'}
                                primaryText={'Automated Reminders - Invoices'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CommonData
                                onClickAction={(value) => setEdit(value)}
                                getDataAction={(item) => setData(item)}
                                id={'bill_'}
                                dataTestId={'bill_'}
                                listURL={list_bill_reminder}
                                updateURL={update_bill_reminder}

                                type={'bill'}
                                editURL={'update_bill_reminder'}
                                deleteURL={'delete_bill_reminder'}
                                createURL={'create_bill_reminder'}
                                primaryText={'Automated Reminders - Bills'}

                            />
                        </Grid>
                    </Grid>
                </CustomContainer>
            </div>
    )
}

export default RemindersPage;

const CommonData = (props) => {
    const { id, onClickAction, getDataAction, dataTestId, primaryText, createURL, listURL, updateURL, deleteURL, editURL, type } = props;
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [results, setResults] = React.useState([]);

    React.useEffect(() => {
        if (listURL) {
            getApiResults(listURL)
        }
        // eslint-disable-next-line
    }, [listURL])

    const getApiResults = async (listURL) => {
        setLoader(dispatch, Actions, true);
        let res = await apiAction({
            method: 'get',
            navigate: navigate,
            dispatch: dispatch,
            url: listURL(getBusinessInfo().id)
        })
        if (res?.success) {
            setLoader(dispatch, Actions, false);
            setResults(res?.result);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    const onTurnOff = async (data) => {
        let res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            url: updateURL(data.id),
            data: { ...data, is_active: !data.is_active },
        })
        if (res?.success) {
            var index = results.indexOf(data);

            if (index !== -1) {
                results[index] = res?.result;
            }
            setResults([...results])
        } else {
        }
    }
    return (
        <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '24px', }}>

            <Box sx={{ borderBottom: '2px solid #ececec', display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <CustomTypography
                        text={primaryText}
                        sx={{
                            mb: 1,
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '500',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                    <CustomTypography
                        text={'Based on Due Date'}
                        sx={{
                            mb: 1,
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </Box>
                <Box>
                    <CustomButton
                        variant="contained"
                        onClick={() => { onClickAction(type); }}
                        // onClick={() => { navigate(routesName.remindersComponentAdd.path, { state: { type: type, createURL: createURL, deleteURL: deleteURL, } }) }}
                        btnLabel='+ New Reminder'
                        sx={{ textTransform: 'none', }}
                    />
                </Box>
            </Box>

            <Box sx={{}}>
                {results.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <FlatList
                                id={id + index + '_'}
                                dataTestId={dataTestId + index + '_'}
                                data={item}
                                onTurnOff={onTurnOff}

                                // onEdit={(data) => { navigate(routesName.remindersComponentAdd.path, { state: { type: type, updateURL: editURL, deleteURL: deleteURL, data: data } }) }}
                                onEdit={(data) => { onClickAction(type); getDataAction(data) }}
                            />
                        </React.Fragment>
                    )
                })}
            </Box>

            {/* <Box sx={{ pb: 1, pt: results.length ? 0 : 1, borderBottom: '2px solid #ececec', }}>
               
            </Box> */}
        </div>
    )
}

const FlatList = (props) => {
    const { id, dataTestId, data, onTurnOff, onEdit } = props;

    return (
        <Box sx={{ mb: 1, borderBottom: '2px solid #ececec', }}>
            <Grid container spacing={0} sx={{ pt: 1, pb: 1 }} style={{
                alignItems: 'center',
                justifyItems: 'center',
                alignContent: 'space-evenly',
                justifyContent: 'space-evenly',
            }}>
                <Grid item xs={6} sx={{ display: 'flex', }}>
                    <Box sx={{ justifyItems: 'center' }}>
                        <CustomTypography
                            text={<span>{data.name}<span style={{ marginLeft: '2px', padding: '2px', fontSize: '8px', background: data.is_active ? '#388a10' : '#6c757d', color: '#fafafa' }}>{data.is_active ? 'ENABLED' : 'DISABLED'}</span></span>}
                            sx={{
                                color: '#2464EB',
                                fontSize: '14px',
                                fontWeight: '500',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                display: 'inline',
                                fontFamily: "Noto Sans",
                            }}
                        />
                        {
                            data.is_active &&
                            <CustomTypography
                                text={`Reminder ${data.remind_to ? data.remind_to : 'will be sent'} ${data.days} day(s) ${data.relative_due} due date.`}
                                sx={{
                                    mt: 1,
                                    color: '#000000',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        }
                    </Box>

                </Grid>
                <Grid item xs sx={{ textAlign: 'end' }}>
                    {
                        data.is_active &&
                        <IconButton sx={{ mr: 0.5 }} onClick={() => { onEdit(data) }} id={id + 'edit_icon'} dataTestId={dataTestId + 'edit_icon'}>
                            <EditIcon sx={{ color: '#2464EB', }} />
                        </IconButton>
                    }
                    <CustomButton
                        id={id + 'active_btn'}
                        dataTestId={id + 'active_btn'}
                        variant="outlined"
                        timeoutValue={500}
                        onClick={() => {
                            if (data.is_active) {
                                onTurnOff(data);
                            } else {
                                onEdit(data)
                            }
                        }}
                        sx={{ textTransform: 'none', }}
                        btnLabel={data.is_active ? 'Turn Off' : 'Turn On'}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}