import { isNumeric } from '../../../../utils/Utils';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import { opening_balance_adjustments } from '../../../../utils/Constant';

// Utility function to safely retrieve numeric values
const getNumericValue = (value) => isNaN(value) ? 0 : Number(value);

const getUniqueKeys = (data, key, lastKey = null) => {
    // Map over data to get the desired keys, using fallback logic
    let keys = data.map((element) => {
        return element.adjusted_amount ? (element[key] ? element[key] : element.account_name) : null;
    });

    // Remove duplicates and filter out nulls
    let uniqueKeys = new Set(keys.filter(item => item !== null));

    // Optionally move the specified lastKey to the end, if present
    if (lastKey && uniqueKeys.has(lastKey)) {
        uniqueKeys.delete(lastKey); // Remove it from the set first
        uniqueKeys.add(lastKey);    // Re-add it to ensure it appears last
    }

    return uniqueKeys;
};

// Refactored groupByAccountType function
export const groupByAccountType = (accounts) => {
    const groupedAccountTypes = [...new Set(accounts.map(account => account.account_type))].filter(Boolean);

    return groupedAccountTypes.map((type) => {

        const filteredData = accounts
            .filter(account => account.account_type === type && !["Account Receivable", "Account Payable", opening_balance_adjustments].includes(account.account_name))
            .map(account => ({
                ...account,
                initial_adjusted_transaction_type: account.adjusted_transaction_type,
                debit: account.adjusted_transaction_type === "DEBIT" ? account.adjusted_amount || '' : '',
                credit: account.adjusted_transaction_type === "CREDIT" ? account.adjusted_amount || '' : ''
            }))

        const totalDebitAmount = filteredData.reduce((sum, item) => sum + getNumericValue(item.debit), 0);
        const totalCreditAmount = filteredData.reduce((sum, item) => sum + getNumericValue(item.credit), 0);

        return {
            name: type,
            transactions: filteredData,
            total_debit_amount: totalDebitAmount,
            total_credit_amount: totalCreditAmount,
        }
    })
};

export const createTransactionBody = (data) => {
    console.log('====>createTransactionBody',data)
    const filterList = [...new Set(data.flatMap(account => account.transactions))];
    return filterList?.filter(transaction => transaction.hasChanged)?.map(item => ({
      business_account_id: item.id,
      journal_entry_id: item.journal_entry_id,
      transaction_date: item.opening_balance_date,
      currency_id: getBusinessInfo()?.currency?.id,
      transaction_type: item.debit ? 'DEBIT' : 'CREDIT',
      amount: Number(item.debit) || Number(item.credit),
    }));
  };

export const groupByAutoForView = (data, key) => {
    // console.log('data', data)
    let unique_keys = getUniqueKeys(data,key,'EQUITY');

    let result = [];
    for (const element of unique_keys) {
        if (element) {
            let filtered_result = data.filter((value) => {
                value['debit'] = value.adjusted_transaction_type === "DEBIT" ? value.adjusted_amount ? value.adjusted_amount : 0 : 0;
                value['credit'] = value.adjusted_transaction_type === "CREDIT" ? value.adjusted_amount ? value.adjusted_amount : 0 : 0;
                return value.adjusted_amount ? value[key] ? value[key] === element : value.account_name === element : null;
            });
            let total_debit_amount = 0;
            let total_credit_amount = 0;
            filtered_result.forEach(x => {
                total_debit_amount += x['debit'];
                total_credit_amount += x['credit'];
            });
            result.push({ "account_type": element, "transactions": [...filtered_result], 'total_debit_amount': isNumeric(total_debit_amount) ? Number(total_debit_amount) : 0, 'total_credit_amount': isNumeric(total_credit_amount) ? Number(total_credit_amount) : 0, });
        }
    }
    return result
}

export const calculateTotalBalances = (accounts) => {
    return accounts?.reduce((totals, account) => {
        const { debit, credit } = account?.transactions?.reduce((acc, trans) => {
            if (trans.account_name !== 'opening_balance_adjustments') {
                acc.debit += trans.debit || 0;
                acc.credit += trans.credit || 0;
            }
            return acc;
        }, { debit: 0, credit: 0 });

        totals.totalDebit += debit;
        totals.totalCredit += credit;

        // Calculate total_amount and adjusted_amount outside the reduce to avoid recalculating each iteration
        totals.totalAmount = Math.max(totals.totalDebit, totals.totalCredit);
        totals.adjustmentAmount = Math.abs(totals.totalDebit - totals.totalCredit);

        return totals;
    }, { totalDebit: 0, totalCredit: 0, totalAmount: 0, adjustmentAmount: 0 });
};



