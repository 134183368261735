import React from 'react';
import { isNumeric } from '../../utils/Utils';
import { amountFormatter } from '../../utils/Utils';

const CurrencyFormatter = ({ amount, currency, isFractionDigits = true, }) => {
  return (
    isFractionDigits ?
    <span style={{whiteSpace:'nowrap'}}>{amountFormatter(isNumeric(amount) ? Number(amount) : 0, currency)}</span>
    :
    <span style={{whiteSpace:'nowrap'}}>{amountFormatter(isNumeric(amount) ? Number(amount).toFixed() : 0, currency)?.trim()?.replace('.00', "")}</span>
  )

}

export default CurrencyFormatter;