import React from 'react';
import { Box, Grid } from '@mui/material';
import { apiAction } from '../../../../../api/api';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import { stateChangeManager } from '../../../../../utils/Utils';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { generate_complete_backup_file } from '../../../../../api/urls';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { DownloadRadioButtons } from '../../../../common/CommonDownloadLayout';
import CustomDateRangePicker from '../../../../custom/Datepicker/CustomDateRangePicker';
import { CustomContainer, CustomTitleContainer } from '../../../../custom/container/CustomContainer';

const DataBackup = () => {

    // const [isDownload, setIsDownload] = React.useState(false);
    const [timePeriod, setTimePeriod] = React.useState("all");
    const [filters, setFilters] = React.useState({
        "export_to": "csv",
        "business_id": getBusinessInfo().id,
    });
    const dispatch = Actions.getDispatch(React.useContext);

    React.useEffect(() => {
        if (timePeriod === "all") {
            setFilters({ ...filters, from_datetime: null, to_datetime: null })
        }
    }, [timePeriod])

    const backupData = async () => {
        if (timePeriod === "specificTimePeriod" && !filters.from_datetime) {
            stateChangeManager(dispatch, Actions, true, 'error', "Please select a valid date range.")
            return
        }
        let res = await apiAction({
            method: "post",
            url: generate_complete_backup_file(),
            data: filters,
        })
        if (res?.success) {
            stateChangeManager(dispatch, Actions, true, 'success', res?.status)
            setTimePeriod("all")
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status)
        }
    }

    const onDateRangeChange = (dates) => {
        if (dates) {
            setFilters({
                ...filters,
                from_datetime: dates[0].format("YYYY/MM/DD HH:mm:ss"),
                to_datetime: dates[1].format("YYYY/MM/DD HH:mm:ss"),
            })

        } else {
            delete filters.to_datetime
            delete filters.from_datetime
            setFilters({ ...filters })
        }
    }

    return (
        <Box>
            {/* <CommonDownloadLayout
                open={isDownload}
                setOpen={setIsDownload}
                filters={""}
                componentType={"backup_data"}
            /> */}
            <CustomTitleContainer sx={{ pt: 0.9, pb: 1.7 }}>
                <CustomTitle title={'Data Backup'} />
            </CustomTitleContainer>
            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)', maxHeight: { xs: 'calc(100vh - 180px)', sm: '100%' } }}>
                <CustomTypography
                    text={"Backup Organizational Data"}
                    sx={{ ...TypographyStyle('17px', '700') }}
                />

                <CustomTypography
                    text={"Backing up exports all your data, such as estimates, invoices, credit notes, etc., from Finycs onto a .zip file. After the backup is complete, you can view the downloaded file in the Downloads Section."}
                    sx={TypographyStyle('15px', '550', '#7A7A7A')}
                />

                <Box sx={{ p: 2.5, maxWidth: 500 }}>
                    <Grid item xs={15} sm={6} sx={{ mb: 2 }}>
                        <DownloadRadioButtons
                            timePeriod={timePeriod}
                            setTimePeriod={setTimePeriod}
                            allButtonLabel={"Backup All Data"}
                        />
                        {timePeriod === "specificTimePeriod" &&
                            <CustomDateRangePicker onDateRangeChange={onDateRangeChange} />
                        }
                    </Grid>
                    <CustomButton
                        variant="contained"
                        onClick={() => backupData(true)}
                        btnLabel='Backup Your Data'
                        sx={{ textTransform: 'none' }}
                    />
                </Box>
            </CustomContainer>
        </Box>
    )
}

export default DataBackup;

const TypographyStyle = (fontSize, fontWeight, color = '#000000') => ({
    mb: 1,
    fontSize,
    fontWeight,
    color,
    fontFamily: 'Noto Sans',
    lineHeight: '18px',
    marginBottom: '0px',
    p: 2.5,
});
