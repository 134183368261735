import React from 'react';
import { apiAction } from '../../../api/api';
import { useNavigate } from 'react-router-dom';
import * as Actions from '../../../state/Actions';
import { getBusinessInfo } from '../../../config/cookiesInfo';

import Input from '../../common/Input';
import DateRange from '../../common/DateRange';
import CustomTitle from '../../common/CustomTitle';
import CustomButton from '../../custom/button/CustomButton';
import { LabelWithAsteriskMark } from '../../common/CommonLabel';

import {
    CustomContainer,
    CustomTitleContainer,
    CustomButtonContainer,
} from '../../custom/container/CustomContainer';

import {
    raise_ticket,
    category_list,
} from '../../../api/urls';

import {
    setLoader,
    isFormValid,
    stateChangeManager,
} from '../../../utils/Utils';

import {
    Box,
    Grid,
} from '@mui/material';
const Dropdown = DateRange;
const initialData = {
    subject: null,
    category_id: null,
    description: null,
}
const Support = () => {
    const dispatch = Actions.getDispatch(React.useContext);

    const [data, setData] = React.useState({ ...initialData });
    const [isFormSubmitted, setFormSubmitted] = React.useState(false);
    const [categoryData, setCategoryData] = React.useState([]);

    const updateData = React.useCallback((newData) => {
        setData(prevData => {
            return { ...prevData, ...newData };
        });
    }, []);

    const onSave = async () => {
        setFormSubmitted(true);
        let validation_data = [
            { key: "category_id", message: 'Please Select the category' },
            { key: "subject", message: 'Please enter the subject' },
            { key: "description", message: 'Please enter the description' },
        ]
        const { isValid, message } = isFormValid(data, validation_data);

        if (isValid) {
            setLoader(dispatch, Actions, true);

            let res = await apiAction({
                method: 'post',
                url: raise_ticket(),
                data: { ...data, business_id: getBusinessInfo()?.id },

            })
            if (res?.success) {
                setFormSubmitted(false);
                setData({ ...initialData });
                setLoader(dispatch, Actions, false);
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                setLoader(dispatch, Actions, false);
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    }

    const fetchCategoryList = async () => {
        let res = await apiAction({
            url: category_list(),
        })
        if (res?.success) {
            setCategoryData(res.result)
        }
    }

    React.useEffect(() => {
        fetchCategoryList();
    }, [])

    return (
        <div>
            <CustomTitleContainer>
                <CustomTitle title={'Support'} />
            </CustomTitleContainer>

            <CustomContainer sx={{maxHeight:{xs:'calc(100vh - 235px)',sm:'100%'}}}>
                <Box style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '48px' }}>
                    <FormElement
                        data={data}
                        updateData={updateData}
                        categoryData={categoryData}
                        isFormSubmitted={isFormSubmitted}
                    />
                </Box>
            </CustomContainer>

            <CustomButtonContainer>
                <Buttons
                    onSave={onSave}
                />
            </CustomButtonContainer>

        </div>
    )
}

export default Support;

const Buttons = ({ onSave }) => {
    return (
        <Box component="form" noValidate sx={{ textAlign: 'end' }}>
            <CustomButton id={'submit_btn'} dataTestId={'submit_btn'} variant='contained' btnLabel={'Submit'} onClick={onSave} />
        </Box>
    )
}

const FormElement = ({ data, updateData, categoryData, isFormSubmitted }) => {
    const { subject, category_id, description } = data || {};

    return (
        <Box>
            <Grid container>
                <Grid item xs={12} sm={6} container spacing={3}>
                    <Grid item xs={12}>
                        <Dropdown
                            autoFocus={true}
                            optionLabel='category'
                            results={categoryData}
                            disableClearable={false}
                            id={'category_dropdown'}
                            placeholder='Select category'
                            dataTestId={'category_dropdown'}
                            validation={isFormSubmitted && !category_id}
                            label={<LabelWithAsteriskMark label={'Category'} />}
                            value={category_id ? categoryData.find(i => i.id === category_id) : null}
                            setValue={(event, selected) => { updateData({ category_id: selected?.id }) }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            id={'input_subject'}
                            dataTestId={'input_subject'}
                            validation={isFormSubmitted && !subject}
                            item={{
                                type: 'text',
                                fullWidth: true,
                                value: subject || '',
                                placeholder: 'Enter the Subject',
                                title: <LabelWithAsteriskMark label={'Subject'} />,
                                onChange: (event) => { updateData({ subject: event.target.value !== '' ? event.target.value : null }) }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            id={'input_description'}
                            dataTestId={'input_description'}
                            validation={isFormSubmitted && !description}
                            item={{
                                rows: 10,
                                type: 'text',
                                multiline: true,
                                fullWidth: true,
                                value: description || '',
                                placeholder: 'Enter the description',
                                title: <LabelWithAsteriskMark label={'Description'} />,
                                onChange: (event) => { updateData({ description: event.target.value !== '' ? event.target.value : null }) }
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6} />

            </Grid>
        </Box>
    )
}